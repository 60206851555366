import React, { useEffect } from "react";
import SubNavbar from "../components/business-partner/utils/dashboard-payments-accounts-navbar/SubNavbar";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initializeBusinessAuth } from "../store/slices/businessAuthSlice";
import { readCookie } from "../utils/cookie";

const BusinessAccounts = () => {

    const businessState = useSelector((state) => state.bussinessAuth);
    const navigate = useNavigate();
    const dispatch  = useDispatch()
    useEffect(() => {
      if (!businessState || !businessState.signedIn ) {
        if(readCookie("business_auth_token") && readCookie("businessUserDetails") ){
           dispatch(initializeBusinessAuth());
        }
        else{
          navigate("/mvpdemo/for-bussiness/login");
        }
        
      }
    }, []);
  

  return (
    <>
      <SubNavbar />
      <Outlet />
    </>
  );
};

export default BusinessAccounts;
