import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { showAuthComponent } from "../../store/slices/authSlice";

export default function GetOfferButton({ setIsShuffleOpen }) {
  const token = useSelector((state) => state.auth.accessToken);
  const dispatch = useDispatch();
  const handleGetOffer = function () {
    if (!token) {
      dispatch(showAuthComponent());
      return;
    }
    
    setIsShuffleOpen(true);
  };

  const currentOutlet = useSelector((state) => state.outlets.currentOutlet);
  return (
    <>
      {currentOutlet.DISCOUNTS && currentOutlet.DISCOUNTS.length !== 0 && (
        <div className="fixed bottom-5 w-full text-center tracking-wider text-white opacity-100">
          <button
            className="shadow-l h-10 w-2/3 rounded-full bg-cyan-500 font-bold lg:w-1/3 lg:text-xl"
            onClick={handleGetOffer}
          >
            {`Get Extra ${currentOutlet?.DISCOUNTS[0].DISCOUNT_PERCENT}% Off`}
          </button>
        </div>
      )}
    </>
  );
}
