import { X } from "lucide-react";
import React from "react";
import { ChevronLeft } from "lucide-react";
import { MoveRight } from "lucide-react";
import Card from "./Card";
import CardCongragulationsUnderline from "../../assets/card-congragulations-underline.svg"
import { useNavigate } from "react-router-dom";

const AfterShuffle = ({ setIsShuffleOpen, selectedDiscount,outletID }) => {
  const navigate = useNavigate()
  return (
    <>
      <div className="relative hidden h-4/5 w-3/5 flex-col rounded-xl bg-white md:visible md:flex justify-between">
        <div className="flex items-center justify-center">
          <button
            onClick={() => setIsShuffleOpen(false)}
            className="absolute right-0 top-0 mr-2 mt-2"
          >
            <X />
          </button>
          <h1 className="my-10 flex flex-col items-center text-3xl font-bold leading-10 tracking-wide">
            {" "}
            <div className="flex flex-col items-center">Congratulations<img src={CardCongragulationsUnderline} alt="underline" className="w-36" /> </div> you have saved...
          </h1>
        </div>

 
        <div className=" w-1/4 h-3/6 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Card discount={selectedDiscount} className={"border-8"} />
        </div>


        <div className="flex h-full items-end justify-center">
          <button className="mb-10 flex gap-2 rounded-lg bg-cyan-500 px-10 py-2 font-bold tracking-wider text-white " onClick={()=>navigate(`/mvpdemo/enter-amount/${outletID}`)}>
            Pay Bill <MoveRight />{" "}
          </button>
        </div>
      </div>

      {/* Mobile View */}
      <div className="flex h-full w-full flex-col justify-between gap-5 bg-white p-2 md:hidden relative">
        <div className="flex items-center gap-2 p-2 shadow-md">
          <button onClick={() => setIsShuffleOpen(false)}>
            {" "}
            <ChevronLeft />
          </button>
          <span>SHUFFLE CARDS</span>
        </div>

        <h1 className="flex flex-col items-center text-2xl font-bold tracking-wide">
          {" "}
          <div className="flex flex-col  items-center">Congratulations <img src={CardCongragulationsUnderline} className="w-28" alt="underline"></img>  </div> you have saved...
        </h1>

        <div className=" w-3/5 h-3/6 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Card discount={selectedDiscount} className={"border-8"} />
        </div>

        <div className="flex h-full items-end justify-center">
          <button className="mb-10 flex gap-2 rounded-lg bg-cyan-500 px-10 py-2 font-bold tracking-wider text-white" onClick={()=>navigate(`/mvpdemo/enter-amount/${outletID}`)}>
            Pay Bill <MoveRight />{" "}
          </button>
        </div>
      </div>
    </>
  );
};

export default AfterShuffle;
