import { createListenerMiddleware } from "@reduxjs/toolkit";
import {
  getCardsDataByCoords,
  getCardsDataByLandmark,
  resetOffset,
} from "../slices/outletsSlice";

const listenerMiddleware = createListenerMiddleware();

// Add listener for getCardsDataByCoords
listenerMiddleware.startListening({
  actionCreator: getCardsDataByCoords.pending,
  effect: async (action, listenerApi) => {
    if (action.meta?.arg?.changedCategory) {
      listenerApi.dispatch(resetOffset());
    }
    await listenerApi.delay(0); // Ensure resetOffset is processed
  },
});

// Add listener for getCardsDataByLandmark
listenerMiddleware.startListening({
  actionCreator: getCardsDataByLandmark.pending,
  effect: async (action, listenerApi) => {

    if (action.meta?.arg?.changedCategory) {
      listenerApi.dispatch(resetOffset());
    }
    await listenerApi.delay(0); // Ensure resetOffset is processed
  },
});

export default listenerMiddleware;
