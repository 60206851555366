import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { X, Copy, Camera, ImagePlus, ChevronLeft } from "lucide-react";
import EditProfileForm from "./EditProfileForm";
import { editProfile } from "../../store/apis/regular-user-apis";
import { updateUserData, updateUserProfile } from "../../store/slices/authSlice";
import EditProfileBackground from "../../assets/edit-profile-background.svg";
import Avatar from "../../assets/cartoon-avatar.png";

const EditProfile = ({ setShowEditProfile }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.userData);
  const [selectImage, setSelectImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState( userData?.PROFILE_PIC_URL ?? Avatar);
 

  useEffect(() => {
    if (userData?.PROFILE_PIC_URL) {
      // Add a timestamp to bust cache
      const url = `${userData.PROFILE_PIC_URL}?t=${new Date().getTime()}`;
      setPreviewUrl(url);
    } else {
      setPreviewUrl(Avatar);
    }
  }, [userData?.PROFILE_PIC_URL]);
  
  // Form state moved to parent
  const [formData, setFormData] = useState({
    name: "",
    mobileNumber: "",
    email: "",
    dob: "",
    gender: ""
  });

  // Initialize form data from userData
  useEffect(() => {
    console.log("user" , userData)
    setFormData({
      name: userData.NAME || "",
      mobileNumber: userData.PHONE_NUMBER || "",
      email: userData.EMAIL || "",
      dob: userData.DOB || "",
      gender: userData.GENDER || ""
    });
  }, [userData]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  // Handle image selection
  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const imageUrl = URL.createObjectURL(file);
      setPreviewUrl(imageUrl);
      setSelectImage(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const submitData = new FormData();
    Object.keys(formData).forEach(key => {
      if (formData[key]) { // Only append if value exists
        submitData.append(key, formData[key]);
      }
    });
    
    if (selectedImage) {
      submitData.append('profilePic', selectedImage);
    }

    try {
      const resultAction = dispatch(updateUserProfile(submitData));
      if (updateUserProfile.fulfilled.match(resultAction)) {
        // Success! Close the edit profile modal
        setShowEditProfile(false);
      }
    } catch (err) {
      console.error('Failed to update profile:', err);
    }finally{
      setShowEditProfile(false);
    }
  };

  return (
    <div className="fixed left-0 top-0 z-50 flex h-screen w-screen justify-end overflow-y-auto bg-black/50">
      <div className="h-screen md:w-2/6 w-screen overflow-y-auto bg-white pt-5 md:pt-0">
        <div
          className="relative flex h-[38%] w-full flex-col justify-end gap-5 bg-cover bg-center bg-no-repeat md:px-8 px-5 pb-10"
          style={{ backgroundImage: `url(${EditProfileBackground})` }}
        >
          <X
            className="hidden md:flex absolute left-8 top-8 size-5 cursor-pointer"
            onClick={() => setShowEditProfile(false)}
          />
          <ChevronLeft 
            className="md:hidden absolute left-4 top-0 size-6 cursor-pointer" 
            onClick={() => setShowEditProfile(false)}
          />

          <div className="flex justify-between">
            <div className="flex flex-col">
              <span className="text-[#023047]">Hello,</span>
              <span className="text-2xl font-bold text-[#023047]">
                {userData.NAME}
              </span>
            </div>

            <div className="flex items-center gap-2 self-end font-semibold">
              <Copy className="size-4 cursor-pointer font-semibold" />
              <span className="text-sm">ID- {userData.USER_ID}</span>
            </div>
          </div>

          <div className="flex w-full justify-start md:gap-2">
            <div className="relative md:h-28 md:w-28 h-24 w-24 rounded-full bg-white p-2">
              <div className="h-full w-full rounded-full  overflow-hidden">
                {previewUrl && (
                  <img 
                    src={previewUrl} 
                    alt="Profile" 
                    className="h-full w-full object-cover"
                  />
                )}
              </div>

              <div
                className={`absolute bottom-1 right-2 rounded-full p-1 ${
                  selectImage ? "bg-[#29B3D2]" : "bg-[#FCFCFC]"
                } duration-100`}
              >
                {selectImage ? (
                  <X
                    className="rounded-full bg-[#FCFCFC] p-[4px] text-[#29B3D2]"
                    onClick={() => setSelectImage(false)}
                  />
                ) : (
                  <Camera
                    className="rounded-full bg-[#29B3D2] p-[4px] text-[#FCFCFC]"
                    onClick={() => setSelectImage(true)}
                  />
                )}
              </div>
            </div>
            
            {selectImage && (
              <div className="mb-2 flex h-16 md:w-3/4 w-4/5 justify-between gap-1 self-end rounded-r-2xl bg-gradient-to-r from-transparent via-slate-100 to-[#FCFCFC] md:px-2 pr-[3px]">
                <label className="flex items-center md:gap-2 gap-1 cursor-pointer">
                  <ImagePlus className="md:size-12 size-10 rounded-full border bg-white md:p-3 p-2 text-zinc-600" />
                  <div className="flex flex-col text-zinc-400">
                    <span className="md:text-[13px] text-[11px]">Browse Your Image</span>
                    <span className="md:text-[10px] text-[9px]">
                      JPEG or PNG only <span>.</span> 5 MB max
                    </span>
                  </div>
                  <input 
                    type="file" 
                    accept="image/*"
                    onChange={handleImageSelect}
                    className="hidden"
                  />
                </label>

                <div className="flex items-center md:gap-2 gap-1 text-sm">
                  <Camera className="md:size-12 size-10 rounded-full border bg-white md:p-3 p-2 text-zinc-600" />
                  <span className="md:text-[13px] text-[11px] text-zinc-400">
                    Take Your Photo
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>

        <EditProfileForm 
          formData={formData}
          setFormData={setFormData}
          handleSubmit={handleSubmit}
          setShowEditProfile={setShowEditProfile}
        />
      </div>
    </div>
  );
};

export default EditProfile;
