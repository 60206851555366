import React, { useEffect } from "react";
import LandingPageCard from "./landing-page-card";
import CardBG from "../../assets/landing-page-card-background.svg";
import { useSelector } from "react-redux";
import { ChevronDown } from "lucide-react";
function NearbyPlaces({ fetchMoreOutlets }) {
  const { cardsData, currentCount, totalCount } = useSelector(
    (state) => state.outlets,
  );
  return (
    <div className="">
      <h3 className="mb-6 text-left text-xl font-semibold text-[#303030]">
        Nearby Places
      </h3>

      <div className="relative z-10 grid grid-cols-1 gap-6 py-4 md:grid-cols-2 md:gap-8 lg:grid-cols-3 lg:gap-9 xl:grid-cols-4 xl:gap-10">
        {cardsData.map((card, index) => {
          return (
            <LandingPageCard
              key={index}
              outletId={card.OUTLET_ID}
              mainOffer={card.DISCOUNTS}
              name={card.NAME || "BLR Brewing Co."}
              distance={Math.round(card.currDist * 10) / 10 || 2.2}
              address={
                `${card.ADDRESS_LINE_ONE} ${card.ADDRESS_LINE_TWO}` ||
                "4th Floor, Neeladri Rd, Electronic City"
              }
              numberOffer={card.ADVERTISEMENTS || 0}
              bgImg={card.PROFILE_PIC_URL || CardBG}
              mapUrl={card.MAP_URL}
            />
          );
        })}
      </div>
      <div>
        {currentCount < totalCount && (
          <div className="flex items-center justify-center gap-[40px] md:mt-[5%]">
            <img
              src="/boost-traffic-right.svg"
              alt="left-gradient"
              className="h-[1px] w-[92px] bg-gradient-to-l from-[#7C7C7C] to-[#FCFCFC] md:w-[400px]"
            />
            <div
              className="flex cursor-pointer items-center justify-center"
              onClick={() => {
                fetchMoreOutlets(false);
              }}
            >
              <h4 className="font-outfit mr-[10px] flex flex-row text-[14px] leading-tight text-customTeal md:text-[16px]">
                see more
              </h4>
              <ChevronDown className="text-customTeal" />
            </div>
            <img
              src="/boost-traffic-left.svg"
              alt="right-gradient"
              className="h-[1px] w-[92px] bg-gradient-to-r from-[#7C7C7C] to-[#FCFCFC] md:w-[400px]"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default NearbyPlaces;
