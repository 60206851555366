import { Modal } from "@mui/material";
import React from "react";
import LogoutLogo from "./.././../../../assets/logout-logo.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutBusinessUser } from "../../../../store/slices/businessAuthSlice";

const LogoutToast = ({ onClose, open }) => {
  // Added open prop
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = function () {
    dispatch(logoutBusinessUser());
    navigate("/mvpdemo/landing-page");
  };

  return (
    <Modal
      open={open} // Added open prop
      onClose={onClose}
      aria-labelledby="logout-modal"
      aria-describedby="logout-confirmation-modal"
    >
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform outline-none">
        <div className="flex h-[250px] w-[500px] flex-col items-center justify-center rounded-xl bg-[#FCFCFC] p-8">
          <div>
            <div className="flex items-start justify-center">
              <img src={LogoutLogo} alt="logout-logo" className="h-12 w-12" />
            </div>
            <div className="flex flex-col justify-center gap-10">
              <div className="flex flex-col items-center">
                <h1 className="font-outfit text-lg font-bold text-[#303030]">
                  Leaving Already?
                </h1>
                <p className="text-sm text-[#7C7C7C]">
                  Logging out may lead to a slight increase in the operational
                  costs.
                </p>
              </div>
              <div className="flex flex-row justify-center gap-5">
                <button
                  className="w-32 rounded bg-[#F44242] py-2 font-bold text-[#FCFCFC]"
                  onClick={() => onClose()} // Fixed onClick handler
                >
                  No
                </button>
                <button
                  className="w-32 rounded border border-[#EFEFEF] font-bold text-[#7C7C7C] hover:bg-[#f3f3f3]"
                  onClick={handleLogout}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LogoutToast;
