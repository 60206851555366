import { ChevronLeft, Search } from "lucide-react";
import React, { useEffect, useState } from "react";
import RedeemedCard from "../components/redeem-history/RedeemedCard";
import TotalAmountSaved from "./../assets/total-amount-saved.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getRedeemedHistory } from "../store/apis/regular-user-apis";
import { readCookie } from "../utils/cookie";
import { initializeAuth } from "../store/slices/authSlice";

// DATE_CREATED: "12-09-2024";
// DISCOUNT_AMOUNT: "";
// DISCOUNT_PERCENT: "40";
// ID: "0";
// MAC_ID: 12345;
// TOTAL_AMOUNT: "100";
// USER_ID: "3636153";
// outlet: DISTRICT: "whitefield";
// NAME: "PRODT-DUMMY-3";
// OUTLET_ID: 8569400;
// PROFILE_PIC_URL: "https://dummy-outlet-one.s3.ap-south-1.amazonaws.com/image.png";
// STATE: "Karnataka";

const RedeemedHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const userData = useSelector((state) => state.auth.userData);
  const [redeemedCoupons, setRedeemedCoupons] = useState([]);
  const [summary , setSummary] = useState({})

  const fetchRedeemedHistory = async () => {
    const data = await getRedeemedHistory();
    setRedeemedCoupons(data.history);
    setSummary(data.summary)
  };

  useEffect(() => {
    // Check auth on mount and when userData changes
    if (!userData || !userData.NAME) {
      if(readCookie("authToken") && readCookie("userDetails")){
        dispatch(initializeAuth())
      }
      else  navigate("/mvpdemo/landing-page", { replace: true });
    }
    document.documentElement.scrollTop = 0;
  }, [userData, navigate]);

  useEffect(() => {
    fetchRedeemedHistory();
  }, []);

  // Don't render anything if not authenticated
  if (!userData || !userData.NAME) {
    navigate("/mvpdemo/landing-page");
    return null;
  }

  return (
    <div className="mx-auto flex flex-col gap-6 md:w-screen md:gap-10">
      <div className="flex flex-col gap-3 px-3 py-4 shadow-lg md:w-full md:flex-row md:items-center md:justify-between md:px-14">
        <div
          className="flex flex-row items-center gap-2 md:gap-4"
          onClick={() => navigate("/mvpdemo/profile")}
        >
          <div className="h-6 w-6 text-[#303030]">
            <ChevronLeft className="cursor-pointer" />
          </div>
          <h1 className="cursor-pointer text-base font-bold text-[#303030] md:text-xl">
            REDEEMED HISTORY
          </h1>
        </div>

        <div className="flex w-full flex-row items-center gap-2 rounded-md border border-gray-200 px-3 py-2 md:w-96 md:gap-4 md:px-5 md:py-3">
          <div className="h-6 w-6 text-[#7C7C7C]">
            <Search />
          </div>
          <input
            type="text"
            className="w-full outline-none"
            placeholder="Search your coupons"
          />
        </div>
      </div>

      <div className="flex w-full flex-row px-3 pb-10 md:gap-2 md:px-20  ">
        <div className="flex md:w-[80%] w-full flex-col gap-14 ">
          <div className="flex w-full flex-col items-center justify-center gap-2">
            <h2 className="w-full text-xl font-bold tracking-wide text-[#303030] ">
              Today
            </h2>
            <div className="flex w-full flex-col items-center md:items-start justify-start gap-4 mx-auto ">
              {redeemedCoupons?.map((coupon) => (
                <RedeemedCard
                  key={coupon.MAC_ID}
                  restaurantImage={coupon.PROFILE_PIC_URL}
                  restaurantName={coupon.NAME}
                  restaurantDistrict={coupon.DISTRICT}
                  restaurantState={coupon.STATE}
                  discount={coupon.DISCOUNT_PERCENT}
                  reedemDate={coupon.date}
                  amountSaved={coupon.DISCOUNT_AMOUNT}
                  transactionId = {coupon.ID}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="hidden flex-col gap-6 md:flex  ">
          <h2 className="text-xl font-bold text-[#303030]">SUMMARY</h2>
          <div className="flex flex-col gap-7">
            <div className="rounded-xl px-7 py-5 shadow-lg">
              <div className="flex flex-row items-center gap-5">
                <div className="flex h-20 w-20 items-center justify-center rounded-full">
                  <img
                    src={TotalAmountSaved}
                    alt="total"
                    className="h-full w-full object-cover"
                  />
                </div>
                <div>
                  <h2 className="font-bakbakOne text-base text-[#303030]">
                    Total Amount Saved
                  </h2>
                  <h1 className="text-2xl text-[#96B62C]">₹ {summary?.totalAmountSaved?.toFixed(2) ?? 0}</h1>
                </div>
              </div>
            </div>

            <div className="rounded-xl px-7 py-5 shadow-lg">
              <div className="flex flex-row items-center gap-5">
                <div className="ba-[#B4EDFF] flex h-20 w-20 flex-col items-center justify-center rounded-full bg-[#B4EDFF]">
                  <h1 className="font-bakbakOne text-2xl text-[#FC6D6D]">{summary?.totalRedeemedCards ?? 0}</h1>
                </div>
                <div>
                  <h1 className="font-bakbakOne text-lg text-[#303030]">
                    Coupons Redeemed
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedeemedHistory;
