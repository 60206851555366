import React from "react";
import ButtonJoinNow from "../button-join-now/button-join-now";

function HomePageCard({
  title,
  subtitle,
  handleClick,
  backgroundSvg,
  landingPage,
  showButton
}) {
  return (
    <div
      className="mt-[3%] flex h-[230px] w-[100%] flex-col justify-between rounded-[12px] bg-cover bg-center p-6  text-[#FFFFFF] lg:mt-0 lg:h-[280px] md:min-w-[400px] min-w-full  "
      style={{ backgroundImage: `url(${backgroundSvg})` }}
    > 
      <div className="w-[70%]">
        <h5 className="w-[97%] font-bakbakOne text-[1.2em] leading-tight lg:text-3xl ">
          {title}
        </h5>
        <p className="mt-2 w-[97%]  text-[12px] leading-[15px] lg:text-sm lg:leading-[20px] lg:tracking-tighter ">
          {subtitle}
        </p>
      </div>
      {showButton && <ButtonJoinNow
        title={landingPage ? "Know More" : "Join Now"}
        handleClick={handleClick}
        className="mt-4"
      />}
    </div>
  );
}

export default HomePageCard;
