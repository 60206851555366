import React from "react";
import LoginBgImage from "../assets/for-bussiness-login-background.png";
import LoginBussinessForm from "../components/for-business/login-bussiness-form/Login-bussiness-form";

const ForBussinessLoginForm = () => {
  return (
    <div
      className="h-screen w-screen bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${LoginBgImage})` }}
    >
      <LoginBussinessForm />
    </div>
  );
};

export default ForBussinessLoginForm;
