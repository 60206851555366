import React, { useEffect, useState } from "react";
import { ChevronLeft, Search } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ActiveCoupon from "../components/coupons/ActiveCoupon";
import { fetchCoupons, clearError } from "../store/slices/couponSlice";
import { initializeAuth } from "../store/slices/authSlice";
import { readCookie } from "../utils/cookie";

// Tab identifiers
const TABS = {
  ACTIVE: "active",
  EXPIRED: "expired",
};

// Coupon Header Component
const CouponHeader = ({ onBack, children }) => (
  <div className="flex flex-row items-center gap-2 md:gap-4">
    <ChevronLeft
      className="h-6 w-6 cursor-pointer text-[#303030]"
      onClick={onBack}
    />
    <h1 className="text-lg font-bold text-[#303030] md:text-xl">{children}</h1>
  </div>
);

// Search Bar Component
const SearchBar = () => (
  <div className="flex w-full flex-row items-center gap-2 rounded-md border border-gray-200 px-3 py-2 md:w-96 md:gap-4 md:px-5 md:py-3">
    <Search className="h-6 w-6 text-[#7C7C7C]" />
    <input
      type="text"
      className="w-full outline-none"
      placeholder="Search your coupons"
    />
  </div>
);

// Tab Button Component
const TabButton = ({ isSelected, onClick, label, count }) => (
  <div
    className={`flex cursor-pointer flex-row items-center justify-between gap-10 pb-4 text-sm font-medium md:gap-40 md:text-xl ${
      isSelected ? "border-b-4 border-[#29B3D2] text-[#29B3D2]" : "text-[#BDBDBD]"
    }`}
    onClick={onClick}
  >
    <h2>{label}</h2>
    <h2>{count}</h2>
  </div>
);

// Loading State Component
const LoadingState = () => (
  <div className="flex h-64 items-center justify-center">
    <div className="h-12 w-12 animate-spin rounded-full border-b-2 border-[#29B3D2]"></div>
  </div>
);

// Error State Component
const ErrorState = ({ message, onRetry }) => (
  <div className="flex h-64 flex-col items-center justify-center gap-4">
    <p className="text-red-500">{message}</p>
    <button
      onClick={onRetry}
      className="rounded-md bg-[#29B3D2] px-4 py-2 text-white hover:bg-[#1a8ba3]"
    >
      Retry
    </button>
  </div>
);

// Coupons List Component
const CouponsList = ({ coupons, isActive }) => (
  <div className="my-5 flex w-full flex-row flex-wrap justify-center gap-x-4 gap-y-4 p-4 md:mx-14 md:my-10 md:justify-normal md:gap-x-5 md:gap-y-10">
    {coupons?.map((coupon, index) => (
      <ActiveCoupon key={index} coupon={coupon} isActive={isActive} />
    ))}
  </div>
);

// Main Coupons Component
const Coupons = () => {
  const [selectedTab, setSelectedTab] = useState(TABS.ACTIVE);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.auth.userData);

  // Redux selectors for coupons data
  const couponsData = useSelector((state) => state.coupons.coupons);
  const isLoading = useSelector((state) => state.coupons.isLoading);
  const error = useSelector((state) => state.coupons.error);

  // Fetch coupons on component mount
  useEffect(() => {
    dispatch(fetchCoupons());
  }, [dispatch]);

  // Redirect to landing page if user is not authenticated
  useEffect(() => {
    // Check auth on mount and when userData changes
    if (!userData || !userData.NAME) {
      if(readCookie("authToken") && readCookie("userDetails")){
        dispatch(initializeAuth())
      }
      else  navigate("/mvpdemo/landing-page", { replace: true });
    }
    document.documentElement.scrollTop = 0;
  }, [userData, navigate]);

  // Handle retry for fetching coupons
  const handleRetry = () => {
    dispatch(clearError());
    dispatch(fetchCoupons());
  };

  if (!userData?.NAME) {
    return null; // Render nothing if user data is unavailable
  }

  return (
    <div className="mx-auto">
      {/* Header Section */}
      <div className="flex w-screen flex-col gap-3 px-3 py-4 shadow-lg md:flex-row md:items-center md:justify-between md:px-14 ">
        <CouponHeader onBack={() => navigate("/mvpdemo/profile")}>
          YOUR COUPONS
        </CouponHeader>
        <SearchBar />
      </div>

      {/* Tabs Section */}
      <div className="mt-6 flex flex-col gap-4 px-3 md:mx-14">
        <div className="flex flex-row items-center justify-between gap-5 pl-4 md:justify-start md:gap-14">
          <TabButton
            isSelected={selectedTab === TABS.ACTIVE}
            onClick={() => setSelectedTab(TABS.ACTIVE)}
            label="Active Coupons"
            count={couponsData?.summary?.activeCoupons || 0}
          />
          <TabButton
            isSelected={selectedTab === TABS.EXPIRED}
            onClick={() => setSelectedTab(TABS.EXPIRED)}
            label="Expired Coupons"
            count={couponsData?.summary?.expiredCoupons || 0}
          />
        </div>
      </div>

      <hr className="h-1 w-full text-[#FCFCFC] shadow-xl" />

      {/* Content Section */}
      {isLoading ? (
        <LoadingState />
      ) : error ? (
        <ErrorState message={error} onRetry={handleRetry} />
      ) : (
        <CouponsList
          coupons={
            selectedTab === TABS.ACTIVE
              ? couponsData?.active
              : couponsData?.expired
          }
          isActive={selectedTab === TABS.ACTIVE}
        />
      )}
    </div>
  );
};

export default Coupons;
