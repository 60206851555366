import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
// UI Components
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import NewNavbar from "../../components/navbar/new-navbar";
import BussinessNavbar from "../../components/navbar/Bussiness-Navbar";

// Page Components
import HomePage from "../Home";
import BecomePromoterPage from "../BecomePromoter";
import ForBusiness from "../ForBusiness";
import ForBusinessFormWithHooks from "../../components/for-business/register-business-form-with-hooks/for-business-form-wrapper";
import LandingPage from "../LandingPage";
import OutletDetail from "../OutletDetail";
import Profile from "../Profile";
import Coupons from "../Coupons";
import ForBussinessLoginForm from "../ForBussinessLoginForm";
import DemoPayment from "../demo-payment";
import RedeemedHistory from "../RedeemedHistory";
import AboutUs from "../AboutUs";
import BusinessAccounts from "../Business-accounts";

// Business Dashboard Components
import BusinessDashboardCoupons from "../../components/business-partner/dashboard/coupons/Coupons";
import LiveAds from "../../components/business-partner/dashboard/live-ads/LiveAds";
import Revenue from "../../components/business-partner/dashboard/revenue/Revenue";
import SettlementReport from "../../components/business-partner/dashboard/settlement-report/SettlementReport";
import Tickets from "../../components/business-partner/dashboard/tickets/Tickets";

// Business Promotion Components
import BussinessPromotionOrganizer from "../../pages/Bussiness-promotion-organizer";
import AdLibrary from "../../components/business-partner/promotion-organizer/Ad-library/AdLibrary";
import Highlights from "../../components/business-partner/promotion-organizer/HIghlights/Highlights";
import RegularOffers from "../../components/business-partner/promotion-organizer/Regular-Offers/RegularOffers";
import DailyOffers from "../../components/business-partner/promotion-organizer/Daily-Offers/DailyOffers";
import EventOffer from "../../components/business-partner/promotion-organizer/Event-Offers/EventOffer";

// Business Account Components
import BusinessProfile from "../../components/business-partner/accounts/profile/Profile";
import UserManagement from "../../components/business-partner/accounts/user-management/UserManagement";
import Gstin from "../../components/business-partner/accounts/gstin/Gstin";

// Payment Related Components
import PaymentConfirmation from "../../components/payment/payment-confirmation";
import PaymentFailure from "../../components/payment/payment-failure";
import EnterAmountPopup from "../../components/payment/EnterAmountPopup";
import PaymentBreakdown from "../../components/payment/Payment-Breakdown";

// Redux and Utils
import { useDispatch, useSelector } from "react-redux";
import { initializeAuth } from "../../store/slices/authSlice";
import { initializeBusinessAuth } from "../../store/slices/businessAuthSlice";
import Cookies from "js-cookie";
import ProfileSettings from "../Profile-Settings";
import ProfileContactSupport from "../Profile-Contact-Support";
import AllClients from "../AllClients";
import {
  fetchUserSettings,
  initializePermissions,
} from "../../store/slices/userSettingSlice";
import PrivacyPolicy from "../PrivacyPolicy";
import TermsAndConditions from "../TermsAndConditions";
import BusinessDashboard from "../Business-dashboard";
import { readCookie } from "../../utils/cookie";

/**
 * Default Layout Component
 * Includes main navbar, footer and space for outlet content
 */
const DefaultLayout = () => {
  return (
    <>
      <div className="fixed top-0 z-50 w-full bg-[#fff]">
        <Navbar />
      </div>
      <div className="h-[44px] w-full"></div>
      <Outlet />
      <Footer />
    </>
  );
};

/**
 * MVP Demo Layout Component
 * Similar to default layout but uses new navbar and no footer
 */
const MVPDemoLayout = () => {
  return (
    <>
      <div className="fixed top-0 z-50 w-full bg-[#fff]">
        <NewNavbar />
      </div>
      <div className="h-[44px] w-full"></div>
      <Outlet />
    </>
  );
};

/**
 * Business Layout Component
 * Specialized layout for business-related pages with business navbar
 */
const MVPDemoBussinessLayout = () => {
  return (
    <>
      <div className="fixed top-0 z-50 w-full bg-[#fff]">
        <BussinessNavbar />
      </div>
      <div>
        <Outlet />
      </div>
    </>
  );
};

/**
 * Main Application Routes Component
 * Handles all routing logic and authentication state initialization
 */
function NewAppRoutes() {
  const dispatch = useDispatch();
  const authToken = Cookies.get("authToken");
  const businessAuthToken = Cookies.get("business_auth_token")
  const userSetting = useSelector((state) => state.userSettings);
  // Check if running in Capacitor
  const isCapacitorApp = window.Capacitor?.isNative;

  // Initialize customer authentication state
  useEffect(() => {
    const authToken = Cookies.get("authToken");
    const userDetails = Cookies.get("userDetails");

    if (authToken && userDetails) {
      dispatch(initializeAuth());
    }
  }, [authToken]); // eslint-disable-line react-hooks/exhaustive-deps

  // Initialize business authentication state
  useEffect(() => {
    console.log("initializing business auth")
    const authToken = Cookies.get("business_auth_token");
    const userDetails = Cookies.get("businessUserDetails");
    if (authToken && userDetails) {
      dispatch(initializeBusinessAuth());
    }
  }, [businessAuthToken]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(initializePermissions());
  
    // Check for authToken and fetch user settings
    if (Cookies.get("authToken") && !userSetting.USER_ID) {
      dispatch(fetchUserSettings());
    }
  
    // Check geolocation permission and handle cookie
    navigator.permissions
      .query({ name: "geolocation" })
      .then((status) => {
        if (status.state !== "granted") {
          console.log("Geolocation permission not granted. Removing location cookie.");
          if(readCookie("location") && !JSON.parse(readCookie("location")).isSelectedLocation){
            Cookies.remove("location");
          }
          
        }
      })
      .catch((error) => {
        console.error("Error checking geolocation permission:", error);
      });
  
    // Handle permission changes dynamically
    const handlePermissionChange = (permissionName) => {
      console.log(`Permission for ${permissionName} changed`);
      dispatch(initializePermissions());
    };
  
    const permissionsToWatch = ["geolocation", "camera"];
    const permissionStatuses = [];
  
    const watchPermissions = async () => {
      for (const permissionName of permissionsToWatch) {
        try {
          const status = await navigator.permissions.query({ name: permissionName });
          permissionStatuses.push(status);
          status.onchange = () => handlePermissionChange(permissionName);
        } catch (error) {
          console.error(`Permission query failed for ${permissionName}:`, error);
        }
      }
    };
  
    watchPermissions();
  
    return () => {
      // Clean up event listeners
      permissionStatuses.forEach((status) => {
        status.onchange = null;
      });
    };
  }, [dispatch, userSetting.USER_ID]);
  
  

  return (
    <BrowserRouter>
      <Routes>
        {/* Main Website Routes with Default Layout */}
        <Route element={<DefaultLayout />}>
          {/* For mobile app - start at /mvpdemo/landing-page */}
          {isCapacitorApp && (
            <Route path="/" element={<Navigate to="/mvpdemo/landing-page" />} />
          )}
          {/* For website - start at root */}
          {!isCapacitorApp && <Route path="/" element={<HomePage />} />}

          <Route path="/become-promoter" element={<BecomePromoterPage />} />
          <Route path="/for-business" element={<ForBusiness />} />
          <Route
            path="/for-business-form"
            element={<ForBusinessFormWithHooks />}
          />
        </Route>

        {/* MVP Demo Routes with Custom Layout */}
        <Route element={<MVPDemoLayout />}>
          <Route
            exact
            path="/mvpdemo/outlet-details/:outletId"
            element={<OutletDetail />}
          />
          <Route path="/mvpdemo">
            {/* Customer-facing routes */}
            <Route
              path="landing-page"
              element={
                <>
                  <LandingPage />
                  <Footer />
                </>
              }
            />
            <Route path="/mvpdemo/become-promoter" element={<BecomePromoterPage />} />
            <Route path="payment-demo" element={<DemoPayment />} />
            <Route path="profile" element={<Profile />} />
            <Route path="outlet-details/:outletId" element={<OutletDetail />} />
            <Route
              path="about-us"
              element={
                <>
                  <AboutUs />
                  <Footer />
                </>
              }
            />
          </Route>
        </Route>

        {/* Standalone MVP Demo Routes */}
        <Route path="/mvpdemo/redeemed-history" element={<RedeemedHistory />} />
        <Route path="/mvpdemo/coupons" element={<Coupons />} />
        <Route path="/mvpdemo/settings" element={<ProfileSettings />} />
        <Route path="/mvpdemo/support" element={<ProfileContactSupport />} />

        {/* Business Section Routes with Business Layout */}
        <Route element={<MVPDemoBussinessLayout />}>
          <Route path="/mvpdemo">
            <Route path="for-bussiness">
              {/* Business Authentication */}
              <Route
                index
                element={
                  <>
                    <ForBusiness />
                    <Footer />{" "}
                  </>
                }
              />
              <Route path="login" element={<ForBussinessLoginForm />} />

              {/* Promotion Management Routes */}
              <Route
                path="promotion-organizer"
                element={<BussinessPromotionOrganizer />}
              >
                <Route path="ad-library" element={<AdLibrary />} />
                <Route path="highlights" element={<Highlights />} />
                <Route path="regular-offers" element={<RegularOffers />} />
                {/* <Route path="daily-offers" element={<DailyOffers />} /> */}
                {/* <Route path="event-offers" element={<EventOffer />} /> */}
              </Route>

              {/* Business Account Management Routes */}
              <Route path="account" element={<BusinessAccounts />}>
                <Route path="profile" element={<BusinessProfile />} />
                <Route path="user-management" element={<UserManagement />} />
                <Route path="gstin" element={<Gstin />} />
              </Route>

              {/* Business Dashboard Routes */}
              <Route path="dashboard" element={<BusinessDashboard />}>
                <Route path="live-ads" element={<LiveAds />} />
                <Route path="coupons" element={<BusinessDashboardCoupons />} />
                <Route path="revenue" element={<Revenue />} />
                <Route
                  path="settlement-report"
                  element={<SettlementReport />}
                />
                {/* <Route path="tickets" element={<Tickets />} /> */}
              </Route>
            </Route>
            <Route
              path="for-business-form"
              element={<ForBusinessFormWithHooks />}
            />
          </Route>
        </Route>

        {/* Payment Flow Routes */}
        <Route
          path="/mvpdemo/payment-success"
          element={<PaymentConfirmation />}
        />
        <Route path="/mvpdemo/payment-failure" element={<PaymentFailure />} />
        <Route
          path="/mvpdemo/enter-amount/:outletId"
          element={<EnterAmountPopup />}
        />
        <Route
          path="/mvpdemo/payment-breakdown/:outletId"
          element={<PaymentBreakdown />}
        />

        {/* Client Onboarding */}
        <Route path="/sales" element={<AllClients />} />

        {/* privacy policy and T&C */}
        <Route path="/privacy" element = {<><PrivacyPolicy/> <Footer/></>} />
        <Route path="/terms" element = {<><TermsAndConditions/><Footer/></>} />

      </Routes>
    </BrowserRouter>
  );
}

export default NewAppRoutes;
