import { useEffect, useRef, useState } from "react";
import {
  FormInput,
  FormSelect,
  FormCheckbox,
  SectionTitle,
} from "./FormComponents";
import { X } from "lucide-react";
import AddUser from "./AddUser";
import {
  addOutlet,
  editOutlet,
  getBusinessOwners,
} from "../../store/apis/sales-apis";
import { createDiscount, getOutletDetailsRequest } from "../../store/apis/outlets-apis";

const statusOptions = [
  { value: "trial", label: "Trial" },
  { value: "client", label: "Client" },
  { value: "lost", label: "Lost" },
  { value: "dummy", label: "Dummy" },
  { value: "clearence", label: "Clearence" },
];

const categoryOptions = [
  { value: "food & beverages", label: "Food & Beverages" },
  { value: "fashion & apparel", label: "Fashion & Apparel" },
  { value: "salon & spa", label: "Salon & Spa" },
  { value: "beauty", label: "Beauty" },
  { value: "home & garden", label: "Home & Garden" },
  { value: "electronics", label: "Electronics" },
  { value: "wellness & fitness", label: "Wellness & Fitness" },
  { value: "automobile", label: "Automobile" },
  { value: "entertainment", label: "Entertainment" },
  { value: "gifts & occasions", label: "Gifts & Occasions" },
  { value: "services", label: "Services" },
  { value: "nearby", label: "Nearby" },
];

const designationOptions = [
  { value: "manager", label: "Manager" },
  { value: "admin", label: "Admin" },
];

export default function NewClient({
  setNewClient,
  editClient,
  setEditClient,
  currentOutletId,
}) {
  const [addUser, setAddUser] = useState(false);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [currentMarker, setCurrentMarker] = useState(null);
  const [matchingManager, setMatchingManager] = useState([]);
  const [matchingAdmin, setMatchingAdmin] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const [formData, setFormData] = useState({
    clientName: "",
    status: "toBeActivated",
    startDate: "",
    category: "",
    discountButton: "",
    accountManager: "",
    contactName: "",
    phoneNo: "",
    emailId: "",
    designation: "",
    takeFeedback: false,
    location: "",
    address: "",
    state: "",
    area: "",
    city: "",
    country: "India",
    latitude: "",
    longitude: "",
    managers: [],
    admins: [],
  });

  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [placesService, setPlacesService] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "location" && value.length > 2) {
      fetchLocationSuggestions(value);
    } else if (name === "location" && value.length <= 2) {
      setSuggestions([]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      const formDataObject = {
        name: formData.clientName,
        email: formData.emailId,
        category: formData.category,
        addressLineOne: formData.address,
        addressLineTwo: formData.area,
        city: formData.city,
        district: formData.city,
        state: formData.state,
        pincode: "411041",
        phoneNumber: formData.phoneNo,
        openTime: "10:00 am",
        closeTime: "11:30 PM",
        gstin: "09AKJPPM2266DV",
        contactName : formData.contactName,
        accountManagerName :formData.accountManager ,
        contactDesignation :formData.designation ,
        country : formData.country,
        status : formData.status,
        category : formData.category,
        startDate : formData.startDate,
        boId: formData.managers? formData.managers[0] : "",
        latitude: formData.latitude,
        longitude: formData.longitude,
        rzpAcc: "acc_PDEAV6CvzDd05b",
      };

      const formDataInstance = new FormData();
      formData.managers.forEach((id) => formDataInstance.append("managerIds[]", id));
      formData.admins.forEach((id) => formDataInstance.append("adminIds[]", id));

      // Append all text data
      Object.entries(formDataObject).forEach(([key, value]) => {
        formDataInstance.append(key, value);
      });

      // Append the image file if it exists
      if (image) {
        formDataInstance.append("profilePic", image);
      }

      if (editClient && currentOutletId) {
      
        // add discount button
        const discount = await createDiscount(formData.discountButton , currentOutletId , "true" )
        //edit outlet
        const data = await editOutlet(currentOutletId, formDataInstance);

        console.log("outlet edited", data);
      } else {
        const data = await addOutlet(formDataInstance);
        console.log("outlet added", data);
      }
      setEditClient(false);
      setNewClient(false);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleManagerSelect = (user) => {
    if (!selectedManagers.find((m) => m.id === user.BO_ID)) {
      setSelectedManagers([
        ...selectedManagers,
        {
          id: user.BO_ID,
          username: user.USERNAME,
        },
      ]);
      setFormData((prev) => ({
        ...prev,
        // Ensure managers is an array, using existing managers or creating a new array
        managers: [...(prev.managers || []), user.BO_ID],
      }));
    }
  };

  const handleAdminSelect = (user) => {
    if (!selectedAdmins.find((a) => a.id === user.BO_ID)) {
      setSelectedAdmins([
        ...selectedAdmins,
        {
          id: user.BO_ID,
          username: user.USERNAME,
        },
      ]);
      setFormData((prev) => ({
        ...prev,
        // Ensure admins is an array, using existing admins or creating a new array
        admins: [...(prev.admins || []), user.BO_ID],
      }));
    }
  };

  const handleManagerRemove = (manager) => {
    setSelectedManagers(selectedManagers.filter((m) => m.id !== manager.id));
    setFormData((prev) => ({
      ...prev,
      // Ensure managers exists before filtering
      managers: (prev.managers || []).filter((id) => id !== manager.id),
    }));
  };

  const handleAdminRemove = (admin) => {
    setSelectedAdmins(selectedAdmins.filter((a) => a.id !== admin.id));
    setFormData((prev) => ({
      ...prev,
      // Ensure admins exists before filtering
      admins: (prev.admins || []).filter((id) => id !== admin.id),
    }));
  };

  const fetchLocationSuggestions = async (input) => {
    try {
      if (!window.google || !window.google.maps || !window.google.maps.places) {
        console.error("Google Maps Places library is not loaded");
        return;
      }

      if (!autocompleteService) {
        console.error("Autocomplete service not initialized");
        return;
      }

      if (!input || input.trim() === "") {
        console.error("Input is empty");
        setSuggestions([]);
        return;
      }

      const request = {
        input,
        types: ["establishment", "geocode"],
        componentRestrictions: { country: "IN" },
      };

      const predictions = await new Promise((resolve, reject) => {
        autocompleteService.getPlacePredictions(
          request,
          (predictions, status) => {
            if (
              status === window.google.maps.places.PlacesServiceStatus.OK &&
              predictions
            ) {
              resolve(predictions);
            } else {
              reject(status);
            }
          },
        );
      });

      // console.log('Suggestions received:', predictions);
      setSuggestions(predictions);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setSuggestions([]);
    }
  };

  const handleSuggestionSelect = (placeId) => {
    if (!placesService || !map) return;

    placesService.getDetails({ placeId }, (place, status) => {
      if (
        status === window.google.maps.places.PlacesServiceStatus.OK &&
        place &&
        place.geometry &&
        place.geometry.location
      ) {
        // Remove existing marker if any
        if (currentMarker) {
          currentMarker.setMap(null);
        }

        // Create new marker
        const marker = new window.google.maps.Marker({
          map: map,
          position: place.geometry.location,
          animation: window.google.maps.Animation.DROP,
        });

        // Save marker reference
        setCurrentMarker(marker);

        // Center map and zoom
        map.setCenter(place.geometry.location);

        // If it's a building or establishment, zoom in closer
        const zoomLevel = ["establishment", "premise"].some((type) =>
          place.types?.includes(type),
        )
          ? 18
          : 15;
        map.setZoom(zoomLevel);

        setFormData((prev) => ({
          ...prev,
          location: place.name || "",
          address: place.formatted_address || "",
          latitude: place.geometry.location.lat().toFixed(6),
          longitude: place.geometry.location.lng().toFixed(6),
        }));

        // Process address components
        place.address_components?.forEach((component) => {
          const types = component.types;
          if (types.includes("administrative_area_level_1")) {
            setFormData((prev) => ({ ...prev, state: component.long_name }));
          }
          if (types.includes("locality")) {
            setFormData((prev) => ({ ...prev, city: component.long_name }));
          }
          if (types.includes("sublocality_level_1")) {
            setFormData((prev) => ({ ...prev, area: component.long_name }));
          }
        });

        setSuggestions([]);

        // Optional: Add info window
        const infoWindow = new window.google.maps.InfoWindow({
          content: `<div><strong>${place.name}</strong><br>${place.formatted_address}</div>`,
        });

        marker.addListener("click", () => {
          infoWindow.open(map, marker);
        });
      }
    });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Store the actual file for form submission
      setImage(file);

      // If you still want to show preview
      const reader = new FileReader();
      reader.onloadend = () => {
        // You might want to store the preview URL separately
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA5ZI7RR2Lspj6HUONTjHRabkm8DbHG8Oo&libraries=places`;
    script.async = true;
    script.defer = true;

    script.onload = () => {
      // Check if Places service is available
      if (window.google && window.google.maps && window.google.maps.places) {
        console.log("Places API loaded successfully");
        initMap();
      } else {
        console.error("Places API not loaded");
      }
    };

    document.head.appendChild(script);

    return () => {
      if (script.parentNode) {
        document.head.removeChild(script);
      }
    };
  }, []);

  const fetchSetOutletDetails = async (id) => {
    const data = await getOutletDetailsRequest(id);
    console.log("Outlet", data);
    const incommingManagers = data.owners?.filter((bo)=>(bo.DESIGNATION === "manager"))
    const incommingAdmins = data.owners?.filter((bo)=>(bo.DESIGNATION === "admin"))
    setFormData({
      ...formData,
      clientName: data?.NAME,
      status: data?.STATUS,
      startDate: data?.START_DATE,
      category: data?.CATEGORY,
      discountButton: data?.DISCOUNTS[0]?.DISCOUNT_PERCENT,
      accountManager: data?.ACCOUNT_MANAGER_NAME,
      contactName: data?.CONTACT_NAME,
      phoneNo: data?.PHONE_NUMBER,
      emailId: data?.EMAIL,
      designation: data?.CONTACT_DESIGNATION,
      takeFeedback: false,
      location: "",
      address: data?.ADDRESS_LINE_ONE,
      state: data?.STATE,
      area: data?.ADDRESS_LINE_TWO,
      city: data?.CITY,
      country: data?.COUNTRY,
      latitude: data?.LATITUDE,
      longitude: data?.LONGITUDE,
      managers: incommingManagers.map(manager => manager?.BO_ID), // Extract only the IDs
      admins: incommingAdmins.map(admin => admin?.BO_ID), // Extract only the IDs
    });

    setSelectedManagers(
      incommingManagers.map(manager => ({
        id: manager.BO_ID, 
        username: manager.USERNAME
      }))
    );

    setSelectedAdmins(
      incommingAdmins.map(admin => ({
        id: admin.BO_ID, 
        username: admin.USERNAME
      }))
    );

    setImagePreview(data.PROFILE_PIC_URL);

    // If map is already initialized, update the marker
    if (map && data.LATITUDE && data.LONGITUDE) {
      // Remove existing marker if any
      if (currentMarker) {
        currentMarker.setMap(null);
      }

      const position = {
        lat: parseFloat(data.LATITUDE),
        lng: parseFloat(data.LONGITUDE),
      };

      // Center the map on the outlet's location
      map.setCenter(position);
      map.setZoom(18);

      // Create a new marker
      const marker = new window.google.maps.Marker({
        map: map,
        position: position,
        animation: window.google.maps.Animation.DROP,
      });

      // Add info window
      const infoWindow = new window.google.maps.InfoWindow({
        content: `<div><strong>${data.NAME}</strong><br>${data.ADDRESS_LINE_ONE}</div>`,
      });

      marker.addListener("click", () => {
        infoWindow.open(map, marker);
      });

      // Save marker reference
      setCurrentMarker(marker);
    }
  };

  const getAllUsers = async () => {
    const data = await getBusinessOwners();
    console.log("users" , data)
    setUsers(data);
  };

  const filterBystring = (isManager, str) => {
    if (!users.length) return;

    if (str.length < 1) {
      setMatchingAdmin([]);
      setMatchingManager([]);
      return;
    }

    const searchStr = str.toLowerCase();

    if (isManager) {
      const filteredManagers = users.filter(
        (user) =>
          user?.DESIGNATION === "manager" &&
          user?.USERNAME?.toString().toLowerCase().includes(searchStr),
      );
      setMatchingManager(filteredManagers);
    } else {
      const filteredAdmins = users.filter(
        (user) =>
          user?.DESIGNATION === "admin" &&
          user?.USERNAME?.toString().toLowerCase().includes(searchStr),
      );
      setMatchingAdmin(filteredAdmins);
    }
  };

  useEffect(() => {
    if (editClient && currentOutletId) {
      fetchSetOutletDetails(currentOutletId);
    }
    getAllUsers();
  }, []);

  useEffect(() => {
    if (editClient && map && formData.latitude && formData.longitude) {
      const position = {
        lat: parseFloat(formData.latitude),
        lng: parseFloat(formData.longitude),
      };

      // Remove existing marker if any
      if (currentMarker) {
        currentMarker.setMap(null);
      }

      // Center the map on the outlet's location
      map.setCenter(position);
      map.setZoom(18);

      // Create a new marker
      const marker = new window.google.maps.Marker({
        map: map,
        position: position,
        animation: window.google.maps.Animation.DROP,
      });

      setCurrentMarker(marker);
    }
  }, [editClient, map, formData.latitude, formData.longitude]);

  const initMap = () => {
    if (!mapRef.current || !window.google) {
      console.error("Google Maps not loaded");
      return;
    }

    try {
      // Set initial map center - either outlet location or default
      const initialCenter =
        editClient && formData.latitude && formData.longitude
          ? {
              lat: parseFloat(formData.latitude),
              lng: parseFloat(formData.longitude),
            }
          : { lat: 18.5204, lng: 73.8567 }; // Default location

      const mapInstance = new window.google.maps.Map(mapRef.current, {
        zoom: editClient ? 18 : 13, // Zoom in closer if editing
        center: initialCenter,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeId: "roadmap",
      });

      setAutocompleteService(
        new window.google.maps.places.AutocompleteService(),
      );

      setPlacesService(
        new window.google.maps.places.PlacesService(mapInstance),
      );

      setMap(mapInstance);

      // If we're in edit mode and have coordinates, set the marker
      if (editClient && formData.latitude && formData.longitude) {
        const position = {
          lat: parseFloat(formData.latitude),
          lng: parseFloat(formData.longitude),
        };

        // Create a marker at the outlet's location
        const marker = new window.google.maps.Marker({
          map: mapInstance,
          position: position,
          animation: window.google.maps.Animation.DROP,
        });

        // Add info window with outlet details
        const infoWindow = new window.google.maps.InfoWindow({
          content: `<div><strong>${formData.clientName || "Outlet"}</strong><br>${formData.address || ""}</div>`,
        });

        marker.addListener("click", () => {
          infoWindow.open(mapInstance, marker);
        });

        // Save marker reference
        setCurrentMarker(marker);
      }
    } catch (error) {
      console.error("Error initializing map:", error);
    }
  };

  return (
    <div className="fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-50 p-4">
      {addUser && (
        <AddUser users={users} setUsers={setUsers} setAddUser={setAddUser} />
      )}
      <div
        className="h-[85%] w-[65%] overflow-auto rounded-xl bg-white"
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      >
        <div className="flex items-center justify-between border-b px-10 py-5 shadow-sm">
          <h2 className="text-xl font-medium">
            {editClient ? "Edit Client" : "New Client"}
          </h2>
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={() => {
              setNewClient(false);
              setEditClient(false);
            }}
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-20 px-10 py-6">
          {/* Client Information */}
          <div>
            <SectionTitle>CLIENT INFORMATION</SectionTitle>
            <div className="space-y-10">
              <FormInput
                label="Client Name"
                name="clientName"
                placeholder="Please enter full name of the outlet"
                value={formData.clientName}
                onChange={handleChange}
                required
              />

              <div className="flex gap-6">
                <FormSelect
                  label="Status"
                  name="status"
                  options={statusOptions}
                  value={formData.status}
                  onChange={handleChange}
                  required
                />
                <FormInput
                  label="Start Date"
                  name="startDate"
                  type="date"
                  value={formData.startDate}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="flex gap-6 ">
                <FormSelect
                  label="Category"
                  name="category"
                  options={categoryOptions}
                  value={formData.category}
                  onChange={handleChange}
                  required
                />
                {editClient&& currentOutletId &&  <div className="relative flex-1">
                  <FormInput
                    label="Discount Button"
                    name="discountButton"
                    placeholder="for e.x. Extra 20% OFF"
                    value={formData.discountButton}
                    onChange={handleChange}
                  />
                  <span className="absolute -bottom-6 right-10 text-sm text-gray-500">
                    <span className="font-medium">Note:</span> Please check the
                    spelling mistakes if any
                  </span>
                </div>}
              </div>

              <FormInput
                label="Account Manager"
                name="accountManager"
                placeholder="Name of the sales executive"
                value={formData.accountManager}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {/* Contact Details */}
          <div>
            <SectionTitle>CONTACT DETAILS</SectionTitle>
            <div className="space-y-10">
              <FormInput
                label="Contact Name"
                name="contactName"
                placeholder="Name of the Owner, Manager, etc..."
                value={formData.contactName}
                onChange={handleChange}
                required
              />

              <div className="flex gap-6">
                <FormInput
                  label="Phone No."
                  name="phoneNo"
                  type="tel"
                  placeholder="Contact Number"
                  value={formData.phoneNo}
                  onChange={handleChange}
                  required
                />
                <FormInput
                  label="Email Id"
                  name="emailId"
                  type="email"
                  placeholder="name@email.com"
                  value={formData.emailId}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="flex gap-6">
                <FormSelect
                  label="Designation"
                  name="designation"
                  options={designationOptions}
                  value={formData.designation}
                  onChange={handleChange}
                  required
                />
                <FormCheckbox
                  label="Take Feedback?"
                  name="takeFeedback"
                  checked={formData.takeFeedback}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          {/* Location Details */}
          <div>
            <SectionTitle>LOCATION DETAILS</SectionTitle>
            <div className="space-y-10">
              <div className="relative">
                <FormInput
                  label="Location"
                  name="location"
                  id="location"
                  placeholder="Search for a location"
                  value={formData.location}
                  onChange={handleChange}
                  required
                />
                {suggestions.length > 0 && (
                  <div className="absolute left-36 z-10 mr-10 mt-1 w-[86%] rounded-md bg-white shadow-lg">
                    <ul className="max-h-60 overflow-auto rounded-md py-1 text-base">
                      {suggestions.map((suggestion) => (
                        <li
                          key={suggestion.place_id}
                          className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                          onClick={() =>
                            handleSuggestionSelect(suggestion.place_id)
                          }
                        >
                          {suggestion.description}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

              <FormInput
                label="Address"
                name="address"
                placeholder="Enter the full Address if autofill is incorrect"
                value={formData.address}
                onChange={handleChange}
                required
              />

              <div className="flex gap-6">
                <FormInput
                  label="State"
                  name="state"
                  placeholder="State Name"
                  value={formData.state}
                  onChange={handleChange}
                  required
                />
                <FormInput
                  label="Area"
                  name="area"
                  placeholder="Area Name"
                  value={formData.area}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="flex gap-6">
                <FormInput
                  label="City"
                  name="city"
                  placeholder="City Name"
                  value={formData.city}
                  onChange={handleChange}
                  required
                />
                <FormInput
                  label="Country"
                  name="country"
                  placeholder="India"
                  value={formData.country}
                  onChange={handleChange}
                  disabled
                />
              </div>

              <div className="flex gap-6">
                <FormInput
                  label="Latitude"
                  name="latitude"
                  placeholder="18.5204 N"
                  value={formData.latitude}
                  onChange={handleChange}
                  required
                />
                <FormInput
                  label="Longitude"
                  name="longitude"
                  placeholder="73.8576 E"
                  value={formData.longitude}
                  onChange={handleChange}
                  required
                />
              </div>

              <div
                ref={mapRef}
                className="h-[400px] w-full rounded-lg border"
              />
            </div>
          </div>

          {/* Thumbnail and Credentials */}
          <div className="relative">
            <SectionTitle>THUMBNAIL & CREDENTIALS</SectionTitle>
            <div className="flex gap-52">
              <div className="relative flex items-center gap-4">
                <label
                  htmlFor="file-input"
                  className="w-[130px] text-end text-base font-medium text-gray-700"
                >
                  Outlet Thumbnail
                </label>
                <div className="relative flex h-40 w-56 items-center justify-center rounded-lg bg-gray-300">
                  <input
                    id="file-input"
                    type="file"
                    onChange={handleImageUpload}
                    className="hidden"
                    accept="image/*"
                  />
                  {imagePreview ? (
                    <div className="relative h-full w-full">
                      <img
                        src={imagePreview}
                        alt="Uploaded Thumbnail"
                        className="h-full w-full rounded-lg object-cover"
                      />
                      <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 transition-opacity hover:opacity-100">
                        <button
                          type="button"
                          onClick={() =>
                            document.getElementById("file-input").click()
                          }
                          className="rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                        >
                          Change Image
                        </button>
                      </div>
                    </div>
                  ) : (
                    <button
                      type="button"
                      onClick={() =>
                        document.getElementById("file-input").click()
                      }
                      className="rounded-md border bg-gray-100 p-2 hover:bg-gray-200"
                    >
                      Upload
                    </button>
                  )}
                </div>
                <span className="absolute -bottom-8 left-36 text-xs text-[#7C7C7C]">
                  <span className="font-medium">Note:</span> Rename the image
                  file as the outlet name before uploading
                </span>
              </div>

              <div className="relative flex flex-col">
                <MultiSelectInput
                  label="Managers"
                  value={selectedManagers}
                  suggestions={matchingManager}
                  onSelect={handleManagerSelect}
                  onRemove={handleManagerRemove}
                  placeholder="Search Username"
                  filterBystring={filterBystring}
                />
                <MultiSelectInput
                  label="Admins"
                  value={selectedAdmins}
                  suggestions={matchingAdmin}
                  onSelect={handleAdminSelect}
                  onRemove={handleAdminRemove}
                  placeholder="Search Username"
                  filterBystring={filterBystring}
                />
              </div>
              <button
                type="button"
                className="absolute right-0 top-14 rounded-md border border-black px-4 py-2 font-medium"
                onClick={() => setAddUser(true)}
              >
                Add User
              </button>
            </div>
          </div>
          <div className="flex items-center justify-between border-t pt-4">
            <span className="text-sm text-[#7C7C7C]">
              *All the fields are mandatory.
            </span>
            <button type="submit" className="rounded-md bg-[#EFEFEF] px-6 py-2">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

const MultiSelectInput = ({
  label,
  value,
  suggestions,
  onSelect,
  onRemove,
  placeholder,
  filterBystring,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const wrapperRef = useRef(null);
  const isManager = label === "Managers";

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setShowSuggestions(true);
    filterBystring(isManager, newValue);
  };

  const handleInputClick = () => {
    setShowSuggestions(true);
    filterBystring(isManager, inputValue);
  };

  return (
    <div className="relative flex w-[200px] flex-col" ref={wrapperRef}>
      <label className="mb-2 w-[130px] text-base font-medium text-gray-700">
        {label}
      </label>
      <div
        className="h-[45px] overflow-auto rounded-md border border-gray-300 p-2"
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      >
        <div className="flex flex-wrap gap-2">
          {value.map((user) => (
            <div
              key={user.id}
              className="flex items-center gap-1 rounded-md bg-gray-100 px-2 py-1"
            >
              <span className="text-sm">{user.username}</span>
              <X
                className="h-4 w-4 cursor-pointer text-gray-500 hover:text-gray-700"
                onClick={() => onRemove(user)}
              />
            </div>
          ))}
          <input
            type="text"
            className="flex-1 border-none outline-none"
            placeholder={placeholder}
            value={inputValue}
            onChange={handleInputChange}
            onClick={handleInputClick}
          />
        </div>
      </div>
      {showSuggestions && suggestions.length > 0 && (
        <div className="absolute right-0 top-full z-10 mt-1 max-h-32 w-full overflow-y-auto rounded-md border-2 bg-white p-2 shadow-lg">
          {suggestions.map((user) => (
            <div
              key={user.BO_ID}
              className="cursor-pointer px-2 py-1 hover:bg-gray-100"
              onClick={() => {
                onSelect(user);
                setInputValue("");
                setShowSuggestions(false);
              }}
            >
              {user.USERNAME}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};