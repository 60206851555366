import React, { useState, useCallback } from "react";
import { Plus, Search } from "lucide-react";

const AdLibraryHeader = ({ page, setShowCreateAd, setFilteredAds, ads = [] }) => {
  const [isSearchActive, setIsSearchActive] = useState(false);

  // Debounced handleAdsFilter to optimize filtering
  const handleAdsFilter = useCallback(
    (e) => {
      const searchValue = e.target.value.toLowerCase();
      const searchedAds = ads.filter((ad) =>
        ad.NAME?.toLowerCase().includes(searchValue) ||
        ad.HEADLINE?.toLowerCase().includes(searchValue) ||
        ad.DISCOUNT_PERCENT?.includes(searchValue)
      );
      setFilteredAds(searchedAds);
    },
    [ads, setFilteredAds]
  );

  return (
    <div
      className={`flex items-center ${
        page === "ad" ? "gap-12" : "justify-between"
      } `}
    >
      {/* Search Bar */}
      <div className={`relative flex w-[75%]`}>
        <Search
          className={`absolute top-2 ${
            isSearchActive
              ? "right-3 text-cyan-600"
              : "left-3 text-gray-500"
          }`}
        />
        <input
          type="text"
          placeholder="Search Ads..."
          className={`flex-grow rounded-full border py-2 ${
            isSearchActive ? "px-4 placeholder-transparent" : "px-10"
          } focus:outline-none`}
          onFocus={() => setIsSearchActive(true)}
          onBlur={() => setIsSearchActive(false)}
          onChange={handleAdsFilter}
          aria-label="Search Ads"
        />
      </div>

      {/* Create New Ad Button */}
      <div
        className={`flex cursor-pointer gap-1 text-cyan-500 underline underline-offset-8 hover:text-cyan-700 ${
          page === "ad" ? "visible" : "hidden"
        }`}
        onClick={() => setShowCreateAd(true)}
      >
        <Plus className="hover:scale-[1.03]" />
        <span className="hover:scale-[1.03]">Create new Ad</span>
      </div>
    </div>
  );
};

export default AdLibraryHeader;
