import { ChevronLeft } from "lucide-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NewNavbar from "../components/navbar/new-navbar";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top smoothly
    }, []);

  return (
    <div className="w-screen">
      <div className="fixed left-0 top-0 w-screen flex-col gap-3 bg-[#FCFCFC] px-3 py-5 shadow-lg  md:flex-row md:items-center md:justify-between md:px-[10%]">
        <div className="flex flex-row items-center gap-2 md:gap-4">
          <ChevronLeft
            className="h-6 w-6 cursor-pointer text-[#303030]"
            onClick={() => navigate(-1)}
          />
          <h1 className="text-lg font-bold text-[#303030] md:text-xl">
            Privacy Policy
          </h1>
        </div>
      </div>

      <div className="mt-20  px-5 py-4 text-justify text-sm tracking-wide text-[#7C7C7C] md:mt-24  md:px-[10%] md:text-base">
        <span>
          {" "}
          Meask Solutions Private Limited and its affiliates (referred to as
          "MEask," "the Company," "we," "us," or "our") respect your privacy and
          are committed to protecting it through compliance with this Privacy
          Policy. This document outlines:
        </span>
        <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
          <li>
            The types of information MEask collects from you when you access or
            use its websites, applications,or other online services
            (collectively, the "Services").
          </li>
          <li>
            {" "}
            How MEask collects, uses, maintains, protects, and discloses that
            information.
          </li>
        </ul>
        This Privacy Policy applies solely to information collected by MEask
        through its Services, including email, text, and other electronic
        communications related to the Services. It does not apply to information
        provided to or collected by third parties, such as restaurants, retail
        stores, or outlets where you make reservations or payments through
        MEask's Services, or social networks connected to our Services. Please
        review the privacy policies of those third parties to understand their
        practices. By accessing or using the Services and/or registering an
        account with MEask, you agree to this Privacy Policy and consent to
        MEask's collection, use, disclosure, retention, and protection of your
        personal information as described herein. If you choose not to provide
        the required information, MEask may be unable to deliver certain
        Services effectively.
        <br />
        If you reside outside India, Meask Solutions Private Limited, located at
        Aryaman-2, A-2, Sumitra Nagar, Tukum, Chandrapur, Maharashtra 442401,
        India, will act as the controller of your personal data collected or
        processed in connection with our Services.
        <br />
        Your data controller ensures the collection, usage, disclosure,
        retention, and protection of your personal information in accordance
        with its privacy standards and applicable national laws. Data may be
        transferred to other MEask entities as described in this Privacy Policy.
        MEask processes and stores personal information on servers located in
        India, where its data centers are based.
        <br />
        This Privacy Policy may be updated periodically. Continued use of
        MEask's Services following changes to the policy constitutes acceptance
        of those updates, so please review it regularly.
        <br />
        Information We Collect and How We Use It
        <br />
        MEask collects various types of information from and about users of our
        Services, including: 1. Personal Information ("PI") <br />
        Personal Information refers to data that can identify a specific
        individual, either directly or in combination with other information
        available to us. It excludes anonymized or aggregated information that
        cannot identify a specific person. 2. Technical and Usage Information{" "}
        <br />
        This includes details about your internet connection, the devices you
        use to access our Services, and usage patterns.
        <br />
        Information Collection Methods
        <br />
        We collect information in the following ways:
        <br />
        <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
          <li>
            Directly from you: Information you provide when using the Services.{" "}
          </li>

          <li>
            Automatically: Data collected through cookies, web beacons, and
            other tracking technologies as you navigate the Services.
          </li>
        </ul>
        Examples of Information Collected
        <br />
        <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
          <li>
            Account Information: Your name, email, postal code, password,
            gender, mobile phone number, and profile picture.
          </li>

          <li>
            Preferences: Time zone, language settings, and other preferences.
          </li>

          <li>
            Content: Reviews, comments, photographs, and information about
            redeemed coupons and favorite outlets.
          </li>

          <li>
            Browsing Data: Search history, pages viewed, and time spent on the
            Services.
          </li>

          <li>
            Communications: Messages exchanged with other users or merchants and
            survey participation details.
          </li>

          <li>
            Transaction Details: Billing information, phone number, and payment
            card details for reservations or purchases.{" "}
          </li>
        </ul>
        Public Contributions
        <br />
        Information you choose to make public on the Services, such as ratings,
        reviews, or tips, is shared at your own risk. While MEask implements
        privacy settings, no security measures are completely failproof. Public
        posts may be visible to a broader audience, including third-party sites.
        <br />
        Friends and Contacts
        <br />
        If you invite friends to use the Services, their contact information
        will be used solely to process your request.
        <br />
        Location Data
        <br />
        With your consent, MEask may collect real-time location information to
        customize features and provide location-based content. You can disable
        location tracking in your device settings.
        <br />
        Cookies and Tracking Technologies
        <br />
        We use cookies and similar technologies to enhance functionality, store
        preferences, and deliver personalized content. Third-party advertisers
        and service providers may also use these tools; we recommend reviewing
        their privacy policies for details.
        <br />
        Third-Party Information
        <br />
        We may obtain data about you from social media platforms, marketing
        partners, and other third parties, combining it with data collected
        directly through our Services.
        <br />
        Anonymous Data
        <br />
        Aggregated or anonymized data is not subject to this policy and may be
        used or shared without restriction.
        <br />
        Use of Collected Information
        <br />
        MEask uses the information collected to:
        <br />
        <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
          <li> Process and respond to inquiries. </li>
          <li>
            Improve the functionality, quality, and user experience of our
            Services.
          </li>
          <li>Administer accounts and provide customer support.</li>
          <li>Personalize content, including targeted advertisements. </li>
          <li> Communicate about promotions, surveys, or updates. </li>
          <li>Process transactions securely and efficiently. </li>
          <li>Comply with legal obligations and enforce our rights.</li>
        </ul>
        Changes to This Privacy Policy
        <br />
        MEask reserves the right to modify this Privacy Policy at any time.
        Changes will be posted on this page, and continued use of the Services
        after updates signifies acceptance of the revised policy.
        <br />
        1. How We Share the Information We Collect
        <br />
        General Information Disclosures
        <br />
        We may disclose personal information that we collect or you provide, as
        described in this Privacy Policy, in the following ways:
        <br />
        <ol className="list-decimal md:px-7 pl-7 md:pl-0">
          <li>
            Subsidiaries and Affiliates:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                {" "}
                To our subsidiaries and affiliates under common ownership or
                control with Meask Solutions Private Limited.
              </li>{" "}
            </ul>
          </li>

          <li>
            Contractors and Service Providers:{" "}
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                To contractors, advertisers, or service providers bound by
                contractual obligations to keep personal information
                confidential and use it only for the purposes disclosed.
              </li>
            </ul>
          </li>

          <li>
            Business Transfers:{" "}
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                To a buyer or successor in the event of a merger, restructuring,
                or sale of Meask’s assets, including during bankruptcy or
                liquidation proceedings.
              </li>{" "}
            </ul>
          </li>

          <li>
            Third Parties for Marketing:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                {" "}
                To third parties to market their products or services if you
                have consented to receive such updates. These third parties are
                contractually required to keep your information confidential.
              </li>{" "}
            </ul>
          </li>

          <li>
            Specific Purposes:{" "}
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                To fulfill the purpose for which you provide the information or
                as disclosed when you provide it.
              </li>
            </ul>
          </li>
        </ol>
        Service Providers
        <br />
        We may share your information with external vendors for purposes
        including, but not limited to:
        <br />
        <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
          <li>Sending communications such as emails, messages, or calls.</li>

          <li> Providing voice recognition services.</li>

          <li>Analyzing usage of our services and processing payments. </li>
        </ul>
        These vendors will only receive information necessary to perform their
        services.
        <br />
        Legal Purposes
        <br />
        We may share your information:
        <br />
        <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
          <li>
            {" "}
            To investigate illegal activities, threats, or violations of this
            Privacy Policy or our Terms of Use.{" "}
          </li>

          <li>
            In response to legal requests, such as subpoenas or court orders.{" "}
          </li>
        </ul>
        Social Networks
        <br />
        If you interact with social media features on our Services, such as a
        "Like" button, these interactions are governed by the privacy policies
        of the respective social media platforms.
        <br />
        Additional Situations
        <br />
        <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
          <li>To enforce or apply our Terms of Use. </li>
          <li>
            To protect the rights, property, or safety of Meask, its customers,
            or others.
          </li>
          <li>With your consent, in other circumstances.</li>
        </ul>
        2. Information Shared with Outlets
        <br />
        When you generate coupons or execute transactions through our Services:
        <br />
        <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
          <li> Your information is shared with the outlets you visit.</li>
          <li>
            Outlets may use this information according to their own privacy
            practices. Please contact them directly for details.
          </li>{" "}
        </ul>
        3. Payment Card Information
        <br />
        To use certain Services, we may require UPI or credit/debit card
        information. By providing this information, you agree to:
        <br />
        <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
          <li>
            Share your payment details with outlets, payment processors, and
            fraud detection vendors.{" "}
          </li>

          <li>
            The storage of your payment details by third-party processors for
            future transactions.{" "}
          </li>
        </ul>
        For security details, see the "Security" section below.
        <br />
        4. Analytics and Tailored Advertising
        <br />
        Web Analytics
        <br />
        We use tools like Google Analytics to understand usage patterns. You can
        opt-out using the Google Analytics Opt-Out Browser Add-on.
        <br />
        Tailored Advertising
        <br />
        Third parties may collect information to provide tailored ads based on
        your usage. You can adjust privacy settings on your device or platform
        to opt-out.
        <br /> 5. Choices About Your Information
        <br />
        Cookie Management
        <br />
        You can manage cookies via browser or device settings. Disabling cookies
        may limit some functionalities of our Services.
        <br />
        Communication Preferences <br />
        You can opt-out of promotional emails but may still receive
        administrative messages.
        <br />
        6. Reviewing, Changing, or Deleting Information <br />
        You may:
        <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
          {" "}
          <li>
            {" "}
            Request changes or deletion of personal information via the "Contact
            Us" link.
          </li>
          <li>
            Delete your account, though cached or archived copies may persist.
          </li>
        </ul>
        7. Accessing & Correcting Personal Information
        <br />
        We encourage you to update and correct your information. Requests may be
        denied if:
        <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
          <li>They are excessive or abusive.</li>

          <li>They infringe on others’ rights or safety.</li>

          <li>They relate to ongoing legal proceedings.</li>
        </ul>
        8. Security: How We Protect Your Information
        <br />
        We implement industry-standard safeguards for data security. Users are
        responsible for safeguarding their account credentials. We assume no
        liability for unauthorized access due to errors or causes beyond our
        control.
        <br />
        9. Permissible Age 
        <br />
        Our services are intended for users who are at least 18 years old. If
        you are below 18, you may only use the services with the consent of your
        parent or legal guardian. By accessing or using our services, you
        represent and warrant that you meet these eligibility requirements. If
        we discover that a user does not meet these requirements, we reserve the
        right to suspend or delete the account and any associated data.
        <br />
        10. Third-Party Links and Services <br />
        Our Services may contain links to third-party websites. We are not
        responsible for their content or privacy practices. Review their privacy
        policies for more information.
        <br />
        11. Data Retention and Account Termination
        <br />
        Upon account closure, we may retain your information for lawful
        purposes. Public posts may be dissociated from your profile but not
        deleted entirely.
        <br />
        12. Changes to This Privacy Policy
        <br />
        We may amend this Privacy Policy periodically. Continued use of our
        Services constitutes acceptance of any updates.
        <br />
        13. Contact Us
        <br />
        For any queries about this Privacy Policy, email us at:
        support@themeask.com
      </div>
    </div>
  );
};

export default PrivacyPolicy;
