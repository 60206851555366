import {
  createSlice,
  createAsyncThunk,
  createListenerMiddleware,
} from "@reduxjs/toolkit";
import {
  getCategoriesRequest,
  getNearbyOutletsByLandmarkRequest,
  getNearbyOutletsRequest,
  getOutletDetailsRequest,
} from "../apis/outlets-apis";

const initialState = {
  outletCategories: {
    loading: false,
    categories: false,
    error: "",
    allCategories: [],
  },
  selectedCategory: "food & beverages",
  cardsData: [],
  limit: 16,
  offSet: 0,
  totalCount: 100,
  currentCount: 0,
  loading: false,
  error: "",
  currentOutlet: "",
  loadingOutletDetails: false,
  errorOutletDetails: "",
};

export const getOutletDetails = createAsyncThunk(
  "outlets/getOutletDetails",
  async (outletId, { getState, rejectWithValue }) => {
    const { loading } = getState().outlets.outletCategories;
    if (loading) {
      rejectWithValue("Already fetching");
      console.log("rejected");
    }

    try {
      return await getOutletDetailsRequest(outletId);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getCategories = createAsyncThunk(
  "outlets/getCategories",
  async (data, { getState, rejectWithValue }) => {
    const { loading } = getState().outlets.outletCategories;
    if (loading) {
      rejectWithValue("Already fetching");
      console.log("rejected");
    }

    try {
      return await getCategoriesRequest();
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getCardsDataByCoords = createAsyncThunk(
  "outlets/getCardsDataByCoords",
  async (requestData, { getState, rejectWithValue }) => {
    const { loading } = getState().outlets;
    if (loading) {
      rejectWithValue("Already fetching");
      console.log("rejected");
    }
    try {
      return await getNearbyOutletsRequest(requestData);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getCardsDataByLandmark = createAsyncThunk(
  "outlets/getCardsDataByLandmark",
  async (requestData, { getState, rejectWithValue }) => {
    const { loading } = getState().outlets;
    if (loading) {
      rejectWithValue("Already fetching");
      console.log("rejected");
    }
    try {
      return await getNearbyOutletsByLandmarkRequest(requestData);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const outletsSlice = createSlice({
  name: "outlets",
  initialState,
  reducers: {
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
      state.cardsData = [];
    },
    resetOffset: (state, action) => {
      state.offSet = 0;
      state.cardsData = [];
      state.currentCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategories.pending, (state) => {
      state.outletCategories.loading = true;
      state.outletCategories.error = null;
    });
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.outletCategories.loading = false;
      state.outletCategories.error = null;
      state.outletCategories.allCategories = action.payload.categories;
      state.selectedCategory = action.payload.categories[0].NAME;
    });
    builder.addCase(getCategories.rejected, (state, action) => {
      state.outletCategories.loading = false;
      state.outletCategories.error = action.payload;
      state.outletCategories.allCategories = [];
    });
    builder.addCase(getCardsDataByCoords.pending, (state) => {
      state.pending = true;
      state.error = "";
    });
    builder.addCase(getCardsDataByCoords.fulfilled, (state, action) => {
      state.pending = false;
      state.error = "";
      state.totalCount = action.payload[0]?.TOTAL_COUNT;
      if (!action.meta.arg.newOffset) {
        state.cardsData = [...state.cardsData, ...action.payload];
        state.offSet = state.offSet + 1;
        state.currentCount = state.currentCount + state.limit;
      } else {
        state.cardsData = [...action.payload];
        state.offSet = 1;
        state.currentCount = state.limit;
      }
    });
    builder.addCase(getCardsDataByCoords.rejected, (state, action) => {
      state.pending = false;
      state.error = action.payload;
    });
    builder.addCase(getCardsDataByLandmark.pending, (state) => {
      state.pending = true;
      state.error = "";
    });
    builder.addCase(getCardsDataByLandmark.fulfilled, (state, action) => {
      state.pending = false;
      state.error = "";
      state.currentCount = state.currentCount + state.limit;
      state.totalCount = action.payload[0]?.TOTAL_COUNT;
      if (!action.meta.arg.newOffset) {
        state.cardsData = [...state.cardsData, ...action.payload];
        state.offSet = state.offSet + 1;
        state.currentCount = state.currentCount + state.limit;
      } else {
        state.cardsData = [...action.payload];
        state.offSet = 1;
        state.currentCount = state.limit;
      }
    });
    builder.addCase(getCardsDataByLandmark.rejected, (state, action) => {
      state.pending = false;
      state.error = action.payload;
    });
    builder.addCase(getOutletDetails.pending, (state) => {
      state.loadingOutletDetails = true;
    });
    builder.addCase(getOutletDetails.fulfilled, (state, action) => {
      state.currentOutlet = action.payload;
      state.loadingOutletDetails = false;
      state.errorOutletDetails = "";
    });
    builder.addCase(getOutletDetails.rejected, (state, action) => {
      state.currentOutlet = {};
      state.loadingOutletDetails = false;
      state.errorOutletDetails = "Error fetching outlet Details";
    });
  },
});

export const { setSelectedCategory, resetOffset } = outletsSlice.actions;
export default outletsSlice.reducer;
