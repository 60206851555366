import React, { useState } from "react";
import { ChevronUp, ChevronDown } from "lucide-react";

const CustomDropdown = ({ adTypes, selectedValue, onSelect }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <div className="relative">
      <div
        className="relative border border-gray-100 cursor-pointer"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <div className="flex items-center justify-between h-12 px-3">
          <span className={`${!selectedValue ? "text-gray-500 text-sm" : ""}`}>
            {selectedValue
              ? adTypes.find((type) => type.value === selectedValue)?.label
              : "Select Ad type"}
          </span>
          {isDropdownOpen ? (
            <ChevronUp className="text-gray-500" />
          ) : (
            <ChevronDown className="text-gray-500" />
          )}
        </div>

        {isDropdownOpen && (
          <div className="absolute left-0 right-0 top-full mt-1 bg-white border border-gray-100 shadow-lg z-10">
            {adTypes.map((type) => (
              <div
                key={type.value}
                className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => {
                  onSelect(type.value);
                  setIsDropdownOpen(false);
                }}
              >
                {type.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomDropdown;
