import React from "react";
import { MoveRight } from 'lucide-react';
import { useNavigate } from "react-router-dom";

const PayBillButton = ({selectedDiscount , outletID }) => {
  const navigate = useNavigate()
  
  return (
    <>
      {/* <div className="fixed bottom-0 h-20 w-[100%] bg-white opacity-30"></div> */}

      <div className="fixed bottom-5 right-28 lg:flex justify-center items-center w-[86%]   tracking-wider  opacity-100 h-24 hidden md:visible">
        <div className="lg:flex justify-between h-22 rounded-lg  items-center bg-white p-2 shadow-xl hidden lg:visible w-full">
          <div className="">
            <span className="text-slate-700 text-2xl">Flat</span>
            <div className="flex gap-10">
              <span className="text-xl  font-bold">{selectedDiscount}% Off</span>

              <ul className="flex gap-10 marker:text-base list-disc text-gray-600 text-lg items-center">
                <li>on total Bill</li>
                <li>expires at 12:00 am</li>
              </ul> 
            </div>
          </div>

          <button className="h-12  rounded-xl w-1/4 bg-cyan-500 p-3 text-xl font-bold text-white flex justify-center items-center gap-4" onClick={()=>navigate(`/mvpdemo/enter-amount/${outletID}`)}>
            <span>Pay Bill</span> <MoveRight/>
          </button>
        </div>
      </div>

      <div className="fixed w-full bottom-5 text-center tracking-wider text-white opacity-100 lg:hidden ">
      <button className=" lg:w-1/3 w-1/3 bg-cyan-500 rounded-full lg:text-xl font-bold h-10 shadow-l " onClick={()=>navigate(`/mvpdemo/enter-amount/${outletID}`)}>
        <span className="flex justify-center items-center gap-4">Pay Bill <MoveRight/></span>
      </button>
      </div>

    </>
  );
};

export default PayBillButton;
