import React from "react";
import PromoterCardImage from "../../assets/promotor-card-bg.png";
import { ArrowRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

const PromoterCard = () => {

  const navigate = useNavigate()

  return (
    <div
      style={{
        backgroundImage: `url(${PromoterCardImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="h-96 w-96 rounded-lg bg-cover bg-center p-8"
    >
      <div className="flex flex-col gap-7 text-center text-white">
        <div>
          <h1 className="font-bakbakOne text-3xl md:text-4xl">
            Become a <br />
            Promoter Today.
          </h1>
          <p>The more you share, the more you earn!</p>
        </div>
        <div className="flex items-center justify-center gap-2">
          <button className="flex items-center justify-between rounded-md border border-gray-300 px-4 py-2 font-semibold text-white" onClick={()=>navigate("/mvpdemo/become-promoter")}>
            Know more <ArrowRight size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PromoterCard;
