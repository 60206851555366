import axios from "axios";
import API_URLS from "./apiUrls";
import { isCookieExpired, readCookie } from "./cookie";

const axiosInstance = axios.create({
  baseURL: API_URLS.baseURL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use((config) => {
  const token = readCookie("authToken");
  if (token && !isCookieExpired("authToken")) {
    config.headers.Authorization = `${token}`;
  }
  // else if(readCookie("business_auth_token") && !isCookieExpired("business_auth_token")){
  //   config.headers.Authorization = `${readCookie("business_auth_token")}`
  // }
  return config;
});

export default axiosInstance;
