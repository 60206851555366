import React from "react";
import { Plus } from "lucide-react";

const CreateAdButton = ({ setShowCreateAd }) => {
  const handleCreateAd = function () {
    setShowCreateAd(true);
  };
  //
  return (
    <div className="mt-5 flex justify-center">
      <button
        className="flex items-center justify-center gap-2 rounded-xl bg-cyan-500 px-10 py-2 text-lg text-white duration-200 hover:bg-cyan-700"
        onClick={handleCreateAd}
      >
        <Plus />
        <span>Create New Ad</span>
      </button>
    </div>
  );
};

export default CreateAdButton;
