import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const NavItem = ({ name, isActive, link }) => (
  <Link to={link}>
      <div
    className={`px-3 py-1 cursor-default tracking-wide ${
      isActive
        ? "border border-cyan-500 text-cyan-500 rounded-full"
        : "text-gray-600 border-white"
    }`}
  >
    {name}
  </div>
  </Link>
);

const PromotionOrganizerNavbar = ({navItems}) => {
  const navigate = useNavigate()

    const currentPath = window.location.href

    const navItems1 = navItems.slice(0,2)
    const navItems2 = navItems.slice(2)

  
  const renderNavItems = (items) =>
    items.map((item, index) => (
      <NavItem
        key={index}
        name={item.name}
        isActive={currentPath.includes(item.link)}
        link={item.link}
      />
    ));

  return (
    <div className="mt-[4%] w-full fixed top-[7px]  h-[84px] bg-white  z-30 ">
      <div className="flex justify-between px-10 py-7">
        <div className="flex gap-10 items-center">{renderNavItems(navItems1)}</div>
        <div className="flex gap-10 items-center">{renderNavItems(navItems2)}</div>
      </div>
    </div>
  );
};

export default PromotionOrganizerNavbar
