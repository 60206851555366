import React, { useState, useEffect } from "react";
import ConnectingBrands from "../assets/connecting-brand.svg";
import ConnectingBrandsMobile from "../assets/connecting-brands-mobile.svg";
import OurIndustry from "../assets/our-industry.png";
import OurIndustryMobile from "../assets/our-industry-mobile.png";
import { useNavigate } from "react-router-dom";
import HomepageFooterButton from "../components/homepage/homepage-footer-buttons/homepage-footer-button";
import MegaPhoneShadow from "./../assets/about-us/megaphone-shadow.svg";
import OurMission from "./../assets/about-us/our-mission.svg";
import WhatWeDo from "./../assets/about-us/what-we-do.png";
import OurCommitment1 from "./../assets/about-us/our-commitment-1.svg";
import OurCommitment2 from "./../assets/about-us/our-commitment-2.svg";
import OurCommitment3 from "./../assets/about-us/our-commitment-3.svg";
import JoinUs from "./../assets/about-us/join-us.png";
import { useSelector } from "react-redux";

function AboutUs() {
  const navigate = useNavigate();

  ///

  const userData = useSelector((state) => state.auth.userData);

  useEffect(() => {
    // Check auth on mount and when userData changes
    if (!userData || !userData.NAME) {
      navigate("/mvpdemo/landing-page", { replace: true });
    }
    window.scrollTo({ top: 0, behavior: "smooth" });

  }, [userData, navigate]);

  ////

  const [connectingBrandsStyles, setConnectingBrandsStyles] = useState({});
  const [ourIndustryStyles, setOurIndustryStyles] = useState({});
  const [megaphoneShadow, setMegaphoneShadow] = useState({});
  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // }, []);
  const updateBackground = () => {
    if (window.innerWidth >= 780) {
      setConnectingBrandsStyles({
        backgroundImage: `url(${ConnectingBrands})`,
        aspectRatio: "10/3",
      });
      setOurIndustryStyles({
        backgroundImage: `url(${OurIndustry})`,
      });
      setMegaphoneShadow({
        display: "inline-block",
      });
    } else {
      setConnectingBrandsStyles({
        backgroundImage: `url(${ConnectingBrandsMobile})`,
        aspectRatio: "412/321",
      });
      setOurIndustryStyles({
        aspectRatio: "1",
        backgroundImage: `url(${OurIndustryMobile})`,
      });
      setMegaphoneShadow({
        display: "none",
      });
    }
  };

  useEffect(() => {
    updateBackground();

    window.addEventListener("resize", updateBackground);

    return () => {
      window.removeEventListener("resize", updateBackground);
    };
  }, []);

  // Don't render anything if not authenticated
  if (!userData || !userData.NAME) {
    navigate("/mvpdemo/landing-page");
    return null;
  }

  return (
    <div className="md:mt-[29px] mt-[22px]">
      <div
        className="flex h-auto w-full bg-cover bg-center md:items-center"
        style={connectingBrandsStyles}
      >
        <div className="flex w-full flex-col items-center md:ml-[8%] md:items-start">
          <h3 className="font-bakbakOne text-[28px] text-customWhite md:text-[68px]">
            Connecting Brands
          </h3>
          <span className="text-[16px] font-normal tracking-wide text-customWhite md:text-[24px]">
            {" "}
            Bridging you to exclusive deals from top brands.
          </span>
        </div>
      </div>
      <div className="mt-[3%] w-full">
        <div className="mx-[3%] md:mx-[8%]">
          <div>
            <div className="flex items-center justify-between">
              <h5 className="font-bakbakOne text-[22px] leading-[56px] text-customTeal md:mb-[1%] md:text-[40px]">
                Who we are
              </h5>
              <img src={MegaPhoneShadow} alt="shadow" style={megaphoneShadow} />
            </div>
            <p className="text-justify text-[16px] font-light leading-[20px] text-customTextGray md:text-[20px] md:leading-[24px]">
              We are redefining the way brands and consumers connect by
              transforming the offline marketing landscape through seamless
              digital integration.
            </p>
            <br />
            <p className="text-justify text-[16px] font-light leading-[20px] text-customTextGray md:text-[20px] md:leading-[24px]">
              We believe that effective marketing should be simple, dynamic, and
              tailored to the evolving needs of today’s consumers. Whether it's
              through personalized promotions or interactive campaigns, we
              ensure that every touchpoint between brands and their audience is
              impactful and meaningful.
            </p>
            <br />
            <p className="text-justify text-[16px] font-light leading-[20px] text-customTextGray md:text-[20px] md:leading-[24px]">
              MEask leverages innovative technologies to drive customer loyalty,
              brand awareness, and measurable business results. We are creating
              a more connected, engaging marketplace where brands thrive and
              consumers enjoy richer, more rewarding experiences. Discover how
              MEask is creating a more engaging and connected marketplace, where
              brands thrive and consumers benefit.
            </p>
          </div>
          <div className="mt-[15%] flex w-full flex-col items-center justify-between gap-[10%] md:flex-row">
            <img
              alt="our mission"
              src={OurMission}
              className="h-auto w-[40%]"
            />
            <div className="flex flex-col items-center">
              <h5 className="font-bakbakOne text-[22px] leading-[56px] text-customTeal md:mb-[4%] md:text-[40px]">
                Our Mission
              </h5>
              <p className="text-justify text-[16px] font-light leading-[20px] text-customTextGray md:text-[20px] md:leading-[24px]">
                Make it effortless for brands to reach their audience and for
                customers to discover and enjoy exclusive offers and promotions.
                By bridging the gap between the physical and digital worlds, we
                enhance the shopping experience, empowering consumers to engage
                with their favorite brands in a way that feels natural and
                intuitive.
              </p>
            </div>
          </div>

          <div
            className="mt-[7%] flex min-h-[450px] w-full flex-col items-center justify-center bg-contain bg-center bg-no-repeat p-[11%] md:p-[5%] md:pt-[3%]"
            style={ourIndustryStyles}
          >
            <h3 className="font-bakbakOne text-[28px] text-customWhite md:text-[40px]">
              Our Industry
            </h3>
            <p className="text-justify text-[16px] font-light leading-[20px] text-customWhite md:mt-[2%] md:w-[80%] md:text-[20px] md:leading-[24px]">
              As part of the Marketing Services Industry, MEask is dedicated to
              delivering innovative solutions that drive brand awareness,
              customer loyalty, and measurable results. We leverage cutting-edge
              technology to provide the best tools for brands to engage with
              their audience in a more efficient and targeted way.
            </p>
          </div>

          <div className="mt-[7%] flex flex-col-reverse items-center md:flex-row md:justify-between">
            <div className="flex flex-col items-center">
              <h5 className="font-bakbakOne text-[22px] leading-[56px] text-customTeal md:mb-[4%] md:text-[40px]">
                What we do
              </h5>
              <p className="text-justify text-[16px] font-light leading-[20px] text-customTextGray md:text-[20px] md:leading-[24px]">
                At MEask, we provide a comprehensive solution for brands to
                promote their products and services to a wider audience. Our
                platform equips brands with powerful tools to create, manage,
                and track promotions, ensuring maximum reach and customer
                engagement.
              </p>
              <br />
              <p className="text-justify text-[16px] font-light leading-[20px] text-customTextGray md:text-[20px] md:leading-[24px]">
                For consumers, we offer a user-friendly interface that makes
                discovering nearby deals and offers quick and effortless,
                enhancing their shopping experience.
              </p>
            </div>
            <img
              src={WhatWeDo}
              alt="what we do"
              className="h-[145px] w-[182px] md:h-[303px] md:w-[383px]"
            />
          </div>
          <div className="">
            <h5 className="mt-[15%] text-center font-bakbakOne text-[22px] leading-[56px] text-customTeal md:text-left md:text-[40px]">
              Our Commitment
            </h5>
            <div className="mt-[5%] flex flex-col gap-5">
              {/* First Row */}
              <div className="flex flex-wrap gap-5 md:flex-nowrap">
                {/* First Card */}
                <div className="bg-lightblue flex w-full flex-col justify-between rounded-lg p-[5%] shadow-md md:w-1/2">
                  <img
                    src={OurCommitment1}
                    alt="our-commitment-1"
                    className="h-[80px] w-[80px]"
                  />
                  <p className="text-justify text-[16px] font-light text-customTextGray md:text-[20px] md:leading-[24px]">
                    For brands, we provide the tools and strategies needed to
                    expand their reach, engage effectively, and deliver tailored
                    promotions that resonate with their audience. For consumers,
                    we simplify the process of discovering relevant deals and
                    offers, ensuring a smoother and more enjoyable shopping
                    journey.
                  </p>
                </div>

                {/* Second Card */}
                <div className="bg-lightblue flex w-full flex-col justify-between rounded-lg p-[5%] shadow-md md:w-1/2">
                  <img
                    src={OurCommitment2}
                    alt="our-commitment-2"
                    className="h-[80px] w-[80px]"
                  />
                  <p className="text-justify text-[16px] font-light text-customTextGray md:text-[20px] md:leading-[24px]">
                    We are deeply committed to building a dynamic and vibrant
                    ecosystem where offline marketing flourishes in the digital
                    era. Our mission goes beyond simply connecting brands and
                    consumers—we aim to create meaningful interactions that
                    enhance the entire shopping experience for both parties.
                  </p>
                </div>
              </div>

              {/* Second Row */}
              <div className="flex justify-center">
                <div className="bg-lightblue flex max-h-[380px] w-full flex-col justify-between rounded-lg p-[5%] shadow-md">
                  <img
                    src={OurCommitment3}
                    alt="our-commitment-3"
                    className="h-[80px] w-[80px]"
                  />
                  <p className="text-justify text-[16px] font-light text-customTextGray md:text-[20px] md:leading-[24px]">
                    Innovation is at the heart of everything we do. We are
                    constantly evolving our platform to stay at the forefront of
                    technology, ensuring that it delivers the best possible
                    results for both brands and customers. Our goal is to
                    continue pushing boundaries and making sure that, in an
                    ever-changing digital landscape, MEask remains the go-to
                    solution for integrating offline marketing with the modern
                    consumer experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-[15%] flex flex-col-reverse items-center md:flex-row md:justify-between ">
            <div className="flex flex-col items-center">
              <h5 className="font-bakbakOne text-[22px] leading-[56px] text-customTeal md:mb-[4%] md:self-start md:text-[40px]">
                Join Us
              </h5>
              <p className="text-justify text-[16px] font-light leading-[20px] text-customTextGray md:text-[20px] md:leading-[24px]">
                Be a part of the movement that's redefining the offline
                marketing landscape. At MEask, we’re revolutionizing how brands
                connect with consumers, making it easier than ever for both
                sides to interact in meaningful ways. Whether you’re a brand
                eager to expand your reach and boost engagement, or a consumer
                on the lookout for the best deals and promotions, MEask is your
                ultimate platform.
              </p>
              <br />
              <p className="text-justify text-[16px] font-light leading-[20px] text-customTextGray md:text-[20px] md:leading-[24px]">
                For brands, we offer a powerful suite of tools designed to
                streamline the process of creating, managing, and tracking
                promotions, ensuring your message reaches the right audience at
                the right time. Our platform is built to help you maximize your
                marketing efforts with ease and precision.
              </p>
              <br />
              <p className="text-justify text-[16px] font-light leading-[20px] text-customTextGray md:text-[20px] md:leading-[24px]">
                For consumers, MEask transforms the shopping experience by
                offering a simple, intuitive interface that brings the best
                deals and offers directly to you—right when and where you need
                them. No more searching for promotions or missing out on great
                deals. With MEask, discovering and enjoying offers becomes a
                seamless part of your day.
              </p>
              <br />
              <p className="text-justify text-[16px] font-light leading-[20px] text-customTextGray md:text-[20px] md:leading-[24px]">
                Together, we can simplify and enhance the way people connect
                with brands, making the entire process more effective,
                rewarding, and enjoyable for everyone involved. Join us and
                experience a smarter, more connected world of marketing.
              </p>
              <div className="mx-[3%] mt-[7%]  w-full flex flex-col-reverse items-center gap-[24px] md:mx-[8%] md:flex-row md:justify-between">
                <HomepageFooterButton
                  title="Become a Promoter"
                  handleClick={() => navigate("become-promoter")}
                />

                <HomepageFooterButton
                  title="Become a Business Partner"
                  handleClick={() => navigate("for-business")}
                />
              </div>
            </div>
            <img
              src={JoinUs}
              alt="join-us"
              className="m-[5%] h-[145px] w-[182px] md:h-auto md:w-[35%]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
