import React, { useState } from "react";
import RedeemRestaurantImage from "../../assets/redeem-restaurant-image.png";
import { Link } from "react-router-dom";
import ViewTransactionDetails from "./ViewTransactionDetails";

const RedeemedCard = ({
  restaurantImage,
  restaurantName,
  restaurantDistrict,
  restaurantState,
  discount,
  reedemDate,
  amountSaved,
  transactionId,
}) => {
  const [viewTransaction, setViewTransaction] = useState(false);

  return (
    <div className="p-1 md:p-4">
      {viewTransaction && (
        <ViewTransactionDetails
          setViewTransaction={setViewTransaction}
          transactionId={transactionId}
        />
      )}
      <div className="relative flex flex-col overflow-hidden rounded-3xl bg-[#FCFCFC] shadow-xl md:w-[1004px]  w-[340px] sm:w-[400px]  md:flex-row">
        <div className="relative w-full p-2 md:w-auto md:pr-7">
          <img
            src={restaurantImage ?? RedeemRestaurantImage}
            alt="redeem-image"
            className="h-[108px]  w-full rounded-2xl object-cover md:w-32"
          />
        </div>

        <div className="relative flex flex-col items-center gap-4 border-t-4 border-dashed border-gray-400 pt-4 sm:gap-24 md:m-0 md:flex-row md:gap-48 md:border-l-4 md:border-t-0 md:border-dashed md:pl-5 md:pt-0">
          {/* Mobile cutouts */}
          <div className="absolute -left-3 top-[-2px] z-10 h-3 w-6 md:hidden">
            <div className="absolute left-0 h-6 w-6 translate-y-[-50%] rounded-full bg-[#f0f0f0]"></div>
          </div>
          <div className="absolute -right-3 top-[-2px] z-10 h-3 w-6 md:hidden">
            <div className="absolute right-0 h-6 w-6 translate-y-[-50%] rounded-full bg-[#f0f0f0]"></div>
          </div>

          {/* Desktop cutouts */}
          <div className="absolute left-[-2px] top-0 z-10 hidden h-6 w-3 md:block">
            <div className="absolute right-0 h-6 w-6 translate-y-[-50%] rounded-full bg-[#f0f0f0]"></div>
          </div>
          <div className="absolute bottom-0 left-[-2px] z-10 hidden h-6 w-3 md:block">
            <div className="absolute right-0 h-6 w-6 translate-y-[50%] rounded-full bg-[#f0f0f0]"></div>
          </div>

          <div>
            <div className="flex flex-row items-center gap-3 px-3 md:p-0">
              <h1 className="font-outfit text-base font-bold text-[#303030] md:text-2xl">
                {restaurantName ?? "unavailable"}
              </h1>
              <p className="font-outfit flex items-center gap-1 text-sm text-[#7C7C7C]">
                <div className="h-2 w-2 rounded-full bg-[#D9D9D9]"></div>

                {restaurantDistrict + " " + restaurantState ?? "unavailable"}
              </p>
            </div>
            <div className="flex flex-col gap-2 px-3 md:px-0">
              <h2 className="font-outfit text-lg font-bold text-[#525252]">
                Flat {discount}% OFF
              </h2>
              <p className="font-outfit text-base text-[#7C7C7C]">
                Redeemed on - {reedemDate}
              </p>
            </div>
            <div
              className="font-outfit cursor-pointer px-3 text-base text-[#303030] underline underline-offset-2 md:px-0"
              onClick={() => setViewTransaction(true)}
            >
              View transaction details
            </div>
          </div>
        </div>
        <div className="relative md:absolute right-0 flex h-full w-full flex-col items-center justify-center md:w-[150px]">
          {/* Background SVG with zigzag pattern */}

          {/* SVG for medium screens and larger */}
          <svg
            className="absolute inset-0 hidden h-full w-full md:block"
            viewBox="0 0 150 160"
            preserveAspectRatio="none"
          >
            <defs>
              <clipPath id="zigzagClipMedium">
                <path d="M15,0 L0,20 L15,40 L0,60 L15,80 L0,100 L15,120 L0,140 L15,160 H150 V0 Z" />
              </clipPath>
            </defs>
            <rect
              x="0"
              y="0"
              width="150"
              height="160"
              fill="#B8D354"
              clipPath="url(#zigzagClipMedium)"
            />
          </svg>

          {/* SVG for small screens */}
          <svg
            className="absolute inset-0 block h-full w-full md:hidden"
            viewBox="0 0 160 150"
            preserveAspectRatio="none"
          >
            <defs>
              <clipPath id="zigzagClipSmall">
                <path
                  d="
        M160,10 
        L150,0 L140,10 
        L130,0 L120,10 
        L110,0 L100,10 
        L90,0 L80,10 
        L70,0 L60,10 
        L50,0 L40,10 
        L30,0 L20,10 
        L10,0 L0,10 
        V150 H160 Z"
                />
              </clipPath>
            </defs>
            <rect
              x="0"
              y="0"
              width="160"
              height="150"
              fill="#B8D354"
              clipPath="url(#zigzagClipSmall)"
            />
          </svg>

          {/* Content overlay */}
          <div className="  relative z-10 flex w-full flex-row items-center justify-between p-3 text-[#303030] md:flex-col md:justify-center md:gap-3 md:p-0">
            <h2 className="hidden text-xs font-semibold md:block">SAVED</h2>
            <h2 className="text-xs font-bold md:hidden md:font-semibold">
              SAVED on total bill
            </h2>
            <h1 className="font-babbakOne md:text-4xl text-xl">₹{amountSaved}</h1>
            <h2 className="hidden text-xs font-semibold md:block">
              on total bill
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedeemedCard;
