import React, { useEffect, useState } from "react";
import { Search } from "lucide-react";
import { useLocation } from "react-router-dom";

const SubNavbar = () => {
  const location = useLocation()
  const [isDownloadOpen, setIsDownloadOpen] = useState(false);
  const [currentPath , setCurrentPath] = useState(location.pathname)

  useEffect(()=>{
    setCurrentPath(location.pathname)
  },[location.pathname])

  return (
    <nav className={`fixed top-[7px] z-30 mt-[4%] bg-white h-[84px] w-full ${currentPath.includes("account/profile") ? "hidden" : "visible"}`}>
      <div className="flex items-center gap-10 px-10 py-7">
        <button className="rounded-full border-2 border-cyan-400 px-5 py-2  font-semibold text-cyan-500 tracking-wide">
          Combined
        </button>

        <div className="flex flex-grow items-center ">
          <div className="relative flex-grow">
            <input type="text" placeholder="Search" className=" w-1/2 h-12 pl-10 border border-gray-200 rounded-3xl outline-none" />
            <Search className="absolute top-3 left-2 text-gray-500" />
          </div>

          {/* <div className={`cursor-default mx-10`}>
            <div
              className="text-gray-600 underline decoration-gray-300 underline-offset-8 hover:scale-105 hover:text-gray-950"
              onClick={() => setIsDownloadOpen(!isDownloadOpen)}
            >
              Download report
            </div>
            <ul
              className={`absolute right-20 top-20 flex flex-col gap-2 rounded-lg border border-gray-200 bg-white py-1 shadow-xl ${!isDownloadOpen ? "hidden" : false}`}
            >
              <li className="pb-1 pl-4 pr-10 pt-2 hover:bg-gray-200">
                Ad's Library
              </li>
              <li className="pb-1 pl-4 pr-10 pt-2 hover:bg-gray-200">
                Coupons
              </li>
              <li className="pb-1 pl-4 pr-10 pt-2 hover:bg-gray-200">
                Revenue
              </li>
              <li className="pb-1 pl-4 pr-10 pt-2 hover:bg-gray-200">
                Settlement Report
              </li>
              <li className="pb-1 pl-4 pr-10 pt-2 hover:bg-gray-200">
                Tickets
              </li>
              <li className="pb-1 pl-4 pr-10 pt-2 hover:bg-gray-200">
                Payments
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </nav>
  );
};

export default SubNavbar;
