import React, { useEffect, useRef, useState } from "react";
import RegularOfferHeader from "./Regular-Offer-Header";
import CreateAdButton from "../utils/create-ad/Create-ad-button";
import Timeline from "../utils/timiline/Timeline";
import CreateAdForm from "../utils/create-ad/create-ad-form/CreateAdForm";
import EditTimeSlots from "../utils/Edit-time-slot/EditTimeSlot";
import AddAdvertisement from "../utils/add-advertisement/AddAdvertisement";
import { useDispatch, useSelector } from "react-redux";
import { getTimeSlots } from "../../../../store/slices/businessPromoterSlice";

const RegularOffers = () => {
  const [isEditTimeOpen, setIsEditTimeOpen] = useState(false);
  const [showCreateAd, setShowCreateAd] = useState(false);
  // const hasFetched = useRef(false);
  const outletId = useSelector((state) => state.bussinessAuth.currentOutletId);
  const timeSlots = useSelector((state) => state.scheduler.regularSlots);
  const dispatch = useDispatch();

  useEffect(() => {
    if (outletId) {
      dispatch(getTimeSlots({ outletId, adType: "R" }));
      // hasFetched.current = true;
    }
  }, [outletId]);

  // useEffect(()=>{
  //   console.log(timeSlots)
  // },[timeSlots])
  
  const [addAd, setAddAd] = useState({
    open: false,
    startTime: null,
    endTime: null,
  });
  

  return (
    <div className=" relative  bg-red-2 mt-[156px] flex h-screen flex-col items-center px-10 pt-1">
      <div
        className="h-[70%] w-[60%] overflow-auto rounded-3xl border px-5 shadow-xl"
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      >
        <RegularOfferHeader
          isEditTimeOpen={isEditTimeOpen}
          setIsEditTimeOpen={setIsEditTimeOpen}
        />
        {isEditTimeOpen && (
          <EditTimeSlots setIsEditTimeOpen={setIsEditTimeOpen} adType={"R"} />
        )}
        <Timeline
          addAd={addAd}
          adType={"R"}
          timeSlots={timeSlots}
          setAddAd={setAddAd}
        />
      </div>
      {showCreateAd && <CreateAdForm setShowCreateAd={setShowCreateAd} />}
      <CreateAdButton setShowCreateAd={setShowCreateAd} />
    </div>
  );
};

export default RegularOffers;
