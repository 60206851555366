// FormInput.jsx
export const FormInput = ({
    label,
    name,
    type = "text",
    placeholder,
    value,
    onChange,
  }) => (
    <div className="flex flex-1 flex-row items-center gap-4">
      <label
        htmlFor={name}
        className="w-[130px] text-end text-base font-medium text-gray-700"
      >
        {label}
      </label>
      <input
        type={type}
        id={name}
        name={name}
        placeholder={placeholder}
        className="flex-1 rounded-md border p-2 outline-none focus:border-blue-500"
        value={value}
        onChange={onChange}
        autocomplete="off" 
      />
    </div>
  );
  
  // FormSelect.jsx
  export const FormSelect = ({
    label,
    name,
    options,
    value,
    onChange,
  }) => (
    <div className="flex flex-1 flex-row items-center gap-4 ">
      <label
        htmlFor={name}
        className="w-[130px] text-end text-base font-medium text-gray-700"
      >
        {label}
      </label>
      <select
        id={name}
        name={name}
        className="flex-1 rounded-md border p-2 outline-none focus:border-blue-500 "
        value={value}
        onChange={onChange}
      >

       <option value="">Select {label}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
  
  // FormCheckbox.jsx
  export const FormCheckbox = ({ label, name, checked, onChange }) => (
    <div className="flex flex-1 flex-row items-center gap-4">
      <label
        htmlFor={name}
        className="w-[130px] text-end text-base font-medium text-gray-700"
      >
        {label}
      </label>
      <input
        type="checkbox"
        id={name}
        name={name}
        className="h-5 w-5 rounded border-gray-300 text-blue-600"
        checked={checked}
        onChange={onChange}
      />
    </div>
  );
  
  // SectionTitle.jsx
  export const SectionTitle = ({ children }) => (
    <h3 className="mb-4 text-center text-lg font-medium">{children}</h3>
  );