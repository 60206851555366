import React, { useEffect, useState } from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDispatch, useSelector } from "react-redux";
import {
  sendLoginOtp,
  openSignup,
  hideAuthComponent,
} from "../../store/slices/authSlice";
import { X } from "lucide-react";
import CustomInput from "./custom-input";

function LogInSendOtp() {
  const [isVisible, setIsVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const dispatch = useDispatch();

  const verifyOtpSchema = z.object({
    phoneNumber: z
      .string()
      .min(1, { message: "Phone Number is required" })
      .regex(/^\d{10}$/, {
        message: "Phone Number should be of 10 digits",
      }),
  });

  const { loading, error } = useSelector((state) => state.auth);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors: validationErrors },
  } = useForm({
    resolver: zodResolver(verifyOtpSchema),
  });

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      dispatch(hideAuthComponent());
    }, 300);
  };

  const onSubmit = (data) => {
    dispatch(sendLoginOtp({ ...data }));
  };

  useEffect(() => {
    setIsVisible(true);
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };

  return (
    <div
      className="fixed left-0 top-0 z-20 flex h-screen w-screen bg-black/50 md:justify-end"
      onClick={handleOverlayClick}
    >
      <div
        className={`h-screen w-screen transform bg-[#FCFCFC] px-5 py-5 transition-transform duration-300 ease-in-out md:w-[35%] ${
          isVisible && !isClosing ? "md:translate-x-0" : "md:translate-x-full"
        }`}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="relative flex max-w-[700px] justify-between">
            <div className="flex justify-between">
              <div>
                <button
                  type="reset"
                  className="text-2xl text-customHeading hover:scale-110 focus:outline-none md:transition-transform md:duration-200"
                  onClick={handleClose}
                >
                  <X />
                </button>
                <h2 className="mt-[36px] text-[32px] font-bold leading-[35px] text-customHeading">
                  Login
                </h2>
                <div className="mt-[10px] flex">
                  <p>or&nbsp;</p>
                  <p
                    onClick={() => {
                      dispatch(openSignup());
                    }}
                    className="cursor-pointer text-customTeal transition-opacity duration-200 hover:opacity-80"
                  >
                    create an account
                  </p>
                </div>
                <div className="mt-[10px] h-[3px] w-[42px] bg-customHeading"></div>
              </div>
            </div>
            <img
              src="/authentication.svg"
              alt="authentication"
              className="absolute -top-5 right-12 md:right-24 md:h-[260px] md:w-[186px]"
            />
          </div>
          <div className="mt-[40px] max-w-[400px] md:mt-24">
            {error && <p className="mb-5px text-red-400">{error}</p>}

            <CustomInput
              label={"Phone Number"}
              placeholder={"Phone Number"}
              type={"text"}
              register={register}
              name={"phoneNumber"}
              error={validationErrors?.phoneNumber?.message}
            />
            <button
              type="submit"
              className={`mt-[5%] h-[44px] w-full cursor-pointer rounded-md md:h-[52px] ${
                isSubmitting || loading
                  ? "bg-[#EFEFEF] text-[#BDBDBD]"
                  : "bg-customTeal text-[#fcfcfc] hover:opacity-90"
              } py-2 text-[16px] text-sm font-medium transition-all duration-200`}
              disabled={isSubmitting || loading}
            >
              LOGIN
            </button>
          </div>
          <p className="mt-4 text-sm text-[#989898]">
            By clicking on Login, I accept the{" "}
            <a
              href="/#"
              className="font-bold text-[#303030] transition-all duration-200 hover:underline"
            >
              Terms & Conditions
            </a>{" "}
            &{" "}
            <a
              href="/#"
              className="font-bold text-[#303030] transition-all duration-200 hover:underline"
            >
              Privacy Policy
            </a>
            .
          </p>
        </form>
      </div>
    </div>
  );
}

export default LogInSendOtp;
