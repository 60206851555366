import { Plus, Search } from "lucide-react";
import logo from "../assets/sales-logo.svg";
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Paginator from "../components/business-partner/promotion-organizer/Ad-library/Paginator";
import NewClient from "../components/client-onboarding/NewClient";
import { getAllOutlets } from "../store/apis/sales-apis";

const AllClients = () => {
  const [newClient, setNewClient] = useState(false);
  const [editClient, setEditClient] = useState(false);
  const [currentOutletId, setCurrentOutletId] = useState(null);
  const [outlets, setOutlets] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOutlets, setFilteredOutlets] = useState([]);

  const fetchOutlets = async () => {
    const data = await getAllOutlets();
    setOutlets(data);
    setFilteredOutlets(data);
  };

  useEffect(() => {
    fetchOutlets();
  }, [newClient, editClient]);

  // Handle search input changes
  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = outlets.filter((outlet) => {
      const nameMatch = outlet.NAME?.toLowerCase().includes(term);
      const statusMatch = outlet.STATUS?.toLowerCase().includes(term);
      const managerMatch = outlet.ACCOUNT_MANAGER_NAME?.toLowerCase().includes(term);
      const idMatch = outlet.OUTLET_ID?.toString().includes(term);

      return nameMatch || statusMatch || managerMatch || idMatch;
    });

    setFilteredOutlets(filtered);
  };

  return (
    <div className="flex flex-col bg-[#FCFCFC]">
      <header className="fixed left-0 top-0 z-10 flex w-screen items-center justify-between gap-3 bg-[#FCFCFC] px-14 py-4 shadow-lg">
        <div className="flex flex-row items-center gap-2 md:gap-4">
          <img src={logo} alt="logo" />
        </div>

        <div className="mx-20 flex w-full flex-row items-center gap-2 rounded-md border border-gray-200 px-3 py-2 md:w-96 md:gap-4 md:px-5 md:py-3">
          <div className="h-6 w-6 text-[#7C7C7C]">
            <Search />
          </div>
          <input
            type="text"
            className="w-full outline-none"
            placeholder="Search clients..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      </header>

      {(newClient || editClient) && (
        <NewClient
          setNewClient={setNewClient}
          editClient={editClient}
          setEditClient={setEditClient}
          currentOutletId={currentOutletId}
        />
      )}

      <div className="mt-[100px] px-28 py-5">
        <div className="mb-5 flex justify-between">
          <h2 className="text-2xl font-semibold">
            All Clients {filteredOutlets.length !== outlets.length && 
              `(${filteredOutlets.length} results)`}
          </h2>

          <button
            className="flex items-center gap-2 rounded-3xl bg-[#29B3D2] px-10 py-3 text-[#FCFCFC]"
            onClick={() => setNewClient(true)}
          >
            <Plus className="size-6 font-semibold" />
            <span className="font-semibold">Create New Client</span>
          </button>
        </div>

        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", border: "none", width: "100%" }}
        >
          <Table
            sx={{
              minWidth: 700,
              width: "100%",
              tableLayout: "fixed",
              "& .MuiTableHead-root .MuiTableRow-root": {
                borderBottom: "1px solid #ddd",
              },
              "& .MuiTableBody-root .MuiTableRow-root": {
                borderBottom: "none",
              },
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "left",
                    fontSize: "16px",
                    paddingLeft: "16px",
                    width: "10%",
                  }}
                >
                  Id No.
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "left",
                    fontSize: "16px",
                    paddingLeft: "16px",
                    width: "45%",
                  }}
                >
                  Client Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "left",
                    fontSize: "16px",
                    paddingLeft: "16px",
                    width: "20%",
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    textAlign: "left",
                    fontSize: "16px",
                    paddingLeft: "16px",
                    width: "30%",
                  }}
                >
                  Account Manager
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredOutlets.length > 0 ? (
                filteredOutlets.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "& td, & th": {
                        borderBottom: "none",
                        textAlign: "left",
                        paddingLeft: "16px",
                        fontSize: "16px",
                      },
                    }}
                  >
                    <TableCell sx={{ width: "10%" }}>{row.OUTLET_ID}</TableCell>
                    <TableCell sx={{ width: "45%" }}>
                      <span
                        className="cursor-pointer hover:underline hover:underline-offset-2"
                        onClick={() => {
                          setCurrentOutletId(row.OUTLET_ID);
                          setEditClient(true);
                        }}
                      >
                        {row.NAME}
                      </span>
                    </TableCell>
                    <TableCell sx={{ width: "20%" }}>
                      {row.STATUS ?? "NA"}
                    </TableCell>
                    <TableCell sx={{ width: "30%" }}>
                      {row.ACCOUNT_MANAGER_NAME ?? "NA"}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                    No results found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default AllClients;