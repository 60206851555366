import React, { useEffect, useRef, useState } from "react";
import { Drawer, Box } from "@mui/material";
import Avatar from "../../assets/cartoon-avatar.png";
import {
  Megaphone,
  BriefcaseBusiness,
  Menu,
  X,
  UserRound,
  MapPin,
  ChevronDown,
  Search
} from "lucide-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Marquee from "./marquee/marquee";
import Authentication from "../auth/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserDiscounts,
  hideAuthComponent,
  showAuthComponent,
} from "../../store/slices/authSlice";
import Categories from "../landing-page/categories";
import SelectLocation from "./select-location/SelectLocation";

import axiosInstance from "../../utils/axios";
import { getLocation } from "../save-location/save-location";
import { API_BASE_URL } from "../../utils/apiUrls";
import {
  updateCoords,
  updateDefaultLocation,
  updateLocation,
} from "../../store/slices/locationSlice";
import { isCookieExpired, readCookie } from "../../utils/cookie";
import SearchInput from "./search-input/SearchInput";

const NewNavbar = () => {
  const locationUrl = useLocation();
  const businessState = useSelector((state) => state.bussinessAuth);
  const currentPage = locationUrl.pathname.substring(1) || "/";
  const location = useSelector((state) => state.location);
  const bodyRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [selectLocation, setSelectLocation] = useState(false);
  const authComponentVisible = useSelector(
    (state) => state.auth.authComponentVisible,
  );
  const [searchValue , setSearchValue] = useState("Search")
  const userSetting = useSelector((state) => state.userSettings);
  const token = useSelector((state) => state.auth.accessToken);

  const userData = useSelector((state) => state.auth.userData);
  // useEffect(() => {
  //   console.log("updated", location);
  // }, [location]);
  const [previewUrl, setPreviewUrl] = useState(
    userData?.PROFILE_PIC_URL ?? Avatar,
  );

  useEffect(() => {
    if (userData?.PROFILE_PIC_URL) {
      // Add a timestamp to bust cache
      const url = `${userData.PROFILE_PIC_URL}?t=${new Date().getTime()}`;
      setPreviewUrl(url);
    } else {
      setPreviewUrl(Avatar);
    }
  }, [userData?.PROFILE_PIC_URL]);

  const ProfileAndSignInNavItem = () => {
    const isProfilePage = currentPage === "mvpdemo/profile";

    return (userData && userData.NAME ? (
      <li
        className={`flex cursor-pointer items-center space-x-2 px-4 py-2 ${
          isProfilePage
            ? "text-[#29B3D2] underline underline-offset-4"
            : "text-gray-700 hover:text-gray-900"
        }`}
        onClick={() => navigate("/mvpdemo/profile")}
      >
        <div className="flex items-center gap-3">
          <div className="order-0 h-6 w-6 flex-none flex-grow-0">
            <img
              className="h-full w-full rounded-full bg-[#FFC7E0] object-cover"
              src={previewUrl}
              alt={userData.NAME}
            />
          </div>
          <div>
            <span className="text-base text-[#303030]">{userData?.NAME}</span>
          </div>
        </div>
      </li>
    ) : (
      <li
        className="flex cursor-pointer items-center space-x-2 px-4 py-2 text-gray-700 hover:text-gray-900"
        onClick={() => dispatch(showAuthComponent())}
      >
        <UserRound className="h-5 w-5" />
        <span className="text-base text-[#303030]">Sign In</span>
      </li>
    ))
  };

  useEffect(() => {
    const fetchReverseGeocoding = async () => {
      try {
        const response = await axiosInstance.get(
          `${API_BASE_URL}/cmn/revGeo?lat=${location.latitude}&long=${location.longitude}`,
        );
        dispatch(updateDefaultLocation(response.data.location));
      } catch (error) {
        console.error("Error fetching reverse geocoding:", error);
      }
    };
    if (
      location.latitude &&
      location.longitude &&
      !location.landmark &&
      !location.isSelectedLocation
    ) {
      fetchReverseGeocoding();
    }
  }, [location.latitude, location.longitude, location.landmark]);

  useEffect(() => {
    if ((!location.latitude || !location.longitude) && !location.landmark) {
      const locationCookie = readCookie("location");
      let locationObj = {};

      if (locationCookie && !isCookieExpired("location")) {
        locationObj = JSON.parse(locationCookie);
        //If user selected location and refreshed app
        if (locationObj?.landmark && locationObj?.isSelectedLocation) {
          dispatch(updateLocation(locationObj.landmark));
        } else if (locationObj?.latitude && locationObj?.longitude) {
          // if user has current location
          dispatch(
            updateCoords({
              ...locationObj,
            }),
          );
        }
      } else {
        // No location found
        getLocation(dispatch);
      }
    }
  }, []);

  const navItems = [
    {
      name: "Become Promoter",
      icon: Megaphone,
      link: "become-promoter",
      link2: "",
    },
    {
      name: "For Business",
      icon: BriefcaseBusiness,
      link:
        businessState && businessState.bo_id
          ? "/mvpdemo/for-bussiness/promotion-organizer/regular-offers"
          : "mvpdemo/for-bussiness",
      link2: "mvpdemo/for-business-form",
    },
  ];

  return (
    <>
      <nav className="bg-[#FCFCFC] shadow-md">
        <div className="container flex items-center justify-between px-4 py-4 md:mx-auto">
          <div className="flex items-center gap-10 space-x-2">
            <Link to="/mvpdemo/landing-page">
              <img
                src="/me-ask-logo.svg"
                alt="Logo"
                className="h-[32px] w-[144px] cursor-pointer"
                onClick={() =>
                  navigate("/mvpdemo/landing-page", { replace: true })
                }
              />
            </Link>

            <div
              className="w-50 hidden cursor-default items-center gap-2 p-2 md:visible md:flex"
              onClick={() => setSelectLocation(true)}
            >
              <MapPin className="text-gray-500 hover:text-cyan-500" />
              <span className="text-gray-400">
                {location.isSelectedLocation
                  ? location.landmark && location.landmark.slice(0, 15) + "..."
                  : userSetting.settings.PERMISSION_LOCATION_ACCESS === "false"
                    ? "Your Location"
                    : location.landmark &&
                      location.landmark.slice(0, 15) + "..."}
              </span>
              <ChevronDown className="cursor-pointer" />
            </div>

            {/* Location Selector */}
            {selectLocation ? (
              <SelectLocation setSelectLocation={setSelectLocation} />
            ) : null}
          </div>
          <ul className="hidden space-x-5 md:flex">

            <SearchInput/>

            {navItems.map((item) => (
              <li
                key={item.link}
                className={`flex cursor-pointer items-center space-x-2 px-4 py-2 ${
                  currentPage === item.link || currentPage === item.link2
                    ? "text-[#29B3D2] underline underline-offset-4"
                    : "text-gray-700 hover:text-gray-900"
                }`}
                onClick={() => {
                  if (item.link === "#" && item.link2 === "#")
                    dispatch(showAuthComponent());
                  if (item.link !== "#") navigate(item.link);
                }}
              >
                <item.icon
                  className={`h-5 w-5 ${
                    currentPage === item.link || currentPage === item.link2
                      ? "text-[#29B3D2]"
                      : "text-gray-700"
                  }`}
                />
                <span>{item.name}</span>
              </li>
            ))}
            <ProfileAndSignInNavItem />
          </ul>

          {/* Mobile Navigation Links */}
          <div className="container mr-[-16px] mt-[-16px] flex items-end justify-end gap-2 align-middle md:hidden">
            <div className="flex items-end gap-1">
              <span className="text-xs text-gray-500">
                {location.landmark && location.landmark.slice(0, 15) + "..."}
              </span>
              <MapPin
                className="size-6 text-gray-600"
                onClick={() => setSelectLocation(true)}
              />
            </div>

            {/* Location Selector */}
            {selectLocation ? (
              <SelectLocation setSelectLocation={setSelectLocation} />
            ) : null}

            {!isMobileMenuOpen && (
              <Menu
                className="mt[16px] mr-[16px]"
                onClick={() => setMobileMenuOpen(true)}
              />
            )}
            {isMobileMenuOpen && (
              <div
                className="fixed right-0 top-0 z-50 flex h-[100vh] w-[75vw] flex-col justify-start bg-white px-[5%] pt-[7%] shadow-lg"
                onClick={() => setMobileMenuOpen(false)}
              >
                <div className="align-middlex flex justify-between">
                  <div className="flex flex-col align-middle">
                    <h3 className="text-[20px] font-bold leading-[25px]">
                      Menu
                    </h3>
                    <img src="/mobile-menu.svg" alt="Menu" />
                  </div>
                  <X
                    onClick={() => {
                      setMobileMenuOpen(false);
                    }}
                  />
                </div>
                <div className="mt-[20%] flex flex-col justify-start gap-5 align-middle">
                  {navItems.map((item) => (
                    <li
                      key={item.link}
                      className={`flex cursor-pointer items-center space-x-2 px-4 py-2 ${
                        currentPage === item.link || currentPage === item.link2
                          ? "text-[#29B3D2] underline underline-offset-4"
                          : "text-gray-700 hover:text-gray-900"
                      }`}
                      onClick={() => {
                        if (item.link === "#" && item.link2 === "#")
                          dispatch(showAuthComponent());
                        if (item.link !== "#") navigate(item.link);
                      }}
                    >
                      <item.icon
                        className={`mr-[5px] h-5 w-5 ${
                          currentPage === item.link ||
                          currentPage === item.link2
                            ? "text-[#29B3D2]"
                            : "text-gray-700"
                        }`}
                      />
                      <span>{item.name}</span>
                    </li>
                  ))}
                  <ProfileAndSignInNavItem />
                </div>
              </div>
            )}
          </div>
        </div>
        {currentPage === "/" && !isMobileMenuOpen && <Marquee />}{" "}
        {authComponentVisible && <Authentication />}
        
          <div className="md:hidden"> <SearchInput/></div>
        {currentPage.includes("landing-page") && (
          <div className="relative z-0">
            <Categories />
          </div>
        )}
      </nav>
    </>
  );
};

export default NewNavbar;
