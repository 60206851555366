import React, { useEffect, useState, useRef } from "react";
import { X, Locate } from "lucide-react";
import RecentSearch from "./RecentSearch";
import { useDispatch, useSelector } from "react-redux";
import { updateLocation } from "../../../store/slices/locationSlice";
import axiosInstance from "../../../utils/axios";
import { API_BASE_URL } from "../../../utils/apiUrls";
import { getLocation } from "../../save-location/save-location";

const SelectLocation = ({ setSelectLocation }) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const location = useSelector((state) => state.location);
  const [locationEdited, setLocationEdited] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const ref = useRef(null);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setSelectLocation(false);
    }, 300);
  };

  const fetchResults = async (searchTerm) => {
    if (!searchTerm.trim()) {
      setResults([]);
      return;
    }

    setIsSearching(true);
    try {
      const response = await axiosInstance.get(
        `${API_BASE_URL}/cmn/geoSearch?location=${searchTerm}&&limit=3&&offSet=0`,
      );
      const data = response.data;
      setResults(data["locations"] || []);
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setIsSearching(false);
    }
  };

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      fetchResults(query);
    }, 1000);

    return () => clearTimeout(delayDebounce);
  }, [query]);

  const handleOverlayClick = (event) => {
    // Only close if clicking the overlay itself (not its children)
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };

  const recentSearch = [
    { area: "HSR Layout", city: "Bengaluru, Karnataka, India" },
    { area: "HSR Layout", city: "Bengaluru, Karnataka, India" },
    { area: "HSR Layout", city: "Bengaluru, Karnataka, India" },
  ];

  const confirmCurrentLocation = (selectedLocation) => {
    dispatch(updateLocation(selectedLocation.LANDMARK));
    handleClose();
  };

  return (
    <div
      className="fixed left-0 top-0 z-50 h-screen w-screen bg-black/50"
      onClick={handleOverlayClick}
    >
      {/* mainComponent */}
      <div
        className={`flex h-full w-full transform bg-white py-7 transition-transform duration-300 ease-in-out ${
          isVisible && !isClosing ? "translate-x-0" : "-translate-x-full"
        } lg:w-[40%] lg:justify-end`}
      >
        <div className="flex w-full flex-col gap-5  px-4 md:ml-[218px] md:pr-12 lg:w-full lg:gap-10">
          <X
            className="size-6 cursor-pointer self-end text-gray-500 transition-colors duration-200 hover:text-gray-700 lg:self-start"
            onClick={handleClose}
          />

          <div className="flex flex-col gap-5">
            <input
              type="text"
              placeholder="Search for area, street..."
              className="w-full border border-gray-200 px-3 py-2 transition duration-200 outline-none rounded-[4px] "
              value={locationEdited ? query : null }
              onChange={(e) => {
                setQuery(e.target.value);
                setLocationEdited(true);
              }}
            />

            {isSearching && (
              <div className="text-sm text-gray-500">Searching...</div>
            )}

            {!isSearching && results.length > 0 && (
              <ul className="max-h-48 w-full overflow-y-auto rounded-md border border-gray-200 bg-white shadow-md ">
                {results.map((result, index) => (
                  <li
                    key={index}
                    className="cursor-pointer p-2 hover:bg-gray-100 rounded-[4px]"
                    onClick={() => confirmCurrentLocation(result)}
                  >
                    <div className="text-gray-800">{result.LANDMARK}</div>
                  </li>
                ))}
              </ul>
            )}

            <button
              className="flex items-center gap-5 border border-gray-100 p-2 py-4 transition-shadow duration-300 hover:shadow-md rounded-md"
              onClick={() => {
                getLocation(dispatch);
                handleClose();
              }}
            >
              <Locate className="text-gray-500" />
              <div className="flex flex-1 flex-col items-start transition-transform duration-200 hover:scale-[1.01] lg:gap-1">
                <span className="text-center hover:text-cyan-500 lg:text-lg">
                  Get Current Location
                </span>
                <span className="text-sm text-gray-500">Using GPS</span>
              </div>
            </button>
          </div>

          <div className="flex flex-col gap-5">
            <h1 className="text-[16px] tracking-wide text-[#989898] ">
              Recent Searches
            </h1>

            <div className="flex flex-col justify-center gap-1 border border-gray-100 lg:gap-3 rounded-md">
              {recentSearch.map((search, index) => (
                <div
                  key={index}
                  className="transition-transform duration-200 hover:scale-[1.01]"
                >
                  <RecentSearch search={search} />
                  {index !== recentSearch.length - 1 && (
                    <hr className="border-t-2 border-dashed border-gray-300" />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectLocation;