import API_URLS from "../../utils/apiUrls"
import axiosInstance from "../../utils/axios"


export const getAllOutlets  = async()=>{
    const {data} = await axiosInstance.get(`${API_URLS.SALES.get_all_outlets}` )
    console.log(data.outlets)
    return data.outlets
}

export const addUser =async(details)=>{
    const {data} = await axiosInstance.post(`${API_URLS.SALES.add_user}` , details);
    return data.bodetails
}


export const addOutlet = async (formData)=>{
    // console.log(formData.get("profilePic"))
    const {data} = await axiosInstance.post(`${API_URLS.SALES.create_outlet}` , formData , {headers:{"Content-Type": "multipart/form-data"}})
    console.log(data)
    return data.response.outlet
    
}

export const getBusinessOwners = async ()=>{
    const {data} = await axiosInstance.get(`${API_URLS.SALES.get_bo}`)
    return data.data
}

export const editOutlet = async(outletId , formData)=>{
    const {data} = await axiosInstance.put(`${API_URLS.SALES.edit_outlet}/${outletId}` , formData , {headers:{"Content-Type": "multipart/form-data"}} )
    return data?.response[0]

}
