import Cookies from "js-cookie";

export const saveCookie = (key, value, expires) => {
  Cookies.set(key, value, { expires: expires }); // `expires: 10` means 10 days
};

// Read a cookie
export const readCookie = (key) => {
  return Cookies.get(key); // Returns the cookie value or `undefined` if not found
};

// Check if a cookie has expired
export const isCookieExpired = (key) => {
  const value = readCookie(key);
  return value === undefined; // If the value is `undefined`, the cookie is expired or doesn't exist
};
