export const fetchPermissionStatus = async () => {
  try {
    const geoPermission = await navigator.permissions.query({
      name: "geolocation",
    });
    const cameraPermission = await navigator.permissions.query({
      name: "camera",
    });

    return {
      geolocation: geoPermission.state,
      camera: cameraPermission.state,
    };
  } catch (error) {
    console.error("Permission fetch error:", error);
    throw error; // Re-throw to let caller handle the error
  }
};



export const togglePermission = async (type) => {
  try {
    const currentStatus = await fetchPermissionStatus();

    switch (type) {
      case "geolocation":
        if (currentStatus.geolocation === "granted") {
          return {
            success: false,
            message:
              "Please revoke geolocation permission manually in browser settings",
            currentStatus: currentStatus.geolocation,
          };
        } else {
          // Request geolocation permission
          return new Promise((resolve) => {
            navigator.geolocation.getCurrentPosition(
              async () => {
                const newStatus = await fetchPermissionStatus();
                resolve({
                  success: true,
                  message: "Geolocation permission granted",
                  currentStatus: newStatus.geolocation,
                });
              },
              async () => {
                const newStatus = await fetchPermissionStatus();
                resolve({
                  success: false,
                  message: "Geolocation permission denied",
                  currentStatus: newStatus.geolocation,
                });
              },
            );
          });
        }

      case "camera":
        if (currentStatus.camera === "granted") {
          return {
            success: false,
            message:
              "Please revoke camera permission manually in browser settings",
            currentStatus: currentStatus.camera,
          };
        } else {
          try {
            const stream = await navigator.mediaDevices.getUserMedia({
              video: true,
            });
            // Stop all tracks immediately after getting permission
            stream.getTracks().forEach((track) => track.stop());
            const newStatus = await fetchPermissionStatus();
            return {
              success: true,
              message: "Camera permission granted",
              currentStatus: newStatus.camera,
            };
          } catch (error) {
            const newStatus = await fetchPermissionStatus();
            return {
              success: false,
              message: "Camera permission denied",
              currentStatus: newStatus.camera,
            };
          }
        }

      default:
        throw new Error("Invalid permission type");
    }
  } catch (error) {
    return {
      success: false,
      message: error.message,
      currentStatus: null,
    };
  }
};
