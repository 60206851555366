import "./App.css";
import AppRoutes from "./pages/router/router";
import { Provider } from "react-redux";
import store from "./store/store";
import NewAppRoutes from "./pages/router/newRouter";
function App() {
  return (
    <Provider store={store}>
      <div className="mx-auto max-w-[1920px] bg-[#FCFCFC]">
        {/* <AppRoutes /> */}
        <NewAppRoutes />
      </div>
    </Provider>
  );
}

export default App;
//
