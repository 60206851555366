import React, { useState } from "react";
import AccountHeader from "../utils/account-header";
import LogoutModel from "../../../profile/LogoutModel";
import LogoutToast from "../utils/LogoutToast";

// Reusable field component for consistent styling
const ProfileField = ({ label, value, className = "", required = false }) => (
  <div className={className}>
    <span className="relative text-sm text-gray-500">
      {label}
      {required && <span className="absolute">*</span>}
    </span>
    <div className="bg-[#EFEFEF] px-5 py-2 font-semibold tracking-wide text-[#7C7C7C]">
      {value}
    </div>
  </div>
);

// Address section component
const AddressSection = () => (
  <div className="flex flex-col gap-2">
    <span className="relative text-sm text-gray-500">
      Address <span className="absolute">*</span>
    </span>
    <div className="flex flex-col gap-2">
      <div className="w-full bg-[#EFEFEF] px-5 py-2 font-semibold tracking-wide text-[#7C7C7C]">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ullam illum
        corrupti
      </div>
      <div className="w-full bg-[#EFEFEF] px-5 py-2 font-semibold tracking-wide text-[#7C7C7C]">
        Lorem, ipsum dolor.
      </div>

      <div className="flex gap-4">
        {["Bengluru", "Bengluru", "Karnataka", "400116"].map((value, index) => (
          <div
            key={index}
            className="flex-grow bg-[#EFEFEF] px-5 py-2 font-semibold tracking-wide text-[#7C7C7C]"
          >
            {value}
          </div>
        ))}
      </div>
    </div>
  </div>
);

const Profile = () => {
  const [openLogout, setOpenLogout] = useState(false);

  const businessInfo = {
    businessName: "Village Restaurant",
    email: "Support@village.com",
    category: "Village Restaurant",
    contactNumber: "+91 992654865",
    gstin: "MSKNG2024NK",
  };

  return (
    <div className="mt-[87px]">
      <AccountHeader setOpenLogout = {setOpenLogout} />
      {openLogout && <LogoutToast open={openLogout} onClose={() => setOpenLogout(false)} />}
      <div className="mt-5 px-10 pt-1">
        <div className="relative flex h-[75vh] flex-col gap-10 rounded-2xl border px-32 py-5 shadow-xl">
          {/* Business Info and Email Row */}
          <div className="flex justify-between">
            <ProfileField
              label="Business name"
              value={businessInfo.businessName}
              className="w-[625px]"
            />
            <ProfileField
              label="Email"
              value={businessInfo.email}
              className="w-[625px]"
            />
          </div>

          {/* Category and Contact Row */}
          <div className="flex justify-between">
            <ProfileField
              label="Business Category"
              value={businessInfo.category}
              className="w-[625px]"
            />
            <ProfileField
              label="Contact Number"
              value={businessInfo.contactNumber}
              className="w-[625px]"
            />
          </div>

          {/* Address Section */}
          <AddressSection />

          {/* GSTIN Section */}
          <ProfileField
            label="GSTIN"
            value={businessInfo.gstin}
            className="w-full"
            required={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Profile;
