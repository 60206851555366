import axios from "axios";
import API_URLS from "../../utils/apiUrls";
import axiosInstance from "../../utils/axios";
import { readCookie } from "../../utils/cookie";

const token = readCookie("authToken");

export const editProfile = async function (formData) {
  const res = await axiosInstance.post(
    `${API_URLS.REGULAR_USER.edit_profile}`,
    formData, // FormData will be sent as multipart/form-data automatically
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "multipart/form-data", // Required for file upload
      },
    },
  );

  return res.data;
};

export const getCoupons = async () => {
  const res = await axiosInstance.get(`${API_URLS.REGULAR_USER.get_coupons}`, {
    headers: {
      Authorization: `${token}`,
    },
  });

  return res.data.response;
};

export const getRedeemedHistory = async () => {
  const res = await axiosInstance.get(
    `${API_URLS.REGULAR_USER.redeemed_history}`,
    {
      headers: {
        Authorization: `${token}`,
      },
    },
  );
  console.log("redem", res.data.response);
  return res.data.response; //array
};

export const deleteUser = async () => {
  const res = await axiosInstance.delete(
    `${API_URLS.REGULAR_USER.delete_account}`,
    {
      headers: {
        Authorization: `${token}`,
      },
    },
  );
  return res.data;
};

export const getTransactionDetails = async (transactionID) => {
  const res = await axiosInstance.get(
    `${API_URLS.REGULAR_USER.redeemed_history}/${transactionID}`,
    {
      headers: {
        Authorization: `${token}`,
      },
    },
  );
  console.log(res);
  return res.data;
};

export const getUserSettings = async () => {
  const res = await axiosInstance.get(
    `${API_URLS.REGULAR_USER.user_settings}`,
    {
      headers: {
        Authorization: `${token}`,
      },
    },
  );

  return res.data.response
};

export const updateUserSettings = async(settings)=>{
  const res = await axiosInstance.put( `${API_URLS.REGULAR_USER.user_settings}`, settings,
    {
      headers: {
        Authorization: `${token}`,
      },
    },)

    console.log("updated settings" , res.data.response)
    return res.data
}


export const sendHelpEmail  = async(data)=>{
  const res = await axiosInstance.post(`${API_URLS.REGULAR_USER.help_support}` , data , 
    {headers: {
      Authorization: `${token}`,
    },
  })
  return res.data.success
}