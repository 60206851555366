import React, { useEffect, useState } from "react";
import { ArrowRight } from "lucide-react";

const ImageSection = React.memo(({ images, profilePic }) => {

  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
   if(images?.length>1){
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % images.length);
    }, 2000);

    return () => clearInterval(interval);
   }
  }, [images?.length]);

  return (
    <section className={`flex h-44 w-full gap-1 md:h-[60%] md:gap-3  ${(!images || images.length<1) ? " justify-center ": ""} `}>
      {/* Main Image */}
      <div className={`relative h-full  ${(!images || images.length<1) ? " w-[98%] ": "w-2/3 md:w-[75%]"}`}>
        <img
          src={profilePic}
          alt="Main Outlet"
          className="h-full w-full rounded-2xl object-cover"
        />
        {/* <button className="absolute bottom-0 flex w-full justify-center gap-1 rounded-b-2xl bg-white px-4 py-2 text-black opacity-80 duration-300 hover:scale-105 hover:shadow-[4px_4px_8px_rgba(0,0,0,1)] md:bottom-8 md:right-8 md:w-52 md:rounded-lg md:opacity-100">
          View all photos
          <ArrowRight />
        </button> */}
      </div>

      {/* Sliding Carousel */}
      { images &&  images.length>0 && (
        <div className="relative h-full w-[30%] overflow-hidden rounded-2xl md:w-[25%]">
          {/* Image Carousel */}
          <div className="relative h-full w-full overflow-hidden rounded-2xl">
            <div
              className="flex h-full transition-transform duration-500 ease-in-out"
              style={{
                transform: `translateX(-${currentImage * 100}%)`,
              }}
            >
              {images?.map((image, index) => (
                <div
                  key={index}
                  className="relative h-full w-full flex-shrink-0"
                >
                  {/* Image */}
                  <img
                    src={image}
                    alt="highlight image"
                    className="h-full w-full rounded-2xl object-cover"
                  />
                  {/* Inner Shadow */}
                  <div className="absolute bottom-0 left-0 h-20 w-full rounded-2xl bg-gradient-to-t from-black via-black/50 to-transparent" />
                </div>
              ))}
            </div>
          </div>

          {/* Dots for Sliding Effect */}
          <div className="absolute bottom-4 left-1/2 flex -translate-x-1/2 transform items-center gap-2">
            {images.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentImage(index)} // Change image on dot click
                className={`rounded-full ${
                  index === currentImage
                    ? "h-1 w-1 bg-blue-500 md:h-3 md:w-3"
                    : "h-1 w-1 bg-white md:h-2 md:w-2"
                } transition-colors duration-300`}
              />
            ))}
          </div>
        </div>
      )}
    </section>
  );
})

export default ImageSection;
