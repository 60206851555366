import { X, Copy } from "lucide-react";
import React, { useEffect, useState } from "react";
import BankLogo from "../../assets/bank-logo.svg";
import { getTransactionDetails } from "../../store/apis/regular-user-apis";

const ViewTransactionDetails = ({ setViewTransaction , transactionId }) => {
  const [transaction , setTransaction] = useState()
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
  };

  useEffect(() => {
    // Disable scrolling on mount
    document.body.style.overflow = "hidden";

    // Re-enable scrolling on unmount
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const getTransactionData = async()=>{
    const data = await getTransactionDetails(transactionId)
    setTransaction(data)
    console.log("transaction",transaction)
  }

  useEffect(()=>{
    getTransactionData()
    
  },[])

  return (
    <div className="fixed left-0 top-0 z-20 flex h-screen w-screen items-center justify-center bg-black/50 px-5 md:px-0">
      <div className="relative flex h-3/5 md:h-4/5 w-full md:w-3/5 flex-col items-center rounded-3xl bg-white md:px-40 px-5 py-5 md:py-10 md:gap-5 ">
        <X
          className="absolute right-4 top-4 md:size-6 size-5 text-[#7C7C7C] cursor-pointer"
          onClick={() => setViewTransaction(false)}
        />

        <h1 className="text-xl font-semibold text-[#303030] self-start md:self-center">
          Transaction Details
        </h1>

        <div className="relative w-full h-full flex flex-col md:gap-20 gap-6 md:shadow-xl border-t border-gray-100 md:px-10 px-2 py-5 rounded-3xl">
          <div className="flex flex-col w-full gap-2">
            <h3 className="text-[#303030] font-medium">Paid To</h3>
            <div className="flex justify-between md:text-2xl text-lg font-semibold text-[#303030]">
              <span>{transaction?.outlet?.NAME ?? "unavailable"}</span>
              <span>₹ {transaction?.transaction.AMOUNT_AFTER_DISCOUNT ?? 0}</span>
            </div>
          </div>

          <hr className="absolute w-[90%] top-24 left-4 md:top-32 md:left-8" />

          <div className="w-full">
            <div className="flex justify-between my-2 md:text-sm font-medium">
              <span className="text-[#7C7C7C] text-sm">Transaction Id:</span>
              <span className="flex gap-2 text-[#303030] text-xs md:text-sm">
                {transactionId}
                <Copy
                  className="size-4 md:size-5 cursor-pointer"
                  onClick={() => handleCopy(transactionId)}
                />
              </span>
            </div>
            <div className="flex justify-between my-2 md:text-sm font-medium">
              <span className="text-[#7C7C7C] text-sm">Date of Payment:</span>
              <span className="text-[#303030] text-xs md:text-sm">{transaction?.transaction.DATE_OF_PAYMENT}</span>
            </div>
            <div className="flex justify-between my-2 md:text-sm font-medium">
              <span className="text-[#7C7C7C] text-sm">Time of Payment:</span>
              <span className="text-[#303030] text-xs md:text-sm">{transaction?.transaction.TIME_OF_PAYMENT}</span>
            </div>
            <div className="flex justify-between my-2 md:text-sm font-medium">
              <span className="text-[#7C7C7C] text-sm">Payment Method:</span>
              <span className="text-[#303030] text-xs md:text-sm">{transaction?.transaction.RZP_PAYMENT_METHOD}</span>
            </div>
          </div>

          <hr className="absolute w-[90%] bottom-32 left-4 md:bottom-44 md:left-8" />

          <div className="w-full ">
            <h2>Debited from</h2>
            <div className="flex gap-4 w-full my-2 h-14">
              <img src={BankLogo} alt="logo" className="h-10 w-10" />
              <div className="w-full flex flex-col gap-1 justify-end ">
                <div className="flex justify-between text-[#303030] font-semibold">
                  <span className="font-semibold">{transaction?.card.CARD_NUMBER}</span>
                  <span className="font-bold text-lg">₹{transaction?.transaction.AMOUNT_AFTER_DISCOUNT ?? 0}</span>
                </div>

                <div className="flex justify-between items-center">
                  <span className="text-sm text-[#7C7C7C]">UTR: 331681312497</span>
                  <Copy
                    className="text-[#303030] md:text-[#7C7C7C] font-medium size-4 md:size-5 cursor-pointer"
                    onClick={() => handleCopy("331681312497")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewTransactionDetails;
