import React, { useState } from "react";
import { Trash2 } from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
} from "@mui/material";
import EditAdForm from "../utils/edit-add/edit-ad";
import { deleteAdvertisementRequest } from "../../../../store/apis/business-promotion-apis";

const DisplayAds = ({ liveAds, ads, getAds }) => {
  const [showEditAd, setShowEditAd] = useState(false);
  const [editAdId, setEditAdId] = useState(null);
  const cellStyle = {
    maxWidth: "200px",
    wordWrap: "break-word",
    verticalAlign: "top",
    borderBottom: "none",
    fontSize: "16px",
  };

  const headerCellStyle = {
    ...cellStyle,
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  };

  const centerAlignStyle = {
    ...cellStyle,
    textAlign: "center",
  };

  const headerCenterStyle = {
    ...headerCellStyle,
    textAlign: "center",
  };

  const convertToDate = (date) => {
    let timestamp = new Date("02/10/2016").getTime();
    let todate = new Date(timestamp).getDate();
    let tomonth = new Date(timestamp).getMonth() + 1;
    let toyear = new Date(timestamp).getFullYear();
    return tomonth + "/" + todate + "/" + toyear;
  };

  const deleteAdvertisement = async (ad) => {
    const res = await deleteAdvertisementRequest(ad.ADV_ID, ad.ADV_TYPE);
    getAds();
  };

  return (
    <div style={{ marginTop: "2rem" }}>
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={headerCellStyle}>
                <Typography fontWeight="bold">Date</Typography>
              </TableCell>
              <TableCell sx={headerCellStyle}>
                <Typography fontWeight="bold">Reference Name</Typography>
              </TableCell>
              <TableCell sx={headerCellStyle}>
                <Typography fontWeight="bold">Offer Headline</Typography>
              </TableCell>
              <TableCell sx={headerCenterStyle}>
                <Typography fontWeight="bold">Discount % OFF</Typography>
              </TableCell>
              <TableCell sx={headerCenterStyle}>
                <Typography fontWeight="bold">Ad Type</Typography>
              </TableCell>
              {liveAds ? (
                <TableCell sx={headerCenterStyle}>
                  <Typography fontWeight="bold">Venue Name</Typography>
                </TableCell>
              ) : (
                <TableCell sx={headerCenterStyle}>
                  <Typography fontWeight="bold">Action</Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {ads &&
              ads.map((ad, index) => (
                <TableRow key={index}>
                  <TableCell sx={cellStyle}>{ad.DATE}</TableCell>
                  <TableCell sx={cellStyle}>{ad.NAME}</TableCell>
                  <TableCell sx={cellStyle}>
                    {" "}
                    {ad.ADV_TYPE === "Descriptive" ? `${ad.HEADLINE}` : ad.NAME}
                  </TableCell>
                  <TableCell sx={centerAlignStyle}>
                    {ad.ADV_TYPE === "Descriptive"
                      ? `${ad.DISCOUNT_PERCENT}% Off`
                      : "-"}
                  </TableCell>
                  <TableCell sx={centerAlignStyle}>{ad.ADV_TYPE}</TableCell>
                  {liveAds ? (
                    <TableCell sx={centerAlignStyle}>{ad.venueName}</TableCell>
                  ) : (
                    <TableCell sx={centerAlignStyle}>
                      <div className="flex items-center gap-2 justify-self-center text-gray-600">
                        <button
                          className="border border-gray-200 px-3 py-1 hover:scale-[1.02] hover:border-black"
                          onClick={() => {
                            setEditAdId(ad.ADV_ID);
                            setShowEditAd(true);
                          }}
                        >
                          Edit
                        </button>
                        <span className="text-2xl font-semibold text-gray-800">
                          |
                        </span>
                        <button
                          className="hover:text-black"
                          onClick={() => deleteAdvertisement(ad)}
                        >
                          <Trash2 className="h-5 w-5 hover:scale-[1.05]" />
                        </button>
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {showEditAd && (
        <EditAdForm
          setShowEditAd={setShowEditAd}
          editId={editAdId}
          ads={ads}
          getAds={getAds}
        />
      )}
    </div>
  );
};

export default DisplayAds;
