import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LogOut } from "lucide-react";

const AccountHeader = ({setOpenLogout}) => {
  const items = [
    {
      name: "GSTIN",
      slug: "/account/gstin",
      link: "/mvpdemo/for-bussiness/account/gstin",
    },
    {
      name: "User Management",
      slug: "/account/user-management",
      link: "/mvpdemo/for-bussiness/account/user-management",
    },
    {
      name: "Profile",
      slug: "/account/profile",
      link: "/mvpdemo/for-bussiness/account/profile",
    },
  ];

  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  return (
    <div className="flex items-start justify-between">
      <div className="mx-5 flex-1">
        <div className="flex gap-20 px-5 pt-5">
          {items.map((item, index) => (
            <Link to={`${item.link}`}>
              <div
                key={index}
                className={`relative cursor-pointer font-semibold tracking-wide ${currentPath.includes(item.slug) ? "text-cyan-500" : "text-gray-500"}`}
              >
                {item.name}
                {currentPath.includes(item.slug) && (
                  <div className="absolute top-6 z-20 mt-2 w-32 rounded-full bg-cyan-500 p-[1px]" />
                )}
              </div>
            </Link>
          ))}
        </div>
        <hr className="mt-2 bg-black" />
      </div>

      {currentPath.includes("/account/profile") && <button className="mr-24 mt-7 flex gap-4 self-end rounded-full border-2 border-red-500 px-8 py-2 font-semibold text-red-500" onClick={()=>setOpenLogout(true)}>
        <LogOut /> Logout
      </button>}
    </div>
  );
};

export default AccountHeader;
