import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { businessLogin, businessLogout } from "../apis/business-auth-api";
import { readCookie, saveCookie } from "../../utils/cookie";
import Cookies from "js-cookie";

const initialState = {
  userData: {
    phoneNumber: "",
    name: "",
    pan: "",
    designation: "",
    isMaster: false,
    masterId: null,
  },
  signedIn: false,
  loading: false,
  error: null,
  accessToken: "",
  bo_id: "",
  authComponentVisible: false,
  currentOutletId: "",
  allOutletForBusiness: [],
};

export const loginBusinessUser = createAsyncThunk(
  "auth/business/login",
  async ({ username, password }, { rejectWithValue }) => {
    try {
      const bo_data = await businessLogin({ username, password });
      return bo_data.data;
    } catch (error) {
      console.log(error.response.data);
      return rejectWithValue(error.response.data);
    }
  },
);

export const logoutBusinessUser = createAsyncThunk(
  "auth/business/logout",
  async () => {
    try {
      const res = await businessLogout(readCookie("business_auth_token"));
      return res.data;
    } catch (error) {
      console.log(error.response.data);
    }
  },
);

const businessAuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    initializeBusinessAuth: (state) => {
      const authToken = Cookies.get("business_auth_token");
      const userDetails = Cookies.get("businessUserDetails");
      const currentOutlet = readCookie("current_outlet")
      let outletDetails = Cookies.get("businessOutletDetails");
      if(currentOutlet) currentOutlet = JSON.parse(currentOutlet)
      if (outletDetails) outletDetails = JSON.parse(outletDetails);
      if (authToken && userDetails) {
        const parsedUserDetails = JSON.parse(userDetails);
        state.accessToken = authToken;
        state.bo_id = parsedUserDetails.bo_id;
        state.userData.phoneNumber = parsedUserDetails.userData.phoneNumber;
        state.userData.name = parsedUserDetails.userData.name;
        state.userData.pan = parsedUserDetails.userData.pan;
        state.userData.designation = parsedUserDetails.userData.designation;
        state.userData.isMaster = parsedUserDetails.userData.isMaster;
        state.userData.masterId = parsedUserDetails.userData.masterId;
        state.signedIn = parsedUserDetails.signedIn;
        state.loading = parsedUserDetails.loading;
        state.error = parsedUserDetails.error;
        state.authComponentVisible = parsedUserDetails.authComponentVisible;
        if(currentOutlet){
          state.currentOutletId = currentOutlet.OUTLET_ID;
        }
        if (outletDetails) {
          state.allOutletForBusiness = outletDetails;
        }
        console.log("signed in" , parsedUserDetails.signedIn)
      }
      
    },
    updateCurrentLocation: (state, action) => {
      state.currentOutletId = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder

      .addCase(loginBusinessUser.pending, (state) => {
        console.log("pending");
        state.loading = true;
        state.error = null;
      })

      .addCase(loginBusinessUser.rejected, (state, action) => {
        console.log("rejected");
        state.loading = false;
        state.error = action.payload.error;
      })

      .addCase(loginBusinessUser.fulfilled, (state, action) => {
        console.log("fullfilled");
        state.accessToken = action.payload.accessToken;
        state.bo_id = action.payload.businessOwnerDetails.BO_ID;
        state.userData.phoneNumber =
          action.payload.businessOwnerDetails.PHONE_NUMBER;
        state.userData.name = action.payload.businessOwnerDetails.USERNAME;
        state.userData.pan = action.payload.businessOwnerDetails.PAN;
        state.userData.designation =
          action.payload.businessOwnerDetails.DESIGNATION;
        state.userData.isMaster = action.payload.businessOwnerDetails.IS_MASTER;
        state.userData.masterId = action.payload.businessOwnerDetails.MASTER_ID;
        state.loading = false;
        state.error = null;
        state.signedIn = true;
        state.allOutletForBusiness = action.payload.outletDetails;
        state.currentOutletId = action.payload.outletDetails[0].OUTLET_ID;

        const stateToStore = {
          bo_id: state.bo_id,
          userData: {
            phoneNumber: state.userData.phoneNumber,
            name: state.userData.name,
            pan: state.userData.pan,
            designation: state.userData.designation,
            isMaster: state.userData.isMaster,
            masterId: state.userData.masterId
          },
          currentOutletId: state.currentOutletId,
          signedIn :  state.signedIn,
          loading : state.loading,
          error : state.error,
        };
        const compactOutletDetails = action.payload.outletDetails.map(outlet => ({
          OUTLET_ID: outlet.OUTLET_ID,
          NAME: outlet.NAME,
          ADDRESS_LINE_TWO : outlet.ADDRESS_LINE_TWO,
        }));

        console.log("state to store" ,action.payload.outletDetails )

        Cookies.set("business_auth_token", state.accessToken, {expires:1 , path : '/', sameSite:"strict"});
        Cookies.set("businessUserDetails", JSON.stringify(stateToStore), {expires:1 , path : '/', sameSite:"strict"});
        Cookies.set(
          "businessOutletDetails",  
          JSON.stringify(compactOutletDetails),
          {expires:1 , path : '/', sameSite:"strict"},
        );
        Cookies.remove("authToken")

      })

      .addCase(logoutBusinessUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logoutBusinessUser.rejected, (state, action) => {
        state.loading = false;
        console.log(action.payload);
        state.error = "Failed to Logout";
      })
      .addCase(logoutBusinessUser.fulfilled, (state, action) => {
        state.loading = false;
        state.signedIn = false;
        state.error = null;
        state.accessToken = "";
        state.bo_id = "";
        state.userData.phoneNumber = "";
        state.userData.name = "";
        state.userData.pan = "";
        state.userData.designation = "";
        state.userData.isMaster = "";
        state.userData.masterId = null;
        state.loading = false;

        Cookies.remove("business_auth_token");
        Cookies.remove("businessUserDetails");
        Cookies.remove("businessOutletDetails");
      });
  },
});

export const { initializeBusinessAuth, updateCurrentLocation } =
  businessAuthSlice.actions;
export default businessAuthSlice.reducer;
