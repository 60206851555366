import ImageSection from "../components/outlet/ImageSection";
import OutletInfo from "../components/outlet/Outlet-info";
import CurrentOffer from "../components/outlet/CurrentOffer/CurrentOffer";
import GetOfferButton from "../components/outlet/GetOfferButton";
import PayBillButton from "../components/outlet/PayBillButton";
import { useEffect, useState } from "react";
import ShuffleCards from "../components/shuffle-cards/ShuffleCards";
import AfterShuffle from "../components/shuffle-cards/AfterShuffle";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOutletDetails } from "../store/slices/outletsSlice";
import {
  getShuffleDiscountsRequest,
  saveUserSelectedDiscountRequest,
} from "../store/apis/outlets-apis";
import { fetchUserDiscounts } from "../store/slices/authSlice";
import { current } from "@reduxjs/toolkit";

export default function OutletDetail() {
  const [isShuffled, setIsShuffled] = useState(false);
  const [isShuffleOpen, setIsShuffleOpen] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState(0);
  const { outletId } = useParams("outletId");
  const dispatch = useDispatch();
  const outletDetail = useSelector((state) => state.outlets.currentOutlet);
  const [discounts, setDiscount] = useState([]);
  const [corousalImages, setCorousalImages] = useState([]);
  const [showPaybil, setShowPayBill] = useState(false);
  const [redeemedCoupon, setRedeemedCoupon] = useState(false);
  const userDiscounts = useSelector((state) => state.auth.discounts);
  const isLoading = useSelector((state) => state.auth.loading);
  const token = useSelector((state) => state.auth.accessToken);
  useEffect(() => {
    let currentOutletActiveDiscounts = userDiscounts.filter(
      (cur) => String(cur.OUTLET_ID) === outletId && cur.IS_LIVE === "true",
    );

    if (!currentOutletActiveDiscounts.length) {
      setShowPayBill(false);
      setRedeemedCoupon(false);
    } else {
      if (currentOutletActiveDiscounts[0].REDEEMED === "false") {
        setShowPayBill(true);
        setRedeemedCoupon(false);
      } else {
        setShowPayBill(false);
        setRedeemedCoupon(true);
      }
    }
  }, [isShuffled]);

  useEffect(()=>{
    console.log(outletDetail)
  } , [outletDetail])

  useEffect(() => {
    dispatch(fetchUserDiscounts());
  }, []);

  useEffect(() => {
    if (!isLoading && userDiscounts.length > 0) {
      let currentOutletActiveDiscounts = userDiscounts.filter((cur) => {
        console.log(cur.OUTLET_ID, outletId, cur.IS_LIVE);
        return String(cur.OUTLET_ID) === outletId && cur.IS_LIVE === "true";
      });
      console.log("hello", currentOutletActiveDiscounts);
      if (!currentOutletActiveDiscounts.length) {
        setShowPayBill(false);
        setRedeemedCoupon(false);
      } else {
        if (currentOutletActiveDiscounts[0].REDEEMED === "false") {
          setShowPayBill(true);
          setRedeemedCoupon(false);
        } else {
          setShowPayBill(false);
          setRedeemedCoupon(true);
        }
      }
    }
  }, [isLoading, userDiscounts]);

  useEffect(() => {
    dispatch(getOutletDetails(outletId));
    // eslint-disable-next-line
    // setCorousalImages(outletDetail?.ADVERTISEMENTS?.highlightAdv?.map(item => item.IMG_ONE))
  }, [outletId]);

  useEffect(() => {
    setCorousalImages(
      outletDetail?.ADVERTISEMENTS?.highlightAdv?.map((item) => item.PIC_URL),
    );
  }, [outletDetail]);

  useEffect(() => {
    // console.log("disc" , selectedDiscount , isShuffled)
  }, [selectedDiscount, isShuffled]);

  useEffect(() => {
    const getShuffleDataFunction = async () => {
      if (outletDetail && outletDetail.DISCOUNTS.length) {
        const res = await getShuffleDiscountsRequest(
          outletId,
          outletDetail.DISCOUNTS[0].MAC_ID,
        );
        setDiscount(res);
      }
    };
    if (token) getShuffleDataFunction();
    // eslint-disable-next-line
  }, [outletId, outletDetail]);

  return (
    <div className="mx-[3%] mt-[10%] h-screen p-2 sm:mt-[6%] lg:mx-[7%]">
      {/* Image Section */}
      <ImageSection
        profilePic={outletDetail?.PROFILE_PIC_URL}
        images={corousalImages}
      />

      {/* Outlet Information */}
      <OutletInfo outletInfo={{ ...outletDetail, status: "Open now" }} />

      {/* Divider */}
      <hr className="mb-3 mt-10 border-2 text-gray-700 lg:mb-5" />
      <h1 className="text-2xl font-semibold lg:p-2 lg:text-3xl">
        Current Offers
      </h1>

      {/* Current Offer Section */}
      <div className="h-[30rem]">
        <CurrentOffer advertisments={outletDetail?.ADVERTISEMENTS?.descAdv} />
      </div>

      {/* Buttons Section */}
      <div>
        {showPaybil && !redeemedCoupon ? (
          <PayBillButton
            selectedDiscount={selectedDiscount}
            outletID={outletId}
          />
        ) : (
          !redeemedCoupon && (
            <GetOfferButton setIsShuffleOpen={setIsShuffleOpen} />
          )
        )}
      </div>

      {/* ShuffleCards Modal */}
      {isShuffleOpen && !selectedDiscount && (
        <div className="fixed inset-0 z-50 items-center justify-center bg-black bg-opacity-50 md:flex">
          <ShuffleCards
            discounts={discounts}
            setIsShuffled={setIsShuffled}
            isShuffleOpen={isShuffleOpen}
            setIsShuffleOpen={setIsShuffleOpen}
            isShuffled={isShuffled}
            setSelectedDiscount={setSelectedDiscount}
            macId={outletDetail?.DISCOUNTS[0]?.MAC_ID}
          />
        </div>
      )}

      {/* After Shuffle */}
      {isShuffleOpen && isShuffled && selectedDiscount && (
        <div className="fixed inset-0 z-50 items-center justify-center bg-black bg-opacity-50 md:flex">
          <AfterShuffle
            setIsShuffleOpen={setIsShuffleOpen}
            selectedDiscount={selectedDiscount}
            outletID={outletId}
          />
        </div>
      )}

      {/* Buttons Section*/}
      {!isShuffleOpen ? (
        !showPaybil && !redeemedCoupon ? (
          <GetOfferButton setIsShuffleOpen={setIsShuffleOpen} />
        ) : !redeemedCoupon ? (
          <PayBillButton
            selectedDiscount={selectedDiscount}
            outletID={outletId}
          />
        ) : null
      ) : null}
    </div>
  );
}
