import { ChevronLeft } from "lucide-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NewNavbar from "../components/navbar/new-navbar";

const TermsAndConditions = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top smoothly
  }, []);

  return (
    <div className="w-screen">

      <div className="fixed left-0 top-0  w-screen flex-col gap-3 bg-[#FCFCFC] px-3 py-5 shadow-lg  md:flex-row md:items-center md:justify-between md:px-[10%]">
        <div className="flex flex-row items-center gap-2 md:gap-4">
          <ChevronLeft
            className="h-6 w-6 cursor-pointer text-[#303030]"
            onClick={() => navigate(-1)}
          />
          <h1 className="text-lg font-bold text-[#303030] md:text-xl">
            Terms & Conditions
          </h1>
        </div>
      </div>


      <div className="mt-20  px-5 py-4 text-justify text-sm tracking-wide text-[#7C7C7C] md:mt-24  md:px-[10%] md:text-base">
        I. Acceptance of Terms <br />
        <ol className="list-decimal md:px-7 pl-7 md:pl-0 ">
          <li>
            Introduction:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                Thank you for using MEask. These Terms of Service ("Terms")
                outline your legal rights and responsibilities related to your
                use of the MEask website at{" "}
                <a
                  href="https://www.themeask.com/"
                  target="blank"
                  className="underline underline-offset-2"
                >
                  www.themeask.com{" "}
                </a>
                ("Site") and any associated mobile or software applications
                ("MEask Platform"), including but not limited to delivery of
                information via the website, whether existing now or in the
                future (collectively, the "Services").
              </li>
            </ul>
          </li>

          <li>
            Applicability:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                These Terms apply to all existing and future MEask customers,
                including users managing their claimed business listings via the
                'Outlet Business Page.'
              </li>
            </ul>
          </li>

          <li>
            Agreement:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                By accessing or using the MEask Platform, you agree to these
                Terms and enter into a legally binding contract with Meask
                Solutions Private Limited and/or its affiliates (collectively
                referred to as "MEask").
              </li>
              <li>
                If you do not accept or are unable to comply with these Terms,
                you may not use the Services.
              </li>
            </ul>
          </li>

          <li>
            Acceptance Criteria:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                You accept the Terms by:
                <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
                  <li>
                    Clicking to accept or agree to the Terms where this option
                    is provided by MEask.
                  </li>
                  <li>
                    Using the Services, which constitutes your acceptance of
                    these Terms.
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            Usage Risks:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                Your use of the MEask Platform is at your own risk, including
                the possibility of encountering objectionable or inappropriate
                content.
              </li>
            </ul>
          </li>
        </ol>
        II. Definitions <br />
        <ol className="list-decimal md:px-7 pl-7 md:pl-0">
          <li>
            Customer:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                Refers to you as the user of the Services, including those
                managing business listings or participating in other activities
                on the MEask Platform.
              </li>
            </ul>
          </li>

          <li>
            Content:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                Includes reviews, images, photos, audio, video, location data,
                and other forms of information or data.
              </li>
              <li>
                "Your Content" or "Customer Content": Content you upload, share,
                or transmit via the Services.
              </li>
              <li>
                "MEask Content": Content created and provided by MEask, such as
                visual interfaces, interactive features, and aggregate ratings.
              </li>
              <li>
                "Third Party Content": Content from parties other than MEask or
                its Customers, including Outlet Business Partners.
              </li>
            </ul>
          </li>

          <li>
            Outlet:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                Refers to businesses listed on the MEask Platform, including
                restaurants, retail stores, and other establishments.
              </li>
            </ul>
          </li>
        </ol>
        III. Eligibility to Use the Services <br />
        <ol className="list-decimal md:px-7 pl-7 md:pl-0">
          <li>
            Age and Competence:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                You must be at least 18 years old and capable of understanding
                and agreeing to these Terms.
              </li>
            </ul>
          </li>
          <li>
            Compliance with Laws:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                You are responsible for ensuring your use of the Services
                complies with applicable laws and regulations in your
                jurisdiction.
              </li>
            </ul>
          </li>
        </ol>
        IV. Changes to the Terms <br />
        <ol className="list-decimal md:px-7 pl-7 md:pl-0">
          <li>
            Modifications:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                MEask reserves the right to modify or update these Terms at its
                discretion.
              </li>
              <li>
                Continued use of the Services after any changes constitutes your
                acceptance of the revised Terms.
              </li>
            </ul>
          </li>
        </ol>
        V. Translation of the Terms <br />
        <ol className="list-decimal md:px-7 pl-7 md:pl-0">
          <li>
            Language Priority:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                Translations of these Terms are provided for convenience. The
                English version shall govern in case of inconsistencies.
              </li>
            </ul>
          </li>
        </ol>
        VI. Provision of Services <br />
        <ol className="list-decimal md:px-7 pl-7 md:pl-0">
          <li>
            Evolution of Services:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                MEask may update or modify its Services, suspend access, or
                discontinue features without prior notice.
              </li>
            </ul>
          </li>

          <li>
            Updates and Upgrades:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                Automatic updates may be required for continued access to the
                Services. Such updates form part of the Services.
              </li>
            </ul>
          </li>

          <li>
            Account Access Restrictions:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                MEask reserves the right to disable account access, preventing
                access to account details and associated content.
              </li>
            </ul>
          </li>

          <li>
            Service Limits:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                MEask may impose limits on transmissions or usage at its
                discretion.
              </li>
            </ul>
          </li>

          <li>
            Research and Experiments:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                MEask may conduct experiments on various aspects of the Services
                to improve functionality and user experience.
              </li>
            </ul>
          </li>

          <li>
            Disclaimers:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                Content on the Services is for informational purposes only.
                MEask disclaims liability for outdated or incorrect information.
              </li>
              <li>
                MEask does not guarantee product quality, pricing, or
                availability at listed outlets.
              </li>
              <li>
                Certifications displayed on the Platform are provided "as
                available" by business partners, and MEask does not guarantee
                their accuracy or validity.
              </li>
            </ul>
          </li>

          <li>
            Charges and Programs:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                MEask reserves the right to introduce fees, subscription
                charges, referral programs, and incentives.
              </li>
              <li>
                MEask may modify or discontinue programs and credits without
                prior notice.
              </li>
            </ul>
          </li>
        </ol>
        VII. Use of Services by Customers <br />
        <ol className="list-decimal md:px-7 pl-7 md:pl-0">
          <li>
            Account Creation:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                You must create an account to access certain features, including
                claiming a business listing.
              </li>
              <li>Your account details must be accurate and up-to-date.</li>
              <li>
                You are responsible for maintaining the confidentiality of your
                account credentials and all activities under your account.
              </li>
            </ul>
          </li>

          <li>
            Social Media Integration:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                You may register using third-party social networking
                credentials. By doing so, you authorize MEask to access relevant
                account information.
              </li>
            </ul>
          </li>

          <li>
            Prohibited Activities:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                Impersonating others, creating multiple unauthorized accounts,
                or providing false information is prohibited.
              </li>
              <li>
                Unauthorized access or use of another person’s account is
                strictly prohibited.
              </li>
            </ul>
          </li>

          <li>
            Communication:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                By creating an account, you agree to receive communications
                related to the Services, subject to opt-out preferences.
              </li>
            </ul>
          </li>

          <li>
            General Terms:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                Use the Services in compliance with applicable laws and these
                Terms.
              </li>
              <li>
                Data provided by MEask may only be used for personal purposes
                unless expressly authorized.
              </li>
              <li>
                Automated access or interference with the Services is
                prohibited.
              </li>
            </ul>
          </li>
        </ol>
        VIII. Content <br />
        <ol className="px-2">
          <li>
            1. Ownership:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                MEask owns all rights to the Services and its Content, including
                copyrights, trademarks, and proprietary information.
              </li>
              <li>
                Unauthorized use or reproduction of MEask Content is prohibited.
              </li>
            </ul>
          </li>

          <li>
            1. License to Use Content:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                MEask grants you a limited, non-exclusive license to access and
                use its Content for personal purposes.
              </li>
              <li>
                Any violation of this license may result in termination of
                access and potential legal action.
              </li>
            </ul>
          </li>

          <li>
            1. Confidentiality:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                Information designated as confidential by MEask must not be
                disclosed without prior written consent.
              </li>
            </ul>
          </li>

          <li>
            1. Intellectual Property Protection:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                You agree not to infringe MEask’s intellectual property rights
                or use proprietary information without authorization.
              </li>
            </ul>
          </li>

          <li>1. License to MEask and Customers' Content </li>
        </ol>
        By submitting Your Content to the MEask Platform in consideration of
        accessing the Services, you grant MEask an irrevocable, perpetual,
        worldwide, non-exclusive, fully paid, royalty-free, assignable,
        sub-licensable, and transferable license to use Your Content and all
        associated intellectual property rights for any purpose, including but
        not limited to API partnerships with third parties and media in
        existence now or in the future. This "use" includes but is not limited
        to copying, displaying, distributing, modifying, translating,
        reformatting, incorporating into advertisements and other works,
        promoting, commercializing, analyzing, creating derivative works, and
        allowing third parties and their users to do the same. You further grant
        MEask the right to use the name or username associated with Your
        Content. <br />
        You waive any moral rights or attribution claims regarding Your Content,
        including any claims against MEask, its Customers, third-party services,
        or users, in relation to the use of Your Content. <br />
        <span className="px-2">
          1. Representations Regarding Your Content
        </span>{" "}
        <br />
        a. Ownership and Control: You represent and warrant that You are the
        sole author, owner, or otherwise control all rights to Your Content or
        have obtained explicit permission from the rightful holder of such
        rights. You confirm that Your Content was not copied from, nor based on,
        any other content or website, and was not submitted via automated means,
        such as bots or scripts. You also warrant that the use of Your Content
        by MEask, third-party services, and their users will not infringe upon
        or violate any intellectual property or rights of any third party.{" "}
        <br />
        b. Review Content: If Your Content consists of a review, you represent
        and warrant that:
        <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
          <li>You are the sole author of the review.</li>
          <li>
            The review accurately reflects an actual experience (e.g., dining or
            shopping).
          </li>
          <li>
            You were not compensated or influenced in any way for the review.
          </li>
          <li>
            You have no personal, financial, or competitive interests
            influencing the review.
          </li>
        </ul>
        c. Risks: You assume all risks associated with Your Content, including
        the reliability and accuracy of the information, or the disclosure of
        personal information that may make you identifiable. While MEask
        reserves the right to remove Content, it does not control or guarantee
        the quality, accuracy, or integrity of Customer-posted Content. Any
        liability arising from Customer-posted Content rests with the Customer,
        not MEask. <br />
        <span className="px-2">1. Content Removal</span> <br />
        MEask reserves the right to remove, block, or disable access to any
        Content at its sole discretion, without prior notice, if it deems the
        content to be objectionable, in violation of the Terms, or harmful to
        the Services or Customers. MEask is not obligated to return any of Your
        Content, and the Outlet reserves the right to delete any images or
        content associated with Customer listings at its sole discretion.
        <span className="px-2">1. Third-Party Content and Links</span> <br />
        a. Some content on the Services may include or link to materials owned
        by third parties (e.g., third-party reservation services). The use of
        these third-party services will be governed by their respective terms of
        service and privacy policies. MEask may obtain business contact
        information from third-party vendors that source data from public
        platforms. <br />
        b. MEask does not control, endorse, or represent the accuracy, legality,
        or quality of any third-party content, including products, services, and
        advertisements linked to from the Services. MEask makes no warranty
        regarding the completeness, accuracy, or compliance of such third-party
        content. <br />
        c. MEask reserves the right to improve or correct any errors or
        omissions in the content on the Services but is not obligated to do so.
        MEask is not responsible for the availability of external sites or
        resources and does not endorse any advertising, products, or materials
        from such third-party sites. <br />
        d. The views expressed in third-party content, including Customer
        reviews, do not represent MEask’s opinions or that of its affiliates,
        employees, officers, or directors. MEask assumes no responsibility for
        the content posted by customers or third-party partners. <br />
        e. MEask disclaims any liability for loss or damage arising from the use
        of external sites or resources, or reliance on the completeness,
        accuracy, or legality of third-party content. <br />
        <span className="px-2">1. Customer Reviews</span> <br />
        a. Independence of Reviews: Reviews and ratings for Outlets posted by
        Customers reflect the personal opinions of the individual Customers and
        do not represent MEask’s views. MEask is a neutral platform, providing a
        means for communication between Customers and Outlet representatives.{" "}
        <br />
        b. Dispute Resolution: If an Outlet disputes a review, it should contact
        the reviewer or post a public response to clarify the situation. If an
        Outlet believes a review violates MEask policies, it may contact MEask
        at Support@themeask.com. MEask reserves the right to remove reviews that
        violate the Terms or Content Guidelines in its sole discretion. <br />
        IX. Content Guidelines and Privacy Policy <br />
        1. Content Guidelines <br />
        You acknowledge that you have read, understood, and agreed to abide by
        the MEask Content Guidelines and Policies. These guidelines set forth
        the rules and standards for submitting, posting, and managing Content on
        the MEask Platform. <br />
        2. Privacy Policy <br />
        You acknowledge that you have read, understood, and agreed to the MEask
        Privacy Policy. MEask is committed to protecting your privacy but may
        disclose your information to third parties or government authorities
        under the following circumstances: <br />
        a. Suspected Illegal Activities: When disclosure is necessary to take
        action concerning suspected unlawful conduct. <br />
        b. Enforcement of Terms and Policies: To enforce or apply the MEask
        Terms of Service and Privacy Policy. <br />
        c. Legal Compliance: To comply with a legal process or government
        inquiry, including search warrants, subpoenas, statutes, judicial
        proceedings, or other legal notices served on MEask. <br />
        d. Protection of Rights and Interests: To protect the rights,
        reputation, and property of MEask, its Customers, affiliates, or the
        general public. <br />
        X. Restrictions on Use <br />
        In utilizing the MEask Services, you agree not to post or transmit any
        content or engage in any activities that, at MEask's sole discretion,
        violate these Terms. Specifically, you agree not to: <br />
        1. Prohibited Content and Activities <br />
        Violate the MEask Guidelines and Policies, Post or engage in activities
        that are harmful, threatening, abusive, harassing, tortious, indecent,
        defamatory, discriminatory, vulgar, profane, obscene, libelous, hateful,
        invasive of privacy, or otherwise objectionable. This includes content
        promoting gambling or money laundering, Submit inauthentic or knowingly
        false reviews unrelated to the goods, services, or atmosphere of a
        business. Include material that violates the standards of good taste or
        the platform’s standards. Violate any third-party rights, including
        intellectual property, privacy, or publicity rights, Accuse others of
        illegal activities or describe physical confrontations. Address health
        code violations requiring government intervention. Violate any local,
        state, federal, or international laws or regulations, Impersonate
        another individual or entity, Disguise the origin of content, including
        false identities or IP address masking, Create deceptive advertisements
        or engage in conflicts of interest, Engage in spam, surveys, contests,
        pyramid schemes, or any other commercial activity. Assert or imply
        sponsorship or endorsement by MEask, Post content not in English, unless
        relevant to specific services or products, Falsify or conceal
        affiliations with other individuals or entities, Use another customer’s
        account without authorization, Distribute harmful computer code, such as
        viruses, Interfere with or disrupt Services or related servers or
        networks, Access proprietary records or engage in hacking activities,
        Breach any fiduciary or contractual relationships by disclosing
        confidential information, Reverse engineer, decompile, or derive source
        code from MEask Services, Circumvent security features or bypass
        restrictions, Violate any automated restrictions, such as robot
        exclusion headers, Collect or store personal information about other
        customers, Use bots to post content, Harm minors in any way, Threaten
        national unity, integrity, sovereignty, public order, or incite criminal
        offenses, Modify, copy, scrape, or commercialize MEask Services or
        content without authorization, Attempt any prohibited actions outlined
        above, Post false, misleading, or harassing content with the intent to
        cause harm or gain financially. <br />
        2. MEask’s Monitoring Rights <br />
        MEask reserves the right, but is not obligated, to monitor, review, or
        edit content posted or transmitted via its Services. Such actions may be
        taken to enhance platform functionality, ensure compliance with these
        Terms, prevent fraud, and comply with legal obligations or government
        orders. <br />
        3. Lawful Use <br />
        You agree to use the MEask Platform solely for lawful purposes, adhering
        to all applicable local, state, federal, and international laws. You
        further agree not to upload or transmit unsolicited advertising, spam,
        chain letters, or any other unauthorized promotional material. <br />
        4. Prohibition of Abusive Behavior <br />
        While interacting on the MEask Platform, including providing feedback or
        communicating with support agents, you agree not to use abusive or
        derogatory language, or post unlawful, threatening, or defamatory
        content. MEask reserves the right to suspend or block your access to the
        platform or its services if you violate this provision. <br />
        5. Compliance with Applicable Laws <br />
        All content uploaded by you is subject to the laws of India or the
        country of use. Non-compliance with these laws or MEask’s terms and
        policies may result in the removal of non-compliant content, suspension
        of your account, and legal action as deemed appropriate by MEask. <br />
        XI. Customer Feedback <br />
        1. General Feedback <br />
        If you provide MEask with any ideas, suggestions, changes, or documents
        regarding MEask's existing business ("Feedback"), you agree to the
        following: <br />
        a. Your Feedback does not include confidential, proprietary, or
        secretive information belonging to any third party. <br />
        b. MEask has no obligation to maintain confidentiality regarding your
        Feedback and may use it on an unrestricted basis. <br />
        c. MEask may have already received similar Feedback from another
        Customer or it may already be under consideration or development. <br />
        d. By submitting Feedback, you grant MEask a binding, non-exclusive,
        royalty-free, perpetual, and global license to use, modify, develop,
        publish, distribute, and sublicense the Feedback. Additionally, you
        irrevocably waive any claims or assertions against MEask and its
        Customers regarding such Feedback. Please ensure that your Feedback
        pertains only to MEask's existing products or marketing strategies. Do
        not include any ideas that are outside MEask's policy or are otherwise
        unacceptable for consideration. <br />
        2. Prohibition of Unsolicited Ideas <br />
        Notwithstanding the above, MEask and its employees do not accept or
        consider unsolicited ideas, including but not limited to: <br />
        a. New advertising campaigns. <br />
        b. New promotions. <br />
        c. New or improved products or technologies. <br />
        d. Product enhancements, processes, materials, marketing plans, or new
        product names. <br />
        e. Original creative artwork, suggestions, or other works
        ("Submissions"). <br />
        Please refrain from submitting unsolicited ideas in any form to MEask or
        its employees. <br />
        3. Purpose of Policy <br />
        This policy is designed to prevent potential misunderstandings or
        disputes when MEask's products or marketing strategies might appear
        similar to ideas submitted by you. <br />
        4. Terms of Idea Submission <br />
        If you disregard this policy and submit unsolicited ideas
        ("Submissions") to MEask, the following terms will apply regardless of
        any statements in your submission: <br />
        a. Your Submissions and their contents will automatically become the
        property of MEask without any compensation to you. <br />
        b. MEask may use or redistribute the Submissions and their contents for
        any purpose, in any manner, and without restriction. <br />
        c. MEask is under no obligation to review your Submissions. <br />
        d. MEask is under no obligation to maintain confidentiality regarding
        your Submissions. <br />
        XII. Advertising <br />
        <ol className="list-decimal md:px-7 pl-7 md:pl-0">
          <li>
            Ad-Supported Services
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                Some MEask Services are supported by advertising and may display
                ads or promotions.
              </li>
              <li>
                Ads may be targeted based on the content of the Services, user
                queries, or other relevant information.
              </li>
              <li>
                The manner, mode, and extent of advertising on the Services are
                subject to change without prior notice.
              </li>
              <li>
                By using the Services, you agree to the placement of such
                advertising by MEask.
              </li>
            </ul>
          </li>

          <li>
            Third-Party Advertising Content
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                Portions of the MEask Platform or mobile applications may
                include advertisements, promotional materials, or other content
                submitted by third parties or Customers.
              </li>
              <li>
                Responsibility for compliance with applicable laws rests solely
                with the party providing the content.
              </li>
              <li>
                MEask is not liable for errors, omissions, inaccuracies, or any
                damages resulting from such advertising materials or
                interactions with third-party advertisers.
              </li>
            </ul>
          </li>

          <li>
            Interactions with Advertisers
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                Any dealings or correspondence with advertisers, including
                payment and delivery of goods or services, are solely between
                you and the advertiser.
              </li>
              <li>
                MEask assumes no liability for any issues arising from your
                interactions with third-party advertisers on the MEask Platform
                or mobile applications.
              </li>
            </ul>
          </li>

          <li>
            Charitable Campaigns
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                MEask may display information about charitable campaigns
                ("Charitable Campaigns") for informational purposes only.
              </li>
              <li>
                Donations made through third-party websites or bank accounts are
                not collected or managed by MEask.
              </li>
              <li>
                MEask disclaims responsibility for the accuracy, legality, or
                reliability of Charitable Campaign information.
              </li>
              <li>
                Customers are advised to independently verify information before
                taking action regarding any Charitable Campaign.
              </li>
            </ul>
          </li>
        </ol>
        XIII. Additional Terms and Conditions for Customers <br />
        <ol className="list-decimal md:px-7 pl-7 md:pl-0">
          <li>
            Coupon Generation and Redemption <br />
            a. MEask facilitates coupon generation and redemption through
            agreements with Business Partners ("Outlets") on a
            principal-to-principal basis, enabling Customers to explore offers
            and generate coupons on the MEask Platform. <br />
            b. Customers can access offers listed on the MEask Platform and
            generate coupons for the Business Partners/Outlets. <br />
            c. By requesting coupon generation from a Business Partner or Outlet
            page on the MEask Platform, you provide unconditional and
            irrevocable authorization for MEask to generate coupons on your
            behalf. <br />
            d. Coupon redemption may be undertaken directly by the respective
            Business Partner or Outlet. MEask acts solely as an intermediary in
            such cases. <br />
            e. For coupons generated and redeemed, MEask will issue necessary
            documents, such as order summaries and tax invoices, in accordance
            with applicable laws and standard business practices.
          </li>{" "}
          <br />
          <li>Coupon Generation and Redemption with Business Partners</li>
        </ol>
        a. Offer Details: <br />
        All offers displayed on the MEask Platform are provided by the
        respective Business Partner, including offer descriptions and discounts,
        as shared at the time of publication. <br />
        b. Accuracy of Offers: <br />
        MEask strives to ensure the offers are accurate and up-to-date; however,
        the final price charged by the Business Partner shall prevail in case of
        any discrepancy. <br />
        c. Conflict Resolution: <br />
        In the event of a conflict between the offers displayed on the MEask
        Platform and those provided directly by the Business Partner, the latter
        shall be considered the correct offer. <br />
        General Terms and Conditions <br />
        <ol className="list-decimal md:px-7 pl-7 md:pl-0">
          <li>
            Role of MEask
            <ul>
              <li>
                a. MEask acts as a facilitator between Customers and Business
                Partners/Outlets for the sale and purchase of Products. MEask is
                not a manufacturer, seller, or distributor of Products.
              </li>
              <li>
                b. MEask is not liable for any deficiencies or errors by
                Business Partners/Outlets, including product quality, incorrect
                delivery, pricing issues, or quantity discrepancies.
              </li>
            </ul>
          </li>

          <li>
            Responsibility of Business Partners/Outlets
            <ul>
              <li>
                a. Business Partners/Outlets are solely responsible for
                providing warranties or guarantees for the Products sold.
              </li>
              <li>
                b. For Customers in India, any regulatory violations related to
                the Products shall solely be the responsibility of the sellers,
                brand owners, vendors, importers, or manufacturers. Pre-Packaged
                Goods are as defined under applicable laws, including the Legal
                Metrology Act, 2009.
              </li>
            </ul>
          </li>

          <li>
            Product Suitability
            <ul>
              <li>
                {" "}
                a. Some Products may have age restrictions or suitability
                criteria. Customers should review product descriptions before
                purchasing. MEask is not liable if a Product does not meet your
                requirements.
              </li>
            </ul>
          </li>

          <li>
            Resale Prohibition
            <ul>
              <li>
                a. Customers shall not resell Products purchased via the MEask
                Platform.
              </li>
            </ul>
          </li>

          <li>
            Pricing and Payment
            <ul>
              {" "}
              <li>
                {" "}
                a. The total price, including applicable charges, will be
                displayed at checkout. Payments must be made in full.
              </li>
              <li>
                {" "}
                b. Any additional charges by MEask will include applicable
                taxes.
              </li>
            </ul>
          </li>

          <li>
            Coupons
            <ul>
              <li>
                a. Personal coupon codes may only be used by the intended
                recipient and are subject to MEask’s terms.
              </li>
            </ul>
          </li>

          <li>
            Cancellations and Refunds a. No replacement, refund, or resolution
            will be provided without the consent of the Business Partner/Outlet.
            b. Complaints, such as quality issues, damages, or incorrect
            delivery, must be supported with proof and submitted to the Business
            Partner/Outlet for resolution. c. Refunds will not be provided for
            unmet special instructions unless explicitly agreed by the Business
            Partner/Outlet. d. Refunds, if applicable, will be processed using
            the original payment method. Customers are advised to contact or
            visit the Business Partner/Outlet for assistance.
          </li>
        </ol>
        MEask platform to Pay Terms and Conditions (India) <br />
        <ol className="list-decimal md:px-7 pl-7 md:pl-0">
          <li>
            General Terms
            <ul>
              <li>
                a. Customers can pay the Bill Amount (defined below) using any
                payment method in the 'Payment' section of the MEask Platform in
                cities where MEask Pay is available.
              </li>
              <li>
                b. Bill Amount includes the total amount (taxes, service
                charges, and other applicable charges, excluding tips) for
                Products/experiences purchased from MEask-partnered businesses.
              </li>
            </ul>
          </li>

          <li>
            Payment and Confirmation
            <ul>
              <li>
                {" "}
                a. After paying the Bill Amount via MEask Pay, Customers must
                show the payment confirmation to the MEask-partnered business.
              </li>
              <li>
                b. Payments made using MEask Pay may entitle Customers to MEask
                Pay Benefits (defined below), subject to successful payment.
              </li>
            </ul>
          </li>

          <li>
            MEask Pay Benefits
            <ul>
              <li>a. MEask Pay Benefits include:</li>
              <li>i. Instant discounts on the Bill Amount.</li>
              <li>
                ii. Additional bank partner offers, subject to order value
                criteria set by the bank.
              </li>
            </ul>
          </li>

          <li>
            Payment Methods
            <ul>
              <li>
                a. Customers can use MEask Platform to Pay by selecting a
                partnered business on the MEask Platform or through active
                coupons in their user profile coupon section.
              </li>
              <li>
                {" "}
                b. Customers must pay any costs for items not covered under the
                Bill Amount directly to the business partner.
              </li>
              <li>
                c. Payment concerns will be addressed by MEask but are subject
                to verification with the partnered business.
              </li>
            </ul>
          </li>

          <li>
            MEask Coupons
            <ul>
              <li>
                a. To receive MEask Coupons, Customers must shuffle cards and
                use revealed offers before their expiry date (visible on each
                card).
              </li>
            </ul>
          </li>

          <li>
            Eligibility and Conditions
            <ul>
              <li>
                a. Customers must be present at the partnered business/outlet
                when using MEask platform to Pay.
              </li>
            </ul>
          </li>

          <li>
            Suspension/Termination
            <ul>
              <li>
                a. MEask may suspend or terminate MEask Pay Benefits if the
                Customer:
              </li>
              <li>i. Violates MEask Pay terms.</li>
              <li>ii. Engages in fraudulent or unlawful activities.</li>
              <li>
                b. MEask reserves the right to block MEask Pay Benefits for
                violations or suspicious activities.
              </li>
            </ul>
          </li>

          <li>
            Applicability and Exclusions
            <ul>
              <li>
                a. MEask Pay Benefits apply daily, with default expiry by 2am.
              </li>
            </ul>
          </li>

          <li>
            Modification and Discontinuation
            <ul>
              <li>
                a. MEask may modify or discontinue MEask Pay Benefits and terms
                at any time without prior notice.
              </li>
            </ul>
          </li>

          <li>
            Voluntary Use
            <ul>
              <li>
                a. MEask Pay is offered on a “best effort” basis, and its use is
                voluntary.
              </li>
            </ul>
          </li>

          <li>
            Support
            <ul>
              <li>
                a. For queries or assistance, contact chat support or email
                support@themeask.com.
              </li>
            </ul>
          </li>
        </ol>
        XIV. Disclaimer of Warranties, Limitation of Liability, and
        Indemnification <br />
        <ol className="list-decimal md:px-7 pl-7 md:pl-0">
          <li>
            Disclaimer of Warranties
            <ul>
              {" "}
              <li>
                a. The Services, including mobile apps, are provided "as is" and
                "as available." Use of the Services is at your sole risk.
              </li>
              <li>
                b. To the fullest extent permitted by law, MEask and its
                affiliates, officers, employees, agents, and licensors ("MEask
                Parties") disclaim all express or implied warranties, including
                but not limited to:
              </li>
              <li>i. Accuracy or completeness of the content.</li>
              <li>ii. Errors, bugs, viruses, or unauthorized access.</li>
              <li>iii. Service interruptions or loss of data.</li>
              <li>
                iv. Personal injury or property damage arising from use of the
                Services.
              </li>
              <li>
                c. MEask is not liable for transactions between you and
                third-party providers or for any advice or information received
                through the Services.
              </li>
            </ul>
          </li>

          <li>
            Limitation of Liability
            <ul>
              {" "}
              <li>
                a. To the fullest extent permitted by law, MEask Parties are not
                liable for damages arising from:
              </li>
              <li>
                {" "}
                i. Errors, inaccuracies, or interruptions in the Services.
              </li>
              <li> ii. Unauthorized access or security breaches.</li>
              <li>iii. Bugs, viruses, or data loss.</li>
              <li>
                iv. Reliance on advertising or relationships with advertisers or
                sponsors.
              </li>
              <li>v. Delays or failures beyond MEask’s reasonable control.</li>
              <li>
                b. MEask Parties are not liable for indirect, incidental,
                punitive, or consequential damages, including loss of profit,
                goodwill, data, or reputation.
              </li>
            </ul>
          </li>

          <li>
            Indemnification
            <ul>
              {" "}
              <li>
                a. You agree to indemnify and hold harmless MEask Parties from
                third-party claims, damages, or expenses arising from:
              </li>
              <li>i. Your content or unauthorized use of the Services.</li>
              <li>
                ii. Breach of these Terms or infringement of intellectual
                property rights.
              </li>
              <li>
                iii. Access or use of the Services in violation of another
                party’s rights.
              </li>
              <li>
                {" "}
                b. MEask reserves the right to settle or defend claims at its
                discretion and requires your cooperation in any defense. You may
                not settle any claim involving MEask without prior written
                consent.
              </li>
            </ul>
          </li>
        </ol>
        XV. Termination of Access to the Services <br />
        <ol className="list-decimal md:px-7 pl-7 md:pl-0">
          <li>
            User-Initiated Termination:
            <ul>
              <li>
                You may delete your account at any time by contacting us through
                the "Contact Us" link on the website and discontinuing use of
                the Services.
              </li>
            </ul>
          </li>

          <li>
            MEask-Initiated Termination:
            <ul>
              {" "}
              <li>
                MEask may terminate or restrict your access to the Services at
                its sole discretion, without prior notice, for any reason,
                including:
              </li>
              <li> i. Violation of these Terms.</li>
              <li> ii. Inactivity or lack of use of the Services.</li>
            </ul>
          </li>

          <li>
            Consequences of Termination:
            <ul>
              <li>
                {" "}
                Upon termination, MEask may deactivate or delete your account,
                along with all related data, and bar further access. If
                termination is due to a violation of these Terms, MEask may
                retain data collected from your use of the Services. MEask is
                not liable for termination of access or data deletion.
              </li>
            </ul>
          </li>
        </ol>
        XVI. General Terms <br />
        <ol className="list-decimal md:px-7 pl-7 md:pl-0">
          <li>
            Interpretation:
            <ul>
              <li>
                Section and subject headings are for reference only and do not
                affect the interpretation of these Terms.
              </li>
            </ul>
          </li>

          <li>
            Entire Agreement:
            <ul>
              <li>
                These Terms, along with the Privacy Policy and Guidelines,
                constitute the full agreement between you and MEask regarding
                the Services. Any failure by MEask to enforce its rights does
                not waive those rights.
              </li>
            </ul>
          </li>

          <li>
            Severability:
            <ul>
              <li>
                If any provision is deemed invalid or unenforceable, the
                remaining provisions will remain in effect.
              </li>
            </ul>
          </li>

          <li>
            Partnership or Agency:
            <ul>
              <li>
                These Terms do not create a partnership, agency, or authority
                for you to bind MEask.
              </li>
            </ul>
          </li>

          <li>
            Governing Law and Jurisdiction:
            <ul>
              <li>
                i. For users in India, these Terms are governed by Indian law,
                with exclusive jurisdiction in Maharashtra courts.
              </li>
              <li>
                ii. Legal action must be initiated within one (1) year of the
                alleged harm, or the claim will be barred.
              </li>
            </ul>
          </li>

          <li>
            Carrier Rates:
            <ul>
              <li>
                Accessing Services through a mobile or other device may incur
                charges from your internet or mobile provider. You are solely
                responsible for such costs.
              </li>
            </ul>
          </li>

          <li>
            Linking and Framing:
            <ul>
              <li>i. Framing the Services is prohibited.</li>
              <li>
                ii. Linking is permitted only if the linked site does not
                contain inappropriate, infringing, or unlawful content.
              </li>
              <li>
                iii. Violations may result in immediate termination of access to
                the Services.
              </li>
            </ul>
          </li>
        </ol>
        XVII. Notice of Copyright Infringement <br />
        <ol className="list-decimal md:px-7 pl-7 md:pl-0">
          <li>
            MEask's Liability:
            <ul>
              <li>
                {" "}
                MEask is not responsible for copyright infringement arising from
                content posted, transmitted, or advertised by users or third
                parties on the MEask Platform.
              </li>
            </ul>
          </li>

          <li>
            Respect for Intellectual Property:
            <ul>
              <li>
                {" "}
                MEask respects intellectual property rights and requires users
                to do the same. We may remove or disable access to material that
                infringes on copyrights and may terminate the accounts of repeat
                infringers at our sole discretion.
              </li>
            </ul>
          </li>

          <li>
            Filing a Copyright Infringement Notice:
            <ul>
              {" "}
              <li>
                If you believe your copyright has been infringed, follow these
                steps to file a notification:
              </li>
              <li>i. Identify the copyrighted material allegedly infringed.</li>
              <li>
                ii. Identify the infringing material on the Services with enough
                detail to locate it (e.g., username and business listing).
              </li>
              <li>
                iii. Include this statement: "I have a good faith belief that
                the use of the content on the Services as described above is not
                authorized by the copyright owner, its agent, or law."
              </li>
              <li>
                iv. Include this statement: "I swear under penalty of perjury
                that the information in my notice is accurate and that I am the
                copyright owner or authorized to act on the copyright owner's
                behalf."
              </li>
              <li>
                v. Provide your contact information (address, phone number, and
                email).
              </li>
              <li> vi. Provide your physical or electronic signature.</li>
              <li>vii. Send the notice to support@themeask.com.</li>
            </ul>
          </li>

          <li>
            False Claims:
            <ul>
              <li>
                You may be held liable for knowingly making false statements in
                a takedown notice.
              </li>
            </ul>
          </li>
        </ol>
        XVIII. Contact Us <br />
        <ol className="list-decimal md:px-7 pl-7 md:pl-0">
          <li>
            Company Details
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>Legal Name: Meask Solutions Private Limited</li>
              <li>CIN: U73100MH2024PTC426561 </li>
              <li>
                Registered Address: ARYANAN-2 APARTMENT, FLAT NO.A-2, 1ST FLOOR,
                Chandrapur, Chandrapur Ho, Chandrapur- 442401, Maharashtra
              </li>
              <li>
                Website and App: www.themeask.com and "MEask" mobile application
              </li>
              <li>Contact: support@themeask.com </li>
            </ul>
          </li>

          <li>
            Grievance Redressal Mechanism <br />
            i. Customer Support:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                For order issues, contact us via chat support on the MEask app
                for immediate assistance or email support@themeask.com.
              </li>
              <li>
                Complaints via email will receive a unique ticket number for
                tracking.
              </li>
              <li>
                If unsatisfied with chat or email support, escalate the issue to
                the Grievance Officer with the ticket number for resolution
                within 48 hours.
              </li>
            </ul>
          </li>

          <li>
            ii. Grievance Officer:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>Name: Tanay Waghe</li>
              <li>Designation: Chief Operating Officer</li>
              <li>
                Address: ARYANAN-2 APARTMENT, FLAT NO.A-2, 1ST FLOOR,
                Chandrapur, Chandrapur Ho, Chandrapur- 442401, Maharashtra
              </li>
              <li>Email: tanay@themeask.com</li>
              <li>Availability: Monday to Friday, 9:00 a.m. to 6:00 p.m.</li>
            </ul>
          </li>

          <li>
            iii. Nodal Officer:
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>Name: Neeraj Kumar</li>
              <li>Designation: Chief Executive Officer</li>
              <li>
                Address:ARYANAN-2 APARTMENT, FLAT NO.A-2, 1ST FLOOR, Chandrapur,
                Chandrapur Ho, Chandrapur- 442401, Maharashtra
              </li>
              <li>Email: admin@themeask.com</li>
            </ul>
          </li>

          <li>
            Compliance Notice
            <ul className="list-disc md:px-8 pl-5 md:pl-0 marker:text-xs marker:leading-tight">
              <li>
                This grievance redressal mechanism complies with The Information
                Technology Act, 2000, The Consumer Protection Act, 2019, and
                related rules.
              </li>
              <li>
                MEask does not request sensitive details such as OTP, CVV, PIN,
                or card numbers. Do not disclose such information to
                unauthorized persons claiming to represent MEask. Report any
                suspicious activity to support@themeask.com.
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default TermsAndConditions;
