import { X } from "lucide-react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const PaymentConfirmation = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log("location state", state);
  const data = state;
  return (
    <div className="fixed z-50 flex h-screen w-screen items-center justify-center rounded-3xl bg-white">
      <div className="relative flex h-full w-full flex-col items-center gap-12 border-2 border-gray-300 bg-[#FCFCFC] py-16 shadow-xl md:mx-4 md:h-[550px] md:w-[425px] md:rounded-3xl md:py-0">
        {/* Left Cutout*/}
        <div
          className={`shadow-r-inner absolute left-[-30px] top-52 hidden h-10 w-10 -translate-y-1/2 rounded-full border-r-2 border-gray-300 bg-white md:flex`}
        ></div>

        {/* Right Cutout */}
        <div
          className={`shadow-r-inner absolute right-[-30px] top-52 hidden h-10 w-10 -translate-y-1/2 rounded-full border-l-2 border-gray-300 bg-white md:flex`}
        ></div>

        <X
          className="absolute right-3 top-3 cursor-pointer text-[#7C7C7C]"
          onClick={() => navigate("/mvpdemo/redeemed-history")}
        />

        <div className="flex flex-col items-center gap-2">
          <div className="flex flex-col items-center">
            <iframe
              src="https://lottie.host/embed/d8a1ad8b-4c6f-4612-851d-f5f34f3d4bb4/OyK1AnIjlp.lottie"
              className="h-[100px] w-[100px]"
            ></iframe>
            <span className="text-xl font-semibold text-[#7C7C7C]">
              Payment Successful!
            </span>
          </div>
          <span className="text-2xl font-semibold text-[#1E1E1E]">
            ₹ {data.AMOUNT_AFTER_DISCOUNT}
          </span>
        </div>

        <div className="absolute top-52 w-full px-5 md:px-10">
          <hr className="w-full p-1" />
        </div>

        <div className="flex w-full flex-col gap-8 px-4 py-2 md:px-4">
          <div className="flex flex-col gap-2">
            <div className="flex justify-between">
              <span className="text-[#7C7C7C]">Transaction ID :</span>
              <span className="text-sm text-[#303030]">{data.ID}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-[#7C7C7C]">Paid to :</span>
              <span className="text-[#303030]">{data.NAME}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-[#7C7C7C]">Date of Payment :</span>
              <span className="text-[#303030]">{data.date}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-[#7C7C7C]">Time of Payment :</span>
              <span className="text-[#303030]"> {data.time}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-[#7C7C7C]">Payment Method :</span>
              <span className="text-[#303030]">{data.RZP_PAYMENT_METHOD}</span>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex justify-between">
              <span className="text-[#7C7C7C]">Total Bill : </span>
              <span className="text-[#303030]">₹ {data.TOTAL_AMOUNT}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-[#7C7C7C]">Coupon Applied :</span>
              <span className="text-[#303030]">- ₹{data.DISCOUNT_AMOUNT}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-[#7C7C7C]">Transaction Charges :</span>
              <span className="text-[#303030]">
                ₹ {data.TRANSACTION_CHARGES}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="text-[#7C7C7C]">Platform Fees :</span>
              <span className="text-[#303030]">₹ {data.PLATFORM_CHARGES}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentConfirmation;

// config
// :
// {transitional: {…}, adapter: Array(3), transformRequest: Array(1), transformResponse: Array(1), timeout: 0, …}
// data
// :
// message
// :
// "success"
// successData
// :
// Array(1)
// 0
// :
// AMOUNT_AFTER_DISCOUNT
// :
// "870"
// CONVENIENCE_FEE
// :
// "2"
// DISCOUNT_AMOUNT
// :
// "130"
// ID
// :
// "13dd9f2a-4d17-4fce-834f-641389e5e43b"
// NAME
// :
// "Dummy - Mihan, Nagpur"
// RZP_ORDER_ID
// :
// "order_PmOVDi7A6Pcz03"
// RZP_PAYMENT_METHOD
// :
// "upi"
// TOTAL_AMOUNT
// :
// "1000"
// USER_ID
// :
// "6632590"
// date
// :
// "21/01/1970"
// time
// :
// "08:08:48"
