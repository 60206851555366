import { createSlice } from "@reduxjs/toolkit";
import { readCookie, saveCookie } from "../../utils/cookie";
import Cookies from "js-cookie"

const initialState = {
  latitude: "",
  longitude: "",
  landmark: "",
  isSelectedLocation: false,
  fetchedLocation: false,
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    updateLocation: (state, action) => {
      const updatedState = {
        ...state,
        landmark: action.payload,
        isSelectedLocation: true,
        longitude: "",
        latitude: "",
        fetchedLocation: true,
      };

      saveCookie("location", JSON.stringify(updatedState), 1);
      return updatedState;
    },
    updateDefaultLocation: (state, action) => {
      const updatedState = {
        ...state,
        landmark: action.payload,
        isSelectedLocation: false,
        fetchedLocation: true,
      };

      if (navigator.permissions) {
        navigator.permissions.query({ name: 'geolocation' })
          .then(permissionStatus => {
            if (permissionStatus.state === 'granted') {
              
            }
            else if(permissionStatus.state !== 'granted' && state.latitude ){
              //dont save location cookie
              Cookies.remove("location")
            }
            else if( permissionStatus.state !== 'granted' && state.latitude ){
              saveCookie("location", JSON.stringify(updatedState), 1);
            }
          })
          .catch(console.error);
      }

      return updatedState;
    },
    updateCoords: (state, action) => {
      const updatedState = {
        ...state,
        ...action.payload,
        landmark: "",
        isSelectedLocation: false,
        fetchedLocation: true,
      };

      if (navigator.permissions) {
        navigator.permissions.query({ name: 'geolocation' })
          .then(permissionStatus => {
            if (permissionStatus.state === 'granted') {
              saveCookie("location", JSON.stringify(updatedState), 1);
            }
          })
          .catch(console.error);
      }

      return updatedState;
    },
    // initializeLocation: (state) => {
    //   const locationCookie = readCookie("location");
    //   return locationCookie ? { ...state, ...JSON.parse(locationCookie) } : state;
    // }
  },
});

export const { updateLocation, updateDefaultLocation, updateCoords, initializeLocation } =
  locationSlice.actions;
export default locationSlice.reducer;