import API_URLS from "../../utils/apiUrls";
import axiosInstance from "../../utils/axios";

export const businessLogin = async ({ username, password }) => {
  const res = await axiosInstance.post(`${API_URLS.BUSINESS_AUTH.login}`, {
    username,
    password,
  });

  return res
};

export const businessLogout = async(token)=>{
    const res = await axiosInstance.post(`${API_URLS.BUSINESS_AUTH.logout}` , {} , {headers:{
        Authorization : `${token}`
    }});
    return res
}
