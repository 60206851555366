import React from "react";
import { useSelector } from "react-redux";
import Login from "./login";
import Signup from "./signup";

function Authentication() {
  const sidebarState = useSelector((state) => state.auth["sidebarState"]);

  if (sidebarState === "login")
    return (
      <div className="z-20 ">
        <Login />
      </div>
    );
  else {
    return (
      <div className="z-20">
        <Signup />
      </div>
    );
  }
}

export default Authentication;
