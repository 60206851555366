import { useEffect, useState } from "react";
import { Search, X } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { scheduleAds } from "../../../../../store/slices/businessPromoterSlice";

export default function AddAdvertisement({
  adType,
  setAddAd,
  startTime,
  endTime,
  curDay,
  curDate,
}) {
  const { adsState, adsToSchedule } = useSelector((state) => {
    switch (adType) {
      case "R":
        return {
          adsState: state.scheduler.regularSlots,
          adsToSchedule: state.scheduler.regAdsToSchedule,
        };
      case "E":
        return {
          adsState: state.scheduler.eventSlots,
          adsToSchedule: state.scheduler.eventAdsToSchedule,
        };
      case "D":
        return {
          adsState: state.scheduler.dailySlots,
          adsToSchedule: state.scheduler.dailyAdsToSchedule,
        };
      default:
        return { adsState: [], adsToSchedule: [] };
    }
  });
  const outletId = useSelector((state) => state.bussinessAuth.currentOutletId);

  const [ads, setAds] = useState([]);
  const dispatch = useDispatch();
  const handleScheduleAds = () => {
    console.log("Scheduling ads", outletId);
    const data = {};
    data.adType = adType;
    data.outletId = outletId;
    data.startSlot = startTime;
    data.endSlot = endTime;
    data.day = curDay;
    data.date = curDate;
    const advData = ads
      .filter((ad) => ad.SCHEDULED_STATUS) // Only include ads with SCHEDULED_STATUS true
      .map((ad) => ({
        advId: ad.ADV_ID,
        startSlot: startTime,
        endSlot: endTime,
        enabled: ad.SCHEDULED_STATUS.toString(),
        day: curDay,
        date: curDate,
      }));

    data.advObj = advData;
    console.log("ads", ads, data);
    if (adType === "D") {
    } else if (adType === "E") {
    }

    dispatch(scheduleAds(data));

    setAddAd(null);
  };

  useEffect(() => {
    // Find the ads for the current time slot
    const matchingAd = adsState.find(
      (ad) => ad.startTime === startTime && ad.endTime === endTime,
    );

    // Get scheduled ads or set an empty array
    const currentAdsState = matchingAd?.SCHEDULED_ADV || [];

    // Merge adsToSchedule with the current scheduled ads
    const mergedAds = adsToSchedule.map((ad) => {
      const isLive = currentAdsState.some(
        (stateAd) => stateAd.ADV_ID === ad.ADV_ID,
      );

      return {
        ...ad,
        SCHEDULED_STATUS: isLive,
      };
    });

    setAds(mergedAds);
  }, [adsState, adsToSchedule, startTime, endTime]);

  const [searchQuery, setSearchQuery] = useState("");

  const handleToggle = (id) => {
    setAds((prevAds) =>
      prevAds.map((ad) =>
        ad.ADV_ID === id
          ? { ...ad, SCHEDULED_STATUS: !ad.SCHEDULED_STATUS }
          : ad,
      ),
    );
  };

  // const filteredAds = ads.filter((ad) =>
  //   `${ad.HEADLINE} - ${ad.DISCOUNT_PERCENT} %OFF`
  //     .toLowerCase()
  //     .includes(searchQuery.toLowerCase()),
  // );

  const liveAdsCount = ads.filter((ad) => ad.isLive).length;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
      <div className="relative w-7/12 rounded-lg bg-white p-6 shadow">
        {/* Header */}
        <div className="relative mb-6 flex items-center justify-center">
          <h1 className="text-xl font-semibold">Add Advertisement</h1>
          <button className="absolute right-0 text-gray-400 hover:text-gray-600">
            <X className="h-5 w-5" onClick={() => setAddAd(null)} />
          </button>
        </div>

        {/* Search */}
        <div className="full relative mb-6 flex justify-center">
          <Search className="absolute left-40 top-1/2 size-6 -translate-y-1/2 transform text-gray-400" />
          <input
            type="text"
            placeholder="Search Ads"
            className="w-2/3 rounded-md border border-gray-200 py-2 pl-10 pr-4 outline-none"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        {/* Live Ads Counter */}
        {/* <div className="mb-2 text-right text-sm text-gray-500">
          Live Ads: {liveAdsCount}/3
        </div> */}

        {/* Table Header */}
        <div className="mb-4 grid grid-cols-[150px,1fr,auto] gap-4">
          <div className="text-sm font-medium">Date</div>
          <div className="text-sm font-medium">
            Advertisement ({ads.length})
          </div>
          <div className="justify-self-end text-sm font-medium">
            Scheduled Ads
          </div>
        </div>

        {/* Ads List */}
        <div
          className="mb-6 max-h-[400px] space-y-4 overflow-y-auto"
          style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
        >
          {ads.map((ad, idx) => (
            <div
              key={idx}
              className="grid grid-cols-[150px,1fr,auto] items-center gap-4"
            >
              <div className="text-sm text-gray-600">{ad.date}</div>
              <div className="text-sm text-gray-900">{`${ad.HEADLINE} - ${ad.DISCOUNT_PERCENT}% OFF`}</div>
              <div className="justify-self-end">
                <button
                  className={`h-6 w-12 rounded-full transition-colors duration-200 ease-in-out ${
                    ad.SCHEDULED_STATUS ? "bg-[#31C3E0]" : "bg-gray-200"
                  } relative ${!ad.isLive >= 3 ? "cursor-not-allowed opacity-50" : "cursor-pointer"}`}
                  onClick={() => handleToggle(ad.ADV_ID)}
                  value={ad.SCHEDULED_STATUS}
                >
                  <span
                    className={`absolute left-1 top-1 h-4 w-4 rounded-full bg-white transition-transform duration-200 ease-in-out ${
                      ad.SCHEDULED_STATUS ? "translate-x-6" : "translate-x-0"
                    }`}
                  />
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Footer */}
        <div className="flex justify-center">
          <button
            className="rounded-md bg-[#31C3E0] px-6 py-2 text-white transition-colors hover:bg-[#2BB1CC]"
            onClick={() => {
              handleScheduleAds();
            }}
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
}
