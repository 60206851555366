import { Plus, X } from "lucide-react";
import React, { useEffect } from "react";
import AddAdvertisement from "../add-advertisement/AddAdvertisement";
import { useSelector } from "react-redux";

const TimelineCard = ({ slot, setAddAd, adType, addAd, curDay, curDate }) => {
  const handleAddAd = () => {
    setAddAd({ open: true, startTime: slot.startTime, endTime: slot.endTime });
  };

  const handleAdRemove = () => {};

  return (
    <div className="ml-16 rounded-lg bg-white p-4">
      {slot.SCHEDULED_ADV.length < 1 ? (
        <p className="mb-4 text-sm text-gray-500">
          "No advertisement is assigned to Regular Offers. Add a few from your
          Ad's library."
        </p>
      ) : (
        <div className="mb-5 flex flex-wrap items-center gap-x-5 gap-y-2">
          {slot.SCHEDULED_ADV.map((ad, index) => (
            <div
              key={index}
              className="flex items-center gap-20 rounded-3xl border-2 border-slate-900 px-5 py-2 text-sm"
            >
              <span>{`${ad.HEADLINE} - ${ad.DISCOUNT} % OFF`} </span>
              <X onClick={handleAdRemove} className="size-4" />
            </div>
          ))}
        </div>
      )}
      {addAd &&
        String(addAd?.startTime) === String(slot.startTime) &&
        String(addAd?.endTime) === String(slot.endTime) && (
          <AddAdvertisement
            adType={adType}
            setAddAd={setAddAd}
            startTime={slot.startTime}
            endTime={slot.endTime}
            curDay={curDay}
            curDate={curDate}
          />
        )}

      <button
        className="inline-flex items-center gap-3 px-4 py-2 text-sm font-medium"
        onClick={() => handleAddAd()}
      >
        <Plus className="h-4 w-4 rounded-full bg-black text-white" />
        Add Advertisement
      </button>
    </div>
  );
};

export default TimelineCard;
