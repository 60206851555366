import axiosInstance from "../../utils/axios";
import API_URLS from "../../utils/apiUrls";

export const getAmountBreakdownRequest = async function (
  macId,
  outletId,
  amount,
) {
  const res = await axiosInstance.post(
    `${API_URLS.PAYMENT.get_amount_breakdown}`,
    {
      macId,
      amount,
      outletId,
    },
  );
  return res.data;
};

export const getCoupons = async () => {
  const res = await axiosInstance.get(
    `${API_URLS.REGULAR_USER.get_coupons}`,
    {},
  );
  console.log(res.data.response);
  return res.data.response;
};
