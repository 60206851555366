import { useEffect, useState } from "react";
import Stars from "../../assets/two-stars.svg";
import Discount from "../../assets/discount.svg";
import { Plus, MoveRight } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { getOutletDetailsRequest } from "../../store/apis/outlets-apis";
import EnterAmountHeader from "./EnterAmountHeader";
import { getAmountBreakdownRequest } from "../../store/apis/payment-apis";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserDiscounts } from "../../store/slices/authSlice";

export default function EnterAmountPopup() {
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const dispatch = useDispatch();
  const { outletId } = useParams("outletId");
  const [outletDetail, setOutletDetail] = useState(null);
  const [macId, setMacId] = useState(0);
  const [discountAmount, setDiscountAmount] = useState("");
  const [error, setError] = useState("");
  const discounts = useSelector((state) => state.auth.discounts);
  
  const getOutletInfo = async (id) => {
    const res = await getOutletDetailsRequest(id);
    setOutletDetail(res);
  };

  useEffect(() => {
    getOutletInfo(outletId);
    dispatch(fetchUserDiscounts());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log(discounts);
    discounts.map((curr, idx) => {
      if (String(outletId) === String(curr.OUTLET_ID)) {
        console.log("Matched" , curr);
        setMacId(curr.MAC_ID);
        setDiscountAmount(curr.DISCOUNT_PRECENT);
      }
    });
  }, [discounts, outletId]);

  const handleAmountChange = (e) => {
    const value = e.target.value;
    setAmount(value);
    // Clear error when user starts typing
    if (error) setError("");
  };

  const getBreakDown = async () => {
    // First check if amount is less than 50
    if (!amount) {
      setError("Please enter an amount");
      return;
    }
    
    if (Number(amount) < 50) {
      setError("Amount must be at least ₹50");
      return;
    }

    if (amount && macId) {
      const res = await getAmountBreakdownRequest(
        macId,
        String(outletId),
        Number(amount),
      );
      if (res.message === "success") {
        console.log(res.result);
        navigate(`/mvpdemo/payment-breakdown/${outletId}`, {
          state: res.result,
        });
      } else {
        setError(res.message + " Error Occurred");
      }
    }
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError("");
      }, 10000);
    }
  }, [error]);

  return (
    <div className="h-screen bg-white">
      <EnterAmountHeader outletDetail={outletDetail} />

      <main className="mx-auto mt-16 w-full p-6 md:mt-28 md:w-1/3">
        <div className="flex flex-col gap-16 space-y-8">
          <div className="flex flex-col items-center gap-8">
            <h2 className="text-center text-xl font-semibold text-[#303030]">
              ENTER YOUR AMOUNT
            </h2>
            <div className="flex flex-col items-center">
              <div className="flex items-center justify-center space-x-2 border-b-2 border-black">
                <span className="text-4xl font-medium">₹</span>
                <input
                  type="text"
                  value={amount}
                  onChange={handleAmountChange}
                  className="w-24 text-center text-4xl font-medium text-[#303030] outline-none"
                  placeholder="0"
                />
              </div>
              {error && (
                <p className="mt-2 text-sm text-red-500">{error}</p>
              )}
            </div>
          </div>

          <div className="flex flex-col items-center justify-center gap-5 md:flex-row md:gap-10">
            <div className="flex flex-1 cursor-pointer items-center space-x-4 rounded-lg p-4 shadow-md">
              <div className="flex items-center space-x-2">
                <img src={Stars} alt="stars" className="h-10 w-10" />
                <div>
                  <p className="text-sm font-medium text-[#303030]">
                    WALK-IN OFFER
                  </p>
                  <p className="text-xs text-[#989898]">on bill payment</p>
                </div>
              </div>
            </div>

            <Plus />

            <div className="flex flex-1 cursor-pointer items-center space-x-4 rounded-lg p-4 shadow-md">
              <div className="flex items-center space-x-2">
                <img src={Discount} alt="discount" className="h-10 w-10" />
                <div>
                  <p className="text-sm font-medium text-[#303030]">
                    FLAT {discountAmount} % OFF
                  </p>
                  <p className="text-xs text-[#989898]">
                    on total bill payment
                  </p>
                </div>
              </div>
            </div>
          </div>

          <button
            className="flex w-full justify-center gap-2 rounded-lg bg-[#29B3D2] py-3 text-base font-semibold text-white"
            onClick={getBreakDown}
          >
            Proceed <MoveRight />
          </button>
        </div>
      </main>
    </div>
  );
}