import axiosInstance from "../../utils/axios";
import API_URLS from "../../utils/apiUrls";
import { readCookie } from "../../utils/cookie";

export const getTimeSlotsRequest = async function (
  adType,
  outletId,
  day,
  date,
) {
  console.log(day, date);
  const res = await axiosInstance.post(
    `${API_URLS.BUSINESS_PROMOTION.get_timeslots}`,
    {
      adType,
      outletId,
      day,
      date,
    },
  );
  return res.data;
};

export const editTimeSlotsRequest = async function (
  adType,
  outletId,
  timeSlots,
  day,
  date,
) {
  const res = await axiosInstance.put(
    `${API_URLS.BUSINESS_PROMOTION.get_timeslots}`,
    {
      adType,
      outletId,
      timeSlots,
      day,
      date,
    },
  );
  console.log("Edited Timeslots", res);
  return res.data;
};

export const getAdsToScheduleRequest = async function (adType, outletId) {
  const res = await axiosInstance.post(
    `${API_URLS.BUSINESS_PROMOTION.get_ads_to_schedule}`,
    {
      adType,
      outletId,
    },
  );
  return res.data;
};

export const scheduleAdsRequest = async function (reqData) {
  const res = await axiosInstance.post(
    `${API_URLS.BUSINESS_PROMOTION.schedule_ads}`,
    {
      ...reqData,
    },
  );
  console.log(res.data);
  return res.data;
};

export const createNewAdRequest = async function (formData) {
  const res = await axiosInstance.post(
    `${API_URLS.BUSINESS_PROMOTION.create_new_ad}`,
    formData,
    {
      headers: {
        Authorization: `${readCookie("business_auth_token")}`,
        "Content-Type": "multipart/form-data",
      },
    },
  );
  console.log("response", res.data);
  return res.data;
};

export const editAdvertisementRequest = async function (formData) {
  const res = await axiosInstance.post(
    `${API_URLS.BUSINESS_PROMOTION.edit_ad}`,
    formData,
    {
      headers: {
        Authorization: `${readCookie("business_auth_token")}`,
        "Content-Type": "multipart/form-data",
      },
    },
  );
  console.log("response", res.data);
  return res.data;
};

export const getAllAds = async function (outletId) {
  const offSet = 0;
  // const limit = 0
  const res = await axiosInstance.get(
    `${API_URLS.BUSINESS_PROMOTION.get_all_ads}?outletId=${outletId}&offset=${offSet}&limit=1000`,
    { headers: { Authorization: `${readCookie("business_auth_token")}` } },
  );

  return res.data.response;
};

export const deleteAdvertisementRequest = async function (adId, adType) {
  const res = await axiosInstance.post(
    `${API_URLS.BUSINESS_PROMOTION.delete_ad}`,
    {
      advId: adId,
      adType,
    },
    { headers: { Authorization: `${readCookie("business_auth_token")}` } },
  );
  console.log("response", res.data);
  return res.data;
};

export const getEventsRequest = async function (outletId, adType) {
  const res = await axiosInstance.post(
    `${API_URLS.BUSINESS_PROMOTION.get_events}`,
    {
      outletId,
      adType,
    },
  );

  return res.data;
};

export const createEventRequest = async function (outletId, adType, eventBody) {
  const res = await axiosInstance.post(
    `${API_URLS.BUSINESS_PROMOTION.create_event}`,
    {
      outletId,
      adType,
      eventBody,
    },
  );

  return res.data;
};

export const editEventRequest = async function ({
  outletId,
  adType,
  eventBody,
}) {
  const res = await axiosInstance.put(
    `${API_URLS.BUSINESS_PROMOTION.edit_event}`,
    {
      outletId,
      adType,
      eventBody,
    },
  );

  return res.data;
};

export const getHighlightsRequest = async function (outletId) {
  const res = await axiosInstance.post(
    `${API_URLS.BUSINESS_PROMOTION.get_highlights}?outletId=${outletId}&offset=0&limit=1000`,
    {
      outletId,
      limit: 1000,
      offset: 0,
    },
  );
  return res.data.response;
};

export const scheduleHighlightsRequest = async function (outletId, advObj) {
  const res = await axiosInstance.put(
    `${API_URLS.BUSINESS_PROMOTION.schedule_highlights}`,
    {
      outletId,
      adType: "Highlight",
      advObj,
    },
  );

  return res.data;
};
