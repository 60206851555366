import React from "react";
import RedCross from "../../assets/red-cross.svg";
import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";

const PaymentFailure = () => {
    const navigate = useNavigate()
  return (
    <div className="fixed z-50 flex h-screen w-screen items-center justify-center rounded-3xl bg-white">
      <div className="relative flex md:mx-4 md:h-[550px] md:w-[425px] h-full w-full flex-col items-center gap-20 md:rounded-3xl border-2 border-gray-300 bg-[#FCFCFC] md:py-16 py-32 shadow-xl">
        {/* Left Cutout*/}
        <div
          className={`shadow-r-inner absolute left-[-30px] top-52 h-10 w-10 -translate-y-1/2 rounded-full border-r-2 border-gray-300 bg-white hidden md:flex`}
        ></div>

        {/* Right Cutout */}
        <div
          className={`shadow-r-inner absolute right-[-30px] top-52 h-10 w-10 -translate-y-1/2 rounded-full border-l-2 border-gray-300 bg-white hidden md:flex`}
        ></div>
        <X className="absolute top-3 right-3 text-[#7C7C7C] cursor-pointer" onClick={()=>navigate("/mvpdemo/landing-page")}/>

        <div className="flex flex-col items-center gap-4">
          <img src={RedCross} className="h-[62px] w-[62px]" />
          <span className="text-xl font-semibold text-[#303030]">
            Payment Failed!
          </span>
        </div>

        <div className="absolute top-52 w-full md:px-10 px-5">
          <hr className="w-full" />
        </div>

        <div className="flex w-full flex-col items-center gap-14">
          <div className="flex w-full flex-col gap-2 md:px-10 px-5">
            <div className="flex justify-between">
              <span className="text-[#7C7C7C]">Total Bill : </span>
              <span className="text-[#303030]">₹500</span>
            </div>
            <div className="flex justify-between">
              <span className="text-[#7C7C7C]">Coupon Applied :</span>
              <span className="text-[#303030]">- ₹30</span>
            </div>
            <div className="flex justify-between">
              <span className="text-[#7C7C7C]">Convenience Fee :</span>
              <span className="text-[#303030]">₹1</span>
            </div>
          </div>

          <button className="border-2 border-[#29B3D2] text-[#29B3D2] px-10 py-2 font-semibold rounded-md">Try Again</button>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailure;
