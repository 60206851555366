import {
  Ticket,
  Heart,
  History,
  CreditCard,
  HelpCircle,
  Headphones,
  Info,
  SquarePen,
  Settings,
  LogOut,
  ChevronRight,
} from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";

const ProfileSections = ({ setShowLogoutModal }) => {
  const navigate = useNavigate();
  return (
    <div className="flex min-h-screen flex-col items-center justify-start gap-8 md:w-1/2 md:gap-16 md:overflow-y-auto md:[-ms-overflow-style:none] md:[scrollbar-width:none] md:[&::-webkit-scrollbar]:hidden">
      {/* Account Details Section */}
      <div className="flex w-full flex-col gap-7 rounded-2xl border border-gray-300 py-7 shadow-md md:gap-14">
        <SectionTitle>Account Details</SectionTitle>
        <ul className="flex flex-col px-6">
          <MenuListItem
            icon={Ticket}
            title="Your Coupons"
            onClick={() => navigate("/mvpdemo/coupons")}
          />

          {/* <MenuListItem
            icon={Heart}
            title="My Favourites"
            onClick={() => console.log("Menu Item")}
          /> */}

          <MenuListItem
            icon={History}
            title="Redeemed History"
            onClick={() => navigate("/mvpdemo/redeemed-history")}
          />

          {/* <MenuListItem
            icon={CreditCard}
            title="Manage Payment Details"
            onClick={() => console.log("Menu Item")}
          /> */}
        </ul>
      </div>

      {/* Help & Support Section */}
      <div className="flex w-full flex-col gap-7 rounded-2xl border border-gray-300 py-7 shadow-md md:gap-14">
        <SectionTitle>Help and Support</SectionTitle>
        <ul className="flex flex-col px-6">
          {/* <MenuListItem
            icon={HelpCircle}
            title="FAQ"
            onClick={() => console.log("Menu Item")}
          /> */}

          <MenuListItem
            icon={Headphones}
            title="Contact Support"
            onClick={() => navigate('/mvpdemo/support')}
          />
        </ul>
      </div>

      {/* More Section */}
      <div className="flex w-full flex-col gap-7 rounded-2xl border border-gray-300 py-7 shadow-md md:gap-14">
        <SectionTitle>More</SectionTitle>
        <ul className="flex flex-col px-6">
          <MenuListItem
            icon={Info}
            title="About us"
            onClick={() => navigate("/mvpdemo/about-us")}
          />

          {/* <MenuListItem
            icon={SquarePen}
            title="Send Feedback"
            onClick={() => console.log("Menu Item")}
          /> */}
          <MenuListItem
            icon={Settings}
            title="Settings"
            onClick={() => navigate('/mvpdemo/settings')}
          />

          <MenuListItem
            icon={LogOut}
            title="Logout"
            onClick={() => setShowLogoutModal(true)}
          />
        </ul>
      </div>
    </div>
  );
};

const SectionTitle = ({ children }) => (
  <h2 className="font-outfit border-l-4 border-l-[#0079AF] px-6 text-[#303030] sm:text-base md:text-xl md:font-semibold">
    {children}
  </h2>
);

const MenuListItem = ({ icon: Icon, title, onClick }) => {
  const isLogout = title === "Logout";
  return (
    <li
      className="group mb-4 flex w-full cursor-pointer flex-row items-center justify-between border-b border-[#EFEFEF] pb-4 md:mb-6 md:pb-6"
      onClick={() => {
        onClick();
      }}
    >
      <div className="flex flex-row gap-3 md:gap-6">
        <Icon
          className={`size-6 text-[#303030] md:size-7 ${isLogout && "group-hover:text-red-500"}`}
        />
        <h1
          className={`font-outfit text-sm font-medium text-[#303030] md:text-base ${isLogout && "group-hover:text-red-500"}`}
        >
          {title}
        </h1>
      </div>
      <div>
        <ChevronRight className="h-5 w-5 text-[#303030]" />
      </div>
    </li>
  );
};

export default ProfileSections;
