import React, { useEffect, useState } from "react";
import AdLibraryHeader from "./Ad-library-header";
import DisplayAds from "./DisplayAds";
import Paginator from "./Paginator";
import CreateAdForm from "../utils/create-ad/create-ad-form/CreateAdForm";
import { getAllAds } from "../../../../store/apis/business-promotion-apis";
import { useSelector } from "react-redux";

const AdLibrary = () => {
  const [showCreateAd, setShowCreateAd] = useState(false);
  const [ads, setAds] = useState([]);
  const [filteredAds ,setFilteredAds] = useState([])
  const outletId = useSelector((state) => state.bussinessAuth.currentOutletId);
  const getAds = async () => {
    // console.log("outletId", outletId);
    const data = await getAllAds(outletId);
    setAds(data);
    setFilteredAds(data)
    // console.log("all ads", data);
  };

  useEffect(() => {
    if(outletId) getAds();
  }, [outletId]);

  const onPageChange = () => {};

  return (
    <div className="mt-[156px] h-max px-10 pt-1">
      <div className="relative h-full rounded-2xl border px-20 py-5 shadow-xl">
        <AdLibraryHeader page={"ad"} setShowCreateAd={setShowCreateAd} setFilteredAds={setFilteredAds} ads={ads} />
        <DisplayAds ads={filteredAds} getAds={getAds} />
        {showCreateAd && (
          <CreateAdForm setShowCreateAd={setShowCreateAd} getAds={getAds} />
        )}
      </div>
      {/* <Paginator onPageChange={onPageChange}  /> */}
    </div>
  );
};

export default AdLibrary;
