import { ChevronLeft } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfileSections from "../components/profile/ProfileSections";
import ProfileCard from "../components/profile/ProfileCard";
import PromoterCard from "../components/profile/PromoterCard";
import { useDispatch, useSelector } from "react-redux";
import LogoutModel from "../components/profile/LogoutModel";
import EditProfile from "../components/profile/EditProfile";
import { initializeAuth } from "../store/slices/authSlice";
import { readCookie } from "../utils/cookie";

const Profile = () => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showEditProfile , setShowEditProfile] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const userData = useSelector((state) => state.auth.userData);

  useEffect(() => {
    // Check auth on mount and when userData changes
    if (!userData || !userData.NAME) {
      if(readCookie("authToken") && readCookie("userDetails")){
        dispatch(initializeAuth())
      }
      else  navigate("/mvpdemo/landing-page", { replace: true });
    }
    document.documentElement.scrollTop = 0;
  }, [userData, navigate]);

  // Don't render anything if not authenticated
  if (!userData || !userData.NAME) {
    navigate('/mvpdemo/landing-page');
    return null;
  }

  return (
    <>
    <div className="container mx-auto mt-[35px] flex flex-col gap-10 pt-6 md:gap-16 mb-8 px-5 md:px-0">
    {showEditProfile && <EditProfile setShowEditProfile={setShowEditProfile}/>}
      <div className="flex flex-row items-center gap-4">
        <ChevronLeft className="hidden size-6 font-bold text-[#303030] md:block cursor-pointer" onClick={()=>navigate("/mvpdemo/landing-page")} />
        <h1 className="font-outfit text-base font-bold text-[#303030] md:text-xl">
          MY PROFILE
        </h1>
      </div>
      <div className="flex flex-col   items-center justify-center md:hidden md:w-1/2">
        <div>
          <ProfileCard setShowEditProfile={setShowEditProfile} />
        </div>
      </div>
      <div className="flex w-full flex-col md:h-screen md:flex-row">
        <ProfileSections setShowLogoutModal={setShowLogoutModal} />
        <div className="flex h-screen flex-col  items-center justify-center gap-20 md:w-1/2">
          <div className="hidden md:block">
            <ProfileCard className="md:mb-10" setShowEditProfile={setShowEditProfile} />
          </div>
          <div>
            <PromoterCard />
          </div>
        </div>
      </div>
    </div>
    {showLogoutModal && 
    <LogoutModel
    open={showLogoutModal}
    onClose={() => setShowLogoutModal(false)}
    />}
    </>
  );
};

export default Profile;
