import axiosInstance from "../../utils/axios";
import API_URLS from "../../utils/apiUrls";

export const getCategoriesRequest = async () => {
  const response = await axiosInstance.get(API_URLS.LANDINGPAGE.get_categories);

  return response.data;
};

export const getNearbyOutletsRequest = async (requestData) => {
  // console.log("requestData", requestData);
  let offset = requestData.offSet;
  if (requestData.newOffset) {
    offset = 0;
  }

  let noLocation = false;
  if (!requestData.fetchedLocation) noLocation = true;

  // console.log("NoLocation", noLocation);
  // console.log("offset", offset);
  const response = await axiosInstance.post(
    `${API_URLS.LANDINGPAGE.get_nearby_outlets}?offSet=${offset}&limit=${requestData.limit}`,
    {
      latitude: requestData.latitude,
      longitude: requestData.longitude,
      category: requestData.selectedCategory,
      noLocation,
    },
  );
  return response.data;
};
export const getNearbyOutletsByLandmarkRequest = async (requestData) => {
  // console.log("requestData", requestData);

  let offset = requestData.offSet;
  if (requestData.newOffset) {
    offset = 0;
  }
  let noLocation = false;
  if (!requestData.fetchedLocation) noLocation = true;

//   console.log("NoLocation", noLocation);
//   console.log("offset", offset);
// 
  const response = await axiosInstance.get(
    `${API_URLS.LANDINGPAGE.get_nearby_outlets_by_landmark}?location=${requestData.landmark}&offSet=${offset}&limit=${requestData.limit}`,
    {
      latitude: requestData.latitude,
      longitude: requestData.longitude,
      category: requestData.selectedCategory,
      noLocation,
    },
  );
  // console.log("By Landmark", response);
  return response.data.locations;
};

export const getOutletDetailsRequest = async (outletId) => {
  const res = await axiosInstance.post(
    `${API_URLS.LANDINGPAGE.get_outlet_details}?outletId=${outletId}`,
  );
  return res.data.response[0];
};

export const getShuffleDiscountsRequest = async (outletId, macId) => {
  // console.log(macId, outletId);
  const res = await axiosInstance.post(
    `${API_URLS.LANDINGPAGE.get_shuffle_discuounts}`,
    {
      macId,
      outletId,
    },
  );
  return res.data.response;
};

export const saveUserSelectedDiscountRequest = async (macId, discount) => {
  const res = await axiosInstance.post(
    `${API_URLS.LANDINGPAGE.get_save_user_discount}`,
    {
      macId,
      discount,
      isLive: "true",
      validity: "12-10-2024 23:10",
    },
  );
  return res.data.response;
};


export const createDiscount = async(discount , outletId , liveStatus)=>{
  const {data} = await axiosInstance.post(`${API_URLS.SALES.create_discount}` , {discount , outletId , liveStatus});
  console.log("discount created");
  return data
}
