import { useState, useEffect } from "react";
import { Trash2, ChevronUp, ChevronDown } from "lucide-react";
import TimeSlotSelector from "./TimeSlotSelector";
import { useDispatch, useSelector } from "react-redux";
import { editTimeSlots } from "../../../../../store/slices/businessPromoterSlice";

export default function EditTimeSlots({
  setIsEditTimeOpen,
  adType,
  day,
  date,
}) {
  const [slots, setSlots] = useState([]);
  const outletId = useSelector((state) => state.bussinessAuth.currentOutletId);
  const [newSlot, setNewSlot] = useState({
    startTime: "",
    endTime: "",
  });
  const dispatch = useDispatch();

  const [isStartSelectorOpen, setIsStartSelectorOpen] = useState(false);
  const [isEndSelectorOpen, setIsEndSelectorOpen] = useState(false);

  const initialData = useSelector((state) => {
    switch (adType) {
      case "R":
        return state.scheduler.regularSlots;
      case "D":
        return state.scheduler.dailySlots;
      case "E":
        return state.scheduler.eventSlots;
      default:
        return [];
    }
  });

  useEffect(() => {
    const extractedSlots = initialData.map((slot) => ({
      startTime: formatTime(slot.startTime),
      endTime: formatTime(slot.endTime),
    }));
    setSlots(extractedSlots);
  }, [initialData]);

  const formatTime = (curTime) => {
    if (typeof curTime === "number") curTime = curTime.toString();

    let hours = curTime.slice(0, 2);
    let minutes = curTime.slice(-2);

    return `${hours}:${minutes}`;
  };

  const formatTimeForRequest = (timeStr) => {
    const [hours, minutes] = timeStr.split(":");
    return `${hours}${minutes}`;
  };

  const isValidTimeRange = (start, end) => {
    const startMinutes = parseTime(start);
    const endMinutes = parseTime(end);

    // Ensure start time is not before venue opening (09:00)
    if (startMinutes < 540) return false; // 540 minutes = 09:00

    // Handle midnight crossing
    if (endMinutes === 0) return true; // 00:00 is valid end time

    // Normal case: end time should be after start time
    return endMinutes > startMinutes;
  };

  const parseTime = (timeString) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const mergeOverlappingSlots = (newSlots) => {
    newSlots.sort((a, b) => {
      const startDiff = parseTime(a.startTime) - parseTime(b.startTime);
      if (startDiff === 0) {
        return parseTime(a.endTime) - parseTime(b.endTime);
      }
      return startDiff;
    });
    console.log("New Slots", newSlots);

    const mergedSlots = [];
    for (let i = 0; i < newSlots.length; i++) {
      const currentSlot = { ...newSlots[i] };

      if (mergedSlots.length === 0) {
        mergedSlots.push(currentSlot);
        continue;
      }

      const lastSlot = mergedSlots[mergedSlots.length - 1];

      if (parseTime(currentSlot.startTime) <= parseTime(lastSlot.endTime)) {
        if (parseTime(currentSlot.startTime) > parseTime(lastSlot.startTime)) {
          // Split the existing slot
          mergedSlots.pop();
          mergedSlots.push(
            { startTime: lastSlot.startTime, endTime: currentSlot.startTime },
            currentSlot,
          );
          if (parseTime(currentSlot.endTime) < parseTime(lastSlot.endTime)) {
            mergedSlots.push({
              startTime: currentSlot.endTime,
              endTime: lastSlot.endTime,
            });
          }
        } else if (
          parseTime(currentSlot.endTime) > parseTime(lastSlot.endTime)
        ) {
          // Extend the existing slot
          lastSlot.endTime = currentSlot.endTime;
        }
      } else {
        mergedSlots.push(currentSlot);
      }
    }

    console.log("Merged slots", mergedSlots);
    return mergedSlots;
  };

  //   const mergeOverlappingSlots = (newSlots) => {
  //     // Sort slots by start time
  //     newSlots.sort((a, b) => parseTime(a.startTime) - parseTime(b.startTime));
  //     console.log("New SLots", newSlots);
  //     const mergedSlots = [];
  //     let currentSlot = newSlots[0];
  //
  //     for (let i = 1; i < newSlots.length; i++) {
  //       const nextSlot = newSlots[i];
  //       if (parseTime(nextSlot.startTime) < parseTime(currentSlot.endTime)) {
  //         // Merge overlapping slots
  //         currentSlot.endTime =
  //           parseTime(nextSlot.endTime) > parseTime(currentSlot.endTime)
  //             ? nextSlot.endTime
  //             : currentSlot.endTime;
  //       } else {
  //         mergedSlots.push(currentSlot);
  //         currentSlot = nextSlot;
  //       }
  //     }
  //     mergedSlots.push(currentSlot);
  //     console.log("merged slots", mergedSlots);
  //     return mergedSlots;
  //   };

  const handleAddSlot = () => {
    if (newSlot.startTime && newSlot.endTime) {
      if (!isValidTimeRange(newSlot.startTime, newSlot.endTime)) {
        alert("Invalid time range. End time must be after start time.");
        return;
      }

      const updatedSlots = mergeOverlappingSlots([...slots, newSlot]);
      const uniqueSlots = updatedSlots.filter(
        (slot, index, self) =>
          index ===
          self.findIndex(
            (t) => t.startTime === slot.startTime && t.endTime === slot.endTime,
          ),
      );
      setSlots(uniqueSlots);
      setNewSlot({ startTime: "", endTime: "" });
    }
  };

  const handleDeleteSlot = (index) => {
    setSlots(slots.filter((_, i) => i !== index));
  };

  const handleDone = () => {
    const parsedTime = slots.map((slot) => ({
      startTime: formatTimeForRequest(slot.startTime),
      endTime: formatTimeForRequest(slot.endTime),
    }));
    dispatch(
      editTimeSlots({
        outletId: outletId,
        adType: adType,
        timeSlots: parsedTime,
        day,
        date,
      }),
    );
    setIsEditTimeOpen(false);
  };

  return (
    <div className="absolute right-[440px] top-14 z-20 w-[300px] rounded-lg bg-white px-2 pb-4 text-sm shadow-xl">
      {/* Header */}
      <div className="rounded-t-lg">
        <h2 className="mb-3 rounded-t-lg bg-black/80 px-3 py-1 text-xs font-medium text-white">
          Venue time slots
        </h2>

        <div className="grid grid-cols-2 gap-2 py-1">
          <div>
            <label className="block text-xs text-gray-500">
              Venue start time
            </label>
            <div className="text-sm font-medium text-gray-500">09:00</div>
          </div>
          <div>
            <label className="block text-xs text-gray-500">
              Venue end time
            </label>
            <div className="text-sm font-medium text-gray-500">00:00</div>
          </div>
        </div>

        <p className="mt-1 text-xs text-gray-500">
          Please schedule time slots between Venue start and end time
        </p>
      </div>

      {/* Time Slot Selector */}
      <div className="mb-3">
        <div className="mb-2 grid grid-cols-2 gap-2">
          <div className="relative">
            <div
              className="flex w-full cursor-pointer appearance-none items-center justify-between rounded-md border bg-white p-2 text-xs"
              onClick={() => {
                setIsStartSelectorOpen(!isStartSelectorOpen);
                setIsEndSelectorOpen(false);
              }}
            >
              <span>{newSlot.startTime || "Select Start Time"}</span>
              {isStartSelectorOpen ? (
                <ChevronUp className="h-4 w-4 text-gray-500" />
              ) : (
                <ChevronDown className="h-4 w-4 text-gray-500" />
              )}
            </div>
            {isStartSelectorOpen && (
              <TimeSlotSelector
                initialValue={newSlot.startTime}
                onChange={(value) => {
                  setNewSlot({ ...newSlot, startTime: value });
                  setIsStartSelectorOpen(false);
                }}
                onClose={() => setIsStartSelectorOpen(false)}
                // venueStartTime="00:00"
                // venueEndTime="00:00"
              />
            )}
          </div>
          <div className="relative">
            <div
              className="flex w-full cursor-pointer appearance-none items-center justify-between rounded-md border bg-white p-2 text-xs"
              onClick={() => {
                setIsEndSelectorOpen(!isEndSelectorOpen);
                setIsStartSelectorOpen(false);
              }}
            >
              <span>{newSlot.endTime || "Select End Time"}</span>
              {isEndSelectorOpen ? (
                <ChevronUp className="h-4 w-4 text-gray-500" />
              ) : (
                <ChevronDown className="h-4 w-4 text-gray-500" />
              )}
            </div>
            {isEndSelectorOpen && (
              <TimeSlotSelector
                initialValue={newSlot.endTime}
                onChange={(value) => {
                  setNewSlot({ ...newSlot, endTime: value });
                  setIsEndSelectorOpen(false);
                }}
                onClose={() => setIsEndSelectorOpen(false)}
                venueStartTime="09:00"
                venueEndTime="00:00"
              />
            )}
          </div>
        </div>

        <div className="flex w-full justify-end">
          <button
            onClick={handleAddSlot}
            className="flex items-center justify-center rounded-lg border border-cyan-300 px-2 py-1 text-xs font-medium text-[#31C3E0]"
          >
            <span className="mr-1">+</span> Add slot
          </button>
        </div>
      </div>

      {/* Time Slots List */}
      <div
        className="mb-4 h-28 space-y-1 overflow-auto"
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      >
        {slots.map((slot, index) => (
          <div
            key={index}
            className="flex items-center justify-between rounded-md bg-gray-100 p-2"
          >
            <div className="grid grid-cols-2 gap-4">
              <div>
                <div className="mb-0.5 text-[10px] text-gray-500">From</div>
                <div className="text-xs font-medium">{slot.startTime}</div>
              </div>
              <div>
                <div className="mb-0.5 text-[10px] text-gray-500">To</div>
                <div className="text-xs font-medium">{slot.endTime}</div>
              </div>
            </div>
            <button
              onClick={() => handleDeleteSlot(index)}
              className="text-gray-400 hover:text-gray-600"
            >
              <Trash2 className="h-4 w-4" />
            </button>
          </div>
        ))}
      </div>

      {/* Footer */}
      <div className="flex justify-end gap-2">
        <button
          className="px-3 py-1 text-xs font-medium text-gray-600"
          onClick={() => setIsEditTimeOpen(false)}
        >
          Cancel
        </button>
        <button
          className="rounded-md bg-[#31C3E0] px-3 py-1 text-xs font-medium text-white"
          onClick={handleDone}
        >
          Done
        </button>
      </div>
    </div>
  );
}
