import React from "react";
import { Modal } from "@mui/material";
import Ghost from "./../../assets/ghost.svg";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetAuth } from "../../store/slices/authSlice";
import { deleteUser } from "../../store/apis/regular-user-apis";

const DeleteAccount = ({ open, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDelete = async () => {
    // api call for deleting account
    await deleteUser();
    //  Clear all authentication cookies
    Cookies.remove("authToken");
    Cookies.remove("userDetails");

    // Clear Redux state
    dispatch(resetAuth());

    // Close the modal
    onClose();

    // navigate to the landing-page
    navigate("/mvpdemo/landing-page", { replace: true });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform focus:outline-none">
        <div className="flex h-[221px] w-[340px] flex-col items-center justify-center rounded-2xl bg-[#FCFCFC] p-1 md:h-[350px] md:w-[650px] md:rounded-3xl">
          <div>
            <div className="flex items-start justify-center">
              <img
                src={Ghost}
                alt="logout-logo"
                className="h-12 w-12 md:h-16 md:w-16"
              />
            </div>
            <div className="flex flex-col gap-8 md:gap-14">
              <div className="flex flex-col items-center gap-1">
                <h1 className="font-outfit text-sm font-bold text-[#303030] md:text-2xl">
                  Delete Account?
                </h1>
                <p className="text-center text-base text-[#7C7C7C] md:text-lg">
                  Permenantly remove your account and all associated data
                </p>
              </div>
              <div className="flex flex-row justify-center gap-4 md:gap-6">
                <button
                  className="w-24 rounded bg-[#F44242] py-2 text-xs font-bold text-[#FCFCFC] md:w-32 md:rounded-md md:text-xl"
                  onClick={() => onClose()}
                >
                  No
                </button>
                <button
                  className="w-24 rounded border border-[#EFEFEF] py-2 text-xs font-bold text-[#7C7C7C] hover:bg-[#f3f3f3] hover:text-[#303030] md:w-32 md:rounded-md md:text-xl"
                  onClick={handleDelete}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteAccount;
