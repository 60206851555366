  import React, { useEffect, useState } from "react";
  import Avatar from "../../assets/cartoon-avatar.png";
  import { Pencil } from "lucide-react";
  import { useSelector } from "react-redux";

  const ProfileCard = ({setShowEditProfile}) => {
    const userData = useSelector((state) => state.auth.userData);
    const [imageUrl, setImageUrl] = useState(Avatar);

    useEffect(() => {
      if (userData?.PROFILE_PIC_URL) {
        // Add a timestamp to bust cache
        const url = `${userData.PROFILE_PIC_URL}?t=${new Date().getTime()}`;
        setImageUrl(url);
      } else {
        setImageUrl(Avatar);
      }
    }, [userData?.PROFILE_PIC_URL]);

    return (
      <div className="flex flex-col  rounded-2xl shadow-lg md:rounded-3xl">
        <div className="mx-4 my-6 flex flex-row items-center gap-6">
          <div className="flex size-20 items-center justify-center rounded-full md:size-28">
            <img
              src={imageUrl}
              alt="avatar"
              className="h-20 w-20 md:h-28 md:w-28 rounded-full object-cover"
            />
          </div>
          <div className="flex flex-row gap-24">
            <div className="flex flex-col gap-3">
              <h1 className="text-xl font-bold text-[#303030] md:text-2xl">
                {userData.NAME}
              </h1>
              <p className="text-xs font-normal text-[#303030] md:text-xs">
                {userData.PHONE_NUMBER}
              </p>
              <p className="text-xs font-normal text-[#303030] md:text-xs">
                {userData.EMAIL}
              </p>
            </div>
            <div>
              <button className="h-6 w-6 flex-none" onClick={()=> setShowEditProfile(true)}>
                <Pencil className="h-6 w-6 text-[#7C7C7C]"  />
              </button>
            </div>
          </div>
        </div>
        <div className="relative flex h-[60px] flex-row items-center justify-between rounded-b-[20px] bg-gradient-to-r from-[#29B3D2] via-[rgba(0,121,175,0.3)] to-[rgba(252,252,252,0)] p-5 px-4">
          <div>
            <span className="font-outfit text-xs font-semibold text-[#FCFCFC] md:text-base">
              Switch to promoter
            </span>
          </div>

          <div>
            <label className="inline-flex cursor-pointer items-center">
              <input type="checkbox" value="" className="peer sr-only" />
              <div className="peer relative h-6 w-11 rounded-full bg-gray-200 after:absolute after:start-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
            </label>
          </div>
        </div>
      </div>
    );
  };

  export default ProfileCard;
