import React from "react";
import { Modal } from "@mui/material";
import LogoutLogo from "./../../assets/logout-logo.png";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetAuth } from "../../store/slices/authSlice";

const LogoutModel = ({ open, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
 

  const handleLogout = () => {
    //  Clear all authentication cookies
    Cookies.remove("authToken");
    Cookies.remove("userDetails");

   // Clear Redux state
   dispatch(resetAuth());

    // Close the modal
    onClose();

    // navigate to the landing-page
    navigate("/mvpdemo/landing-page", { replace: true });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform focus:outline-none">
        <div className="flex h-[221px] w-[380px] flex-col items-center justify-center rounded-2xl bg-[#FCFCFC] p-1 md:h-[400px] md:w-[800px] md:rounded-3xl">
          <div>
            <div className="flex items-start justify-center">
              <img
                src={LogoutLogo}
                alt="logout-logo"
                className="h-12 w-12 md:h-16 md:w-16"
              />
            </div>
            <div className="flex flex-col gap-8 md:gap-14">
              <div className="flex flex-col items-center gap-1">
                <h1 className="font-outfit text-sm font-bold text-[#303030] md:text-3xl">
                  Leaving Already?
                </h1>
                <p className="text-base text-[#7C7C7C] md:text-xl">
                  This may increase your expenses
                </p>
              </div>
              <div className="flex flex-row gap-4 md:gap-6">
                <button
                  className="w-32 rounded bg-[#29B3D2] py-4 text-xs font-bold text-[#FCFCFC] hover:bg-[#238fb5] md:w-40 md:rounded-md md:text-xl"
                  onClick={() => onClose()}
                >
                  No
                </button>
                <button
                  className="w-32 rounded border border-[#EFEFEF] py-4 text-xs font-bold text-[#7C7C7C] hover:bg-[#f3f3f3] hover:text-[#303030] md:w-40 md:rounded-md md:text-xl"
                  onClick={handleLogout}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LogoutModel;
