import { Search } from "lucide-react";
import React, { useState } from "react";

const SearchInput = () => {
  const [searchValue, setSearchValue] = useState("");
  const [showPlaceholder, setShowPlaceholder] = useState(false);
  const [isFocused , setIsFocused]  = useState(false)

  return (
    <>
      <div className="relative hidden rounded-xl md:block">
        <Search className="absolute left-1 top-2 text-[#7C7C7C]" />
        <input
          className="h-full w-[122px] rounded-xl border border-gray-100 pl-10 outline-none duration-500 hover:w-[500px]"
          placeholder={
            showPlaceholder ? "Search for Outlet, Offers, Brands" : "Search"
          }
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onMouseEnter={() => setShowPlaceholder(true)}
          onMouseLeave={() => setShowPlaceholder(false)}
        />
      </div>

      <div className="relative w-screen px-5 md:hidden h-[40px] mb-2">
      <Search
        className={`absolute top-2 text-[#7C7C7C] transition-all duration-300 ${
          isFocused ? "right-7 text-cyan-400" : "left-7"
        }`}
      />
      <input
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        onFocus={() => {setIsFocused(true); setShowPlaceholder(false)}}
        onBlur={() => {setIsFocused(false); setShowPlaceholder(true)}}
        className={`border border-gray-100 w-full  outline-none h-full rounded-xl duration-300 placeholder:text-sm ${isFocused? "pr-10 pl-5 ": "pl-10"}`}
        placeholder={
          showPlaceholder ? "Search for Outlet, Offers, Brands" : ""
        }
      />
    </div>
    </>
  );
};

export default SearchInput;
