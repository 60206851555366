import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCoupons } from "../apis/regular-user-apis";

// Initial state
const initialState = {
  coupons: {
    active: [],
    expired: [],
    summary: {
      totalCoupons: 0,
      activeCoupons: 0,
      expiredCoupons: 0, 
    }, 
  },
  isLoading: false,
  error: null,
};

// Async thunk for fetching coupons
export const fetchCoupons = createAsyncThunk(
  "coupons/fetchCoupons",
  async (_, { rejectWithValue }) => {
    try {
      const data = await getCoupons();
      if (!data?.coupons) {
        throw new Error("No coupons data received");
      }
      // Process the coupons data
      const active = data.coupons.filter(
        (coupon) => coupon.IS_LIVE === "true",
      );
      const expired = data.coupons.filter(
        (coupon) => coupon.IS_LIVE === "false",
      );
      console.log("expired" , expired)
      console.log("active" , active)
      return {
        active,
        expired,
        summary: data.summary || {
          activeCoupons: active.length,
          expiredCoupons: expired.length,
        },
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

// Coupon slice
const couponSlice = createSlice({
  name: "coupons",
  initialState,
  reducers: {
    clearCoupons: (state) => {
      state.coupons = initialState.coupons;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCoupons.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCoupons.fulfilled, (state, action) => {
        state.isLoading = false;
        state.coupons = action.payload;
        state.error = null;
      })
      .addCase(fetchCoupons.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { clearCoupons, clearError } = couponSlice.actions;
export default couponSlice.reducer;
