import React, { useState, useSyncExternalStore } from "react";
import { Upload, X } from "lucide-react";
import FloatingLabelInput from "./FloatingLabelInput";
import CustomDropdown from "./CustomDropdown";
import CreateNewAdUnderline from "../../../../../../assets/create-new-ad-underline.svg";
import SelectImage from "../Select-image/SelectImage";
import { useDispatch, useSelector } from "react-redux";
import { createNewAdRequest } from "../../../../../../store/apis/business-promotion-apis";
import { getTimeSlots } from "../../../../../../store/slices/businessPromoterSlice";
const CreateAdForm = ({
  setShowCreateAd,
  getAds,
  day,
  date,
  getHighlights,
  isHighlight
}) => {
  const [imageSelect, setImageSelect] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [selectedAdType, setSelectedAdType] = useState("");

  const [formState, setFormState] = useState({
    reference: "",
    headline: "",
    discount: "",
    details: "",
    terms: "",
  });
  const adTypes = [
    { value: "descriptive", label: "Descriptive Ads" },
    { value: "highlights", label: "Highlights Ads" },
  ];

  const handleImageSelect = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setSelectedImage(e.target.result);
      setSelectedImageFile(file);
    };
    reader.readAsDataURL(file);
    setImageSelect(false);
  };

  const handleInputChange = (field, value) => {
    setFormState((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const outletId = useSelector((state) => state.bussinessAuth.currentOutletId);
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("masterOutletId", outletId);
    if (selectedAdType === "highlights") {
      formData.append("name", formState.reference);
      formData.append("adType", "H");
      if (selectedImageFile) {
        formData.append("img", selectedImageFile);
      }
    } else {
      Object.entries(formState).forEach(([key, value]) => {
        if (key === "details") formData.append("description", value);
        else if (key === "discount") formData.append("discountPercent", value);
        else if (key === "reference") formData.append("name", value);
        else if (key === "terms") formData.append("termsCond", value);
        else formData.append(key, value);
      });
      formData.append("adType", "D");
      if (selectedImageFile) {
        formData.append("img", selectedImageFile);
      }
    }

    console.log("Form Submitted:", Object.fromEntries(formData));
    const data = await createNewAdRequest(formData);
    dispatch(getTimeSlots({ outletId, adType: "R" }));
    dispatch(getTimeSlots({ outletId, adType: "D", day, date }));
    dispatch(getTimeSlots({ outletId, adType: "E", day, date }));

    if (getAds) getAds();
    if (getHighlights) {
      console.log("Getting highlights after ad create");
      getHighlights();
    }
    setShowCreateAd(false);
  };
  const renderConditionalFields = () => {
    if (selectedAdType === "highlights") {
      return (
        <div className="relative flex w-full justify-end border border-gray-100 px-3 py-2">
          <label className="absolute left-2 top-3 text-sm text-gray-500">
            Upload Image
          </label>
          <input type="file" className="hidden" />
          <button
            type="button"
            className="flex items-center gap-2 self-end border px-2 py-1 text-gray-500"
            onClick={() => setImageSelect(true)}
          >
            <span className="text-sm">Upload</span>
            <Upload className="size-5" />
          </button>
        </div>
      );
    }

    if (selectedAdType === "descriptive") {
      return (
        <>
          <div className="flex w-full gap-4">
            <div className="w-[70%]">
              <FloatingLabelInput
                field="headline"
                label="Offer's Headline"
                value={formState.headline}
                onChange={handleInputChange}
              />
            </div>
            <div className="w-[30%]">
              <FloatingLabelInput
                field="discount"
                label="Discount in %"
                value={formState.discount}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <FloatingLabelInput
            field="details"
            label="Offer Details"
            className="h-24"
            value={formState.details}
            onChange={handleInputChange}
          />

          <FloatingLabelInput
            field="terms"
            label="Terms & Conditions"
            className="h-24"
            value={formState.terms}
            onChange={handleInputChange}
          />
          <div className="relative flex w-full justify-end border border-gray-100 px-3 py-2">
            <label className="absolute left-2 top-3 text-sm text-gray-500">
              Upload Image
            </label>
            <input type="file" className="hidden" />
            <button
              type="button"
              className="flex items-center gap-2 self-end border px-2 py-1 text-gray-500"
              onClick={() => setImageSelect(true)}
            >
              <span className="text-sm">Upload</span>
              <Upload className="size-5" />
            </button>
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
      <div className="relative mt-16 flex h-[75%] w-7/12 flex-col items-center gap-10 rounded-3xl bg-white pb-5 pt-10">
        <h1 className="flex flex-col">
          <span className="text-xl font-bold leading-none tracking-wider">
            Create New Advertisement
          </span>
          <img
            src={CreateNewAdUnderline}
            alt="underline"
            className="w-40 self-end"
          />
          <X
            className="absolute right-5 top-5 cursor-pointer text-gray-500"
            onClick={() => setShowCreateAd(false)}
          />
        </h1>

        {imageSelect && (
          <SelectImage
            setImageSelect={setImageSelect}
            onImageSelect={handleImageSelect}
          />
        )}
        <form
          className="flex h-[80%] w-full flex-1 flex-col gap-6 overflow-auto px-24"
          style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
        >
          {/* Default fields always shown */}
          <FloatingLabelInput
            field="reference"
            label="Name for your reference"
            value={formState.reference}
            onChange={handleInputChange}
          />

          <CustomDropdown
            adTypes={adTypes}
            selectedValue={selectedAdType}
            onSelect={setSelectedAdType}
          />

          {/* Conditional fields based on ad type */}
          {renderConditionalFields()}
        </form>

        <div className="flex w-full justify-center border-t pt-4">
          <button
            type="submit"
            className="rounded-lg bg-cyan-500 px-10 py-2 text-white"
            disabled={!selectedAdType}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateAdForm;
