import React, { useEffect, useState } from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  cancelSignupOtp,
  verifyOnboardingOtp,
} from "../../store/slices/signupSlice";
import {
  cancelLoginOtp,
  hideAuthComponent,
  initializeAuth,
  verifyLoginOtp,
} from "../../store/slices/authSlice";
import CustomInput from "./custom-input";
import { ChevronLeft } from "lucide-react";

function VerifyOtp({ login }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userId, loading, otpVerified, error } = useSelector((state) => {
    return login ? state.auth : state.signup;
  });

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      dispatch(login ? cancelLoginOtp() : cancelSignupOtp());
    }, 200);
  };

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };

  useEffect(() => {
    if (otpVerified) {
      dispatch(hideAuthComponent());
    }
  }, [otpVerified, navigate, dispatch]);

  useEffect(() => {
    setIsVisible(true);
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const verifyOtpSchema = z.object({
    otp: z
      .string()
      .min(1, { message: "otp is required" })
      .regex(/^\d{6}$/, {
        message: "otp should be of 6 digits",
      }),
  });

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors: validationErrors },
  } = useForm({
    resolver: zodResolver(verifyOtpSchema),
  });

  const onSubmit = async(otp) => {
    if (login) {
      dispatch(verifyLoginOtp({ ...otp, userId }));
    } else {
      const result = await dispatch(verifyOnboardingOtp({ ...otp, userId })).unwrap();
      if (result) {
        //login directly after signup
        //update the state of authSlice
        dispatch(initializeAuth());
      }
    }
  };

  return (
    <div
      className="fixed left-0 top-0 z-20 flex h-screen w-screen bg-black/50 md:justify-end"
      onClick={handleOverlayClick}
    >
      <div
        className={`h-screen w-screen transform bg-[#FCFCFC] px-5 py-5 transition-transform duration-300 ease-in-out md:block md:w-[35%] ${
          isVisible && !isClosing ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="relative flex w-full justify-between md:max-w-[700px]">
            <div className="flex justify-between">
              <div>
                <button
                  type="reset"
                  className="text-2xl text-customHeading transition-transform duration-200 hover:scale-110 focus:outline-none"
                  onClick={handleClose}
                >
                  <ChevronLeft />
                </button>
                <h2 className="mt-[36px] text-[32px] font-bold leading-[35px] text-customHeading">
                  Enter OTP
                </h2>
                <div className="mt-[10px] flex">
                  <p>We've sent an OTP to your phone number.</p>
                </div>
              </div>
            </div>
            <img
              src="/otp.svg"
              alt="otp"
              className="absolute -top-12 right-10 md:right-20 md:h-[260px] md:w-[186px]"
            />
          </div>

          <div className="mt-[40px] max-w-[400px] space-y-2 md:mt-24">
            {otpVerified && (
              <p className="mb-5px animate-in fade-in text-green-400">
                {"OTP verified"}
              </p>
            )}
            {error && (
              <p className="mb-5px animate-in fade-in text-red-400">{error}</p>
            )}

            <CustomInput
              label={"One time password"}
              placeholder={"One time password"}
              type={"text"}
              register={register}
              name={"otp"}
              error={validationErrors?.otp?.message}
            />
            <button
              type="submit"
              className={`mt-[5%] h-[44px] w-full cursor-pointer rounded-md md:h-[52px] ${
                isSubmitting || loading
                  ? "bg-[#EFEFEF] text-[#BDBDBD]"
                  : "bg-customTeal text-[#fcfcfc] hover:opacity-90"
              } py-2 text-[16px] text-sm font-medium transition-all duration-200`}
              disabled={isSubmitting || loading}
            >
              {login ? "Login" : "Signup"}
            </button>
          </div>
          <p className="mt-4 text-sm text-[#989898]">
            By clicking on Login, I accept the{" "}
            <a
              href="/#"
              className="font-bold text-[#303030] transition-all duration-200 hover:underline"
            >
              Terms & Conditions
            </a>{" "}
            &{" "}
            <a
              href="/#"
              className="font-bold text-[#303030] transition-all duration-200 hover:underline"
            >
              Privacy Policy
            </a>
            .
          </p>
        </form>
      </div>
    </div>
  );
}

export default VerifyOtp;
