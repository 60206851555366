import React, { useEffect, useState } from "react";
import EnterAmountHeader from "./EnterAmountHeader";
import { useLocation, useParams } from "react-router-dom";
import { getOutletDetailsRequest } from "../../store/apis/outlets-apis";
import PaymentBreakdownBg from "../../assets/total-bill-background.svg";
import ProceedToCheckout from "./proceed-to-checkout";

const PaymentBreakdown = () => {
  const { outletId } = useParams("outletId");
  const [outletDetail, setOutletDetail] = useState(null);
  const location = useLocation();
  // console.log("location", location);
  const breakDownData = location.state;
  // console.log("Breakdown data", breakDownData);
  const getOutletInfo = async (id) => {
    const res = await getOutletDetailsRequest(id);
    setOutletDetail(res);
  };

  useEffect(() => {
    getOutletInfo(outletId);
    // eslint-disable-next-line
  }, []);
  return (
    <div className="h-screen bg-white">
      <EnterAmountHeader outletDetail={outletDetail} />

      <div className="mx-auto mt-5 w-full px-5 pb-5 md:w-1/3 md:px-10">
        <div
          style={{ backgroundImage: `url(${PaymentBreakdownBg})` }}
          className="flex flex-col items-center gap-5 rounded-t-2xl py-10"
        >
          <div className="flex flex-col items-center gap-1">
            <span className="text-[#303030]">TOTAL BILL</span>
            <span className="text-3xl font-semibold">
              {" "}
              ₹{" "}
              {breakDownData.finalUserAmount}
            </span>
            <span className="text-2xl text-[#7C7C7C]">
              {" "}
              ₹ <span className="line-through">{breakDownData.amount}</span>
            </span>
          </div>
          <div className="flex w-full items-center justify-center gap-2 rounded-2xl bg-[#DED0FF] py-2">
            <img
              src="https://s3-alpha-sig.figma.com/img/967e/ba0a/cfb7fb1c0329894d880403a1410ed2d1?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=cbmUEjrB7cyDMBov5vifjTjSRf-J9-NAgIUAS32rAXK6FqQMDkgOEtsHpGcszDVs9C~xdsiHXSs190BPTE0rJq4Vzy882dHjdMOrY4qpmd7v6x77tY67uDSyyJWICPB~TZoOB~haVQ4Ru3jfLBIWhH8OgMTMvkXb116294GvsMCMbTjdUOA1CptICyuq3VdeAf0ROzb7dp-24bUCQo~KbMhkyadhnzknD3O1LWETvwEyB91O4A4M5lqHx-lC1cq5k5Pa5Ru0mcQwZOGEvrm4rmzZC~0J~rw4qDyiuc09geZPgqX9qDlfiRvhSvGHHh7TXB88mMUhqVg8Vgw9q-3umA__"
              alt="emoji"
              className="h-8 w-8"
            />

            <span className="text-[#4F0A94]">You saved ₹ {breakDownData.discountAmount} on this bill</span>
          </div>
        </div>

        <div className="flex w-full flex-col gap-2">
          <h2 className="text-[#303030]">BILL DETAILS</h2>
          <div className="my-2 flex w-full flex-col gap-4 rounded-lg px-4 shadow-lg">
            <div className="flex w-full flex-col gap-2">
              <div className="flex w-full justify-between text-[#7C7C7C]">
                <span>Total Bill</span>
                <span>₹ {breakDownData.amount}</span>
              </div>
              <div className="flex w-full justify-between text-[#29B3D2]">
                <span>Flat {breakDownData.discountPercent}% Off</span>
                <span>- ₹{breakDownData.discountAmount}</span>
              </div>
              <div className="flex w-full justify-between text-[#7C7C7C]">
                <span>
                  Platform Fees <span className="text-xs"> - 0.5%</span>
                </span>
                <span>₹{breakDownData.platformCharges}</span>
              </div>
              <div className="flex w-full justify-between text-[#7C7C7C]">
                <span>
                  Transaction Charges <span className="text-xs"> - 1%</span>
                </span>
                <span>₹{breakDownData.transactionCharges}</span>
              </div>
            </div>

            <div className="flex justify-between border-t-2 py-2 text-[#303030]">
              <span>Bill to pay</span>
              <span>
                ₹
                {Number(breakDownData.finalUserAmount)}
              </span>
            </div>
          </div>
        </div>

        <div className="my-2 flex w-full flex-col gap-2 py-5">
          <h2 className="text-[#303030]">TERMS & CONDITIONS</h2>
          <ul className="my-2 flex w-full list-disc flex-col gap-1 rounded-lg px-8 py-2 shadow-xl">
            <li className="text-xs text-[#7C7C7C]">
            All payments are processed securely through <span className="text-blue-800 font-bold">razorpay</span> payment gateway. By proceeding with your payment, you confirm that you accept our terms.
            </li>
            <li className="text-xs text-[#7C7C7C]">
              Refunds are subject to the outlet’s policy. Please check before
              confirming your payment.
            </li>
            <li className="text-xs text-[#7C7C7C]">Other T&C’s may apply</li>
          </ul>
        </div>

        <div className="flex w-full justify-center">
          <ProceedToCheckout
            measkOrderId={breakDownData.id}
            name={"Proceed to Checkout"}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentBreakdown;
