import React from "react";
import CouponCardImage from "./../../assets/coupon-card-image.png";
import CouponShopnameUnderline from "./../../assets/coupon-shopname-underline.png";
import { useNavigate } from "react-router-dom";

const ActiveCoupon = ({ coupon , isActive}) => {
  const navigate = useNavigate()

  const handlePayNow = function(){
    if(isActive){
      navigate(`/mvpdemo/enter-amount/${coupon.OUTLET_ID}`)
    }
  }
  function formatExpiry(expiryDate, expiryTime) {
    // Convert expiry time to AM/PM format
    const hours24 = parseInt(expiryTime?.slice(0, 2), 10); // Get hours part (first two characters)
    const minutes = expiryTime?.slice(2); // Get minutes part (last two characters)
  
    const period = hours24 >= 12 ? 'PM' : 'AM'; // Determine AM or PM
    const hours12 = hours24 % 12 || 12; // Convert to 12-hour format (handle 0 as 12)
  
    // Format the time as hh:mm AM/PM
    const formattedTime = `${hours12}:${minutes} ${period}`;
  
    // Combine the expiry date with the formatted time
    return `${expiryDate} ${formattedTime}`;
  }

  return (
    <div
      className={`relative flex flex-col items-center justify-center gap-4 overflow-hidden rounded-3xl p-2 shadow-2xl md:gap-3 md:p-3 ${isActive ? "bg-[#FCFCFC]" : "bg-[#DCDCDC]"}`}
    >
      {/* Left Cutout */}
      <div
        className={`absolute left-[-36px] top-1/2 h-12 w-12 -translate-y-1/2 rounded-full shadow-inner ${isActive ? "bg-[#E5E5E5]" : "bg-[#E6E6E6]"}`}
      ></div>

      {/* Right Cutout */}
      <div
        className={`absolute right-[-36px] top-1/2 h-12 w-12 -translate-y-1/2 rounded-full shadow-inner ${isActive ? "bg-[#E5E5E5]" : "bg-[#E6E6E6]"}`}
      ></div>

      {/* Card Content */}
      <div className="flex flex-col items-center justify-center gap-8">
        <div className="h-auto w-full max-w-[340px] md:h-[212px] md:w-[340px]">
          <img
            className={`h-auto max-h-full w-full max-w-full rounded-lg object-cover md:rounded-2xl ${isActive ? "" : "grayscale"}`}
            src={coupon.PROFILE_PIC_URL}
            alt="coupon card"
          />
        </div>
        <div className="w-[296px]">
          <hr
            className={`my-2 w-full border-2 border-dashed ${isActive? "border-[#EFEFEF]" : "border-[#BDBDBD]"}`}
          />
        </div>
      </div>
      <div className="gap- flex h-[244px] w-[304px] flex-col items-center justify-center p-2">
        <div>
          <h2 className={`text-base font-medium text-[#303030] md:text-xl`}>
            {coupon.NAME}
          </h2>
          <img
            src={CouponShopnameUnderline}
            alt="Coupon Shopname Underline"
            className={`${isActive ? "" : "grayscale"}`}
          />
        </div>
        <div className="flex h-24 flex-col items-center justify-center">
          <h1
            className={`font-regular font-bakbakOne text-4xl md:text-[40px] ${isActive ? "text-[#303030]" : "text-[#7C7C7C]"}`}
          >
           {coupon.DISCOUNT_PRECENT}% OFF
          </h1>
          <h2 className="mt-[-8px] text-xs font-semibold text-[#7C7C7C] md:text-base">
            ON TOTAL BILL
          </h2>
        </div>
        <div className="flex w-full flex-col justify-start gap-4">
          <p className="text-center text-xs font-light text-[#BDBDBD]">
            Pay now & Get flat {coupon.DISCOUNT_PRECENT}% off <br />
            on your total bill
          </p>
          <div>
            <button
              className={`text-output w-full rounded-md py-2 text-xs font-bold md:text-base ${isActive ? "bg-[#29B3D2] text-[#FCFCFC]" : "bg-[#EFEFEF] text-[#BDBDBD]"}`}
            onClick={handlePayNow}>
              Pay Now
            </button>
            <h2
              className={`text-regular mt-1 text-center text-xs md:text-base ${isActive ? "text-[#7C7C7C]" : "text-[#BDBDBD]"}`}
            >
              Valid till - { formatExpiry(coupon.EXPIRY_DATE  ,coupon.EXPIRY_TIME)}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveCoupon;
