import React from "react";
import Paginator from "../../promotion-organizer/Ad-library/Paginator";
import DashboardHeader from "../utils/dashboard-header";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const Revenue = () => {
  const transactionData = [
    { date: '16/10/2024', userId: 'ID - 202400001', offerRedeemed: '6%', billAmount: '₹ 2000', taxes: '10%', amountReceivable: '₹ 1,770' },
    { date: '16/10/2024', userId: 'ID - 202400024', offerRedeemed: '4%', billAmount: '₹ 2000', taxes: '10%', amountReceivable: '₹ 1,728' },
    { date: '16/10/2024', userId: 'ID - 202400053', offerRedeemed: '6%', billAmount: '₹ 2000', taxes: '10%', amountReceivable: '₹ 1,770' },
    { date: '16/10/2024', userId: 'ID - 202400123', offerRedeemed: '10%', billAmount: '₹ 2000', taxes: '10%', amountReceivable: '₹ 1,620' },
    { date: '16/10/2024', userId: 'ID - 202400247', offerRedeemed: '6%', billAmount: '₹ 2000', taxes: '10%', amountReceivable: '₹ 1,770' },
    { date: '16/10/2024', userId: 'ID - 202400001', offerRedeemed: '6%', billAmount: '₹ 2000', taxes: '10%', amountReceivable: '₹ 1,770' },
    { date: '16/10/2024', userId: 'ID - 202400001', offerRedeemed: '6%', billAmount: '₹ 2000', taxes: '10%', amountReceivable: '₹ 1,770' },
    { date: '16/10/2024', userId: 'ID - 202400001', offerRedeemed: '6%', billAmount: '₹ 2000', taxes: '10%', amountReceivable: '₹ 1,770' },
    { date: '16/10/2024', userId: 'ID - 202400024', offerRedeemed: '4%', billAmount: '₹ 2000', taxes: '10%', amountReceivable: '₹ 1,728' },
    { date: '16/10/2024', userId: 'ID - 202400024', offerRedeemed: '4%', billAmount: '₹ 2000', taxes: '10%', amountReceivable: '₹ 1,728' },
    { date: '16/10/2024', userId: 'ID - 202400024', offerRedeemed: '4%', billAmount: '₹ 2000', taxes: '10%', amountReceivable: '₹ 1,728' },
    { date: '16/10/2024', userId: 'ID - 202400053', offerRedeemed: '6%', billAmount: '₹ 2000', taxes: '10%', amountReceivable: '₹ 1,770' },
    { date: '16/10/2024', userId: 'ID - 202400053', offerRedeemed: '6%', billAmount: '₹ 2000', taxes: '10%', amountReceivable: '₹ 1,770' },
    { date: '16/10/2024', userId: 'ID - 202400053', offerRedeemed: '6%', billAmount: '₹ 2000', taxes: '10%', amountReceivable: '₹ 1,770' },
    { date: '16/10/2024', userId: 'ID - 202400123', offerRedeemed: '10%', billAmount: '₹ 2000', taxes: '10%', amountReceivable: '₹ 1,620' },
    { date: '16/10/2024', userId: 'ID - 202400123', offerRedeemed: '10%', billAmount: '₹ 2000', taxes: '10%', amountReceivable: '₹ 1,620' },
    { date: '16/10/2024', userId: 'ID - 202400123', offerRedeemed: '10%', billAmount: '₹ 2000', taxes: '10%', amountReceivable: '₹ 1,620' },
    { date: '16/10/2024', userId: 'ID - 202400247', offerRedeemed: '6%', billAmount: '₹ 2000', taxes: '10%', amountReceivable: '₹ 1,770' },
    { date: '16/10/2024', userId: 'ID - 202400247', offerRedeemed: '6%', billAmount: '₹ 2000', taxes: '10%', amountReceivable: '₹ 1,770' },
    { date: '16/10/2024', userId: 'ID - 202400247', offerRedeemed: '6%', billAmount: '₹ 2000', taxes: '10%', amountReceivable: '₹ 1,770' },
  ];

  const onPageChange = function () {};

  return (
    <div className="relative mt-[156px]">
      <DashboardHeader />
      <div className="h-max px-10 pb-2 pt-1">
        <div className="relative mt-20 h-full rounded-2xl border px-20 pt-5 shadow-xl">
          <TableContainer sx={{ boxShadow: "none", border: "none", width: "100%" }}>
            <Table   sx={{
                minWidth: 700,
                width: "100%",
                "& .MuiTableHead-root .MuiTableRow-root": {
                  borderBottom: "1px solid #ddd",
                },
                "& .MuiTableBody-root .MuiTableRow-root": {
                  borderBottom: "none",
                },
              }}>
              <TableHead>
                <TableRow className="bg-gray-50">
                  <TableCell sx={{ fontWeight: "bold", textAlign: "left", fontSize: "16px", paddingLeft: "16px", width : "16.6%" }}>
                    Date
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", textAlign: "left", fontSize: "16px", paddingLeft: "16px", width : "16.6%" }}>
                    User ID
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", textAlign: "left", fontSize: "16px", paddingLeft: "16px", width : "16.6%" }}>
                    Offer Redeemed
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", textAlign: "left", fontSize: "16px", paddingLeft: "16px", width : "16.6%" }}>
                    Bill Amount
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", textAlign: "left", fontSize: "16px", paddingLeft: "16px", width : "16.6%" }}>
                    Taxes
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", textAlign: "left", fontSize: "16px", paddingLeft: "16px", width : "16.6%" }}>
                    Amount Receivable
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                        "& td, & th": {
                          borderBottom: "none",
                          textAlign: "left",
                          paddingLeft: "16px",
                          fontSize: "16px", // Increase font size for body cells
                        },
                      }}
                  >
                    <TableCell className="text-base text-gray-900">
                      {row.date}
                    </TableCell>
                    <TableCell className="text-base text-gray-900">
                      {row.userId}
                    </TableCell>
                    <TableCell className="text-base text-gray-900">
                      {row.offerRedeemed}
                    </TableCell>
                    <TableCell className="text-base text-gray-900">
                      {row.billAmount}
                    </TableCell>
                    <TableCell className="text-base text-gray-900">
                      {row.taxes}
                    </TableCell>
                    <TableCell className="text-base text-gray-900">
                      {row.amountReceivable}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Paginator onPageChange={onPageChange} />
      </div>
    </div>
  );
};

export default Revenue;