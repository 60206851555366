export const API_BASE_URL = "https://api.themeask.com";

const API_URLS = {
  AUTH: {
    sign_up_verify_otp: `${API_BASE_URL}/onboarding/verifyOtp`,
    login_send_otp: `${API_BASE_URL}/onboarding/login`,
    login_verify_otp: `${API_BASE_URL}/onboarding/verifyOtp`,
    sign_up: `${API_BASE_URL}/onboarding/signup`,
    logout: `${API_BASE_URL}/onboarding/logout`,
    fetch_user_discounts: `${API_BASE_URL}/user/discounts`,
  },
  LANDINGPAGE: {
    get_categories: `${API_BASE_URL}/cmn/category`,
    get_nearby_outlets: `${API_BASE_URL}/outlet/getNearbyOutlets`,
    get_outlet_details: `${API_BASE_URL}/outlet/details`,
    get_nearby_outlets_by_landmark: `${API_BASE_URL}/outlet/location/search`,
    get_shuffle_discuounts: `${API_BASE_URL}/outlet/getShuffle`,
    get_save_user_discount: `${API_BASE_URL}/user/saveDiscount`,
  },
  BUSINESS_AUTH: {
    login: `${API_BASE_URL}/onboarding/bo/login`,
    logout: `${API_BASE_URL}/onboarding/bo/logout`,
  },
  REGULAR_USER: {
    edit_profile: `${API_BASE_URL}/user/edit-profile`,
    get_coupons: `${API_BASE_URL}/user/discounts`,
    redeemed_history: `${API_BASE_URL}/user/redeemed-history`,
    delete_account: `${API_BASE_URL}/user/delete`,
    user_settings: `${API_BASE_URL}/user/settings`,
    help_support: `${API_BASE_URL}/user/help-and-support`,
  },
  PAYMENT: {
    get_amount_breakdown: `${API_BASE_URL}/outlet/getBill`,
    create_order: `${API_BASE_URL}/outlet/route/v1/create`,
    confirm_order: ``,
  },
  BUSINESS_PROMOTION: {
    get_timeslots: `${API_BASE_URL}/business/v1/timeSlots`,
    get_ads_to_schedule: `${API_BASE_URL}/adv/schedule/adv`,
    create_new_ad: `${API_BASE_URL}/adv/create`,
    get_all_ads: `${API_BASE_URL}/adv`,
    schedule_ads: `${API_BASE_URL}/adv/schedule`,
    edit_ad: `${API_BASE_URL}/adv/edit`,
    delete_ad: `${API_BASE_URL}/adv/delete`,
    create_event: `${API_BASE_URL}/business/go/events/create`,
    edit_event: `${API_BASE_URL}/business/go/events/modify`,
    get_events: `${API_BASE_URL}/business/go/events`,
    get_highlights: `${API_BASE_URL}/adv/highlight`,
    schedule_highlights: `${API_BASE_URL}/adv/highlight/edit`
  },
  BUSINESS_DASHBOARD: {
    get_coupons: `${API_BASE_URL}/business/dashboard/coupons`,
  },
  SALES : {
    get_all_outlets  : `${API_BASE_URL}/outlet/all-outlets`,
    add_user : `${API_BASE_URL}/onboarding/bo/signup`,
    create_outlet : `${API_BASE_URL}/outlet/create`,
    get_bo : `${API_BASE_URL}/business-owners`,
    edit_outlet : `${API_BASE_URL}/outlet`,
    create_discount : `${API_BASE_URL}/outlet/discount`

  }
};

export default API_URLS;
