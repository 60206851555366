import React, { useEffect, useState } from "react";
import DashboardHeader from "../utils/dashboard-header";
import Paginator from "../../promotion-organizer/Ad-library/Paginator";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessCoupons } from "../../../../store/slices/businessDashboardSlice";

const Coupons = () => {
  const dispatch = useDispatch();
  const { coupons, fetchingCoupons, couponError } = useSelector(
    (state) => state.businessDashboard,
  );
  const outletId = useSelector((state) => state.bussinessAuth.currentOutletId);
  const [outletCoupons , setOutletCoupons] = useState([])

  const filterCoupouns = function(coupons,  outletId){
    const outletSpecificCoupouns = coupons.filter((c)=>(toString(c.OUTLET_ID) === toString(outletId)))
    setOutletCoupons(outletSpecificCoupouns)
  }


  useEffect(() => {
    if (coupons.length < 1) {
      dispatch(getBusinessCoupons());
      console.log(coupons);
    }
  }, []);

  useEffect(()=>{
    if(coupons.length>0){
      filterCoupouns(coupons , outletId)
    }
  },[outletId])


  // const onPageChange = function () {};

  return (
    <div className="relative mt-[156px]">
      <DashboardHeader />
      {couponError && couponError.length > 1 ? (
        <div>{couponError}</div>
      ) : (
        <div className="h-max px-10 pb-2 pt-1">
          <div className="relative mt-20 h-full rounded-2xl border px-20 pt-5 shadow-xl">
            <TableContainer
              component={Paper}
              sx={{ boxShadow: "none", border: "none", width: "100%" }}
            >
              <Table
                sx={{
                  minWidth: 700,
                  width: "100%",
                  "& .MuiTableHead-root .MuiTableRow-root": {
                    borderBottom: "1px solid #ddd",
                  },
                  "& .MuiTableBody-root .MuiTableRow-root": {
                    borderBottom: "none",
                  },
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        textAlign: "left",
                        fontSize: "16px",
                        paddingLeft: "16px",
                      }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        textAlign: "left",
                        fontSize: "16px",
                        paddingLeft: "16px",
                      }}
                    >
                      User ID
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        textAlign: "left",
                        fontSize: "16px",
                        paddingLeft: "16px",
                      }}
                    >
                      Offer Generated
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        textAlign: "left",
                        fontSize: "16px",
                        paddingLeft: "16px",
                      }}
                    >
                      Offer Redeemed
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        textAlign: "left",
                        fontSize: "16px",
                        paddingLeft: "16px",
                      }}
                    >
                      Venue Name
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {coupons.map((row) => (
                    <TableRow
                      key={row.MAC_ID}
                      sx={{
                        "& td, & th": {
                          borderBottom: "none",
                          textAlign: "left",
                          paddingLeft: "16px",
                          fontSize: "16px", // Increase font size for body cells
                        },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.DATE_CREATED}
                      </TableCell>
                      <TableCell>{row.USER_ID}</TableCell>
                      <TableCell>{row.DISCOUNT_PERCENT}</TableCell>
                      <TableCell
                        sx={{
                          color: row.REDEEMED === "Yes" ? "#96B62C" : "black",
                          fontWeight: "bold",
                        }}
                      >
                        {row.REDEEMED}
                      </TableCell>
                      <TableCell>{row.NAME}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {/* <Paginator onPageChange={onPageChange} /> */}
        </div>
      )}
    </div>
  );
};

export default Coupons;
