import API_URLS from "../../utils/apiUrls";
import axiosInstance from "../../utils/axios";
import { readCookie } from "../../utils/cookie";

export const getCoupons = async () => {
  const { data } = await axiosInstance.get(
    `${API_URLS.BUSINESS_DASHBOARD.get_coupons}`,
    { headers: { Authorization: `${readCookie("business_auth_token")}` } },
  );

  return data.response.data
};
