import { ChevronLeft, ChevronRight } from 'lucide-react'

export default function Paginator({ 
  currentPage = 2 , 
  totalPages = 10, 
  onPageChange 
}) {
  const renderPageNumbers = () => {
    const pages = []
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 || // First page
        i === totalPages || // Last page
        (i >= currentPage - 1 && i <= currentPage + 1) // Pages around current
      ) {
        pages.push(
          <button
            key={i}
            onClick={() => onPageChange(i)}
            className={`h-12 w-12 flex items-center justify-center rounded-full text-sm font-medium transition-colors
              ${currentPage === i 
                ? 'bg-sky-500 text-white ' 
                : 'text-gray-600 hover:bg-gray-100'
              }`}
          >
            <span className='text-lg border h-12 w-12 rounded-full flex items-center justify-center '>{i}</span>
          </button>
        )
      } else if (i === currentPage - 2 || i === currentPage + 2) {
        pages.push(
          <span key={i} className="px-1 text-gray-400">
            ...
          </span>
        )
      }
    }
    return pages
  }

  return (
    <div className="flex items-center justify-between  py-3 px-6 mt-10 mb-5 relative">
     
      <div className=" flex  flex-1 items-center justify-center">
        <div>
          <nav className="isolate inline-flex gap-12 rounded-md" aria-label="Pagination">
            <button
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center rounded-l-md p-2 text-gray-400 hover:bg-gray-50 disabled:opacity-50"
            >   
              <ChevronLeft className="h-12 w-12 border rounded-full p-2" />
            </button>
            
            <div className="flex items-center gap-1 px-4">
              {renderPageNumbers()}
            </div>

            <button
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="relative inline-flex items-center rounded-r-md p-2 text-gray-400 hover:bg-gray-50 disabled:opacity-50"
            >
              <ChevronRight className="h-12 w-12 border rounded-full p-2" />
            </button>
          </nav>
        </div>
        <div className="text-sm text-gray-700 absolute right-2 flex  items-center gap-3">
          <span>Page </span>
          <div className='flex gap-1 items-center'><span className="font-medium border py-1 px-2">{currentPage}</span>
          <span> of </span>
          <span className="font-medium">{totalPages}</span></div>
        </div>
      </div>
    </div>
  )
}


