import React, { useEffect, useRef, useState } from "react";
import PromotionOrganizerNavbar from "../components/business-partner/promotion-organizer/utils/promotion-organizer-navbar";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initializeBusinessAuth } from "../store/slices/businessAuthSlice";
import { getTimeSlots } from "../store/slices/businessPromoterSlice";
import { isCookieExpired, readCookie } from "../utils/cookie";

const BussinessPromotionOrganizer = () => {
  const businessState = useSelector((state) => state.bussinessAuth);
  const navigate = useNavigate();
  const dispatch  = useDispatch()
  useEffect(() => {
    if (!businessState || !businessState.signedIn ) {
      if(readCookie("business_auth_token") && readCookie("businessUserDetails") ){
         dispatch(initializeBusinessAuth());
      }
      else{
        navigate("/mvpdemo/for-bussiness/login");
      }
      
    }
  }, []);



  const navItems = [
    {
      name: "AD's Library",
      link: "/mvpdemo/for-bussiness/promotion-organizer/ad-library",
    },
    {
      name: "Highlights",
      link: "/mvpdemo/for-bussiness/promotion-organizer/highlights",
    },
    {
      name: "Regular Offers",
      link: "/mvpdemo/for-bussiness/promotion-organizer/regular-offers",
    },
    // {
    //   name: "Daily Offers",
    //   link: "/mvpdemo/for-bussiness/promotion-organizer/daily-offers",
    // },
    // {
    //   name: "Event Offers",
    //   link: "/mvpdemo/for-bussiness/promotion-organizer/event-offers",
    // },
  ];

  return (
    <>
      <PromotionOrganizerNavbar navItems={navItems} />

      <Outlet />
    </>
  );
};

export default BussinessPromotionOrganizer;
