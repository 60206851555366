import React, { useEffect, useState } from "react";
import { Eye, EyeOff } from "lucide-react";
import {  useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initializeBusinessAuth, loginBusinessUser } from "../../../store/slices/businessAuthSlice";

const LoginBussinessForm = () => {

    const [username , setUsername] = useState("")
    const [password , setPassword] = useState("")
    const [showPassword, setShowPassword] = useState(false)
    const businessState = useSelector(state=>state.bussinessAuth)
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const handleLogin = async function(e){
      e.preventDefault()
      dispatch(loginBusinessUser({username , password}));
      if(businessState.signedIn){
        navigate("/mvpdemo/for-bussiness/promotion-organizer/regular-offers")
      }
      setUsername("")
      setPassword("")
    }

    useEffect(()=>{
      if(businessState.signedIn){
        navigate("/mvpdemo/for-bussiness/promotion-organizer/regular-offers")
      }
    },[businessState])


  return (
    <div className="  flex h-full w-full items-center justify-center">
      <form className=" relative flex h-3/6 w-1/3 flex-col gap-5 rounded-xl border-2 border-white bg-white bg-opacity-40 px-8 py-4">
        <div className="h-1/4">
          <h1 className="text-2xl font-bold tracking-wide">Welcome Back !</h1>
          <span className="text-sm text-gray-500">
            Login to bussiness account
          </span>
        </div>
        {businessState.error && <div className="absolute  top-20 text-red-500" > Invalid Username or Password </div>}
        <div className="flex h-3/4 flex-col items-start justify-center gap-4">
          
          <div className="relative w-full">
            <label
              htmlFor="username "
              className="absolute left-5 top-3 text-xs tracking-wider"
            >
              {" "}
              Username
            </label>
            <input type="text" value={username} onChange={(e)=>setUsername(e.target.value)} className="w-full h-16 px-5 pb-2 pt-8 text-sm rounded-md outline-none " />
          </div>

          <div className="relative w-full">
            <label
              htmlFor="password"
              className="absolute left-5 top-3 text-xs tracking-wider"
            >
              Password
            </label>
            <input type={showPassword ? "text" : "password"} value={password} onChange={(e)=>setPassword(e.target.value)}  className={`w-full h-16 px-5 pb-2 pt-8   text-gray-900 rounded-md outline-none ${showPassword?"text-sm" : "text-3xl"}`}  />
            {showPassword ? (
            <EyeOff 
              className="absolute right-5 top-7 cursor-pointer text-gray-600 size-5" 
              onClick={() => setShowPassword(false)}
            />
          ) : (
            <Eye 
              className="absolute right-5 top-7 cursor-pointer text-gray-600 size-5" 
              onClick={() => setShowPassword(true)}
            />
          )}
            <button className="text-sm">Forget Password?</button>
          </div>
        </div>

        <div className="flex h-1/4 w-full flex-col gap-2">
          <button className="w-full rounded-md bg-cyan-500 py-2 font-semibold tracking-wider text-white" onClick={handleLogin}>
            Login
          </button>
          <span className="text-xs text-gray-500">

            <span className="">By clicking on login, I accept the </span>
            <span className="font-bold text-slate-800 mx-1">Terms and Conditions</span>

            & 
            
            <span className="font-bold text-slate-800 mx-1">Privacy policy</span>
          </span>
        </div>
      </form>
    </div>
  );
};

export default LoginBussinessForm;
