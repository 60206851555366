import React from "react";
import AccountHeader from "../utils/account-header";

const Gstin = () => {

    const gsts = [
        {number : "27AADKH654R5FE" , companyName : "Village Foods and Service Pvt. Ltd " },
        {number : "27AADKH654R5FE" , companyName : "Village Foods and Service Pvt. Ltd " },
    ]

  return (
    <div className="mt-[156px]">
      <AccountHeader />

      <div className="mt-5 flex h-[70vh] flex-col items-center px-10 pt-1">
        <div
          className="relative h-full w-[65%] overflow-auto rounded-3xl border shadow-xl"
          style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
        >

        {/* Grid Container */}
          <div className="px-5">
            {/* Grid Header */}
            <div className="sticky top-0 border-b bg-white py-4 font-semibold text-gray-700">
              <div className="grid grid-cols-3 px-20">
                <div className="flex justify-center text-left ">GSTIN</div>
                <div className="flex justify-center text-center ">Company Name</div>
                <div className="flex justify-center text-center ">Venue Mapped</div>
              </div>
            </div>

            {/* Grid Body */}
            <div className="mt-5 px-20">
              {gsts.map((gst, index) => (
                <div 
                  key={index} 
                  className="grid grid-cols-3 py-2 hover:bg-gray-50"
                >
                  <div className="flex justify-center text-center">
                    <div className="w-full ">{gst.number}</div>
                  </div>
                  <div className="flex justify-center text-center">
                    <div className="w-full ">{gst.companyName}</div>
                  </div>
                  <div className="flex justify-center text-center ">
                    <div className="w-full  underline cursor-pointer underline-offset-4">Check Venues</div>
                  </div>
                </div>
              ))}
            </div>
          </div>



        </div>
      </div>
    </div>
  );
};

export default Gstin;
