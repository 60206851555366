import React, { useEffect, useState } from "react";
import CreateAdButton from "../utils/create-ad/Create-ad-button";
import EventOfferHeader from "./EventOfferHeader";
import EventOfferSelector from "./EventOfferSelector";
import Timeline from "../utils/timiline/Timeline";
import AddEventCalender from "./AddEventCalender";
import CreateAdForm from "../utils/create-ad/create-ad-form/CreateAdForm";
import EditTimeSlots from "../utils/Edit-time-slot/EditTimeSlot";
import AddAdvertisement from "../utils/add-advertisement/AddAdvertisement";
import { useDispatch, useSelector } from "react-redux";
import {
  editEvent,
  getEvent,
  getTimeSlots,
} from "../../../../store/slices/businessPromoterSlice";
import { editEventRequest } from "../../../../store/apis/business-promotion-apis";

const EventOffer = () => {
  const [isEditTimeOpen, setIsEditTimeOpen] = useState(false);
  const [addEventOpen, setAddEventOpen] = useState(false);
  const [showCreateAd, setShowCreateAd] = useState(false);
  const [addAd, setAddAd] = useState({
    open: false,
    startTime: null,
    endTime: null,
  });

  const dispatch = useDispatch();
  const outletId = useSelector((state) => state.bussinessAuth.currentOutletId);
  const events = useSelector((state) => state.scheduler.eventOffers);

  let firstEvent = events[0] || { DATE: "" };
  const [currentDate, setCurrentDate] = useState(firstEvent.DATE);

  const { eventOffers, eventSlots: timeSlots } = useSelector(
    (state) => state.scheduler,
  );

  const [fetchOnce, setFetchOnce] = useState(true);

  useEffect(() => {
    if (!eventOffers.length) {
      setFetchOnce(false);
      dispatch(
        getEvent({
          outletId,
          adType: "E",
          date: currentDate,
        }),
      );
    }
  });

  useEffect(() => {
    dispatch(getTimeSlots({ outletId, adType: "E", date: currentDate }));
  }, [currentDate]);

  const handleOfferClick = async (day, status) => {
    const eventBody = {
      eventName: day.EVENT_NAME,
      date: day.DATE,
      enabled: status.toString(),
      eventId: day.EVENT_ID,
    };

    // dispatch(
    //   editEvent({ outletId: outletId.toString(), adType: "E", eventBody }),
    // );

    await editEventRequest({ outletId, eventBody, adType: "E" });
    dispatch(
      getEvent({
        outletId,
        adType: "E",
        date: currentDate,
      }),
    );
  };

  return (
    <div className="bg-red-2 mt-[156px] flex h-screen flex-col px-10 pt-1">
      <div className="flex h-full flex-col">
        <div className="flex flex-col gap-5">
          <div className="flex h-[62%] gap-5">
            <div
              className="relative h-full w-[60%] overflow-auto rounded-3xl border px-5 shadow-xl"
              style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
            >
              <EventOfferHeader
                isEditTimeOpen={isEditTimeOpen}
                setIsEditTimeOpen={setIsEditTimeOpen}
                currentDate={currentDate}
              />
              {isEditTimeOpen && (
                <EditTimeSlots
                  setIsEditTimeOpen={setIsEditTimeOpen}
                  adType={"E"}
                  date={currentDate}
                />
              )}
              <Timeline
                addAd={addAd}
                adType={"E"}
                timeSlots={timeSlots}
                setAddAd={setAddAd}
                currentDate={currentDate}
              />{" "}
            </div>
            {showCreateAd && <CreateAdForm setShowCreateAd={setShowCreateAd} />}
            {addEventOpen && (
              <AddEventCalender setAddEventOpen={setAddEventOpen} />
            )}

            <div className="h-full w-[40%]">
              <EventOfferSelector
                currentDate={currentDate}
                setAddEventOpen={setAddEventOpen}
                events={events}
                setCurrentDate={setCurrentDate}
                handleOfferClick={handleOfferClick}
              />
            </div>
          </div>
          <CreateAdButton setShowCreateAd={setShowCreateAd} />
        </div>
      </div>
    </div>
  );
};

export default EventOffer;
