import { useState } from "react";
import TimelineCard from "./TimelineCard";

export default function Timeline({
  adType,
  timeSlots,
  currentDay,
  currentDate,
}) {
  const [addAd, setAddAd] = useState(null); // Store the slot object when clicked

  return (
    <div className="relative pb-4 pl-8 pr-4 pt-4">
      <div className="">
        {timeSlots.map((slot, index) => (
          <div key={index} className="relative mb-5">
            <div className="absolute -left-7 mt-1.5 h-3 w-4 bg-[#B8D354]" />
            <div className="mb-4 flex items-center justify-between text-sm font-medium text-gray-900">
              <span>{`${String(slot.startTime).substr(0, 2)}:${String(
                slot.startTime,
              ).substr(2, 4)}`}</span>
              <hr className="w-[90%]"></hr>
            </div>
            <TimelineCard
              adType={adType}
              slot={slot}
              setAddAd={setAddAd}
              addAd={
                addAd?.startTime === slot.startTime &&
                addAd?.endTime === slot.endTime
                  ? addAd
                  : null
              }
              curDay={currentDay}
              curDate={currentDate}
            />
          </div>
        ))}
      </div>
      <div
        className="absolute left-[11px] top-6 w-0.5 bg-[#B8D354]"
        style={{
          height: `calc(100% - ${timeSlots.length > 1 ? "185px" : "0px"})`,
        }}
      />
    </div>
  );
}
