import React from "react";
import { Phone } from "lucide-react";
import { Heart, MousePointer2, SquareArrowOutUpRight } from "lucide-react";

const OutletInfo = ({ outletInfo }) => {
  const handleExternalNavigation = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="mt-2 flex flex-col gap-3 md:mt-10">
      {/* Header Section */}
      <div className="flex items-center justify-between">
        {/* Outlet Name */}
        <h1 className="text-3xl font-bold text-gray-800 lg:text-4xl">
          {outletInfo?.NAME}
        </h1>

        {/* Mobile Section (Visible only on large screens) */}
        <span className="hidden items-center gap-1 text-xl font-medium duration-100 hover:scale-105 lg:visible lg:flex">
          <Phone className="text-gray-600 hover:text-black" />{" "}
          {outletInfo?.PHONE_NUMBER}
        </span>
      </div>

      {/* Address and Status Section */}
      <div className="flex flex-col  items-start md:flex-row   md:items-center md:justify-between gap-3 ">
        {/* Outlet Address and Status */}
        <div className="flex md:w-2/5 flex-1 flex-col gap-1 text-gray-500 lg:text-xl  w-full">
          {`${outletInfo?.ADDRESS_LINE_ONE}, ${outletInfo?.ADDRESS_LINE_TWO}, ${outletInfo?.DISTRICT}, ${outletInfo?.STATE}`}

          <div>
            {/* Outlet Status (Open/Closed) */}
            <span className="lg:text-xl">
              <span
                className={
                  outletInfo.status === "Open now"
                    ? "text-cyan-500"
                    : "text-red-600"
                }
              >
                {outletInfo.status}
              </span>
              <span className="text-gray-400">
                {" "}
                - {outletInfo?.OPEN_TIME} -{outletInfo?.CLOSE_TIME} (Today)
              </span>
            </span>
          </div>
        </div>

        {/* Action Buttons Section */}
        <div className="flex flex-wrap gap-1 lg:gap-5">
          {/* Call Button (Visible only on small screens) */}
          <button className="flex items-center gap-1 rounded-md border-2 border-gray-400 px-1 py-1 text-xs hover:bg-gray-100 lg:hidden lg:gap-2 lg:px-3 lg:py-2">
            <Phone className="size-5 transform text-sm text-gray-600 hover:text-black" />
            Call
          </button>

          {/* Direction Button */}
          <button
            className="flex items-center gap-1 rounded-md border-2 border-gray-400 px-1 py-1 text-xs hover:bg-gray-100 md:text-base lg:gap-2 lg:px-3 lg:py-2"
            onClick={() => handleExternalNavigation(outletInfo?.MAP_URL ||"https://maps.app.goo.gl/uvZt7d3nCYZVaNnf6")}
          >
            <MousePointer2 className="scale-x-[-1] transform text-sm text-gray-600 hover:text-black md:text-base" />
            Direction
          </button>

          {/* Share Button */}
          {/* <button className="flex items-center gap-1 rounded-md border-2 border-gray-400 px-1 py-1 text-xs hover:bg-gray-100 md:text-base lg:gap-2 lg:px-3 lg:py-2">
            <SquareArrowOutUpRight className="text-sm text-gray-600 hover:text-black" />
            Share
          </button> */}

          {/* Favourites Button */}
          {/* <button className="flex items-center gap-1 rounded-md border-2 border-gray-400 px-1 py-1 text-xs hover:bg-gray-100 md:text-base lg:gap-2 lg:px-3 lg:py-2">
            <Heart className="text-sm text-red-500" /> Favourites
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default OutletInfo;
