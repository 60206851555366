import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchUserDiscountsRequest,
  sendLogInOtpRequest,
  verifyLogInOtpRequest,
} from "../apis/auth-apis";
import Cookies from "js-cookie";
import { editProfile } from "../apis/regular-user-apis";

const initialState = {
  userData: {
    email: "",
    phoneNumber: "",
    name: "",
    address: "",
    referral: "",
    profilePic: null
  },
  discounts: [],
  otpVerified: false,
  otpSent: false,
  signedIn: false,
  loading: false,
  error: null,
  accessToken: "",
  userId: "",
  sidebarState: "login",
  authComponentVisible: false,
};

export const sendLoginOtp = createAsyncThunk(
  "auth/sendLoginOtp",
  async (phoneNumber, { rejectWithValue }) => {
    try {
      return await sendLogInOtpRequest({ ...phoneNumber });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const verifyLoginOtp = createAsyncThunk(
  "auth/verifyLoginOtp",
  async ({ otp, userId }, { rejectWithValue }) => {
    try {
      return await verifyLogInOtpRequest({ otp, userId });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchUserDiscounts = createAsyncThunk(
  "auth/fetchUserDiscounts",
  async (reqData, rejectWithValue) => {
    try {
      return await fetchUserDiscountsRequest();
    } catch (err) {
      console.log("Discount err", err);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateUserProfile = createAsyncThunk(
  "auth/updateUserProfile",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await editProfile(formData); 
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUserData: (state, action) => {
      state.userData = { ...state.userData, ...action.payload };
    },
    openLogin: (state) => {
      state.sidebarState = "login";
    },
    openSignup: (state) => {
      state.sidebarState = "signup";
    },
    showAuthComponent: (state) => {
      state.authComponentVisible = true;
    },
    hideAuthComponent: (state) => {
      state.authComponentVisible = false;
    },
    cancelLoginOtp: (state) => {
      state.loading = false;
      state.userId = "";
      state.otpSent = false;
    },
    initializeAuth: (state) => {
      console.log("initializing auth")
      const authToken = Cookies.get("authToken");
      const userDetails = Cookies.get("userDetails");

      if (authToken && userDetails) {
        state.signedIn = true;
        state.otpVerified = true;
        state.userData = JSON.parse(userDetails);
        state.accessToken = authToken;
      }
    },
    resetAuth: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendLoginOtp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendLoginOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.userId = action.payload.userId;
        state.otpSent = true;
      })
      .addCase(sendLoginOtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.Message;
      })
      .addCase(verifyLoginOtp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyLoginOtp.fulfilled, (state, action) => {
        // console.log("Access Token:", action.payload.accessToken);

        // Store token and user details in cookies with expiry of 1 day
        Cookies.set("authToken", action.payload.accessToken, {
          expires: 1,
          path: "/",
          sameSite: "strict",
        });
        Cookies.set("userDetails", JSON.stringify(action.payload.userDetails), {
          expires: 1,
          path: "/",
          sameSite: "strict",
        });
        Cookies.remove("business_auth_token")

        // Initialize state from cookies
        const authToken = Cookies.get("authToken");
        const userDetails = Cookies.get("userDetails");

        state.loading = false;
        state.otpVerified = true;
        state.signedIn = true;
        state.userData = userDetails
          ? JSON.parse(userDetails)
          : { ...action.payload.userDetails };
        state.accessToken = authToken || action.payload.accessToken;
      })
      .addCase(verifyLoginOtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.Message;
      })
      .addCase(fetchUserDiscounts.fulfilled, (state, action) => {
        state.discounts = action.payload.coupons;
      })
      .addCase(fetchUserDiscounts.rejected, (state) => {
        state.discounts = [];
      })
      .addCase(updateUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        console.log("payload" , action.payload)
        
        // Update local state
        state.userData = {
          ...action.payload.user
        };
        Cookies.remove("userDetails")

        // Update cookie with new user details
        Cookies.set("userDetails", JSON.stringify(state.userData), {
          expires: 1,
          path: "/",
          sameSite: "strict",
        });
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.Message || "Failed to update profile";
      });
  },
});

export const {
  updateUserData,
  openSignup,
  openLogin,
  showAuthComponent,
  hideAuthComponent,
  cancelLoginOtp,
  initializeAuth,
  resetAuth,
} = authSlice.actions;
export default authSlice.reducer;
