import React, { useEffect, useState } from "react";
import AdLibraryHeader from "../Ad-library/Ad-library-header";
import CreateAdButton from "../utils/create-ad/Create-ad-button";
import Card from "./Card";
import CreateAdForm from "../utils/create-ad/create-ad-form/CreateAdForm";
import AddAdvertisement from "../utils/add-advertisement/AddAdvertisement";
import { useSelector } from "react-redux";
import {
  getHighlightsRequest,
  scheduleHighlightsRequest,
} from "../../../../store/apis/business-promotion-apis";
import ScheduleAdv from "./ScheduleHighlights";

const Highlights = () => {
  const [showCreateAd, setShowCreateAd] = useState(false);
  const [addAd, setAddAd] = useState(false);
  const [ads, setAds] = useState([]);
  const [liveHighlights, setLiveHighlights] = useState([]);
  const outletId = useSelector((state) => state.bussinessAuth.currentOutletId);

  const getHighlights = async () => {
    const data = await getHighlightsRequest(outletId);
    console.log(data);
    setLiveHighlights(data.filter((ad) => ad.LIVE_STATUS === "true"));
    console.log(
      "Live highlights",
      data.filter((ad) => ad.LIVE_STATUS === "true"),
    );
    setAds(data);
  };

  useEffect(() => {
    if (outletId ) {
      getHighlights();
    }
  },[outletId]);

  return (
    <div className="bg-red-2 mt-[156px] h-screen px-10 pt-1">
      <div className="relative h-[69%] rounded-2xl border px-20 py-5 shadow-xl">
        <AdLibraryHeader page={"highlights"} />

        <div className="flex h-[90%] items-center justify-center gap-5">
          {liveHighlights.length >= 1 ? (
            <Card setAddAd={setAddAd} adData={liveHighlights[0]} isLive />
          ) : (
            <Card setAddAd={setAddAd} />
          )}
          {liveHighlights.length >= 2 ? (
            <Card setAddAd={setAddAd} adData={liveHighlights[1]} isLive />
          ) : (
            <Card setAddAd={setAddAd} />
          )}
          {liveHighlights.length >= 3 ? (
            <Card setAddAd={setAddAd} adData={liveHighlights[2]} isLive />
          ) : (
            <Card setAddAd={setAddAd} />
          )}
        </div>
        {addAd && (
          <ScheduleAdv
            setAddAd={setAddAd}
            highlightAds={ads}
            getHighlights={getHighlights}
          />
        )}
      </div>
      {showCreateAd && (
        <CreateAdForm
          setShowCreateAd={setShowCreateAd}
          getHighlights={getHighlights}
        />
      )}
      <CreateAdButton setShowCreateAd={setShowCreateAd} />
    </div>
  );
};

export default Highlights;
