// import { configureStore } from "@reduxjs/toolkit";
// import signupReducer from "./slices/signupSlice.js";
// import locationReducer from "./slices/locationSlice";
// import authReducer from "./slices/authSlice";
// import outletsReducer from "./slices/outletsSlice";
// import bussinessAuthReducer from "./slices/bussinessAuthSlice.js";
// import listenerMiddleware from "./middlewares/outlet-middlewares.js";
//
// export const store = configureStore({
//   reducer: {
//     signup: signupReducer,
//     auth: authReducer,
//     location: locationReducer,
//     outlets: outletsReducer,
//     bussinessAuth: bussinessAuthReducer,
//   },
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware().concat(listenerMiddleware),
// });
//
// export default store;

import { configureStore } from "@reduxjs/toolkit";
import signupReducer from "./slices/signupSlice.js";
import locationReducer from "./slices/locationSlice";
import authReducer from "./slices/authSlice";
import outletsReducer from "./slices/outletsSlice";
import bussinessAuthReducer from "./slices/businessAuthSlice.js";
import listenerMiddleware from "./middlewares/outlet-middlewares.js";
import couponsReducer from "./slices/couponSlice.js";
import schedulerReducer from "./slices/businessPromoterSlice";
import userSettingsReducer from "./slices/userSettingSlice.js"
import businessDashboardReducer from "./slices/businessDashboardSlice.js"

export const store = configureStore({
  reducer: {
    signup: signupReducer,
    auth: authReducer,
    location: locationReducer,
    outlets: outletsReducer,
    bussinessAuth: bussinessAuthReducer,
    coupons: couponsReducer,
    scheduler: schedulerReducer,
    userSettings: userSettingsReducer,
    businessDashboard : businessDashboardReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(listenerMiddleware.middleware),
});
export default store;
