import React, { useState, useRef } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCategory } from '../../store/slices/outletsSlice';

const CategoriesCarousel = () => {
  const dispatch = useDispatch();
  const { pending, allCategories } = useSelector(
    (state) => state.outlets.outletCategories
  );
  const selectedCategory = useSelector(
    (state) => state.outlets.selectedCategory
  );

  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef(null);

  const scrollAmount = 5 * (140 + 20); // 140px is min-width of item, 20px is gap

  const scroll = (direction) => {
    const container = containerRef.current;
    if (!container) return;

    const newScrollAmount = direction === 'left' ? -scrollAmount : scrollAmount;
    container.scrollBy({ left: newScrollAmount, behavior: 'smooth' });
    setScrollPosition(container.scrollLeft + newScrollAmount);
  };

  const handleScroll = (e) => {
    setScrollPosition(e.target.scrollLeft);
  };

  const canScrollLeft = scrollPosition > 20;
  const canScrollRight = containerRef.current && 
    scrollPosition < (containerRef.current.scrollWidth - containerRef.current.clientWidth - 20);

  const CustomArrow = ({ direction, onClick, show }) => {
    if (!show) return null;

    return (
      <button
        onClick={onClick}
        className={`absolute top-12 z-10 hidden -translate-y-1/2 transform md:block ${
          direction === 'left' ? 'left-2' : 'right-2'
        }`}
        aria-label={`Scroll ${direction}`}
      >
        <div className="p-2 rounded-full border border-gray-300 bg-white hover:bg-gray-50 transition-colors">
          {direction === 'left' ? (
            <ChevronLeft className="text-gray-700 w-5 h-5" />
          ) : (
            <ChevronRight className="text-gray-700 w-5 h-5" />
          )}
        </div>
      </button>
    );
  };

  if (pending) {
    return (
      <div className="relative px-8 h-full w-full md:w-[90%] z-20 mt-5">
        <div className="flex items-center justify-center h-24 text-gray-500">
          Loading categories...
        </div>
      </div>
    );
  }

  if (!allCategories || allCategories.length === 0) {
    return (
      <div className="relative px-8 h-full w-full md:w-[90%] z-20 mt-5">
        <div className="flex items-center justify-center h-24 text-gray-500 absolute -top-5 left-1/2">
          No categories available
        </div>
      </div>
    );
  }

  return (
    <div className="relative mx-auto h-full w-full md:w-[90%] z-20 mb-5">
      <div className="md:px-8 "> {/* Added padding container */}
        <CustomArrow 
          direction="left" 
          onClick={() => scroll('left')} 
          show={canScrollLeft} 
        />
        
        <div
          ref={containerRef}
          onScroll={handleScroll}
          className="flex gap-2 md:gap-4 overflow-x-auto scrollbar-hide  pb-4 snap-x snap-mandatory scroll-smooth"
        >
          {allCategories.map((category, index) => (
            <div
              key={index}
              className="flex-none snap-start"
            >
              <button
                onClick={() => dispatch(setSelectedCategory(category.NAME))}
                className="flex flex-col items-center   md:min-w-[120px] p-4 rounded-lg transition-all"
              >
                <img
                  src={category.ICON_URL || "/categories-fallback.svg"}
                  alt={category.NAME}
                  className={`mb-[15px] w-[22px] h-[22px] md:w-[28px] md:h-[28px]  ${
                    selectedCategory === category.NAME ? "opacity-100 " : "opacity-60"
                  }`}
                />
                <p
                  className={`max-w-[140px] text-center text-[12px] md:text-[14px] ${
                    selectedCategory === category.NAME ? "text-[#303030] md:underline-offset-4 underline-offset-8 underline" : "text-[#7c7c7c]"
                  }`}
                >
                  {category.NAME.charAt(0).toUpperCase() + category.NAME.slice(1)}
                </p>
                {selectedCategory === category.NAME && (
                  <div className="mt-[5px] h-[2px] w-full " />  
                )}
              </button>
            </div>
          ))}
        </div>

        <CustomArrow 
          direction="right" 
          onClick={() => scroll('right')} 
          show={canScrollRight} 
        />
      </div>

      <style jsx global>{`
        .scrollbar-hide::-webkit-scrollbar {
          display: none;
        }
        .scrollbar-hide {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
      `}</style>
    </div>
  );
};

export default CategoriesCarousel;