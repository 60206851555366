import React from "react";
import noPreviewImage from "../../../assets/no-preview-available.svg"

const CurrentOfferCard = ({
  headline,
  discount,
  description,
  liveStatus,
  outletId,
  displayImage,
  termsCondition,
}) => {
  return (
    <div className="mb-10 mt-5 flex gap-3 shadow-sm lg:h-[260px] lg:gap-10 lg:px-0 lg:py-0">
      <div className="lg: w-5/12 lg:w-[236px] flex justify-center items-center border  border-gray-100">
        <img
          src={displayImage ?? noPreviewImage }
          alt="preview"
          className={` rounded-lg  ${displayImage? " h-full w-full object-cover" :"  "}`}
        />
      </div>

      <div className="flex flex-1 flex-col gap-1 pb-2 md:gap-5">
        <div className="flex items-center md:justify-between ">
          <h1 className="flex items-center justify-start text-base font-bold sm:text-xl md:gap-2 md:tracking-wide lg:text-3xl flex-1">
            <div className="tracking-tight leading-tight md:tracking-normal">
              {headline}
            </div>
          </h1>
          <div className="text-lg leading-tight flex  font-bold tracking-tighter sm:text-xl lg:text-3xl   ">
            {discount} % Off
          </div>
        </div>

        <span className="break-words text-sm leading-4 text-slate-600 md:text-lg lg:leading-none">
          {description}
        </span>

        <ul className="mx-6 mt-1 list-disc text-slate-500 marker:text-xs">
            <li
              className="break-words text-sm leading-4 tracking-tighter md:text-lg md:leading-normal md:tracking-normal"
            >
              {termsCondition}
            </li>
        </ul>
      </div>
    </div>
  );
};

export default CurrentOfferCard;
