import React, { useEffect, useState } from "react";
import { X } from "lucide-react";
import Card from "./Card";
import cardShuffleUnderline from "../../assets/card-shuffle-underline.svg";
import { saveUserSelectedDiscountRequest } from "../../store/apis/outlets-apis";
import { fetchUserDiscounts } from "../../store/slices/authSlice";
import { useDispatch } from "react-redux";

const ShuffleCards = ({
  setIsShuffled,
  setIsShuffleOpen,
  discounts,
  isShuffled,
  setSelectedDiscount,
  macId
}) => {
  const dispatch = useDispatch();
  const [isShuffling, setIsShuffling] = useState(false);
  const [myDiscounts, setMyDiscounts] = useState(discounts);
  const [shuffleStage, setShuffleStage] = useState(0);
  const [cardTransforms, setCardTransforms] = useState(
    myDiscounts.map(() => ({ translate: { x: 0, y: 0 }, rotate: 0 })),
  );
  const [openedCard, setOpenedCard] = useState(
    myDiscounts.reduce((acc, discount, index) => {
      acc[index] = false;
      return acc;
    }, {}),
  );

  useEffect(() => {
    console.log("shuffled", isShuffled);
  }, [isShuffled]);

  const changeAllCardsStatus = () => {
    return new Promise((resolve) => {
      // Set all cards to true
      setOpenedCard((prevState) => {
        const updatedState = Object.keys(prevState).reduce((acc, key) => {
          acc[key] = true; // Set each card's value to true
          return acc;
        }, {});
        return updatedState;
      });

      // After 1 second, set all cards back to false
      setTimeout(() => {
        setOpenedCard((prevState) => {
          const updatedState = Object.keys(prevState).reduce((acc, key) => {
            acc[key] = false; // Set each card's value to false
            return acc;
          }, {});
          return updatedState;
        });

        // Resolve the promise after setting cards to false
        resolve();
      }, 1500); // 1.5 second delay
    });
  };

  const handleShuffle = async () => {
    if (isShuffling) return;

    await changeAllCardsStatus();

    setIsShuffling(true);
    setShuffleStage(1);

    // Stage 1: Random initial transforms
    const stage1Transforms = myDiscounts.map(() => ({
      translate: {
        x: Math.random() * 40 - 20,
        y: Math.random() * 40 - 20,
      },
      rotate: Math.random() * 30 - 15,
    }));

    setCardTransforms(stage1Transforms);

    // Move to Stage 2
    setTimeout(() => {
      setShuffleStage(2);

      // Stage 2: Different random transforms
      const stage2Transforms = myDiscounts.map(() => ({
        translate: {
          x: Math.random() * 60 - 30,
          y: Math.random() * 60 - 30,
        },
        rotate: Math.random() * 45 - 22.5,
      }));

      setCardTransforms(stage2Transforms);

      // Move to Final Stage
      setTimeout(() => {
        setShuffleStage(3);

        // Stage 3: Final random transforms and reset
        const finalTransforms = myDiscounts.map(() => ({
          translate: {
            x: Math.random() * 80 - 40,
            y: Math.random() * 80 - 40,
          },
          rotate: Math.random() * 60 - 30,
        }));

        setCardTransforms(finalTransforms);

        // Reset after final stage
        setTimeout(() => {
          // Create a copy of discounts and shuffle it
          const shuffledDiscounts = [...myDiscounts].sort(
            () => Math.random() - 0.5,
          );

          // Update the discounts order and reset related states
          setMyDiscounts(shuffledDiscounts);

          // Reset card transforms to default
          setCardTransforms(
            shuffledDiscounts.map(() => ({
              translate: { x: 0, y: 0 },
              rotate: 0,
            })),
          );

          // Reset opened card state based on new discounts order
          setOpenedCard(
            shuffledDiscounts.reduce((acc, _, index) => {
              acc[index] = false;
              return acc;
            }, {}),
          );

          setIsShuffling(false);
          setShuffleStage(0);
        }, 500);
      }, 500);
      setIsShuffled(true);
    }, 500);
  };

  const handleOpenCard = async (discount, index) => {
    if (!isShuffled || isShuffling) return;
  
    // Open the card by setting the value of the provided index to true
    setOpenedCard((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  
    // Set a timeout to close the card after 1 second (1 second for better user experience)
    setTimeout(async () => {
      setOpenedCard((prevState) => ({
        ...prevState,
        [index]: false,
      }));
  
      // Log the selected discount for debugging
      console.log("Selected Discount:", discount);
  
      // Set the selected discount
      setSelectedDiscount(discount);
  
      // Save the user discount
      try {
        await saveUserDiscount(discount); // Call async saveUserDiscount function
        dispatch(fetchUserDiscounts()); // Dispatch after saving discount
      } catch (error) {
        console.error("Error saving discount:", error); // Handle any errors during the save process
      }
    }, 1000); // Timeout of 1 second for closing the card
  };
  
  // Save the user discount in the async function
  const saveUserDiscount = async (discount) => {
    try {
      const res = await saveUserSelectedDiscountRequest(macId, discount);
      console.log("discount saved" , discount)
    } catch (error) {
      console.error("Failed to save discount:", error);
    }
  };
  
  return (
    <>
      <div className="hidden h-4/5 w-3/5 flex-col justify-between rounded-xl bg-white md:visible md:flex">
        <div className="relative flex h-1/3 items-center justify-center">
          <button
            onClick={() => setIsShuffleOpen(false)}
            className="absolute right-0 top-0 mr-2 mt-2"
          >
            <X />
          </button>
          <h1 className="flex flex-col items-center text-3xl font-bold tracking-wide">
            <div>
              Shuffle &{" "}
              <img
                src={cardShuffleUnderline}
                alt="undeline"
                className="w-28"
              ></img>
            </div>{" "}
            get your discount!
          </h1>
        </div>

        <div className="mt-10 flex h-3/5 w-full flex-wrap justify-center gap-5 p-2">
          {myDiscounts.map((discount, index) => {
            const transform = cardTransforms[index];

            return (
              <div className="relative h-full w-1/5" key={index}>
                {/* Card Component */}
                <Card
                  discount={discount}
                  className={`absolute inset-0 border-4 transition-opacity duration-500 ${
                    openedCard[index] ? "z-10 opacity-100" : "-z-10 opacity-0"
                  }`}
                />

                {/* Image */}
                <img
                  src="/shuffle-card-front.svg"
                  className={`absolute left-0 top-0 h-full w-full object-cover shadow-2xl transition-all duration-500 ${
                    openedCard[index] ? "-z-10 opacity-0" : "z-10 opacity-100"
                  }`}
                  style={{
                    transform: `translate(${transform.translate.x}%, ${transform.translate.y}%) rotate(${transform.rotate}deg)`,
                    transition: "transform 0.5s ease-in-out",
                  }}
                  alt={`card ${index + 1}`}
                  onClick={() => handleOpenCard(discount, index)}
                />
              </div>
            );
          })}
        </div>

        <div className="my-2 flex h-1/3 flex-col items-center justify-end gap-2">
          <button
            className="rounded-full bg-cyan-500 px-8 py-2 text-xl font-semibold tracking-wide text-white"
            onClick={handleShuffle}
            disabled={isShuffling}
          >
            Shuffle
          </button>
          <span className="text-gray-500">How it works?</span>
        </div>
      </div>

      {/* Mobile view */}
      <div className="flex h-full w-full flex-col gap-5 bg-white p-2 md:hidden">
        <div className="flex items-center gap-2 p-2 shadow-md">
          <button onClick={() => setIsShuffleOpen(false)}>
            <X />
          </button>
          <span>SHUFFLE CARDS</span>
        </div>

        <h1 className="flex flex-col items-center text-2xl font-bold tracking-wide">
          <div>
            Shuffle &{" "}
            <img
              src={cardShuffleUnderline}
              alt="undeline"
              className="w-20"
            ></img>{" "}
          </div>{" "}
          get your discount!
        </h1>

        <div className="flex h-2/3 w-full flex-wrap justify-center gap-5">
          {myDiscounts.map((discount, index) => {
            const transform = cardTransforms[index];

            return (
              <div
                className="relative flex h-2/4 w-2/5 flex-wrap rounded-xl"
                key={index}
              >
                {/* Card Component */}
                <Card
                  discount={discount}
                  className={`absolute inset-0 h-full w-full border-4 transition-opacity duration-500 ${
                    openedCard[index] ? "z-10 opacity-100" : "-z-10 opacity-0"
                  }`}
                />

                {/* Image */}
                <img
                  src="/shuffle-card-front.svg"
                  className={`absolute inset-0 h-full w-full rounded-xl object-cover shadow-2xl transition-all duration-500 ${
                    openedCard[index] ? "-z-10 opacity-0" : "z-10 opacity-100"
                  }`}
                  style={{
                    transform: `translate(${transform.translate.x}%, ${transform.translate.y}%) rotate(${transform.rotate}deg)`,
                    transition: "transform 0.5s ease-in-out",
                  }}
                  alt={`card ${index + 1}`}
                  onClick={() => handleOpenCard(index)}
                />
              </div>
            );
          })}
        </div>

        <div className="mb-5 mt-3 flex flex-col items-center justify-end gap-2">
          <button
            className="rounded-full bg-cyan-500 px-8 py-2 text-xl font-semibold leading-tight tracking-wide text-white"
            onClick={handleShuffle}
            disabled={isShuffling}
          >
            Shuffle
          </button>
          <span className="text-gray-500">How it works?</span>
        </div>
      </div>
    </>
  );
};
export default ShuffleCards;
