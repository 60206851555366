import React, { useState } from "react";
import { Plus } from "lucide-react";
import { useRef } from "react";

const Card = ({ setAddAd, adData, isLive }) => {
  console.log("isLive", isLive);
  if (isLive) {
    return (
      <div
        className="h-3/5 w-1/6 rounded-xl shadow-xl"
        onClick={() => setAddAd(true)}
      >
        <div
          className="relative h-full w-full cursor-pointer rounded-xl bg-zinc-300"
          style={{
            backgroundImage: `url(${adData.PIC_URL})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </div>
    );
  }

  return (
    <div
      className="h-3/5 w-1/6 rounded-xl shadow-xl"
      onClick={() => setAddAd(true)}
    >
      <div className="relative h-full w-full cursor-pointer rounded-xl bg-zinc-300">
        <div className="pointer-events-none absolute inset-0 z-10 flex items-center justify-center gap-2">
          <Plus className="size-5 rounded-full bg-black text-white" />
          <span className="text-sm font-semibold">Add Highlight</span>
        </div>
      </div>
    </div>
  );
};

export default Card;
