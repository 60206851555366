import React from "react";
import axios from "axios";
import TabLogo from "../../assets/tab-logo.svg";
import { useDispatch, useSelector } from "react-redux";
// import { showAuthComponent } from "../store/slices/authSlice";
import { showAuthComponent } from "../../store/slices/authSlice";
import { MoveRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
function ProceedToCheckout({ name, measkOrderId }) {
  const { accessToken: token, userData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    if (!token) {
      console.log("displayAuthComponent");
      dispatch(showAuthComponent());
      return;
    }

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js",
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order
    const result = await axios.post(
      "https://api.themeask.com/outlet/route/v1/create",
      {
        measkOrderId: measkOrderId,
      },
      {
        headers: {
          Authorization: `${token}`,
        },
      },
    );

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }
    console.log(result);
    // Getting the order details back
    const {
      RZP_ORDER_ID: order_id,
      AMOUNT_AFTER_DISCOUNT: amount,
      CONVENIENCE_FEE: fee,
      OUTLET_ID: outletId,
    } = result.data.response;
    console.log(amount, fee);
    const orderAmount = amount + fee;
    const options = {
      key: "rzp_test_xzJ61d3UwxISoQ", // Enter the Key ID generated from the Dashboard
      amount: `800`,
      currency: "INR",
      name: "MEask",
      description: "Test Transaction",
      image: <TabLogo />,
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          outletId: outletId,
        };

        const result = await axios.post(
          "https://api.themeask.com/payment/confirm",
          data,
          {
            headers: {
              Authorization: `${token}`,
            },
          },
        );
        console.log(result);
        if (result.status === 200)
          navigate("/mvpdemo/payment-success", {
            state: result.data.successData[0],
          });
        else
          navigate("/mvpdemo/payment-failure", {
            state: result,
          });
      },
      prefill: {
        name: userData.name,
        email: userData.email,
        contact: userData.phoneNumber,
      },
      notes: {
        address: "The meask, headquarters",
      },
      theme: {
        color: "#29B3D2",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  return (
    <button
      className="flex w-2/3 justify-center gap-2 rounded-lg bg-[#29B3D2] py-3 text-base font-semibold text-white"
      id="rzp-button1"
      onClick={displayRazorpay}
    >
      {name} <MoveRight />
    </button>
  );
}

export default ProceedToCheckout;
