import React, { useState } from "react";
import { ChevronUp, ChevronDown } from "lucide-react";

const InputField = ({
  id,
  label,
  type = "text",
  value,
  onChange,
  placeholder,
  className = "",
  disabled = false
}) => (
  <div className="flex flex-col">
    <label htmlFor={id} className="text-sm text-zinc-400">
      {label}
    </label>
    <input
      id={id}
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      className={`rounded-lg border px-5 py-2 text-[#303030] outline-none ${
        disabled ? 'bg-[#EFEFEF] font-semibold text-[#7C7C7C]' : ''
      } ${className}`}
    />
  </div>
);

const EditProfileForm = ({ formData, setFormData, handleSubmit }) => {
  const [isGenderOpen, setIsGenderOpen] = useState(false);
  const genderOptions = ["Male", "Female", "Other"];

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [id === 'name' ? 'name' : 
        id === 'email' ? 'email' : 
        id === 'dob' ? 'dob' : id]: value
    }));
  };

  const handleGenderSelect = (gender) => {
    setFormData(prev => ({
      ...prev,
      gender
    }));
    setIsGenderOpen(false);
  };

  return (
    <div className="flex h-[62%] flex-col">
      <form 
        className="flex flex-grow flex-col gap-6 md:px-10 px-5 pt-5 pb-32 md:pb-0"
        onSubmit={handleSubmit}
      >
        <InputField 
          id="name" 
          label="Full Name" 
          value={formData.name}
          onChange={handleInputChange}
        />
        <InputField
          id="mobile"
          label="Mobile Number"
          value={formData.mobileNumber}
          disabled={true}
          className="bg-[#EFEFEF] font-semibold text-[#7C7C7C]"
        />
        <InputField 
          id="email" 
          label="Email" 
          value={formData.email}
          onChange={handleInputChange}
          type="email"
        />

        <div className="flex md:flex-row flex-col w-full justify-between md:gap-2 gap-6">
          <div className="flex flex-col">
            <InputField 
              id="dob" 
              label="Date of Birth"
              type="text"
              value={formData.dob}
              onChange={handleInputChange}
            />
          </div>

          <div className="flex flex-1 flex-col">
            <label className="text-sm text-zinc-400">Gender</label>
            <div className="relative">
              <div
                onClick={() => setIsGenderOpen(!isGenderOpen)}
                className="flex cursor-pointer items-center justify-between rounded-lg border px-5 py-2 duration-200"
              >
                <span className={formData.gender ? "text-[#303030]" : "text-[#989898]"}>
                  {formData.gender || "Select gender"}
                </span>
                {isGenderOpen ? (
                  <ChevronUp className="h-4 w-4 text-gray-500" />
                ) : (
                  <ChevronDown className="h-4 w-4 text-gray-500" />
                )}
              </div>

              {isGenderOpen && (
                <div className="absolute left-0 right-0 top-full z-10 mt-1 max-h-48 overflow-y-auto rounded-sm border bg-white shadow-lg">
                  {genderOptions.map((gender) => (
                    <div
                      key={gender}
                      className="cursor-pointer px-5 py-2 hover:bg-gray-50"
                      onClick={() => handleGenderSelect(gender)}
                    >
                      {gender}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </form>

      <div className="mt-auto px-10 pb-5">
        <button 
          type="submit"
          onClick={handleSubmit}
          className="w-full rounded-lg bg-[#29B3D2] py-2 text-lg font-semibold text-white hover:bg-[#1a9ab8] transition-colors"
        >
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default EditProfileForm;