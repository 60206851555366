import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCardsDataByCoords,
  getCardsDataByLandmark,
  getCategories,
  resetOffset,
} from "../store/slices/outletsSlice";
import HomePageCard from "../components/homepage/homepage-card/homepage-card";
import NearbyOutletCard from "../assets/nearby-outlet-card.svg";
import ForBusinessCard from "../assets/for-business-card.svg";
import BecomePromoterCard from "../assets/become-promoter-card.svg";
import { useNavigate } from "react-router-dom";
import NearbyPlaces from "../components/landing-page/nearby-places";
import { isCookieExpired, readCookie } from "../utils/cookie";
import { initializeLocation } from "../store/slices/locationSlice";
import { logoutBusinessUser } from "../store/slices/businessAuthSlice";

function LandingPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cardDetails = [
    {
      title: "Become a Promoter Today.",
      subtitle: "The more you share, the more you earn!",
      backgroundSvg: BecomePromoterCard,
      handleClick: () => navigate("/mvpdemo/become-promoter"),
      showButton : true
    },
    {
      title: "Boost Your Bussiness with Us.",
      subtitle:
        "Partner with us to showcase your exclusive offers and reach thousands of user's in your area.",
      backgroundSvg: ForBusinessCard,
      handleClick: () => {
        //logout the user and show register page
        dispatch(logoutBusinessUser());
        navigate("/mvpdemo/for-bussiness");
      },
      showButton : true
    },
    {
      title: "Get Amazing Deals at Nearby Outlets.",
      subtitle:
        "Weather you're shopping, dining or looking for services, we've got you covered with unbeatable offers",
      backgroundSvg: NearbyOutletCard,
      handleClick : ()=>{},
      showButton : false
    },
    
  ];

  const { limit, offSet, selectedCategory } = useSelector(
    (state) => state.outlets,
  );

  const location = useSelector((state) => state.location);
  const { longitude, latitude, isSelectedLocation, landmark, fetchedLocation } =
    location;

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [fetchingCardsData, setFetchingCardsData] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const fetchMoreOutlets = (newOffset) => {
    if (!fetchingCardsData) {
      setFetchingCardsData(true);

      // if(!location){
      //   if(readCookie("location") && !isCookieExpired("location")){
      //     dispatch(initializeLocation())
      //     console.log("location initialized" , location)
      //   }
      // }

      if (isSelectedLocation && landmark) {
        dispatch(
          getCardsDataByLandmark({
            limit,
            offSet,
            landmark,
            selectedCategory,
            newOffset,
            fetchedLocation,
          }),
        ).finally(() => setFetchingCardsData(false));
      } else if (latitude && longitude) {
        dispatch(
          getCardsDataByCoords({
            longitude,
            latitude,
            limit,
            offSet,
            selectedCategory,
            newOffset,
            fetchedLocation,
          }),
        ).finally(() => setFetchingCardsData(false));
      } else {
      }
      dispatch(
        getCardsDataByCoords({
          limit,
          offSet,
          selectedCategory,
          newOffset,
          fetchedLocation,
        }),
      ).finally(() => setFetchingCardsData(false));
    }
  };

  useEffect(() => {
    if (isInitialLoad) {
      dispatch(getCategories());
      setIsInitialLoad(false);
      // if (!fetchedLocation) fetchMoreOutlets(true);
    }
  }, []);

  useEffect(() => {
    if ((latitude && longitude) || landmark) {
      fetchMoreOutlets(true);
    }
  }, [latitude, longitude, landmark]);

  useEffect(() => {
    if (!isInitialLoad) fetchMoreOutlets(true);
  }, [selectedCategory]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % cardDetails.length);
    }, 3000);
    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [cardDetails.length]);

  return (
    <div className="mt-[32px] bg-[#FCFCFC]">
      <div className="mx-[5%] mt-[9%] flex min-h-[380px] flex-col items-center">
        <h3 className="mb-6 mt-6 hidden max-w-[1200px] self-start text-left text-xl font-semibold text-[#303030] md:flex">
          What's new ?
        </h3>
        <div className="hidden flex-col items-center justify-between md:visible md:flex lg:flex-row lg:gap-5">
          {/* <div className="flex flex-col gap-6 px-6 md:max-w-5xl md:flex-row"> */}
          {cardDetails.map((card, index) => (
            <HomePageCard
              title={card.title}
              subtitle={card.subtitle}
              backgroundSvg={card.backgroundSvg}
              handleClick={card.handleClick}
              landingPage
              key={index}
              showButton = {card.showButton}
            />
          ))}
        </div>

        {/* Mobile View */}
        <div className="relative mt-[28%] w-screen md:hidden">
          <h3 className="mx-[2%] mb-4 max-w-[1200px] self-start text-left text-xl font-semibold text-[#303030]">
            What's new ?
          </h3>

          {/* Cards */}
          <div className="mx-[4%] overflow-hidden">
            <div
              className="flex transition-transform duration-500"
              style={{
                transform: `translateX(-${currentIndex * 100}%)`,
              }}
            >
              {cardDetails.map((card, index) => (
                <HomePageCard
                  key={index}
                  title={card.title}
                  subtitle={card.subtitle}
                  backgroundSvg={card.backgroundSvg}
                  handleClick={() => navigate(card.navigate)}
                  landingPage
                  className="min-w-full"
                />
              ))}
            </div>
          </div>
          {/* Dots Indicator */}
          <div className="absolute bottom-2 left-1/2 flex -translate-x-1/2 transform items-center gap-2">
            {cardDetails.map((_, index) => (
              <div
                key={index}
                className={`rounded-full ${
                  index === currentIndex
                    ? "h-3 w-3 bg-blue-500"
                    : "h-2 w-2 bg-gray-400"
                }`}
                onClick={() => setCurrentIndex(index)}
              ></div>
            ))}
          </div>
        </div>
      </div>

      <div className="mx-[2%] mt-[7%] md:mx-[5%]">
        <NearbyPlaces fetchMoreOutlets={fetchMoreOutlets} />
      </div>
    </div>
  );
}

export default LandingPage;
