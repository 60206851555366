import React from "react";
import axios from "axios";
import MeaskBusinessLogo from "../assets/meask-bussiness-partner-logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { showAuthComponent } from "../store/slices/authSlice";
function DemoPayment() {
  const token = useSelector((state) => state.auth.accessToken);
  const dispatch = useDispatch();
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    if (!token) {
      console.log("displayAuthComponent");
      dispatch(showAuthComponent());
      return;
    }

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js",
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order
    const result = await axios.post(
      "https://api.themeask.com/outlet/route/create",
      {
        amount: 19600,
        macId: "123",
        outletId: 87365590,
      },
      {
        headers: {
          Authorization: `${token}`,
        },
      },
    );

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }
    console.log(result);
    // Getting the order details back
    const {
      amount,
      razorpayOrderId: order_id,
      outletId,
    } = result.data.response;
    const options = {
      key: "rzp_test_xzJ61d3UwxISoQ", // Enter the Key ID generated from the Dashboard
      amount: `${amount}`,
      currency: "INR",
      name: "The Meask",
      description: "Test Transaction",
      image: { MeaskBusinessLogo },
      order_id: order_id,
      handler: async function (response) {
        console.log(response);
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          outletId: outletId,
        };

        const result = await axios.post(
          "https://api.themeask.com/payment/confirm",
          data,
          {
            headers: {
              Authorization: `${token}`,
            },
          },
        );

        alert(result.data.msg);
      },
      prefill: {
        name: "Yash",
        email: "Yash@meask.com",
        contact: "9999999999",
      },
      notes: {
        address: "The meask, headquarters",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  return (
    <div className="mt-[100px] flex h-[80vh] items-center justify-center">
      <button
        className="inline-block rounded-md border-2 border-blue-500 p-2"
        id="rzp-button1"
        onClick={displayRazorpay}
      >
        Pay Now
      </button>
    </div>
  );
}

export default DemoPayment;
