import React, { useEffect } from "react";
import CategoriesCarousel from "./categories-carousel";
import { useSelector } from "react-redux";

function Categories() {
  return (
    <div className="relative h-[102px] overflow-hidden ">
      {/* First sliding div */}
      {/* First gradient */}
      <div
        className="absolute inset-0 animate-slide bg-gradient-to-r from-transparent via-[#c1f3f8]/60 to-transparent backdrop-blur-[3px]"
        // style={{
        //   transform: "translateX(-100%)",
        //   willChange: "transform",
        // }}
      />

      {/* Second gradient */}
      {/* <div
        className="absolute inset-0 animate-slideSecond bg-gradient-to-r from-transparent via-[#AD7BFF]/80 to-transparent backdrop-blur-[3px]"
        style={{
          transform: "translateX(-100%)",
          willChange: "transform",
        }}
      /> */}

       <CategoriesCarousel />
    </div>
  );
}

export default Categories;
