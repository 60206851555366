import { useEffect, useState } from "react";
import { ChevronUp, ChevronDown } from "lucide-react";

const TimeSlotSelector = ({
  initialValue,
  onChange,
  onClose,
  venueStartTime = "00:00",
  venueEndTime = "23:59",
}) => {
  const [hours, setHours] = useState(0); // Default to minimum hour
  const [minutes, setMinutes] = useState(0);

  // Convert time string to Date object for comparison
  const parseTime = (timeStr) => {
    const [hour, minute] = timeStr.split(":").map(Number);
    const date = new Date();
    date.setHours(hour, minute);
    return date;
  };

  const venueStart = parseTime(venueStartTime);
  const venueEnd = parseTime(venueEndTime);

  // Check if a time is within venue hours
  const isTimeValid = (h, m) => {
    if (h < 0) return false; // Don't allow hours before 09:00
    if (h === 24) return false; // Don't allow 24:00 (use 00:00 instead)

    const testTime = new Date();
    testTime.setHours(h, m);

    if (venueEnd > venueStart) {
      return testTime >= venueStart && testTime <= venueEnd;
    } else {
      // Handle case where venue ends at midnight or after
      return testTime >= venueStart || testTime <= venueEnd;
    }
  };

  useEffect(() => {
    if (initialValue) {
      const [hour, minute] = initialValue.split(":").map(Number);
      if (isTimeValid(hour, minute)) {
        setHours(hour);
        setMinutes(minute);
      } else {
        // Set to venue start time if initial value is invalid
        setHours(venueStart.getHours());
        setMinutes(venueStart.getMinutes());
      }
    }
  }, [initialValue]);

  const increment = (type) => {
    if (type === "hours") {
      let newHours = hours + 1;
      if (newHours >= 24) newHours = 0; // Loop back to 09:00 if exceeding 23
      while (!isTimeValid(newHours, minutes)) {
        newHours = newHours + 1;
        if (newHours >= 24) newHours = 0;
      }
      setHours(newHours);
    } else {
      let newMinutes = (minutes + 15) % 60;
      if (newMinutes === 0 && hours < 23) {
        increment("hours");
      } else {
        while (!isTimeValid(hours, newMinutes)) {
          newMinutes = (newMinutes + 15) % 60;
          if (newMinutes === 0 && hours < 23) {
            increment("hours");
            break;
          }
        }
      }
      setMinutes(newMinutes);
    }
  };

  const decrement = (type) => {
    if (type === "hours") {
      let newHours = hours - 1;
      if (newHours < 0) newHours = 23; // Loop to 23:00 if going below 09:00
      while (!isTimeValid(newHours, minutes)) {
        newHours = newHours - 1;
        if (newHours < 0) newHours = 23;
      }
      setHours(newHours);
    } else {
      let newMinutes = (minutes - 15 + 60) % 60;
      if (newMinutes === 45 && hours > 0) {
        decrement("hours");
      } else {
        while (!isTimeValid(hours, newMinutes)) {
          newMinutes = (newMinutes - 15 + 60) % 60;
          if (newMinutes === 45 && hours > 0) {
            decrement("hours");
            break;
          }
        }
      }
      setMinutes(newMinutes);
    }
  };
  //
  const decrementNew = (type) => {
    if (type === "hours") {
      let newHours = hours - 1;
      if (newHours > 23) newHours = 0;
      else if (newHours < 0) newHours = 23;
      setHours(newHours);
    } else {
      let newMinutes = minutes - 15;
      if (newMinutes < 0) newMinutes = 60;
      else if (newMinutes > 45) newMinutes = 0;

      setMinutes(newMinutes);
    }
  };
  const incrementNew = (type) => {
    if (type === "hours") {
      let newHours = hours + 1;
      if (newHours > 23) newHours = 0;
      else if (newHours < 0) newHours = 23;
      setHours(newHours);
    } else {
      let newMinutes = minutes + 15;
      if (newMinutes < 0) newMinutes = 60;
      else if (newMinutes > 45) newMinutes = 0;

      setMinutes(newMinutes);
    }
  };

  const formatTime = () =>
    `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;

  return (
    <div className="absolute left-0 top-full z-50 mt-1 w-full rounded-md bg-white px-1 shadow-lg">
      <div className="grid grid-cols-2 gap-4">
        {/* Hours */}
        <div className="flex items-center">
          <div className="flex w-full flex-col">
            <button
              onClick={() => incrementNew("hours")}
              className="flex justify-center rounded hover:bg-gray-100"
            >
              <ChevronUp className="h-4 w-4 text-gray-500" />
            </button>
            <div className="text-center text-sm shadow-2xl">
              {hours.toString().padStart(2, "0")}
            </div>
            <button
              onClick={() => decrementNew("hours")}
              className="flex justify-center rounded hover:bg-gray-100"
            >
              <ChevronDown className="h-4 w-4 text-gray-500" />
            </button>
          </div>
        </div>

        {/* Minutes */}
        <div className="flex items-center">
          <div className="flex w-full flex-col">
            <button
              onClick={() => incrementNew("minutes")}
              className="flex justify-center rounded hover:bg-gray-100"
            >
              <ChevronUp className="h-4 w-4 text-gray-500" />
            </button>
            <div className="text-center text-sm shadow-2xl">
              {minutes.toString().padStart(2, "0")}
            </div>
            <button
              onClick={() => decrementNew("minutes")}
              className="flex justify-center rounded hover:bg-gray-100"
            >
              <ChevronDown className="h-4 w-4 text-gray-500" />
            </button>
          </div>
        </div>
      </div>

      <div className="flex gap-2 border-t border-gray-200">
        <button onClick={onClose} className="w-full rounded py-1 text-sm">
          close
        </button>
        <button
          onClick={() => {
            onChange(formatTime());
            onClose();
          }}
          className="w-full rounded py-1 text-sm text-cyan-400"
        >
          save
        </button>
      </div>
    </div>
  );
};

export default TimeSlotSelector;
