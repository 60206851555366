import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const DashboardHeader = () => {
  const items = [
    {
      name: "Live Ad's",
      slug: "/dashboard/live-ads",
      link: "/mvpdemo/for-bussiness/dashboard/live-ads",
    },
    {
      name: "Coupons",
      slug: "/dashboard/coupons",
      link: "/mvpdemo/for-bussiness/dashboard/coupons",
    },
    {
      name: "Revenue",
      slug: "/dashboard/revenue",
      link: "/mvpdemo/for-bussiness/dashboard/revenue",
    },
    {
      name: "Settlement Report",
      slug: "/dashboard/settlement-report",
      link: "/mvpdemo/for-bussiness/dashboard/settlement-report",
    },
    // {
    //   name: "Tickets",
    //   slug: "/dashboard/tickets",
    //   link: "/mvpdemo/for-bussiness/dashboard/tickets",
    // }
  ];

  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  return (
    <div className=" fixed  w-screen top-[156px] z-20  bg-white flex items-start justify-between ">
      <div className="mx-5 flex-1">
        <div className="flex gap-20 px-5 pt-5">
          {items.map((item, index) => (
            <Link to={`${item.link}`}>
              <div
                key={index}
                className={`relative cursor-pointer font-semibold tracking-wide ${currentPath.includes(item.slug) ? "text-cyan-500" : "text-gray-500"}`}
              >
                {item.name}
                {currentPath.includes(item.slug) && (
                  <div className="absolute top-6 z-20 mt-2 w-32 rounded-full bg-cyan-500 p-[1px]" />
                )}
              </div>
            </Link>
          ))}
        </div>
        <hr className="mt-2 bg-black" />
      </div>

    </div>
  );
};

export default DashboardHeader;
