import React from "react";
import RegisterBusinessForm from "./register-business-form";
import InProgress from "../../in-progress/in-progress";
import FormSubmitted from "../../form-submit/form-submitted/form-submitter";
import { zodResolver } from "@hookform/resolvers/zod";
import { registerBusinessFormSchema } from "./register-business-form-schema";
import { useForm } from "react-hook-form";
function ForBusinessFormWithHooks() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful, isSubmitting },
  } = useForm({
    resolver: zodResolver(registerBusinessFormSchema),
  });

  return (
    <div className="mx-[3%] md:mx-0">
      {!isSubmitSuccessful && !isSubmitting && (
        <div className="flex w-full flex-col justify-center md:mr-[5%] md:w-[95%] md:flex-row">
          <div className="mt-[6%] flex w-full flex-col items-center md:w-[45%]">
            <div className="image-background flex h-[100px] w-full flex-col items-center justify-center md:h-[800px]">
              <h1 className="text-customHeading w-[400px] text-center font-bakbakOne md:text-left md:text-[36px]">
                Let’s get started.
              </h1>
              <p className="w-full text-center md:w-[400px] md:text-left">
                Fill out this simple form to get started. Our Business Success
                team will get back to you shortly.
              </p>
            </div>
          </div>
          <div className="w-full md:w-[50%]">
            <RegisterBusinessForm
              register={register}
              handleSubmit={handleSubmit}
              errors={errors}
            />
          </div>
        </div>
      )}
      {isSubmitting && (
        <div className="h-[80vh] w-[100vw]">
          <InProgress />
        </div>
      )}
      {isSubmitSuccessful && (
        <div className="h-[80vh] w-[100vw]">
          <FormSubmitted
            heading={"Your registration has been submitted."}
            paragraph={
              "Thank you for registering your business with Meask. We’re reviewing your information and our business success team will reach out to you shortly."
            }
            image={"/business-form-submitted.svg"}
          />
        </div>
      )}
    </div>
  );
}

export default ForBusinessFormWithHooks;
