import { ChevronLeft, MessagesSquare } from 'lucide-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const EnterAmountHeader = ({outletDetail}) => {
    const navigate = useNavigate()
  return (
    <header className="border-b">
    <div className="flex items-center justify-between px-2 py-3 md:px-20">
      <button className="p-1 md:p-2">
        <ChevronLeft
          className="h-6 w-6 text-gray-700"
          onClick={() => navigate(-1)}
        />
      </button>
      <div className="flex-1 md:ml-4">
        <h1 className="text-lg font-semibold text-[#303030]">
          {outletDetail?.NAME ?? "VILLAGE RESTAURANT"}
        </h1>
        <p className="text-xs text-[#989898]">
          {`${outletDetail?.ADDRESS_LINE_ONE}, ${outletDetail?.ADDRESS_LINE_TWO}, ${outletDetail?.DISTRICT}, ${outletDetail?.STATE}` ??
            "address not available"}
        </p>
      </div>
      <button className="flex items-center gap-2 rounded-md border-2 border-[#BDBDBD] px-2 py-1 text-[#7C7C7C]">
        <MessagesSquare className="h-4 w-4 text-[#7C7C7C] md:h-5 md:w-5" />
        <span className="text-sm">Help</span>
      </button>
    </div>
  </header>
  )
}

export default EnterAmountHeader
