import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createEventRequest,
  editEventRequest,
  editTimeSlotsRequest,
  getEventsRequest,
  getTimeSlotsRequest,
  scheduleAdsRequest,
} from "../apis/business-promotion-apis";

// Initial state
const initialState = {
  fetchingReg: false,
  fetchingEvents: false,
  fetchingDaily: false,
  regularSlots: [],
  dailySlots: [],
  eventSlots: [],
  regularError: "",
  dailyError: "",
  eventError: "",
  regAdsToSchedule: [],
  dailyAdsToSchedule: [],
  eventAdsToSchedule: [],
  eventOffers: [],
  dailyOffers: [],
};

export const getTimeSlots = createAsyncThunk(
  "outlets/getOutletDetails",
  async ({ adType, outletId, day, date }, { rejectWithValue }) => {
    try {
      return await getTimeSlotsRequest(adType, outletId, day, date);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const editTimeSlots = createAsyncThunk(
  "outlets/editTimeslots",
  async ({ adType, outletId, timeSlots, day, date }, { rejectWithValue }) => {
    try {
      return await editTimeSlotsRequest(adType, outletId, timeSlots, day, date);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const scheduleAds = createAsyncThunk(
  "outlets/scheduleAdv",
  async (reqBody, { rejectWithValue }) => {
    try {
      return await scheduleAdsRequest(reqBody);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getEvent = createAsyncThunk(
  "outlets/getEvent",
  async ({ adType, outletId }, { rejectWithValue }) => {
    try {
      return await getEventsRequest(outletId, adType);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createEvent = createAsyncThunk(
  "outlets/createEvent",
  async (reqBody, { rejectWithValue }) => {
    console.log("");
    try {
      return await createEventRequest(...reqBody);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const editEvent = createAsyncThunk(
  "outlets/editEvent",
  async ({ outletId, adType, eventBody }, { rejectWithValue }) => {
    try {
      return await editEventRequest({outletId, adType, eventBody});
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const schedulerSlice = createSlice({
  name: "scheduler",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTimeSlots.pending, (state, action) => {
      switch (action.meta.arg.adType) {
        case "R":
          return { ...state, fetchingReg: true, regularError: "" };
        case "E":
          return { ...state, fetchingEvents: true, eventError: "" };
        case "D":
          return { ...state, fetchingDaily: true, dailyError: "" };
        default:
          return state;
      }
    });

    builder.addCase(getTimeSlots.fulfilled, (state, action) => {
      switch (action.meta.arg.adType) {
        case "R":
          return {
            ...state,
            fetchingReg: false,
            regularSlots: action.payload.timeSlotRes,
            regAdsToSchedule: action.payload.advLiveAndSched,
          };
        case "E":
          return {
            ...state,
            fetchingEvents: false,
            eventSlots: action.payload.timeSlotRes,
            eventAdsToSchedule: action.payload.advLiveAndSched,
          };
        case "D":
          return {
            ...state,
            fetchingDaily: false,
            dailySlots: action.payload.timeSlotRes,
            dailyAdsToSchedule: action.payload.advLiveAndSched,
          };
        default:
          return state;
      }
    });

    builder.addCase(getTimeSlots.rejected, (state, action) => {
      switch (action.meta.arg.adType) {
        case "R":
          return { ...state, fetchingReg: false, regularError: action.payload };
        case "E":
          return {
            ...state,
            fetchingEvents: false,
            eventError: action.payload,
          };
        case "D":
          return { ...state, fetchingDaily: false, dailyError: action.payload };
        default:
          return state;
      }
    });

    //
    builder.addCase(editTimeSlots.pending, (state, action) => {
      switch (action.meta.arg.adType) {
        case "R":
          return { ...state, fetchingReg: true, regularError: "" };
        case "E":
          return { ...state, fetchingEvents: true, eventError: "" };
        case "D":
          return { ...state, fetchingDaily: true, dailyError: "" };
        default:
          return state;
      }
    });

    builder.addCase(editTimeSlots.fulfilled, (state, action) => {
      switch (action.meta.arg.adType) {
        case "R":
          return {
            ...state,
            fetchingReg: false,
            regularSlots: action.payload.timeSlotRes,
          };
        case "E":
          return {
            ...state,
            fetchingEvents: false,
            eventSlots: action.payload.timeSlotRes,
          };
        case "D":
          return {
            ...state,
            fetchingDaily: false,
            dailySlots: action.payload.timeSlotRes,
          };
        default:
          return state;
      }
    });

    builder.addCase(editTimeSlots.rejected, (state, action) => {
      switch (action.meta.arg.adType) {
        case "R":
          return { ...state, fetchingReg: false, regularError: action.payload };
        case "E":
          return {
            ...state,
            fetchingEvents: false,
            eventError: action.payload,
          };
        case "D":
          return { ...state, fetchingDaily: false, dailyError: action.payload };
        default:
          return state;
      }
    });
    //

    builder.addCase(scheduleAds.pending, (state, action) => {
      switch (action.meta.arg.adType) {
        case "R":
          return { ...state, fetchingReg: true, regularError: "" };
        case "E":
          return { ...state, fetchingEvents: true, eventError: "" };
        case "D":
          return { ...state, fetchingDaily: true, dailyError: "" };
        default:
          return state;
      }
    });

    builder.addCase(scheduleAds.fulfilled, (state, action) => {
      switch (action.meta.arg.adType) {
        case "R":
          return {
            ...state,
            fetchingReg: false,
            regularSlots: action.payload.response.timeSlotRes,
            regAdsToSchedule: action.payload.response.advLiveAndSched,
          };
        case "E":
          return {
            ...state,
            fetchingEvents: false,
            eventSlots: action.payload.response.timeSlotRes,
            eventAdsToSchedule: action.payload.response.advLiveAndSched,
          };
        case "D":
          return {
            ...state,
            fetchingDaily: false,
            dailySlots: action.payload.response.timeSlotRes,
            dailyAdsToSchedule: action.payload.response.advLiveAndSched,
          };
        default:
          return state;
      }
    });

    builder.addCase(scheduleAds.rejected, (state, action) => {
      switch (action.meta.arg.adType) {
        case "R":
          return { ...state, fetchingReg: false, regularError: action.payload };
        case "E":
          return {
            ...state,
            fetchingEvents: false,
            eventError: action.payload,
          };
        case "D":
          return { ...state, fetchingDaily: false, dailyError: action.payload };
        default:
          return state;
      }
    });

    builder.addCase(getEvent.fulfilled, (state, action) => {
      switch (action.meta.arg.adType) {
        case "D":
          return {
            ...state,
            dailyOffers: action.payload.res,
          };
        case "E":
          return {
            ...state,
            eventOffers: action.payload.res,
          };
        default:
          return state;
      }
    });

    builder.addCase(createEvent.fulfilled, (state, action) => {
      switch (action.meta.arg.adType) {
        case "D":
          return {
            ...state,
            dailyOffers: [...state.dailyOffers, action.payload.res],
          };
        case "E":
          return {
            ...state,
            eventOffers: [...state.eventOffers, action.payload.res],
          };
        default:
          return state;
      }
    });

    builder.addCase(editEvent.fulfilled, (state, action) => {
      let newEvent = action.payload.eventObj;
      switch (action.meta.arg.adType) {
        case "D":
          return {
            ...state,
            dailyOffers: [
              ...state.dailyOffers,
              {
                DAY: newEvent.day,
                ENABLED_STATUS: newEvent.enabled,
                EVENT_NAME: newEvent.name,
                EVENT_ID: newEvent.eventId,
              },
            ],
          };
        case "E":
          return {
            ...state,
            eventOffers: [
              ...state.eventOffers,
              {
                DATE: newEvent.date,
                ENABLED_STATUS: newEvent.enabled,
                EVENT_NAME: newEvent.name,
                EVENT_ID: newEvent.eventId,
              },
            ],
          };
        default:
          return state;
      }
    });
  },
});

export const { clearCoupons, clearError } = schedulerSlice.actions;
export default schedulerSlice.reducer;
