import { useEffect, useState } from "react";
import { Search, X } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import {
  getHighlightsRequest,
  scheduleHighlightsRequest,
} from "../../../../store/apis/business-promotion-apis";

export default function ScheduleAdv({ highlightAds, setAddAd, getHighlights }) {
  const outletId = useSelector((state) => state.bussinessAuth.currentOutletId);

  const [ads, setAds] = useState(highlightAds);
  const [liveAdsCount, setLiveAdsCount] = useState(0);
  const [warning, setWarning] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const count = ads.filter((ad) => ad.LIVE_STATUS === "true").length;
    setLiveAdsCount(count);
  }, [ads]);

  const handleScheduleAds = async () => {
    console.log("Schedule Ads", ads);
    const advObj = ads.map((ad) => {
      let adStatus = ad.LIVE_STATUS === "true" ? "true" : "false";
      return { advId: ad.ADV_ID, liveStatus: adStatus };
    });

    await scheduleHighlightsRequest(outletId.toString(), advObj);

    await getHighlights();

    setAddAd(false);
  };

  const [searchQuery, setSearchQuery] = useState("");

  const handleToggle = (id) => {
    setAds((prevAds) => {
      const updatedAds = prevAds.map((ad) => {
        if (ad.ADV_ID === id) {
          if (ad.LIVE_STATUS === "true") {
            return { ...ad, LIVE_STATUS: "false" };
          } else if (liveAdsCount < 3) {
            return { ...ad, LIVE_STATUS: "true" };
          } else {
            setWarning("You can only have 3 live ads at a time.");
            return ad;
          }
        }
        return ad;
      });

      const newLiveCount = updatedAds.filter(
        (ad) => ad.LIVE_STATUS === "true",
      ).length;
      setLiveAdsCount(newLiveCount);

      return updatedAds;
    });
  };

  const filteredAds = ads.filter((ad) =>
    ad.NAME.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
      <div className="relative w-7/12 rounded-lg bg-white p-6 shadow">
        {/* Header */}
        <div className="relative mb-6 flex items-center justify-center">
          <h1 className="text-xl font-semibold">Add Advertisement</h1>
          <button className="absolute right-0 text-gray-400 hover:text-gray-600">
            <X className="h-5 w-5" onClick={() => setAddAd(null)} />
          </button>
        </div>

        {/* Search */}
        <div className="full relative mb-6 flex justify-center">
          <Search className="absolute left-40 top-1/2 size-6 -translate-y-1/2 transform text-gray-400" />
          <input
            type="text"
            placeholder="Search Ads"
            className="w-2/3 rounded-md border border-gray-200 py-2 pl-10 pr-4 outline-none"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        {/* Live Ads Counter */}
        <div className="mb-2 text-right text-sm text-gray-500">
          Live Ads: {liveAdsCount}/3
        </div>

        {/* Warning Message */}
        {warning && (
          <div className="mb-2 text-center text-sm text-red-500">{warning}</div>
        )}

        {/* Table Header */}
        <div className="mb-4 grid grid-cols-[150px,1fr,auto] gap-4">
          <div className="text-sm font-medium">Date</div>
          <div className="text-sm font-medium">
            Advertisement ({filteredAds.length})
          </div>
          <div className="justify-self-end text-sm font-medium">
            Scheduled Ads
          </div>
        </div>

        {/* Ads List */}
        <div
          className="mb-6 max-h-[400px] space-y-4 overflow-y-auto"
          style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
        >
          {filteredAds.map((ad, idx) => (
            <div
              key={idx}
              className="grid grid-cols-[150px,1fr,auto] items-center gap-4"
            >
              <div className="text-sm text-gray-600">{ad.DATE}</div>
              <div className="text-sm text-gray-900">{ad.NAME}</div>
              <div className="justify-self-end">
                <button
                  className={`h-6 w-12 rounded-full transition-colors duration-200 ease-in-out ${
                    ad.LIVE_STATUS === "true" ? "bg-[#31C3E0]" : "bg-gray-200"
                  } relative ${
                    liveAdsCount >= 3 && ad.LIVE_STATUS !== "true"
                      ? "cursor-not-allowed opacity-50"
                      : "cursor-pointer"
                  }`}
                  onClick={() => handleToggle(ad.ADV_ID)}
                  disabled={liveAdsCount >= 3 && ad.LIVE_STATUS !== "true"}
                >
                  <span
                    className={`absolute left-1 top-1 h-4 w-4 rounded-full bg-white transition-transform duration-200 ease-in-out ${
                      ad.LIVE_STATUS === "true"
                        ? "translate-x-6"
                        : "translate-x-0"
                    }`}
                  />
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Footer */}
        <div className="flex justify-center">
          <button
            className="rounded-md bg-[#31C3E0] px-6 py-2 text-white transition-colors hover:bg-[#2BB1CC]"
            onClick={() => {
              handleScheduleAds();
            }}
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
}
