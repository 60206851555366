import React from "react";
import CurrentOfferCard from "./CurrentOfferCard";

const CurrentOffer = ({ advertisments }) => {
  return (
    <>
      <section
        className="h-full overflow-auto pb-7 pr-2"
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      >
        {advertisments?.map((ad, idx) => (
          <div key={idx}>
            <CurrentOfferCard
              key={ad.MASTER_OUTLET_ID}
              headline={ad.HEADLINE}
              discount={ad.DISCOUNT_PERCENT}
              description={ad.DESCRIPTION}
              liveStatus={ad.LIVE_STATUS}
              outletId={ad.MASTER_OUTLET_ID}
              displayImage={ad.PIC_URL}
              termsCondition={ad.TERMS_COND}
            />
          </div>
        ))}
      </section>
    </>
  );
};

export default CurrentOffer;
