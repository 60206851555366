import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { X } from "lucide-react";
import { addUser } from "../../store/apis/sales-apis";
import { useEffect, useState } from "react";

const formSchema = z
  .object({
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    email: z
      .string()
      .optional()
      .or(z.string().email("Please enter a valid email address")),
    designation: z.string().min(1, "Please select a designation"),
    username: z.string().min(3, "Username must be at least 3 characters"),
    password: z.string().min(6, "Password must be at least 6 characters"),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

export default function AddUser({ setAddUser, setUsers, users }) {
  const[error , setError] = useState(null)
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      designation: "",
      username: "",
      password: "",
      confirmPassword: "",
    },
    mode: "onChange",
  });

  const onClose = () => {
    reset();
    setAddUser(false);
  };
  const onSubmit = async (data) => {
    try {
      const user = await addUser({
        firstname: data.firstName,
        lastname: data.lastName,
        phoneNumber: "NA",
        username: data.username,
        pan: "NA",
        designation: data.designation,
        password: data.password,
      });
  
      // Update users array properly by spreading the existing users and adding the new one
      setUsers([...users, {
        BO_ID: user.boId,
        DESIGNATION: user.designation,
        USERNAME: user.username,
      }]);
  
      reset();
      setAddUser(false);
    } catch (error) {
      error?.response?.status === 409 ? setError("user with this username already exists") : setError("incomplete details")
      console.error("Error adding user:", error);
    }
  };



  return (
    <div className="fixed inset-0 z-30 flex items-center justify-center bg-black bg-opacity-50 p-4">
      <div className="w-[45%] rounded-3xl bg-white pb-4">
        <div className="mb-6 flex items-center justify-between p-4 shadow-sm">
          <h2 className="text-xl font-semibold text-gray-900">Add User</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X />
          </button>
        </div>
        {error && <span className="text-red-600 w-full text-center flex justify-center my-2">{error}</span>}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-6 px-6"
        >
          {/* Full Name */}
          <div className="flex items-start gap-5">
            <div className="flex flex-col gap-1">
              <div className="flex items-center gap-4">
                <label className="w-[90px] flex-shrink-0 text-sm font-medium text-gray-700">
                  First Name
                </label>
                <input
                  {...register("firstName")}
                  placeholder="First Name"
                  className="w-full rounded-md border border-gray-300 px-3 py-1.5 outline-none focus:border-blue-500"
                />
              </div>
              {errors.firstName && (
                <span className="ml-[110px] text-xs text-red-500">
                  {errors.firstName.message}
                </span>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <div className="flex items-center">
                <label className="w-[80px] flex-shrink-0 text-sm font-medium text-gray-700">
                  Last Name
                </label>
                <input
                  {...register("lastName")}
                  placeholder="Last Name"
                  className="w-full rounded-md border border-gray-300 px-3 py-1.5 outline-none focus:border-blue-500"
                />
              </div>
              {errors.lastName && (
                <span className="ml-[100px] text-xs text-red-500">
                  {errors.lastName.message}
                </span>
              )}
            </div>
          </div>

          {/* Email */}
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-4">
              <label className="w-[90px] flex-shrink-0 text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="flex-1">
                <input
                  {...register("email")}
                  type="email"
                  placeholder="name@email.com"
                  className="w-full rounded-md border border-gray-300 px-3 py-1.5 outline-none focus:border-blue-500"
                />
              </div>
            </div>
            {errors.email && (
              <span className="ml-[110px] text-xs text-red-500">
                {errors.email.message}
              </span>
            )}
          </div>

          {/* Designation */}
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-4">
              <label className="w-[90px] flex-shrink-0 text-sm font-medium text-gray-700">
                Designation
              </label>
              <div className="flex-1">
                <select
                  {...register("designation")}
                  className="w-full rounded-md border border-gray-300 px-3 py-1.5 outline-none focus:border-blue-500"
                >
                  <option value="">Select Designation</option>
                  <option value="admin">Admin</option>
                  <option value="manager">Manager</option>
                </select>
              </div>
            </div>
            {errors.designation && (
              <span className="ml-[110px] text-xs text-red-500">
                {errors.designation.message}
              </span>
            )}
          </div>

          {/* Username */}
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-4">
              <label className="w-[90px] flex-shrink-0 text-sm font-medium text-gray-700">
                Username
              </label>
              <div className="flex-1">
                <input
                  {...register("username")}
                  placeholder="Username"
                  className="w-full rounded-md border border-gray-300 px-3 py-1.5 outline-none focus:border-blue-500"
                />
              </div>
            </div>
            {errors.username && (
              <span className="ml-[110px] text-xs text-red-500">
                {errors.username.message}
              </span>
            )}
          </div>

          {/* Password */}
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-4">
              <label className="w-[90px] flex-shrink-0 text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="flex-1">
                <input
                  {...register("password")}
                  type="password"
                  placeholder="Password"
                  className="w-full rounded-md border border-gray-300 px-3 py-1.5 outline-none focus:border-blue-500"
                />
              </div>
            </div>
            {errors.password && (
              <span className="ml-[110px] text-xs text-red-500">
                {errors.password.message}
              </span>
            )}
          </div>

          {/* Confirm Password */}
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-4">
              <label className="w-[90px] flex-shrink-0 text-sm font-medium text-gray-700">
                Confirm
              </label>
              <div className="flex-1">
                <input
                  {...register("confirmPassword")}
                  type="password"
                  placeholder="Confirm Password"
                  className="w-full rounded-md border border-gray-300 px-3 py-1.5 outline-none focus:border-blue-500"
                />
              </div>
            </div>
            {errors.confirmPassword && (
              <span className="ml-[110px] text-xs text-red-500">
                {errors.confirmPassword.message}
              </span>
            )}
          </div>

          {/* Submit Button */}
          <div className="flex justify-end pt-4">
            <button
              type="submit"
              disabled={!isDirty || !isValid}
              className={`rounded-md px-6 py-1.5 ${
                !isDirty || !isValid
                  ? "bg-[#EFEFEF] text-[#BDBDBD]"
                  : "bg-gray-100 text-gray-900 hover:bg-gray-200"
              }`}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
