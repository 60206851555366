import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  sendSignUpOtpRequest,
  verifySignUpOtpRequest,
} from "../apis/auth-apis";
import Cookies from "js-cookie"
import { initializeAuth } from "./authSlice";

const initialState = {
  userData: {
    email: "",
    phoneNumber: "",
    name: "",
    address: "",
    referral: "",
  },
  otpVerified: false,
  signupComplete: false,
  loading: false,
  error: null,
  otpSent: false,
  userId: "",
};

export const sendOnboardingOtp = createAsyncThunk(
  "auth/sendOnboardingOtp",
  async (userData, { rejectWithValue }) => {
    try {
      const res = await sendSignUpOtpRequest(userData);
      console.log(res);
      return res;
    } catch (error) {
      console.log("e", error);
      return rejectWithValue(error.response.data);
    }
  },
);

export const verifyOnboardingOtp = createAsyncThunk(
  "auth/verifyOnboardingOtp",
  async ({ otp, userId }, { rejectWithValue }) => {
    try {
      const response = await verifySignUpOtpRequest({ otp, userId });
      
      // Set cookies here since this is async
      Cookies.set("authToken", response.accessToken, {
        expires: 1,
        path: "/",
        sameSite: "strict",
      });
      Cookies.set("userDetails", JSON.stringify(response.userDetails), {
        expires: 1,
        path: "/",
        sameSite: "strict",
      });
      Cookies.remove("business_auth_token");
      
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);



const signupSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUserData: (state, action) => {
      state.userData = { ...state.userData, ...action.payload };
    },
    cancelSignupOtp: (state) => {
      state.loading = false;
      state.otpSent = false;
      state.userId = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendOnboardingOtp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendOnboardingOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.otpSent = true;
        console.log("successfull");
        state.userId = action.payload["userId"];
      })
      .addCase(sendOnboardingOtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.Message;
      })
      .addCase(verifyOnboardingOtp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyOnboardingOtp.fulfilled, (state, action) => {
        console.log("Onboarding verification fulfilled");
        state.loading = false;
        state.otpVerified = true;
        state.userData = { ...state.userData, ...action.payload.userData };
      })
      .addCase(verifyOnboardingOtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.Message;
      });
  },
});

export const { updateUserData, cancelSignupOtp } = signupSlice.actions;
export default signupSlice.reducer;
