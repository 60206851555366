import React from "react";
import DashboardHeader from "../utils/dashboard-header";
import DisplayAds from "../../promotion-organizer/Ad-library/DisplayAds";
import Paginator from "../../promotion-organizer/Ad-library/Paginator";

const LiveAds = () => {

    const onPageChange = function(){

    }

  return (
    <div className="relative mt-[156px]">
      <DashboardHeader />
      <div className="h-max px-10 pt-1 pb-2">
        <div className="relative mt-20  h-full rounded-2xl border px-20  shadow-xl">
          <DisplayAds liveAds= {true}/>
        </div>
        <Paginator onPageChange={onPageChange}  />
      </div>
    </div>
  );
};

export default LiveAds;
