import { updateCoords } from "../../store/slices/locationSlice";
import { saveCookie } from "../../utils/cookie";

export const getLocation = (dispatch) => {
  console.log("Getting the current location");
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const pos = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        dispatch(updateCoords({ ...pos }));
        // saveCookie("location", JSON.stringify(pos), 1);
      },
      (err) => {
        console.log(`Error: ${err.message}`);
      },
      {
        enableHighAccuracy: true, // Request the most accurate location
        timeout: 100000, // Timeout after 100 seconds
        maximumAge: 0, // Don't use cached location
      },
    );
  } else {
    console.log("Geolocation is not supported by this browser.");
  }
};
