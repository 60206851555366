import React, { useState } from "react";
import SignUpSendOtp from "./signup-send-otp";
import VerifyOtp from "./verify-otp";
import { useSelector, useDispatch } from "react-redux";

const Signup = () => {
  const { otpSent } = useSelector((state) => state.signup);
  console.log("otp sent" , otpSent)

  return (
    <div>
      {!otpSent && <SignUpSendOtp />}
      {otpSent && <VerifyOtp />}
    </div>
  );
};

export default Signup;
