import React, { useState } from "react";

const FloatingLabelInput = ({ field, label, value, onChange, className = "h-12" }) => {
  const [focused, setFocused] = useState(false);

  return (
    <div className="relative border border-gray-100">
      <label
        className={`absolute left-2 text-sm transition-all duration-200 ${
          value || focused ? "-translate-y-1 text-gray-500" : "top-3 text-gray-500"
        }`}
      >
        {label}
      </label>
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(field, e.target.value)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        className={`w-full px-3 py-3 outline-none ${className}`}
      />
    </div>
  );
};

export default FloatingLabelInput;
