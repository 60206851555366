import React from "react";
import { ArrowRight } from "lucide-react";

function ButtonJoinNow({ title, handleClick }) {
  return (
    <button
      className="flex h-[25px] w-[90px] items-center justify-center rounded-[6px] border-[1.5px] bg-transparent  px-[5px] py-[6px] lg:px-[5px] lg:py-[12.5px] text-customWhite lg:h-[32px] lg:w-[110px] break-keep"
      onClick={handleClick}
    >
      <span className="mr-[5px] w-[70%] text-[10px]  font-semibold lg:text-xs">
        {title}
      </span>
      {/* <i className="lucide lucide-arrow-right h-[24.3px] w-[24.3px] font-semibold"></i> */}
      <ArrowRight className="h-[12px] w-[12px] stroke-[3] lg:h-[20px] lg:w-[20px]" />
    </button>
  );
}

export default ButtonJoinNow;
