import React, { useState, useCallback, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import DeleteAccount from "../components/profile/DeleteAccount";
import { useDispatch, useSelector } from "react-redux";
import {
  saveUserSettings,
  toggleSetting,
  updatePermission,
  updatePermissionAsync,
} from "../store/slices/userSettingSlice";
import { toHaveDisplayValue } from "@testing-library/jest-dom/matchers";
import { initializeAuth } from "../store/slices/authSlice";
import { readCookie } from "../utils/cookie";

const ToggleSwitch = ({ enabled, onToggle }) => (
  <button
    onClick={onToggle}
    className={`relative h-7 w-12 rounded-full transition-colors duration-200 ease-in-out md:w-14 ${
      enabled ? "bg-[#29B3D2]" : "bg-gray-200"
    }`}
  >
    <div
      className={`absolute left-1 top-1 h-5 w-5 rounded-full bg-white transition-transform duration-200 ${
        enabled ? "translate-x-5 md:translate-x-7" : "translate-x-0"
      }`}
    />
  </button>
);

const SettingsCard = ({ title, subtitle, children }) => (
  <div className="relative rounded-2xl px-5 py-5 shadow-lg">
    <div className="absolute left-0 top-6 h-10 w-1 rounded-r-2xl bg-[#0079AF]" />
    <div className="mb-4 leading-3">
      <h2 className="text-lg font-semibold text-[#303030]">{title}</h2>
      {subtitle && <span className="text-sm text-[#7C7C7C]">{subtitle}</span>}
    </div>
    {children}
  </div>
);

const SettingItem = ({ label, value, onToggle }) => (
  <div className="flex items-center justify-between">
    <span className="font-medium text-[#303030]">
      {label.replace(/([A-Z])/g, " $1").trim()}
    </span>
    <ToggleSwitch enabled={value === "true"} onToggle={onToggle} />
  </div>
);

const NotificationSettings = ({ settings, handleToggle }) => (
  <SettingsCard
    title="Notification Settings"
    subtitle="Control how and when you receive notifications"
  >
    <div className="space-y-6">
      <SettingItem
        label="Enable All"
        value={settings.ENABLE_ALL}
        onToggle={() => handleToggle("ENABLE_ALL" , "setting")}
      />
      <hr />
      <div className="space-y-4">
        <h3 className="text-lg font-medium text-[#303030]">
          Email Notifications:
        </h3>
        {[
          {
            key: "EMAIL_NEW_COUPON_ALERTS",
            label: "New Coupon Alerts",
            type: "setting",
          },
          {
            key: "EMAIL_EXPIRING_COUPONS",
            label: "Expiring Coupons",
            type: "setting",
          },
          {
            key: "EMAIL_WEEKLY_DEALS_DIGEST",
            label: "Weekly Deals Digest",
            type: "setting",
          },
        ].map(({ key, label, type }) => (
          <SettingItem
            key={key}
            label={label}
            value={settings[key]}
            onToggle={() => handleToggle(key, type)}
          />
        ))}
      </div>
      <hr />
      <div className="space-y-4">
        <h3 className="text-lg font-medium text-[#303030]">
          Push Notifications:
        </h3>
        {[
          {
            key: "PUSH_DAILY_DEAL_NOTIFICATIONS",
            label: "Daily Deal Notifications",
            type: "setting",
          },
          {
            key: "PUSH_NEARBY_OFFERS",
            label: "Nearby Offers",
            type: "setting",
          },
          {
            key: "PUSH_LAST_MINUTE_FLASH_SALES",
            label: "Last Minute Flash Sales",
            type: "setting",
          },
        ].map(({ key, label, type }) => (
          <SettingItem
            key={key}
            label={label}
            value={settings[key]}
            onToggle={() => handleToggle(key, type)}
          />
        ))}
      </div>
      <hr />
      <div className="space-y-2">
        <SettingItem
          label="SMS Alerts"
          value={settings.SMS_ALERTS}
          onToggle={() => handleToggle("SMS_ALERTS")}
        />
        <p className="w-[240px] text-sm font-medium text-[#7C7C7C] md:w-auto">
          Subscribe or unsubscribe to receive SMS messages about offers or
          updates
        </p>
      </div>
    </div>
  </SettingsCard>
);

const AppPermissions = ({ permissions, handleToggle }) => (
  <SettingsCard title="App Permissions">
    <div className="space-y-4">
      {[
        {
          key: "PERMISSION_LOCATION_ACCESS",
          label: "Location Access",
          type: "permission",
        },
        {
          key: "PERMISSION_CAMERA_ACCESS",
          label: "Camera Access",
          type: "permission",
        },
      ].map(({ key, label , type }, index, arr) => (
        <React.Fragment key={key}>
          <SettingItem
            label={label}
            value={permissions[key]}
            onToggle={() => handleToggle(key , type)}
          />
          {index !== arr.length - 1 && <hr />}
        </React.Fragment>
      ))}
    </div>
  </SettingsCard>
);

const AboutSection = () => (
  <SettingsCard title="About">
    <div className="space-y-3">
      {[{ name : "Terms and Conditions" , link:"/terms"}, {name : "Privacy Policy" , link:"/privacy"}].map((item, index) => (

        <React.Fragment key={item}>
          <Link  to={item.link} className="block font-medium text-[#303030]">
            {item.name}
          </Link>
          {index !== 2 && <hr />}
        </React.Fragment>
      ))}
    </div>
  </SettingsCard>
);

const AccountSection = ({ setDeleteAccount }) => (
  <SettingsCard title="Account">
    <button
      className="flex w-full items-center justify-between text-gray-700 hover:text-gray-900"
      onClick={() => setDeleteAccount(true)}
    >
      <span className="font-medium text-[#303030]">Delete Account</span>
      <ChevronRight />
    </button>
  </SettingsCard>
);

const ProfileSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const userData = useSelector((state) => state.auth.userData);
  const [deleteAccount, setDeleteAccount] = useState(false);

  const { settings, loading, error } = useSelector(
    (state) => state.userSettings,
  );

  const handleToggle = (key, type) => {
    console.log("key type", key, type);
    if (type === "permission") {
      dispatch(
        updatePermissionAsync(
          key.includes("CAMERA") ? "camera" : "geolocation",
        ),
      );
    } else if (type === "setting") {
      dispatch(toggleSetting(key));
    }
  };

  const handleSaveSettings = async () => {
    try {
      await dispatch(saveUserSettings(settings)).unwrap();
      navigate("/mvpdemo/profile");
    } catch (error) {
      console.error("Error saving settings:", error);
    }
  };


  useEffect(() => {
    // Check auth on mount and when userData changes
    if (!userData || !userData.NAME) {
      if(readCookie("authToken") && readCookie("userDetails")){
        dispatch(initializeAuth())
      }
      else  navigate("/mvpdemo/landing-page", { replace: true });
    }
    document.documentElement.scrollTop = 0;
  }, [userData, navigate]);


  return (
    <div className="flex flex-col justify-start gap-0 pb-10 md:w-screen md:gap-5">
      <div className="w-full gap-3 px-5 py-6 shadow-lg md:items-center md:justify-between md:px-14">
        <div
          className="flex cursor-pointer flex-row items-center gap-2 md:gap-4"
          onClick={handleSaveSettings}
        >
          <ChevronLeft className="h-6 w-6 text-[#303030]" />
          <h1 className="text-base font-bold text-[#303030] md:text-xl">
            SETTINGS
          </h1>
        </div>
      </div>
      <div className="space-y-5 p-6 md:w-6/12 md:space-y-8 md:px-20">
        {deleteAccount && (
          <DeleteAccount
            open={deleteAccount}
            onClose={() => setDeleteAccount(false)}
          />
        )}
        {/* <NotificationSettings settings={settings} handleToggle={handleToggle} /> */}
        <AppPermissions permissions={settings} handleToggle={handleToggle} />
        <AboutSection />
        <AccountSection setDeleteAccount={setDeleteAccount} />
      </div>
    </div>
  );
};

export default ProfileSettings;
