import React, { useEffect, useState } from "react";
import MeaskBussinessPartnerLogo from "../../assets/meask-bussiness-partner-logo.svg";
import { ChevronDown, ChevronUp } from "lucide-react";
import { Bell } from "lucide-react";
import SelectLocation from "./select-location/SelectLocation";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentLocation } from "../../store/slices/businessAuthSlice";
import { getTimeSlots } from "../../store/slices/businessPromoterSlice";
import { readCookie, saveCookie } from "../../utils/cookie";

const BussinessNavbar = () => {
  const { signedIn , bo_id} = useSelector((state) => state.bussinessAuth);
  const isUserLoggedIn = signedIn && bo_id ;
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(location.pathname);
  const [selectLocation, setSelectLocation] = useState(false);
  const [first, setFirst] = useState(true);
  const outletLocations = useSelector(
    (state) => state.bussinessAuth.allOutletForBusiness,
  );
  const [userCurrentLocation, setUserCurrentLocation] = useState("");

  useEffect(() => {
    if (first && outletLocations.length) {
      setFirst(false);
      setUserCurrentLocation(
        `${outletLocations[0].NAME} - ${outletLocations[0].ADDRESS_LINE_TWO}`,
      );
      dispatch(updateCurrentLocation(outletLocations[0].OUTLET_ID));
    }
  }, [outletLocations]);

  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const handleOptionClick = (loc) => {
    setUserCurrentLocation(`${loc.NAME} - ${loc.ADDRESS_LINE_TWO}`);
    dispatch(updateCurrentLocation(loc.OUTLET_ID));

    //save loc in cookies
    saveCookie("current_outlet" , JSON.stringify(loc))

    setIsOpen(false);
  };

  useEffect(() => {
    setCurrentPage(location.pathname);
    const currentOutlet = readCookie("current_outlet")
    if(currentOutlet){
      setUserCurrentLocation(`${JSON.parse(currentOutlet).NAME} - ${JSON.parse(currentOutlet).ADDRESS_LINE_TWO}`)
      dispatch(updateCurrentLocation(JSON.parse(currentOutlet).OUTLET_ID));
    }

  }, [location.pathname, isUserLoggedIn]);

  const navItems = [
    {
      name: "Promotion Organizer",
      link: "/mvpdemo/for-bussiness/promotion-organizer/regular-offers",
      slug: "promotion-organizer",
      visible: isUserLoggedIn,
    },
    {
      name: "Dashboard",
      link: "/mvpdemo/for-bussiness/dashboard/live-ads",
      slug: "dashboard",
      visible: isUserLoggedIn,
    },
    // {
    //   name: "Payments",
    //   link: "/mvpdemo/for-bussiness/payments/overdue",
    //   slug: "payments",
    //   visible: isUserLoggedIn,
    // },
    {
      name: "Account",
      link: "/mvpdemo/for-bussiness/account/gstin",
      slug: "account",
      visible: isUserLoggedIn,
    },
  ];

  return (
    <>
      <nav
        className={`absolute z-50 w-full bg-[#fcfcfc] ${currentPage.includes("for-bussiness/login") ? "bg-opacity-0 shadow-none" : "shadow-lg"}`}
      >
        <div className="mt-2 flex w-full items-center justify-between px-10">
          <div className="flex flex-1 items-center gap-10 space-x-2">
            <img
              src={MeaskBussinessPartnerLogo}
              alt="Logo"
              className={`h-full w-[144px] py-4 ${isUserLoggedIn ? "visible" : "hidden"}`}
            />

            <Link to={"/mvpdemo/landing-page"}>
              <img
                src="/me-ask-logo.svg"
                alt="Logo"
                className={`ml-7 h-full w-[144px] pb-4 pt-3 ${isUserLoggedIn ? "hidden" : "visible"}`}
              />
            </Link>

            <div
              className={`relative z-50 my-1 inline-block w-2/3 border py-2 ${isUserLoggedIn ? "visible" : "hidden"}`}
              onClick={() => setIsOpen(!isOpen)}
            >
              <span className="absolute -top-3 left-2 bg-[#fcfcfc] text-sm">
                Venue Name
              </span>
              <span className="cursor-default px-5 hover:scale-105">
                {userCurrentLocation}
              </span>
              {isOpen && (
                <div className="absolute left-0 top-[100%] z-10 w-full cursor-default border bg-white px-5 h-[500px] overflow-auto" style={{ scrollbarWidth: "none", msOverflowStyle: "none" }} >
                  {outletLocations.length > 0 &&
                    outletLocations.map((loc, idx) => (
                      <div
                        className="p-2 duration-150 hover:scale-[1.01] hover:bg-gray-100"
                        onClick={() => handleOptionClick(loc)}
                      >
                        {`${loc.NAME} - ${loc.ADDRESS_LINE_TWO}`}
                      </div>
                    ))}
                </div>
              )}

              {isOpen ? (
                <ChevronUp className="absolute right-1 top-2 z-50" />
              ) : (
                <ChevronDown className="absolute right-1 top-2 z-50" />
              )}
            </div>

            {/* Location Selector */}
            {selectLocation ? (
              <SelectLocation setSelectLocation={setSelectLocation} />
            ) : null}
          </div>
          <ul
            className={`flex items-center space-x-4 ${isUserLoggedIn ? "visible" : "hidden"}`}
          >
            {navItems.map((item) => (
              <Link to={item.link} key={item.link}>
                <li
                  className={`flex cursor-pointer items-center justify-center space-x-2 px-4 py-2 ${"text-gray-700 hover:text-gray-900"} relative flex flex-col`}
                >
                  <span
                    className={
                      currentPage.includes(item.slug)
                        ? `font-bold tracking-wide`
                        : "tracking-wide"
                    }
                  >
                    {item.name}
                  </span>

                  {currentPage.includes(item.slug) ? (
                    <hr className="absolute bottom-0 w-[80%] rounded-full bg-cyan-500 p-[2px]" />
                  ) : null}
                </li>
              </Link>
            ))}

            <li className="text-gray-700 hover:text-gray-900">
              <Bell />
            </li>
          </ul>

          {/* add login button here */}
          <div className={`mr-10 ${isUserLoggedIn ? "hidden" : "visible"}`}>
            {!currentPage.includes("for-bussiness/login") && (
              <button
                className="rounded-lg border px-5 py-2 text-gray-700 duration-100 hover:border-cyan-400 hover:text-cyan-500"
                onClick={() => navigate("/mvpdemo/for-bussiness/login")}
              >
                Login
              </button>
            )}
            {currentPage.includes("for-bussiness/login") && (
              <div className="flex items-center gap-x-5">
                <span>Don't have an account?</span>
                <button
                  className="rounded-lg border-2 border-black px-8 py-2 text-gray-700 duration-200 hover:scale-[1.01] hover:border-cyan-500 hover:text-cyan-600"
                  onClick={() => navigate("/mvpdemo/for-business-form")}
                >
                  Register Now
                </button>
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

export default BussinessNavbar;
