import React from "react";

const Card = ({ discount, className }) => {
  return (
    <div
      className={`relative flex h-full w-full flex-col items-center justify-between rounded-xl border-purple-600 py-4 shadow-lg ${className} `}
    >
      <div className="flex w-full items-center justify-between text-2xl font-semibold leading-loose tracking-widest">
        <hr className="flex-grow bg-gradient-to-r from-purple-700 via-slate-200 to-white p-[3px]" />
        FLAT
        <hr className="flex-grow bg-gradient-to-l from-purple-700 via-slate-100 to-white p-[3px]" />
      </div>

      <div className="flex items-center gap-2 text-cyan-500">
        <span className="text-9xl font-bold">{discount}</span>
        <div className="flex flex-col items-center justify-center">
          <span className="text-4xl">%</span>
          <span className="text-sm font-semibold">OFF</span>
        </div>
      </div>

      <span className="text-gray-500">On Your Total Bill</span>

      <span className="absolute bottom-0 right-1 text-xs text-gray-400">
        *T&C
      </span>
    </div>
  );
};

export default Card;
