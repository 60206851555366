import React from 'react'
import {ChevronDown, ChevronUp} from "lucide-react"

const RegularOfferHeader = ({isEditTimeOpen , setIsEditTimeOpen}) => {


  return (
    <div className='flex justify-between items-center sticky top-0 z-20 bg-white pt-4 pb-2'> 
      <h1 className='text-xl font-semibold tracking-wide'>REGULAR OFFERS</h1>

      <div className='flex gap-10 items-center'>
        <div className='flex items-center gap-1 text-zinc-500 cursor-pointer ' onClick={()=>setIsEditTimeOpen(!isEditTimeOpen)}>
            <span>Edit time slot</span>
            {!isEditTimeOpen ? <ChevronDown/> : <ChevronUp/>}

        </div>

        <button className='text-zinc-500 cursor-default '>Reset</button>
      </div>
    </div>
  )
}

export default RegularOfferHeader
