import React from "react";
import AccountHeader from "../utils/account-header";

const UserManagement = () => {
  // Dummy data for the grid
  const users = [
    { role: "Admin", username: "john.doe", venues: 5 },
    { role: "Manager", username: "sarah.smith", venues: 3 },
  ];

  return (
    <div className="mt-[156px]">
      <AccountHeader />
      <div className="mt-5 flex h-[70vh] flex-col items-center px-10 pt-1">
        <div
          className="relative h-full w-[65%] overflow-auto rounded-3xl border shadow-xl"
          style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
        >
          {/* Grid Container */}
          <div className="px-5">
            {/* Grid Header */}
            <div className="sticky top-0 border-b bg-white py-4 font-semibold text-gray-700">
              <div className="grid grid-cols-3 px-20">
                <div className="flex justify-center text-center">Role</div>
                <div className="flex justify-center text-center">Username</div>
                <div className="flex justify-center text-center">
                  No. of Venues
                </div>
              </div>
            </div>

            {/* Grid Body */}
            <div className="mt-5 px-20">
              {users.map((user, index) => (
                <div
                  key={index}
                  className="grid grid-cols-3 py-2 hover:bg-gray-50"
                >
                  <div className="flex justify-center text-center">
                    <div className="w-full">{user.role}</div>
                  </div>
                  <div className="flex justify-center text-center">
                    <div className="w-full">{user.username}</div>
                  </div>
                  <div className="flex justify-center text-center">
                    <div className="w-full">{user.venues}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
