import { ChevronLeft, Mail, MoveRight, Phone } from "lucide-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BlueAirplane from "../assets/blue-airplane.svg";
import { sendHelpEmail } from "../store/apis/regular-user-apis";

const ContactPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState("");

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName.trim())
      newErrors.firstName = "First name is required";
    if (!formData.lastName.trim()) newErrors.lastName = "Last name is required";
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\d{10,}$/.test(formData.phone.replace(/[-()\s]/g, ""))) {
      newErrors.phone = "Please enter a valid phone number";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email";
    }
    if (!formData.message.trim()) newErrors.message = "Message is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus("");

    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      console.log("submiting");
      const res = await sendHelpEmail({
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber: formData.phone,
        userEmail: formData.email,
        description: formData.message,
      });
      setSubmitStatus("success");
      setFormData({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        message: "",
      });
    } catch (error) {
      setSubmitStatus("error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const InputField = ({ name, type, placeholder, value, error, onChange }) => (
    <div>
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`w-full rounded-lg border px-2 py-2 shadow-sm md:px-4 md:py-3 ${
          error ? "border-red-500" : "border-gray-100"
        } outline-none`}
      />
      {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
    </div>
  );

  const StatusMessage = ({ status }) => {
    if (!status) return null;
    const messages = {
      success: {
        className: "bg-green-50 text-green-700",
        text: "Thank you! Your message has been sent successfully.",
      },
      error: {
        className: "bg-red-50 text-red-700",
        text: "Sorry, there was an error sending your message. Please try again.",
      },
    };
    const { className, text } = messages[status];
    return <div className={`rounded-lg ${className} p-3`}>{text}</div>;
  };

  return (
    <div className="flex flex-col justify-start gap-0 pb-10 md:h-screen md:w-screen md:gap-5">
      {/* Header */}
      <div
        className="w-full gap-3 px-5 py-6 shadow-sm md:items-center md:justify-between md:px-14"
        onClick={() => navigate(-1)}
      >
        <div className="flex cursor-pointer flex-row items-center gap-2 md:gap-4">
          <ChevronLeft className="h-6 w-6 cursor-pointer text-[#303030]" />
          <h1 className="text-base font-bold text-[#303030] md:text-xl">
            CONTACT
          </h1>
        </div>
      </div>

      {/* Main Content */}
      <div className="mx-auto max-w-7xl py-8 md:py-16 lg:px-8">
        <div className="flex flex-col gap-x-16 gap-y-8 md:grid md:grid-cols-2 md:items-center">
          {/* Order container for mobile - Form first, then Contact Info */}
          <div className="order-2 px-4 md:order-1">
            {/* Contact Info */}
            <div className="mb-5 mt-8 flex h-full flex-col justify-center space-y-5 md:mt-0 md:space-y-8">
              <div>
                <h2 className="text-2xl font-extrabold tracking-wide text-[#303030] md:text-5xl">
                  Contact Us
                </h2>
                <p className="text-[15px] font-medium leading-relaxed text-[#7C7C7C] md:mt-4 md:pr-24">
                  Email, call, or fill out the form to discover how Meask can
                  help you unlock the best deals and maximize your savings.
                </p>
              </div>
              <div className="space-y-2 md:space-y-4">
                <div className="flex items-center space-x-3">
                  <Mail className="text-[#7C7C7C]" />
                  <span className="font-medium text-[#7C7C7C]">
                    support@themeask.com
                  </span>
                </div>
                <div className="flex items-center space-x-3">
                  <Phone className="text-[#7C7C7C]" />
                  <span className="font-medium text-[#7C7C7C]">
                    0222-2024-103
                  </span>
                </div>
              </div>
              <div>
                <a
                  href="#"
                  className="font-medium text-gray-900 underline underline-offset-2"
                >
                  Browse FAQ
                </a>
              </div>
            </div>
          </div>

          {/* Contact Form */}
          <div className="relative order-1 mx-2 rounded-2xl bg-[#FCFCFC] p-6 shadow-sm md:order-2 md:p-10">
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-medium text-[#303030] md:text-2xl md:tracking-wide">
                  We'd love to hear from you!
                </h3>
                <p className="text-sm text-[#7C7C7C] md:text-base">
                  You can reach us anytime
                </p>
              </div>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                  <InputField
                    name="firstName"
                    type="text"
                    placeholder="First name"
                    value={formData.firstName}
                    error={errors.firstName}
                    onChange={handleChange}
                  />
                  <InputField
                    name="lastName"
                    type="text"
                    placeholder="Last name"
                    value={formData.lastName}
                    error={errors.lastName}
                    onChange={handleChange}
                  />
                </div>
                <InputField
                  name="phone"
                  type="tel"
                  placeholder="Phone number"
                  value={formData.phone}
                  error={errors.phone}
                  onChange={handleChange}
                />
                <InputField
                  name="email"
                  type="email"
                  placeholder="Email"
                  value={formData.email}
                  error={errors.email}
                  onChange={handleChange}
                />
                <div>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="How can we help?"
                    rows="4"
                    className={`w-full rounded-lg border px-4 py-3 ${
                      errors.message ? "border-red-500" : "border-gray-100"
                    } outline-none`}
                  />
                  {errors.message && (
                    <p className="mt-1 text-sm text-red-500">
                      {errors.message}
                    </p>
                  )}
                </div>
                <StatusMessage status={submitStatus} />
                <div className="fixed bottom-0 left-0 w-screen bg-white px-8 py-4 md:hidden">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="flex w-full items-center justify-center gap-2 rounded-md bg-[#29B3D2] py-2 font-medium text-[#FCFCFC] transition-colors duration-200 disabled:cursor-not-allowed disabled:opacity-50 md:px-6 md:text-lg"
                  >
                    {isSubmitting ? "Sending..." : "Send Message"}
                    <MoveRight className="size-8" />
                  </button>
                </div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="hidden w-full items-center justify-center gap-2 rounded-md bg-[#29B3D2] py-2 font-medium text-[#FCFCFC] transition-colors duration-200 disabled:cursor-not-allowed disabled:opacity-50 md:flex md:px-6 md:text-lg"
                >
                  {isSubmitting ? "Sending..." : "Send Message"}
                  <MoveRight className="size-8" />
                </button>
              </form>
            </div>
            <div className="absolute right-4 top-4 h-20 w-20 md:h-auto md:w-auto">
              <img src={BlueAirplane} alt="airplane" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
