import React from 'react'
import { History } from 'lucide-react';

const RecentSearch = ({search}) => {
  return (
    <div className='p-2 py-4 flex gap-5 items-center'>
       <History/>

        <div className=' flex flex-col hover:scale-x-[1.01] duration-200  lg:gap-1'>
            <span className='hover:text-cyan-500'>{search.area}</span>
            <span className='text-sm text-gray-500'> {search.city}</span>
        </div>
       <div></div>
    </div>
  )
}

export default RecentSearch
