import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserSettings, updateUserSettings } from "../apis/regular-user-apis";
import {
  fetchPermissionStatus,
  togglePermission,
} from "../../utils/browserPermission";
import { readCookie, saveCookie } from "../../utils/cookie";

const initialState = {
  settings: {
    USER_ID: "",
    ENABLE_ALL: "false",
    EMAIL_NEW_COUPON_ALERTS: "false",
    EMAIL_EXPIRING_COUPONS: "false",
    EMAIL_WEEKLY_DEALS_DIGEST: "false",
    PUSH_DAILY_DEAL_NOTIFICATIONS: "false",
    PUSH_NEARBY_OFFERS: "false",
    PUSH_LAST_MINUTE_FLASH_SALES: "false",
    SMS_ALERTS: "false",
    PERMISSION_LOCATION_ACCESS: "false",
    PERMISSION_CAMERA_ACCESS: "false",
  },
  loading: false,
  error: null,
};

// 2. Create a new async thunk to initialize permissions
export const initializePermissions = createAsyncThunk(
  "userSettings/initializePermissions",
  async () => {
    const permissions = await fetchPermissionStatus();
    return {
      PERMISSION_LOCATION_ACCESS: (
        permissions.geolocation === "granted"
      ).toString(),
      PERMISSION_CAMERA_ACCESS: (permissions.camera === "granted").toString(),
    };
  },
);

// Async thunk for fetching user settings
export const fetchUserSettings = createAsyncThunk(
  "userSettings/fetchSettings",
  async (_, { rejectWithValue }) => {
    try {
      const data = await getUserSettings();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

// Async thunk for updating user settings
export const saveUserSettings = createAsyncThunk(
  "userSettings/saveSettings",
  async (settings, { rejectWithValue }) => {
    try {
      const formattedSettings = {
        enableAll: settings.ENABLE_ALL,
        emailNewCouponAlerts: settings.EMAIL_NEW_COUPON_ALERTS,
        emailExpiringCoupons: settings.EMAIL_EXPIRING_COUPONS,
        emailWeeklyDealsDigest: settings.EMAIL_WEEKLY_DEALS_DIGEST,
        pushDailyDealNotifications: settings.PUSH_DAILY_DEAL_NOTIFICATIONS,
        pushNearbyOffers: settings.PUSH_NEARBY_OFFERS,
        pushLastMinuteFlashSales: settings.PUSH_LAST_MINUTE_FLASH_SALES,
        smsAlerts: settings.SMS_ALERTS,
        // permissionLocationAccess: settings.PERMISSION_LOCATION_ACCESS,
        // permissionCameraAccess: settings.PERMISSION_CAMERA_ACCESS,
      };
      await updateUserSettings(formattedSettings);
      return settings;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

// Async thunk to handle permission toggling
export const updatePermissionAsync = createAsyncThunk(
  "userSettings/updatePermissionAsync",
  async (type) => {
    const result = await togglePermission(type);
    return { type, result }; // Pass the type and result for reducer handling
  }
);

const userSettingsSlice = createSlice({
  name: "userSettings",
  initialState,
  reducers: {
    toggleSetting: (state, action) => {
      const key = action.payload;
      const newValue = state.settings[key] === "true" ? "false" : "true";
      state.settings[key] = newValue;

      // Handle ENABLE_ALL toggle logic
      if (key === "ENABLE_ALL") {
        const notificationKeys = [
          "EMAIL_NEW_COUPON_ALERTS",
          "EMAIL_EXPIRING_COUPONS",
          "EMAIL_WEEKLY_DEALS_DIGEST",
          "PUSH_DAILY_DEAL_NOTIFICATIONS",
          "PUSH_NEARBY_OFFERS",
          "PUSH_LAST_MINUTE_FLASH_SALES",
          "SMS_ALERTS",
        ];
        notificationKeys.forEach((notificationKey) => {
          state.settings[notificationKey] = newValue;
        });
      }
    },
    resetSettings: (state) => {
      state.settings = initialState.settings;
    },
    updatePermission: (state, action) => {
      const { type, value } = action.payload;
      if (type === "camera") {
        state.settings.PERMISSION_CAMERA_ACCESS = value.toString();
      } else if (type === "location") {
        state.settings.PERMISSION_LOCATION_ACCESS = value.toString();
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Initialize permissions cases
      .addCase(initializePermissions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(initializePermissions.fulfilled, (state, action) => {
        state.loading = false;
        //if cookkie is present  , then take the values from the cookie except the location and camera
        // if(readCookie("userSetting")){
        //   state.settings = JSON.parse(readCookie("userSetting"))
        // }
        state.settings.PERMISSION_LOCATION_ACCESS =
          action.payload.PERMISSION_LOCATION_ACCESS;
        state.settings.PERMISSION_CAMERA_ACCESS =
          action.payload.PERMISSION_CAMERA_ACCESS;
      })
      .addCase(initializePermissions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to initialize permissions";
      })
      .addCase(updatePermissionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updatePermissionAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { type, result } = action.payload;

        if (result.success) {
          if (type === "camera") {
            state.settings.PERMISSION_CAMERA_ACCESS = "true";
          } else if (type === "geolocation") {
            state.settings.PERMISSION_LOCATION_ACCESS = "true";
          }
        } else {
          console.error(result.message);
        }
      })
      .addCase(updatePermissionAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Fetch settings cases
      .addCase(fetchUserSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserSettings.fulfilled, (state, action) => {
        state.loading = false;
        // Preserve browser-based permissions
        const currentPermissions = {
          PERMISSION_LOCATION_ACCESS: state.settings.PERMISSION_LOCATION_ACCESS,
          PERMISSION_CAMERA_ACCESS: state.settings.PERMISSION_CAMERA_ACCESS,
        };

        // Update settings while keeping current permissions
        state.settings = {
          ...action.payload,
          PERMISSION_LOCATION_ACCESS:
            currentPermissions.PERMISSION_LOCATION_ACCESS,
          PERMISSION_CAMERA_ACCESS: currentPermissions.PERMISSION_CAMERA_ACCESS,
        };

        // saveCookie("userSetting" , JSON.stringify(state.settings) , 1)
      })
      .addCase(fetchUserSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Save settings cases
      .addCase(saveUserSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveUserSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.settings = action.payload;
      })
      .addCase(saveUserSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { toggleSetting, resetSettings, updatePermission } =
  userSettingsSlice.actions;
export default userSettingsSlice.reducer;
