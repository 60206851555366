import React, { useRef, useState } from "react";
import { X, Upload } from "lucide-react";

export default function SelectImage({ setImageSelect, onImageSelect }) {
  const [isDragging, setIsDragging] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFile = (file) => {
    if (file && file.size <= 15 * 1024 * 1024) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewImage(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert("File size should be less than 15MB");
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    handleFile(file);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    handleFile(file);
  };

  const handleUpload = () => {
    if (selectedFile) {
      onImageSelect(selectedFile);
      setImageSelect(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
      <div className="relative mt-16 h-[65%] w-7/12 rounded-lg bg-white p-10">
        <button className="absolute right-4 top-4">
          <X className="h-5 w-5 text-gray-500" onClick={() => setImageSelect(false)} />
        </button>

        <h2 className="mb-6 text-center text-xl font-semibold">Upload Image</h2>

        <div
          onDragOver={(e) => { e.preventDefault(); setIsDragging(true); }}
          onDragLeave={() => setIsDragging(false)}
          onDrop={handleDrop}
          onClick={() => !previewImage && fileInputRef.current.click()}
          className={`mx-auto mt-20 flex h-1/2 min-h-[200px] w-3/5 flex-col items-center justify-center gap-2 rounded-lg border-2 border-dashed p-8 ${
            isDragging ? "border-blue-500 bg-blue-50" : "border-gray-200 bg-blue-50"
          }`}
        >
          {previewImage ? (
            <div className="relative h-full w-full">
              <img src={previewImage} alt="Preview" className="h-full w-full object-contain" />
              <button 
                className="absolute right-2 top-2 rounded-full bg-white p-1"
                onClick={(e) => {
                  e.stopPropagation();
                  setPreviewImage(null);
                  setSelectedFile(null);
                }}
              >
                <X className="h-4 w-4" />
              </button>
            </div>
          ) : (
            <>
              <div className="flex h-12 w-12 items-center justify-center rounded-full bg-[#E5F7FB]">
                <Upload className="h-6 w-6 text-cyan-500" />
              </div>
              <div className="text-center">
                <p className="text-sm text-gray-600">
                  Drag & drop files or{" "}
                  <button className="text-[#31C3E0] hover:underline">Browse</button>
                </p>
                <p className="mt-1 text-xs text-gray-400">Supported formats: JPEG, PNG, PDF</p>
                <p className="text-xs text-gray-400">max: 15 MB</p>
              </div>
            </>
          )}
        </div>

        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          accept="image/*,.pdf"
          onChange={handleFileChange}
        />

        <div className="mt-16 flex w-full justify-center">
          <button 
            className={`mx-auto mt-6 w-1/2 rounded-md py-3 text-white transition-colors ${
              selectedFile ? 'bg-[#31C3E0] hover:bg-[#2BB1CC]' : 'bg-gray-400 cursor-not-allowed'
            }`}
            onClick={handleUpload}
            disabled={!selectedFile}
          >
            Upload File
          </button>
        </div>
      </div>
    </div>
  );
}