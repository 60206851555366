import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCoupons } from "../apis/business-dashboard-api";

const initialState = {
  fetchingCoupons: false,
  fetchingLiveAds: false,
  fetchingRevenue: false,
  fetchingSettlementReport: false,
  fetchingTickets: false,
  couponError: "",
  liveAdsError: "",
  revenueError: "",
  settlementReportError: "",
  ticketsError: "",
  liveAds: [],
  coupons: [],
  revenue: [],
  settlementReport: [],
  tickets: [],
};

// Async thunk to fetch business coupons
export const getBusinessCoupons = createAsyncThunk(
  "dashboard/getBusinessCoupons",
  async (_, { rejectWithValue }) => {
    console.log("Fetching coupons...");
    try {
      const data = await getCoupons();
      return data;
    } catch (error) {
      console.error("Error fetching coupons:", error);
      return rejectWithValue(
        error?.response?.data || "An error occurred while fetching coupons."
      );
    }
  }
);

const businessDashboardSlice = createSlice({
  name: "businessDashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle pending state
      .addCase(getBusinessCoupons.pending, (state) => {
        state.fetchingCoupons = true;
        state.couponError = "";
      })
      // Handle fulfilled state
      .addCase(getBusinessCoupons.fulfilled, (state, action) => {
        console.log("Coupons fetched successfully:", action.payload);
        state.coupons = action.payload;
        state.fetchingCoupons = false;
        state.couponError = "";
      })
      // Handle rejected state
      .addCase(getBusinessCoupons.rejected, (state, action) => {
        console.error("Failed to fetch coupons:", action.payload);
        state.fetchingCoupons = false;
        state.couponError = action.payload;
      });
  },
});

export default businessDashboardSlice.reducer;
