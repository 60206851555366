import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Copy, Check } from "lucide-react";
import DashboardHeader from "../utils/dashboard-header";
import Paginator from "../../promotion-organizer/Ad-library/Paginator";

const CopyableCell = ({ value }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="flex items-center gap-2">
      <span className="font-mono">{value}</span>
      <Tooltip title={copied ? "Copied!" : "Copy"}>
        <IconButton size="small" onClick={handleCopy}>
          <Copy className="h-4 w-4" />
        </IconButton>
      </Tooltip>
    </div>
  );
};

const SettlementReport = () => {
  const transactionData = [
    {
      id: 1,
      date: "16/10/2024",
      amountReceivable: "₹ 1,770",
      transactionDetails: "T22041823095207378169187",
      settlementDetails: "ch_1CwA6UKc7B17XuoXT5VrVs",
      status: "Success",
    },
    {
      id: 2,
      date: "16/10/2024",
      amountReceivable: "₹ 20,000",
      transactionDetails: "T22041823095207378169187",
      settlementDetails: "ch_1CwA6UKc7B17XuoXT5VrVs",
      status: "Success",
    },
    {
      id: 3,
      date: "16/10/2024",
      amountReceivable: "₹ 1,770",
      transactionDetails: "T22041823095207378169187",
      settlementDetails: "ch_1CwA6UKc7B17XuoXT5VrVs",
      status: "Failed",
    },
    {
      id: 4,
      date: "16/10/2024",
      amountReceivable: "₹ 624",
      transactionDetails: "T22041823095207378169187",
      settlementDetails: "ch_1CwA6UKc7B17XuoXT5VrVs",
      status: "Pending",
    },
    {
      id: 5,
      date: "16/10/2024",
      amountReceivable: "₹ 1,770",
      transactionDetails: "T22041823095207378169187",
      settlementDetails: "ch_1CwA6UKc7B17XuoXT5VrVs",
      status: "Success",
    },
    {
      id: 6,
      date: "16/10/2024",
      amountReceivable: "₹ 20,000",
      transactionDetails: "T22041823095207378169187",
      settlementDetails: "ch_1CwA6UKc7B17XuoXT5VrVs",
      status: "Success",
    },
    {
      id: 7,
      date: "16/10/2024",
      amountReceivable: "₹ 1,770",
      transactionDetails: "T22041823095207378169187",
      settlementDetails: "ch_1CwA6UKc7B17XuoXT5VrVs",
      status: "Failed",
    },
    {
      id: 8,
      date: "16/10/2024",
      amountReceivable: "₹ 624",
      transactionDetails: "T22041823095207378169187",
      settlementDetails: "ch_1CwA6UKc7B17XuoXT5VrVs",
      status: "Pending",
    },
    {
      id: 9,
      date: "16/10/2024",
      amountReceivable: "₹ 6,569",
      transactionDetails: "T22041823095207378169187",
      settlementDetails: "ch_1CwA6UKc7B17XuoXT5VrVs",
      status: "Success",
    },
    {
      id: 10,
      date: "16/10/2024",
      amountReceivable: "₹ 6,569",
      transactionDetails: "T22041823095207378169187",
      settlementDetails: "ch_1CwA6UKc7B17XuoXT5VrVs",
      status: "Success",
    },
    // Add more rows as needed
  ];

  const onPageChange = () => {};

  return (
    <div className="relative mt-[156px]">
      <DashboardHeader />
      <div className="h-max px-10 pb-2 pt-1">
        <div className="relative mt-20 h-full rounded-2xl border px-20 pt-5 shadow-xl">
          <TableContainer
            sx={{ boxShadow: "none", border: "none", width: "100%" }}
          >
            <Table
              sx={{
                minWidth: 700,
                width: "100%",
                "& .MuiTableHead-root .MuiTableRow-root": {
                  borderBottom: "1px solid #ddd",
                },
                "& .MuiTableBody-root .MuiTableRow-root": {
                  borderBottom: "none",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      textAlign: "left",
                      fontSize: "16px",
                      paddingLeft: "16px",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      textAlign: "left",
                      fontSize: "16px",
                      paddingLeft: "16px",
                    }}
                  >
                    Amount Receivable
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      textAlign: "left",
                      fontSize: "16px",
                      paddingLeft: "16px",
                    }}
                  >
                    Transaction Details
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      textAlign: "left",
                      fontSize: "16px",
                      paddingLeft: "16px",
                    }}
                  >
                    Settlement Details
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      textAlign: "left",
                      fontSize: "16px",
                      paddingLeft: "16px",
                    }}
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionData.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "& td, & th": {
                        borderBottom: "none",
                        textAlign: "left",
                        fontWeight: "",
                        color: "black",
                        paddingLeft: "16px",
                        fontSize: "16px", // Increase font size for body cells
                      },
                    }}
                  >
                    <TableCell>{row.date}</TableCell>
                    <TableCell>{row.amountReceivable}</TableCell>
                    <TableCell>
                      <CopyableCell value={row.transactionDetails} />
                    </TableCell>
                    <TableCell>
                      <CopyableCell value={row.settlementDetails} />
                    </TableCell>
                    <TableCell>{row.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Paginator onPageChange={onPageChange} />
      </div>
    </div>
  );
};

export default SettlementReport;
